import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'
import {useEffect} from 'react'
import MicSelect from '../Haircheck/MicSelect'
import SpeakerSelect from '../Haircheck/SpeakerSelect'
import {useDaily} from '@daily-co/daily-react'
import AnimatedAudioLine from '../Audio/AnimatedAudioLine'

export default function AudioSettings() {
    const {updateDeviceState} = useMediaDevices()
    const daily = useDaily()!
    const {isMicMuted} = useMediaDevices()

    useEffect(() => {}, [])

    const toggleMic = () => {
        daily.setLocalAudio(isMicMuted)
        updateDeviceState()
    }

    return (
        <div>
            <MicSelect isMicMuted={isMicMuted} onClick={toggleMic} />
            <SpeakerSelect />
            <h5 className="font-bold mt-3 mb-2">Test Audio:</h5>
            <AnimatedAudioLine isOnLobby={false} />
        </div>
    )
}
