import {useEffect, useState} from 'react'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {useAppSelector} from '~/store'
import {Play as PlayIcon} from '@styled-icons/fa-solid/Play'
import {toast} from 'react-toastify'
import {breakoutTimeOptions} from '~/utils/breakoutTimeOptions'
import {Menu} from '@headlessui/react'
import StopBreakoutMenu from '../../Common/StopBreakoutMenu'
import ControlCenter from '../../ControlCenter/ControlCenter'

import LoadingSvg from '~/assets/svgs/loading.svg'
import classNames from 'classnames'

export interface PopoupListAction {
    text: string
    onClick?: () => void
    title?: string
    color?: string
    icon?: any
    disabled?: boolean
    popupActions?: PopoupListAction[]
}

export default function HostTray() {
    const {startBreakout, stopBreakout} = useAmplifierSocket()
    const {updateRoomSettings} = useAmplifierSocket()
    const hostSettings = useAppSelector(({amplifierSocket}) => amplifierSocket.hostSettings)

    const [controlCenterOpen, setControlCenterOpen] = useState(false)

    const [startingBreakouts, setStartingBreakouts] = useState(false)

    const {
        breakout: {state},
        allParticipantIds,
        localParticipant,
        homeRoomMode,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    useEffect(() => {
        if (startingBreakouts && state === 'intermission') setStartingBreakouts(false)
    }, [state])

    // TODO: Add back muteAll functionality
    // const muteAll = () => {
    //     if (!isOwner) return
    //     Object.values(allParticipants).forEach(participant => {
    //         if (!participant.isHost) {
    //             callObject.updateParticipant(participant.id, {
    //                 setAudio: false,
    //             })
    //         }
    //     })
    //     toast.info(`You've Muted all non-hosts`, {containerId: 'All'})
    // }

    const returnPopupActions: PopoupListAction[] = [
        {
            text: 'End after this round',
            onClick: () => stopBreakout(),
        },
        {
            text: 'End Now',
            onClick: () => stopBreakout(true),
            color: 'error.main',
        },
    ]

    const handleBreakoutTimeChange = (newTime: number) => {
        updateRoomSettings({
            hostSettings: {
                breakoutMode: hostSettings?.breakoutMode ?? '1:1',
                breakoutGroupSize: hostSettings?.breakoutGroupSize ?? 2,
                breakoutTime: newTime,
            },
            homeRoomMode: homeRoomMode,
        })
    }

    const startBreakouts = () => {
        if (allParticipantIds.length < 3) {
            toast.info('Breakout rounds require 3 or more participants', {containerId: 'All'})
            return false
        }

        setStartingBreakouts(true)

        startBreakout()
    }

    return (
        <div className="flex flex-row ml-auto">
            <div className="flex h-16 justify-center items-center">
                <select
                    className="select select-neutral w-full max-w-xs"
                    value={hostSettings?.breakoutTime}
                    onChange={e => handleBreakoutTimeChange(parseFloat(e.target.value + ''))}
                >
                    <option disabled>Select a Breakout Length</option>
                    {breakoutTimeOptions.map((minutes, index) => (
                        <option key={index} value={minutes}>
                            {minutes % 1 === 0 ? minutes + ' minutes' : minutes * 60 + ' seconds'}
                        </option>
                    ))}
                </select>
            </div>

            {state === 'normal' && (
                <div className="flex h-16 ml-2 justify-center items-center">
                    <button
                        type="submit"
                        className="btn btn-accent"
                        disabled={startingBreakouts}
                        onClick={startBreakouts}
                    >
                        {!startingBreakouts && <PlayIcon className="mr-3" size="0.8em" />}
                        {startingBreakouts && (
                            <LoadingSvg
                                className={classNames('mr-3', !startingBreakouts && 'hidden')}
                                height="1em"
                                width="1em"
                            />
                        )}
                        Start Breakouts
                    </button>
                </div>
            )}

            {state !== 'normal' && <StopBreakoutMenu returnPopupActions={returnPopupActions} />}

            {/* <TrayButton disabled={disabled} label="Host Abilities" popupActions={hostPopupActions}>
                <BreakoutSettingsIcon size="1.7em" />
            </TrayButton> */}

            <Menu as="div" className="relative text-left flex h-16 ml-2 justify-center items-center">
                <div>
                    <Menu.Button className="btn btn-active" onClick={() => setControlCenterOpen(true)}>
                        Control Center
                    </Menu.Button>
                </div>
            </Menu>

            {localParticipant.isHost && (
                <ControlCenter isOpen={controlCenterOpen} onClose={() => setControlCenterOpen(false)} />
            )}
        </div>
    )
}
