import React from 'react'
import cn from 'classnames'
import {Draggable, DraggableProvided} from 'react-beautiful-dnd'
import {areEqual} from 'react-window'
import {RowItem} from '../../../../hooks/useParticipantItems'
import ParticipantListItem from './ParticipantListItem'
import {DragDrop as DragIcon} from '@styled-icons/remix-line/DragDrop'

interface ItemProps {
    provided: DraggableProvided
    rowItem: RowItem
    style?: React.CSSProperties
    isDragging: boolean
    isDropping: boolean
    isFavourite: boolean
}
export function Item({provided, rowItem, style, isDragging, isDropping}: ItemProps) {
    const item = React.useMemo(() => {
        return (
            <ParticipantListItem
                id={rowItem.participant.id}
                enableActions={!rowItem.isMatched && (!rowItem.group || rowItem.isOppositeGroup)}
            />
        )
    }, [])

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={getStyle(provided, style, isDragging && !isDropping)}
            className={cn('dragListItem', {dragging: isDragging, dropping: isDropping})}
        >
            {item}
        </div>
    )
}

// Draggable version of Item
// Recommended react-window performance optimisation: memoize the row render function
// Copied from a tutorial. React.memo looks cool
export const DraggableItem = React.memo(
    ({data, index, style}: {data: RowItem[]; index: number; style: React.CSSProperties}) => {
        const item = data[index]

        return (
            <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided, {isDragging, isDropAnimating}) => (
                    <Item
                        provided={provided}
                        rowItem={item}
                        style={style}
                        isDragging={isDragging}
                        isDropping={isDropAnimating}
                        isFavourite={false}
                    />
                )}
            </Draggable>
        )
    },
    areEqual,
)

interface PlaceHolderItemProps {
    isFavouritesEmpty: boolean
}
export const PlaceholderItem = ({isFavouritesEmpty}: PlaceHolderItemProps) => {
    return (
        <div className={cn('dragListPlaceholder', {isFavouritesEmpty})}>
            {isFavouritesEmpty ? (
                <>
                    <DragIcon size="1.4rem" />
                    Drag people here to boost your <br /> chances of getting matched
                </>
            ) : (
                'Nice! Add more people to favourites'
            )}
        </div>
    )
}

function getStyle(provided: DraggableProvided, style?: React.CSSProperties, isDragging = false) {
    const combined = {
        ...style,
        ...provided.draggableProps.style,
        // height: 67,
    }

    const withSpacing = {
        ...combined,
        height: isDragging ? (combined.height as number) + 8 : combined.height,
    }
    return withSpacing
}
