import cn from 'classnames'
import {Combobox} from '@headlessui/react'
import {ChevronDown as ChevronDownIcon} from '@styled-icons/boxicons-regular/ChevronDown'

interface DropdownInputProps<T> {
    options: T[]
    newValue: string
    newValueChange: (newValue: string) => void
    optionSubmit: (newOption: T | string) => T | void
    renderOptionInList: (option: T) => React.ReactNode
    placeholder?: string
    errorText?: string
}
export default function DropdownInput({
    newValue,
    newValueChange,
    optionSubmit,
    options,
    placeholder,
    renderOptionInList,
    errorText,
}: DropdownInputProps<any>) {
    return (
        <Combobox as="div" value={newValue} onChange={optionSubmit}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="input input-bordered w-full focus:outline-none"
                    placeholder={placeholder}
                    onChange={e => newValueChange(e.target.value)}
                    displayValue={() => newValue}
                    name="hashtags"
                    type={undefined}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option, index) => (
                        <Combobox.Option
                            key={index}
                            value={option}
                            className={cn('relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900')}
                        >
                            <span className={cn('block truncate')}>
                                {typeof option == 'string' ? option : renderOptionInList(option)}
                            </span>
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </div>
            {errorText ? <p className="text-sm text-error">{errorText}</p> : null}
        </Combobox>
    )
}
