import clsx from 'clsx'

export default function Skeleton({className = '', fullWidth = false, square = false}) {
    return (
        <div role="status" className={clsx(className, fullWidth ? '' : 'max-w-sm', 'animate-pulse')}>
            <div
                className={`h-full bg-gray-200 ${square ? 'rounded-lg' : 'rounded-full'} dark:bg-gray-200 w-full mb-4`}
            ></div>
        </div>
    )
}
