import {Fragment} from 'react'
import clsx from 'clsx'
import {Dialog, Transition} from '@headlessui/react'
import {Home as HomeIcon, X as XMarkIcon, Cog as SettingsIcon} from '@styled-icons/heroicons-outline'
import {PersonNote as ProfileIcon} from '@styled-icons/fluentui-system-filled/PersonNote'
import ProfileMenu from '~/components/common/ProfileMenu/ProfileMenu'
import {PeopleCommunity as CommunityIcon} from '@styled-icons/fluentui-system-filled/PeopleCommunity'
import {PersonSettings as ProfileSettingsIcon} from '@styled-icons/fluentui-system-filled/PersonSettings'
import {Users as ConnectionsIcon} from '@styled-icons/fa-solid/Users'
import {Link, useLocation} from 'react-router-dom'
import styles from './Navigation.module.scss'

const NavigationItem: React.FC<{
    name: string
    href: string
    icon: typeof CommunityIcon
}> = props => {
    const location = useLocation()
    const current = location.pathname.includes(props.href)
    return (
        <Link to={props.href} className={clsx(styles.navItem, current ? styles.active : null)}>
            <props.icon
                className={clsx(
                    current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-4 h-6 w-6 flex-shrink-0',
                )}
                aria-hidden="true"
            />
            {props.name}
        </Link>
    )
}
export default function Navigation({
    sidebarOpen,
    setSidebarOpen,
}: {
    sidebarOpen: boolean
    setSidebarOpen: (open: boolean) => void
}) {
    const navigationInner = () => {
        return (
            <>
                <div className={styles.dashboardNavItems}>
                    <NavigationItem name="Your Profile" href="/dashboard/profile" icon={ProfileIcon} />
                    <NavigationItem name="Past connections" href="/dashboard/connections" icon={ConnectionsIcon} />
                    <NavigationItem name="Profile settings" href="/dashboard/settings" icon={ProfileSettingsIcon} />
                    <div className={styles.seperator}>Host</div>
                    <NavigationItem name="Events" href="/dashboard/events" icon={HomeIcon} />
                    <NavigationItem name="Settings" href="/dashboard/host-settings" icon={SettingsIcon} />
                </div>
                <ProfileMenu
                    theme="transparent"
                    topOption="join"
                    direction="up"
                    className="relative w-64 mb-2 bg-gray-100 text-black"
                />
            </>
        )
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className={styles.dashboardMobileNav} onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className={styles.contentOverlay} />
                    </Transition.Child>

                    <div className={styles.navContent}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className={styles.navPanel}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className={styles.closeWrapper}>
                                        <button type="button" onClick={() => setSidebarOpen(false)}>
                                            <span>Close sidebar</span>
                                            <XMarkIcon aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>

                                {navigationInner()}
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className={styles.dashboardDesktopNav}>
                <div className={styles.navPanel}>{navigationInner()}</div>
            </div>
        </>
    )
}
