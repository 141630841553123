import {DateTime, DurationLikeObject, DurationUnit} from 'luxon'

const units: DurationUnit[] = ['year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', 'millisecond']

// Format any duration to a human readable string eg. "1 hour, 12 minutes"
export const dateTimeToHuman = (
    dateTime: DateTime,
    nextDateTime: DateTime,
    largestPrecision: DurationUnit,
    smallestPrecision: DurationUnit,
) => {
    const unitIndex = units.indexOf(largestPrecision)
    const lastUnitIndex = units.indexOf(smallestPrecision)

    const largeUnits = units.slice(unitIndex - 1, units.length)
    const smallUnits = units.slice(lastUnitIndex + 1, units.length)

    const unitsToClear: DurationLikeObject = {}

    for (const unit of smallUnits) {
        unitsToClear[unit] = 0
    }

    const diffString = dateTime
        .diff(nextDateTime)
        .shiftTo(...largeUnits)
        .set(unitsToClear)
        .shiftTo()
        .rescale()
        .toHuman()

    return diffString
}
