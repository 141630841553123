import {EventViewModel} from 'shared-types/view-models'
import {ExternalLinkOutline} from '@styled-icons/evaicons-outline/ExternalLinkOutline'

import './Events.scss'
import {Link} from 'react-router-dom'

export default function EventLineItem({key, event}: {key: string; event: EventViewModel}) {
    return (
        <li className="event__lineitem" key={key}>
            <div className="event__lineitem__content">
                <div className="event__lineitem__container">
                    <div className="truncate">
                        <div className="event__titlelink">
                            <Link
                                to={`/dashboard/events/${event.code}/details`}
                                className="truncate font-medium text-gray-900"
                            >
                                {event.title}
                            </Link>
                        </div>
                        <div className="event__lineitem__roomlink">
                            <a target="_blank" href={`/?room=${event.code}`} rel="noreferrer">
                                <p>
                                    {window.location.host}/?room={event.code}
                                </p>
                                <ExternalLinkOutline className="h-3 w-3 text-gray-400" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="">
                    {event.settings.enableAtoB ? (
                        <div className="event__type ab-mode">A:B Mode</div>
                    ) : (
                        <div className="event__type onetoone-mode">1:1 Mode</div>
                    )}
                </div>
                <div className="ml-5 flex-shrink-0"></div>
            </div>
        </li>
    )
}
