import React, {useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import './ChatInput.scss'
import RecipientSelect from './RecipientSelect/RecipientSelect'

interface ChatInputProps {
    sendMessage: (message: string, recipient?: string) => void
    recipientOverride?: string
}
export default function ChatInput({sendMessage, recipientOverride}: ChatInputProps) {
    const [valueText, setValueText] = useState('')
    const [recipient, setRecipient] = useState<string>()

    const handleSubmit = (e: React.FormEvent) => {
        e?.preventDefault()
        if (valueText.trim() === '') {
            return
        }
        sendMessage(valueText.trim(), recipient)
        setValueText('')
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValueText(event.target.value)
    }

    return (
        <div className="chatWrapper">
            <div className="controlRow">
                <RecipientSelect recipientOverride={recipientOverride} setRecipient={setRecipient} />
            </div>
            <form className="chatInputWrap" onSubmit={handleSubmit}>
                <MultilineInput
                    placeholder="Enter a message..."
                    maxHeight={145}
                    minHeight={42}
                    onValueChange={handleChange}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && !e.shiftKey) handleSubmit(e)
                    }}
                    value={valueText}
                />
                <button type="submit" className="btn btn-primary">
                    Send
                </button>
            </form>
        </div>
    )
}

export interface MultilineInputProps {
    value: string
    onValueChange: React.ChangeEventHandler<HTMLTextAreaElement>
    maxHeight?: number
    minHeight?: number
    placeholder?: string
}
const MultilineInput: React.FC<MultilineInputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
    value,
    onValueChange,
    minHeight = 25,
    maxHeight = 200,
    placeholder,
    ...props
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null as any)
    useEffect(() => {
        if (value.length === 0) {
            // Reset the input height after input submit
            const _event = {
                target: inputRef.current,
            }
            onChangeEvent(_event as any)
        }
    }, [value])

    const onChangeEvent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onValueChange(e)

        // Change input height
        if (e.target.style.height !== minHeight + 'px') {
            e.target.style.height = minHeight + 'px'
        }

        let height = maxHeight + 'px'
        if (e.target.scrollHeight <= maxHeight) {
            height = e.target.scrollHeight + 'px'
        }

        if (e.target.style.height !== height) {
            e.target.style.height = height
        }
    }

    return (
        <textarea
            ref={inputRef}
            className={cn('chatInput')}
            placeholder={placeholder}
            onChange={onChangeEvent}
            wrap="hard"
            value={value}
            {...props}
        />
    )
}
