import {useMemo} from 'react'
import {shallowEqual} from 'react-redux'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import {useAppSelector} from '../store'

export interface RowItem {
    type: 'solo'
    id: string
    group?: string
    isOppositeGroup: boolean
    isMatched: boolean
    participant: ParticipantViewModel
}

// Typescript-safe hook for wrapping all participants to a list of solo & team participants
const useParticipantItems = (participantIds: string[]): RowItem[] => {
    const localId = useAppSelector(({amplifierSocket}) => amplifierSocket.localId)
    const {allParticipants, localParticipant} = useAppSelector(
        ({amplifierSocket}) => ({
            allParticipants: amplifierSocket.allParticipants,
            localParticipant: amplifierSocket.localParticipant,
        }),
        shallowEqual,
    )

    return useMemo(() => {
        const participants = participantIds
            .filter(p => p !== localId)
            .map(id => allParticipants[id])
            .filter(p => p !== undefined)

        return participants.map<RowItem>(p => {
            return {
                type: 'solo',
                id: p.id,
                participant: p,
                group: p.group,
                isOppositeGroup: localParticipant.group ? localParticipant.group !== p.group : true,
                isMatched: localParticipant.matchedIds.includes(p.id),
            }
        })
    }, [participantIds, allParticipants])
}

export default useParticipantItems
