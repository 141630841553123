import './TrayButton.scss'
import cn from 'classnames'

interface TrayButtonProps {
    children: React.ReactNode
    onClick: () => void
    backgroundColor?: string
    highlighted?: boolean
    disabled?: boolean
    tooltip?: string
    id?: string
}
export default function TrayButton({
    backgroundColor = '',
    highlighted = false,
    disabled = false,
    onClick,
    children,
    tooltip,
    id,
}: TrayButtonProps) {
    return (
        <div className="tooltip ml-2 mr-2" data-tip={tooltip}>
            <button
                disabled={disabled}
                id={id}
                onClick={e => {
                    e.preventDefault()
                    onClick()
                }}
                className={cn(highlighted ? 'btn-error' : 'btn-active', '  trayButton btn-circle min-h-6')}
                style={{backgroundColor: backgroundColor}}
            >
                {children}
            </button>
        </div>
    )
}
