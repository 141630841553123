import {useRef} from 'react'
import cn from 'classnames'
import {Star as StarIcon} from '@styled-icons/bootstrap/Star'
import {StarFill as StarFilledIcon} from '@styled-icons/bootstrap/StarFill'
import './ParticipantListItem.scss'
import {useAppDispatch, useAppSelector} from '../../../../store'
import actions from '../../../../store/amplifierSocketState'
import HostControls, {HostControlsHandleProps} from '../../Common/HostControls'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import Pill from '~/widgets/Pill'

interface ParticipantListItemProps {
    id: string
    enableActions?: boolean
}
export default function ParticipantListItem({id, enableActions}: ParticipantListItemProps) {
    const dispatch = useAppDispatch()
    const participant = useAppSelector(({amplifierSocket}) => amplifierSocket.allParticipants[id])
    const favouriteIds = useAppSelector(({amplifierSocket}) => amplifierSocket.favouriteIds)
    const hostControlsRef = useRef<HostControlsHandleProps>(null)

    const event = useAppSelector(state => state.eventState.event!)

    if (!participant) return <></>

    const toggleFavourites = () => {
        if (favouriteIds.includes(id)) {
            // Remove from favourites
            dispatch(actions.RemoveFromFavourites(id))
            return
        }
        // Add to favourites
        dispatch(actions.SetFavouriteIds([...favouriteIds, id]))
    }

    const isFavourite = favouriteIds.includes(id)
    let groupLabel: string | undefined = undefined

    if (participant.group && event.settings.enableAtoB) {
        if (participant.group === 'A' && event.settings.groupOneLabel) {
            groupLabel = event.settings.groupOneLabel
        }
        if (participant.group === 'B' && event.settings.groupTwoLabel) {
            groupLabel = event.settings.groupTwoLabel
        }
    }

    return (
        <div
            className={cn('participantListItem', {isFavourite: isFavourite})}
            onContextMenu={event =>
                hostControlsRef.current!.canOpenControls() ? hostControlsRef.current!.openControls(event) : null
            }
        >
            <AmplifierAvatar src={participant.picture} name={participant.name} size="xs" />
            <div className="overflow-x-hidden">
                <h4>{participant.name}</h4>
                <p>
                    {participant.title} at {participant.company}
                </p>
                <p>
                    {groupLabel && <span className="groupTag">{groupLabel}</span>}
                    <div className="flex flex-row gap-1">
                        {participant.hashtags.map(tag => {
                            if (tag == null || tag === undefined || tag?.name == null) return null
                            return (
                                <Pill size="sm" key={tag.id}>
                                    #{tag.name}
                                </Pill>
                            )
                        })}
                    </div>
                </p>
            </div>
            {enableActions && (
                <div className="actions">
                    <button type="button" className="btn text-primary btn-ghost btn-circle" onClick={toggleFavourites}>
                        {isFavourite ? (
                            <StarFilledIcon size="1.7em" color="#FFFFFFB3" />
                        ) : (
                            <StarIcon size="1.7em" color="#FFFFFFB3" />
                        )}
                    </button>
                </div>
            )}
            <HostControls participantId={id} ref={hostControlsRef} />
        </div>
    )
}
