import {trpc} from '~/api/trpc'
import Loading from '../common/Loading'
import styles from './Admin.module.scss'
import {dateTimeToHuman} from '~/utils/dateTimeToHuman'
import {DateTime} from 'luxon'

const AdminIndex = () => {
    const adminHomeQuery = trpc.admin.homeData.useQuery()

    if (adminHomeQuery.isLoading) {
        return <Loading />
    }
    if (adminHomeQuery.isError) {
        return <span className="text-error">{adminHomeQuery.error.message}</span>
    }

    const homeData = adminHomeQuery.data

    return (
        <>
            <div className={styles.title}>Home</div>
            <div className="grid grid-cols-2 gap-8">
                <div className={styles.analyticsCard}>
                    <span>Total User count</span>
                    <h3>{homeData.userCount}</h3>
                </div>
                <div className={styles.analyticsCard}>
                    <span>Recent events</span>
                    <table>
                        <thead>
                            <tr>
                                <td>Event code</td>
                                <td>Attendees</td>
                                <td>Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            {homeData.recentEvents.map(event => (
                                <tr>
                                    <td>{event.Event.code}</td>
                                    <td>{event._count.EventSessionParticipant}</td>
                                    <td>
                                        {event.startDate.toDateString()}{' '}
                                        <span className="font-light">
                                            {dateTimeToHuman(
                                                DateTime.now(),
                                                DateTime.fromJSDate(event.startDate),
                                                'month',
                                                'hour',
                                            )}{' '}
                                            ago
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default AdminIndex
