import {DateTime} from 'luxon'
import {InvoiceViewModel} from 'shared-types/view-models/EventModels'
import {ExternalLinkOutline} from '@styled-icons/evaicons-outline/ExternalLinkOutline'
import Skeleton from '~/components/common/Skeleton'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export default function InvoiceTable({invoices, loading}: {invoices: InvoiceViewModel[]; loading: boolean}) {
    return (
        <table className="min-w-full divide-y   divide-gray-200">
            <thead className="">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Invoice
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Status
                    </th>

                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-gray-900">
                        Amount
                    </th>
                    {/*
                                  `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                */}
                    <th scope="col" className="relative px-6 py-3 text-left text-sm font-medium text-gray-500">
                        <span className="sr-only">View receipt</span>
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {loading && (
                    <tr>
                        <td colSpan={5} className="px-6 py-4 text-sm text-gray-500">
                            <Skeleton className="w-full h-4" />
                        </td>
                    </tr>
                )}

                {!loading &&
                    invoices.map(payment => (
                        <tr key={payment.id}>
                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                {/* <time dateTime={payment.created}>{payment.created}</time> */}
                                {DateTime.fromSeconds(payment.created).toLocaleString(DateTime.DATE_MED)}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">#{payment.number}</td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {payment.status.toLocaleUpperCase()}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {formatter.format(payment.amount_due / 100)}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                                <div className="external__link">
                                    <a target="_blank" href={payment.hosted_invoice_url} rel="noreferrer">
                                        <p className="text-primary">View Invoice</p>
                                        <ExternalLinkOutline className="h-3 w-3 text-gray-400" aria-hidden="true" />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
