export const ACCESS_STATE_UNKNOWN = 'unknown'
export const ACCESS_STATE_LOBBY = 'lobby'
export const ACCESS_STATE_FULL = 'full'
export const ACCESS_STATE_NONE = 'none'

export const MEETING_STATE_JOINED = 'joined-meeting'

export enum NetworkState {
    GOOD = 'good',
    LOW = 'low',
    VERY_LOW = 'very-low',
}

export enum VideoQuality {
    AUTO = 'auto',
    HIGH = 'high',
    LOW = 'low',
    VERY_LOW = 'very-low',
    BANDWIDTH_SAVER = 'bandwidth-saver',
}

export const DEFAULT_ASPECT_RATIO = 16 / 9
export const SECONDARY_ASPECT_RATIO = 4 / 3
