import {useEffect, useState} from 'react'
import {PlanViewModel} from 'shared-types/view-models/EventModels'
import {getPlans} from '~/api/billingApi'
import AmplifierModal from '~/widgets/AmplifierModal'
import {RadioGroup} from '@headlessui/react'
import clsx from 'clsx'
import {DateTime} from 'luxon'
import {useDispatch} from 'react-redux'
import actions from '~/store/identityState'
import {useAppSelector} from '~/store'
import LoadingSvg from '~/assets/svgs/loading.svg'
import isArray from 'lodash/isArray'

interface ChangePlanProps {
    isOpen: boolean
    onClose: (updated: boolean) => void
    currentPlan: string
    endOfPeriod: number
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export default function ChangePlan({isOpen, onClose, currentPlan, endOfPeriod}: ChangePlanProps) {
    const [plans, setPlans]: [PlanViewModel[], any] = useState([])
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const tenant = useAppSelector(state => state.identityState.tenant!)
    const [selectedPlan, setSelectedPlan] = useState(currentPlan)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchPlans = async () => {
            let response = await getPlans()

            if (isArray(response)) {
                setLoading(false)
                setPlans(response)
            } else if (response.type === 'error' || response.type === 'internal_server_error') {
                setLoading(false)
                setError(response.message)
            }
        }

        fetchPlans()
    }, [])

    const handleChangePlan = async () => {
        setLoading(true)

        const billing = await dispatch(actions.ChangePlan(selectedPlan, tenant.slug))

        if (typeof billing === 'string') {
            setError(billing)
            setLoading(false)
            return
        }

        setLoading(false)
        onClose(true)
    }

    return (
        <>
            <AmplifierModal showClose={false} size="large" theme="light" isOpen={isOpen} onClose={() => onClose(false)}>
                <div className="text-lg px-4 font-semibold mb-4">
                    <label>Select New Plan</label>

                    <div className="settings__selectplan__plans mt-8">
                        <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
                            <RadioGroup.Label className="sr-only">Select New Plan</RadioGroup.Label>
                            <div className="space-y-4">
                                {plans.map(plan => (
                                    <RadioGroup.Option
                                        key={plan.id}
                                        value={plan.id}
                                        className={({checked, active}) =>
                                            clsx(
                                                checked ? 'border-transparent ring-2 ring-primary' : 'border-gray-300',
                                                active ? 'ring-primary ring-2 ' : '',
                                                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                                            )
                                        }
                                    >
                                        {({active, checked}) => (
                                            <>
                                                <span className="flex items-center">
                                                    <span className="flex flex-col text-sm">
                                                        <RadioGroup.Label
                                                            as="span"
                                                            className="font-medium text-gray-900"
                                                        >
                                                            {plan.name}
                                                            {plan.id === currentPlan && (
                                                                <div className="ml-2 px-4 bg-gray-200 text-gray-500  h-7 text-sm font-semibold inline-flex flex-wrap items-center select-none justify-center text-center rounded-md  border-none bg-opacity-50">
                                                                    Current
                                                                </div>
                                                            )}
                                                        </RadioGroup.Label>
                                                        <RadioGroup.Description as="span" className="text-gray-500">
                                                            {plan.lineItems
                                                                .filter(e => !e.includes('All features'))
                                                                .map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <span className="block sm:inline mr-2">
                                                                                {!item.includes('All features') && item}
                                                                            </span>
                                                                            {index !==
                                                                                plan.lineItems.filter(
                                                                                    e => !e.includes('All features'),
                                                                                ).length -
                                                                                    1 && (
                                                                                <span
                                                                                    className="hidden sm:mx-1 sm:inline"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    &middot;
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )
                                                                })}
                                                        </RadioGroup.Description>
                                                    </span>
                                                </span>
                                                <RadioGroup.Description
                                                    as="span"
                                                    className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right"
                                                >
                                                    <span className="font-medium text-gray-900">
                                                        {formatter.format(plan.price / 100)}
                                                    </span>
                                                    <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                                                </RadioGroup.Description>
                                                <span
                                                    className={clsx(
                                                        active ? 'border' : 'border-2',
                                                        checked ? 'ring-primary' : 'border-transparent',
                                                        'pointer-events-none absolute -inset-px rounded-lg',
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>

                        {error && (
                            <div className="text-red-500 error text-sm mt-2">
                                <p className="text-red-500">{error}</p>
                            </div>
                        )}

                        <div className="mt-8 flex flex-col">
                            <span className="text-sm font-normal">
                                More information on plans & pricing can be found{' '}
                                <a target="_blank" href="https://amplifier.community/pricing" rel="noreferrer">
                                    here
                                </a>
                            </span>
                            <span className="text-sm font-normal">
                                Please be informed that if a plan downgrade is requested, the downgrade will take effect
                                at the end of the current billing cycle. (
                                {endOfPeriod && DateTime.fromSeconds(endOfPeriod).toLocaleString(DateTime.DATE_MED)})
                            </span>
                            <span className="text-sm font-normal">
                                It is our policy not to prorate flat rate subscriptions in the event of an upgrade
                                request made during a billing cycle.
                            </span>
                        </div>

                        <div className="actions w-full flex gap-3 justify-end">
                            <button
                                //disabled={processing || !setCardComplete}
                                onClick={() => onClose(false)}
                                className="btn btn-primary mt-6 bg-white  hover:border-white border-white hover:bg-gray-200"
                            >
                                Cancel
                            </button>
                            <button
                                //disabled={processing || !setCardComplete}
                                disabled={loading}
                                onClick={handleChangePlan}
                                className="btn btn-primary mt-6 bg-gray-800 text-white hover:border-white border-white hover:bg-gray-700"
                            >
                                {loading && <LoadingSvg className={clsx('mr-3')} height="1em" width="1em" />}
                                Update Subscription
                            </button>
                        </div>
                    </div>
                </div>
            </AmplifierModal>
        </>
    )
}
