import cn from 'classnames'
import {useEffect, useRef, useState} from 'react'
import {useMediaDevices} from '~/contexts/MediaDeviceProvider'
import useAudioMeterRef from '~/hooks/useAudioMeterRef'
import './AnimatedAudioLine.scss'

// Isolate the animated button to it's own component (it updates state alot)
interface AnimatedAudioLineProps {
    isOnLobby: boolean
}
const AnimatedAudioLine = ({isOnLobby}: AnimatedAudioLineProps) => {
    const {currentDevices, mics, isMicMuted} = useMediaDevices()

    const currentMic =
        mics?.find(i => {
            if (!currentDevices?.mic) {
                return false
            }
            return (currentDevices?.mic as MediaDeviceInfo)?.deviceId === i.deviceId
        }) ?? null
    const micId = currentMic?.deviceId ?? null

    const [mediaStream, setMediaStream] = useState<MediaStream>()
    const [audioLevel, setAudioLevel] = useState(0)
    const animationFrameRef = useRef<number>()
    const audioMeterRef = useAudioMeterRef(mediaStream)

    useEffect(() => {
        // Get MediaStream
        if (micId && !isMicMuted) {
            navigator.mediaDevices.getUserMedia({audio: {advanced: [{deviceId: micId}]}}).then(stream => {
                setMediaStream(stream)
            })
            registerAnimationFrame()
        } else {
            setAudioLevel(0)
        }
        return () => {
            setAudioLevel(0)
            mediaStream && setMediaStream(undefined)
            animationFrameRef && cancelAnimationFrame(animationFrameRef.current!)
        }
    }, [micId, isMicMuted])

    useEffect(() => {
        setAudioLevel(audioMeterRef.current!)
    }, [audioMeterRef.current])

    const registerAnimationFrame = async () => {
        const animate = () => {
            setAudioLevel(audioMeterRef.current!)
            animationFrameRef.current = requestAnimationFrame(animate)
        }
        animationFrameRef.current = requestAnimationFrame(animate)
    }

    return (
        <>
            <div
                className={cn('audioMeter', {isOnLobby: isOnLobby})}
                style={{
                    width: `${audioLevel}0%`,
                }}
            />
        </>
    )
}

export default AnimatedAudioLine
