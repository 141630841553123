import {DateTime} from 'luxon'
import {useAppSelector} from '~/store'
import {Edit as EditIcon} from '@styled-icons/evaicons-solid/Edit'
import {useState} from 'react'
import {EventViewModel, EventPostViewModel} from 'shared-types/view-models'
import EditDetails from './EditDetails'
import {updateEvent} from '~/api/eventsApi'
import {useDispatch} from 'react-redux'
import actions from '~/store/eventsState'

export default function Details() {
    const stagedDetailEvent = useAppSelector(state => state.eventsState.stagedDetailEvent!)
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitError, setSubmitError] = useState('')

    const dispatch = useDispatch()

    const editSubmit = async (event: EventViewModel) => {
        setLoading(true)

        const response = await updateEvent(
            event.code,
            {
                title: event.title,
                code: event.code,
                settings: event.settings as EventPostViewModel['settings'],
            },
            tenant.slug,
        )

        if (response.type === 'event') {
            setLoading(false)
            setEditing(false)

            dispatch(actions.SetStagedEvent(event))
        } else if (response.type === 'error' || response.type === 'internal_server_error') {
            setLoading(false)
            setSubmitError(response.message)
        }
    }

    if (editing) {
        return (
            <EditDetails
                onCancel={() => setEditing(false)}
                onSubmit={editSubmit}
                event={stagedDetailEvent}
                loading={loading}
                submitError={submitError}
            ></EditDetails>
        )
    }

    return (
        <div className="px-8 flex flex-row">
            <div className="flex-grow px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Event Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">{stagedDetailEvent.title}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Created</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {DateTime.fromJSDate(stagedDetailEvent.createdAt).toLocaleString(DateTime.DATETIME_MED)}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Room Code</dt>
                        <dd className="mt-1 text-sm text-gray-900">{stagedDetailEvent.code}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Breakout Mode</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {stagedDetailEvent.settings.enableAtoB ? 'A:B Mode' : '1:1 Mode'}
                        </dd>
                    </div>

                    {stagedDetailEvent.settings.enableAtoB && (
                        <div className="sm:col-span-1 ">
                            <dt className="text-sm font-medium text-gray-500">A:B Groups</dt>
                            <dd className="mt-2 text-sm text-gray-900 ">
                                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <span className="text-gray-500 font-semibold">A:</span>
                                        <div className="flex w-0 flex-1 items-center">
                                            <span className="ml-2 w-0 flex-1 truncate">
                                                {stagedDetailEvent.settings.groupOneLabel}
                                            </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0"></div>
                                    </li>
                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <span className="text-gray-500 font-semibold">B:</span>
                                        <div className="flex w-0 flex-1 items-center">
                                            <span className="ml-2 w-0 flex-1 truncate">
                                                {stagedDetailEvent.settings.groupTwoLabel}
                                            </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0"></div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    )}
                </dl>
            </div>
            <div className="details__actions">
                <button
                    onClick={() => setEditing(true)}
                    className="btn text-black bg-transparent border-none hover:bg-gray-200"
                >
                    Edit Details <EditIcon className="w-5 h-5 pl-2"></EditIcon>
                </button>
            </div>
        </div>
    )
}
