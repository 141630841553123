import {useEffect, useState} from 'react'
import {DailyEventObjectCameraError, DailyEventObjectNonFatalError} from '@daily-co/daily-js'
import {useDaily, useRecording} from '@daily-co/daily-react'
import {toast} from 'react-toastify'
import {useParticipants} from '../../../contexts/ParticipantsProvider'
import AmplifierModal, {AmplifierModalProps} from '~/widgets/AmplifierModal'

// Component for displaying a notification.
// Currently handles camera, mic and screenshare permission errors.
export default function Notifications() {
    const {localParticipant} = useParticipants()

    const daily = useDaily()!
    const {isRecording} = useRecording()

    const [modalProps, setModal] = useState<AmplifierModalProps>({
        isOpen: false,
        onClose: () => {
            setModal({
                ...modalProps,
                isOpen: false,
            })
        },
        title: '',
        children: null,
        primaryAction: undefined,
        secondaryAction: undefined,
    })

    // Subscribe to notifications via useEffect here
    useEffect(() => {
        if (localParticipant.micMutedByHost === true) {
            toast.info('The Host has muted your microphone', {containerId: 'All'})
        }
    }, [localParticipant.micMutedByHost])

    useEffect(() => {
        if (isRecording === true) {
            // Set a timeout. This allows the host to let the recording disappear
            setTimeout(() => {
                toast.info('This event is being recorded', {containerId: 'All'})
            }, 10)
        }
    }, [isRecording])

    const handleError = (err: DailyEventObjectNonFatalError | DailyEventObjectCameraError) => {
        if (err.action === 'camera-error') {
            setModal({
                ...modalProps,
                isOpen: true,
                title: err.error.type === 'permissions' ? 'Camera/Mic Permissions Denied' : 'Problem with Camera/mic',
                children: (
                    <>
                        {err.error.type === 'permissions'
                            ? 'Looks like your Camera/Microphone were Permissions are denied.'
                            : err.error.msg}{' '}
                        <br /> For help troubleshooting this, visit{' '}
                        <a
                            target="_blank"
                            href="https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer"
                            rel="noreferrer"
                        >
                            this guide
                        </a>
                        .
                    </>
                ),
                primaryAction: {
                    label: 'Ok',
                    onClick: () => modalProps.onClose(),
                    color: 'primary',
                },
            })
        }
        if (err.action === 'nonfatal-error' && err.type === 'screen-share-error') {
            setModal({
                ...modalProps,
                isOpen: true,
                title: 'Screenshare Permissions Denied',
                children: (
                    <p>
                        Looks like your Screensharing Permissions are denied. <br /> For help troubleshooting this,
                        visit{' '}
                        <a
                            target="_blank"
                            href="https://help.daily.co/en/articles/3400532-enable-screen-recording-permission-for-macos-catalina-10-15-and-later"
                            rel="noreferrer"
                        >
                            this guide
                        </a>
                        .
                    </p>
                ),
                primaryAction: {
                    label: 'Ok',
                    onClick: () => modalProps.onClose(),
                    color: 'primary',
                },
            })
        }
    }

    useEffect(() => {
        daily.on('nonfatal-error', event => handleError(event!))
        daily.on('camera-error', event => handleError(event!))

        return () => {
            daily.off('nonfatal-error', event => handleError(event!))
            daily.off('camera-error', event => handleError(event!))
        }
    }, [localParticipant.micMutedByHost])

    return (
        <>
            <AmplifierModal {...modalProps} />
        </>
    )
}
