import {useState} from 'react'
import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {Warning as WarningIcon} from '@styled-icons/ionicons-solid/Warning'

import {useNavigate} from 'react-router'
import {useAppDispatch} from '../../store'
import actions from '../../store/identityState'
import {Navigate, useSearchParams} from 'react-router-dom'
import {EyeOff as HidePasswordIcon} from '@styled-icons/evaicons-solid/EyeOff'
import {Eye as ShowPasswordIcon} from '@styled-icons/evaicons-solid/Eye'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import validate from '~/utils/validate'

export default function SetPassword() {
    const [success, setSuccess] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const [searchParams] = useSearchParams()

    const token = searchParams.get('t')

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    if (!token) {
        return <Navigate to="/" />
    }

    return (
        <>
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Set New Password</h2>
            <p className="mt-2 text-sm text-gray-600">Please set a new password for your account</p>

            {success ? (
                <div className="alert alert-success alert-sm mt-4 shadow-lg">
                    <div>
                        <div>
                            <h5 className="font-bold">Password Changed</h5>
                            <div className="text-md">Your password has been changed. Redirecting to login...</div>
                        </div>
                    </div>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        password: '',
                    }}
                    validate={validate.generate({
                        password: [validate.required()],
                    })}
                    onSubmit={async (values, {setSubmitting, setStatus}) => {
                        setSubmitting(true)

                        const response = await dispatch(actions.SetPassword({password: values.password, token: token}))

                        if (response.type === 'success') {
                            setSuccess(true)

                            setTimeout(() => {
                                navigate('/')
                            }, 1500)
                        } else if (response.type === 'error') {
                            setStatus(response.message)
                        }
                    }}
                >
                    {({errors, touched, isSubmitting, status}) => (
                        <Form className="mt-6">
                            <label className="label label-text text-black" htmlFor="password">
                                Enter password
                            </label>
                            <div className="relative">
                                <Field
                                    id="password"
                                    name="password"
                                    className={cn('input mt-1', {'input-error': errors.password && touched.password})}
                                    type={showPassword ? 'text' : 'password'}
                                />
                                {showPassword ? (
                                    <ShowPasswordIcon
                                        className="showPasswordToggle"
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                ) : (
                                    <HidePasswordIcon
                                        className="showPasswordToggle"
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                )}
                            </div>

                            {status && !success && status['message'] ? (
                                <div className="alert alert-error alert-sm mt-4 shadow-lg">
                                    <div>
                                        <WarningIcon size="1.5rem" />
                                        <div>
                                            <h5 className="font-bold">Error</h5>
                                            <div className="text-md">{status}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <p className="text-sm text-error">
                                <ErrorMessage className="text-sm text-error" name="password" />
                            </p>

                            <button type="submit" className="btn btn-primary w-full mt-8" disabled={isSubmitting}>
                                Set Password
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    )
}
