import io from 'socket.io-client'
import {SocketJoinData} from 'shared-types/view-models/SocketModels'
import {AmplifierSocketClient} from 'shared-types/socket-events'

export default function createSocket(
    roomCode: string,
    location: 'lobby' | 'event' = 'event',
    socketData: Partial<SocketJoinData> = {},
): AmplifierSocketClient {
    let socketNamespace = ''

    if (location === 'lobby') {
        socketNamespace = 'lobby'
    }

    socketData.roomCode = roomCode

    const socketUrl = `${AMPLIFIER_ENV_INFO.backendTarget}/${socketNamespace}`

    const socket = io(socketUrl, {
        query: socketData,
        transports: ['websocket'],
        forceNew: true,
    })
    return socket
}
