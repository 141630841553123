import {useEffect, useState} from 'react'
import {PastEventViewModel} from 'shared-types/view-models'
import {getPastEvents} from '~/api/eventsApi'
import {Link} from 'react-router-dom'
import isArray from 'lodash/isArray'
import Skeleton from '~/components/common/Skeleton'
import {ChevronRight as ChevronRightIcon} from '@styled-icons/entypo/ChevronRight'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import {DateTime} from 'luxon'

type Result<T> = {type: 'success'; data: T} | {type: 'error'; error: string} | {type: 'loading'}

type Data = PastEventViewModel[]

export default function Connections() {
    const [data, setData] = useState<Result<Data>>({type: 'loading'})

    useEffect(() => {
        const fetchPastEvents = async () => {
            try {
                const response = await getPastEvents()

                if (isArray(response)) {
                    setData({type: 'success', data: response.filter(event => event.breakouts.length > 0)})
                } else {
                    setData({type: 'error', error: response.message})
                }
            } catch (e) {
                setData({type: 'error', error: 'Something went wrong. Please try again later'})
            }
        }
        fetchPastEvents()
    }, [])

    return (
        <div className="w-full p-5 flex flex-col">
            <div className="bg-white w-full px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Connections</h3>
                    <p className="text-sm text-gray-500">Your connections from past events</p>
                </div>
            </div>
            <div className="flex-1 pt-5 overflow-y-scroll">
                <div className="flex flex-col max-w-full">
                    {data.type === 'loading' ? (
                        <div className="flex flex-col gap-4 items-stretch">
                            {new Array(4).fill(0).map((_, i) => (
                                <div className="flex my-2 flex-col gap-2 items-stretch">
                                    <Skeleton className="h-6 rounded-sm" fullWidth />
                                    <Skeleton className="h-4" fullWidth />
                                </div>
                            ))}
                        </div>
                    ) : data.type === 'error' ? (
                        <div className="mt-2 flex flex-col gap-4 justify-center items-center py-8">
                            <h4 className="text-red-500 text-2xl font-semibold">Something went wrong :(</h4>
                            <p>{data.error}</p>
                        </div>
                    ) : data.data.length === 0 ? (
                        <div className="mt-2 flex flex-col gap-4 justify-center items-center py-8">
                            <h4 className="text-primary text-2xl font-semibold">You haven't met anyone yet</h4>
                            <p>The people you meet at events will show up here</p>
                        </div>
                    ) : (
                        <>
                            {data.data.map((event, i) => (
                                <PastEventListItem event={event} key={i} />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

const PastEventListItem = ({event}: {event: PastEventViewModel}) => {
    return (
        <Link
            to={`/dashboard/connections/${event.id}`}
            className="block hover:bg-gray-50 rounded-md no-underline mx-0 px-0"
            key={event.id}
        >
            <div className="flex items-center mx-3  px-0">
                <div className="min-w-0 flex-1 py-3 rounded-md sm:flex sm:items-center sm:justify-between">
                    <div className="flex flex-row flex-grow">
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{`${event.title} `}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                                {event.startDate === null ? 'No date' : ''}
                                <time dateTime={event.startDate.toDateString()}>
                                    {DateTime.fromJSDate(event.startDate).toRelativeCalendar()}
                                </time>
                            </p>
                        </div>
                    </div>
                    <div className="hidden sm:flex space-x-10 sm:flex-row items-center ">
                        <span className="text-sm  text-gray-500 ">
                            Connections: {event.breakouts.map(e => e.participants).flat().length}
                        </span>
                        <div className="flex w-32 justify-end px-4 -space-x-0.5">
                            {event.breakouts
                                .map(e => e.participants)
                                .flat()
                                .sort((a, b) => (a.picture != null ? -1 : 1))
                                .splice(0, 4)
                                .map((commenter, i) => (
                                    <div key={i}>
                                        <AmplifierAvatar
                                            className="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white"
                                            src={commenter.picture}
                                            name={commenter.nickname ?? ''}
                                            size="xs2"
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </div>
            </div>
        </Link>
    )
}
