import {Outlet} from 'react-router'

export default function BaseWrapper() {
    return (
        <div className="lobbyWrapper">
            <div className="leftCard" data-theme="light">
                <div className="leftBox">
                    <Outlet />
                </div>
            </div>
            <div className="rightCard"></div>
        </div>
    )
}
