/**
  Call state is comprised of:
  - "Call items" (inputs to the call, i.e. participants or shared screens)
  - UI state that depends on call items (for now, just whether to show "click allow" message)

  Call items are keyed by id:
  - "local" for the current participant
  - A session id for each remote participant
  - "<id>-screen" for each shared screen
 */
import {DailyParticipant} from '@daily-co/daily-js'
import fasteq from 'fast-deep-equal'
import {DEVICE_STATE_OFF, DEVICE_STATE_BLOCKED, DEVICE_STATE_LOADING} from './useDevices'

// Base type. This is used for screenshares
export interface CallItem {
    type: 'call_item'
    id: string
    isLoading: boolean
    isLocal: boolean
    hasNameSet: boolean
    isScreenshare: boolean
    lastActiveDate?: Date
    isOwner: boolean
}

// Extended CallItem type with both daily and our data
export interface ParticipantItem {
    type: 'participant_item'
    id: string
    isLoading: boolean
    isLocal: boolean
    hasNameSet: boolean
    isScreenshare: boolean
    lastActiveDate?: Date
    isOwner: boolean

    // Properties from daily
    camMutedByHost: boolean
    isActiveSpeaker: boolean
    isCamMuted: boolean
    isMicMuted: boolean
    isRecording: boolean
    micMutedByHost: boolean

    // Our Properties
    isHost: boolean
    name: string
    company: string
    title: string
    picture: string
}

export type ParticipantsState = {
    lastPendingUnknownActiveSpeaker?: {
        date: Date
        id: string
    }
    participants: ParticipantItem[]
    screens: CallItem[]
}
const initialParticipantsState: ParticipantsState = {
    lastPendingUnknownActiveSpeaker: undefined,
    participants: [
        {
            type: 'participant_item',
            camMutedByHost: false,
            hasNameSet: false,
            id: 'local',
            isActiveSpeaker: false,
            isCamMuted: false,
            isLoading: true,
            isLocal: true,
            isMicMuted: false,
            isOwner: false,
            isRecording: false,
            isScreenshare: false,
            lastActiveDate: undefined,
            micMutedByHost: false,
            name: '',
            company: '',
            title: '',
            picture: '',
            isHost: false,
        },
    ],
    screens: [],
}

// --- Derived data ---

function getId(participant: DailyParticipant) {
    return participant.local ? 'local' : participant.user_id
}

function getScreenId(id: string) {
    return `${id}-screen`
}

function isLocalId(id: string) {
    return typeof id === 'string' && id === 'local'
}

function isScreenId(id: string) {
    return typeof id === 'string' && id.endsWith('-screen')
}

// ---Helpers ---

function getNewParticipant(participant: DailyParticipant): ParticipantItem {
    const id = getId(participant)

    const {local} = participant
    const {audio, video} = participant.tracks

    return {
        type: 'participant_item',
        camMutedByHost: video?.off?.byRemoteRequest ?? false,
        hasNameSet: Boolean(participant.user_name),
        id,
        isActiveSpeaker: false,
        isCamMuted: video?.state === DEVICE_STATE_OFF || video?.state === DEVICE_STATE_BLOCKED,
        isLoading: audio?.state === DEVICE_STATE_LOADING || video?.state === DEVICE_STATE_LOADING,
        isLocal: local,
        isMicMuted: audio?.state === DEVICE_STATE_OFF || audio?.state === DEVICE_STATE_BLOCKED,
        isOwner: Boolean(participant.owner),
        isRecording: Boolean(participant.record),
        isScreenshare: false,
        lastActiveDate: undefined,
        micMutedByHost: audio?.off?.byRemoteRequest ?? false,
        name: participant.user_name,
        company: '',
        title: '',
        picture: '',
        isHost: false,
    }
}

function getUpdatedParticipant(participant: DailyParticipant, participants: ParticipantItem[]): ParticipantItem {
    const id = getId(participant)
    const prevItem = participants.find(p => p.id === id)

    // In case we haven't set up this participant, yet.
    if (!prevItem) return getNewParticipant(participant)

    const {local} = participant
    const {audio, video} = participant.tracks
    return {
        ...prevItem,
        camMutedByHost: video?.off?.byRemoteRequest ?? false,
        hasNameSet: Boolean(participant.user_name),
        id,
        isCamMuted: video?.state === DEVICE_STATE_OFF || video?.state === DEVICE_STATE_BLOCKED,
        isLoading: audio?.state === DEVICE_STATE_LOADING || video?.state === DEVICE_STATE_LOADING,
        isLocal: local,
        isMicMuted: audio?.state === DEVICE_STATE_OFF || audio?.state === DEVICE_STATE_BLOCKED,
        isOwner: Boolean(participant.owner),
        isRecording: Boolean(participant.record),
        micMutedByHost: audio?.off?.byRemoteRequest ?? false,
        name: participant.user_name,
    }
}

function getScreenItem(participant: DailyParticipant): CallItem {
    const id = getId(participant)
    return {
        type: 'call_item',
        hasNameSet: false,
        id: getScreenId(id),
        isLoading: false,
        isLocal: participant.local,
        isScreenshare: true,
        lastActiveDate: undefined,
        isOwner: Boolean(participant.owner),
    }
}

// --- Actions ---

const ACTIVE_SPEAKER = 'ACTIVE_SPEAKER'
const PARTICIPANT_JOINED = 'PARTICIPANT_JOINED'
const PARTICIPANT_UPDATED = 'PARTICIPANT_UPDATED'
const PARTICIPANT_LEFT = 'PARTICIPANT_LEFT'
const SWAP_POSITION = 'SWAP_POSITION'

// --- Reducer --

function participantsReducer(
    prevState: ParticipantsState,
    action:
        | {
              type: 'PARTICIPANT_JOINED' | 'PARTICIPANT_UPDATED' | 'PARTICIPANT_LEFT'
              participant: DailyParticipant
          }
        | {
              type: 'ACTIVE_SPEAKER'
              id: string
          }
        | {
              type: 'SWAP_POSITION'
              id1: string
              id2: string
          },
): ParticipantsState {
    switch (action.type) {
        case ACTIVE_SPEAKER: {
            if (!action.id) {
                return {
                    ...prevState,
                    lastPendingUnknownActiveSpeaker: undefined,
                }
            }
            const {participants, ...state} = prevState
            const date = new Date()
            const isParticipantKnown = participants.some(p => p.id === action.id)
            return {
                ...state,
                lastPendingUnknownActiveSpeaker: isParticipantKnown
                    ? undefined
                    : {
                          date,
                          id: action.id,
                      },
                participants: participants.map(p => ({
                    ...p,
                    isActiveSpeaker: p.id === action.id,
                    lastActiveDate: p.id === action.id ? date : p?.lastActiveDate,
                })),
            }
        }
        case PARTICIPANT_JOINED: {
            if (!action.participant) return prevState
            const item = getNewParticipant(action.participant)

            const participants: ParticipantItem[] = [...prevState.participants]
            const screens: CallItem[] = [...prevState.screens]

            const isPendingActiveSpeaker = item.id === prevState.lastPendingUnknownActiveSpeaker?.id
            if (isPendingActiveSpeaker) {
                item.isActiveSpeaker = true
                item.lastActiveDate = prevState.lastPendingUnknownActiveSpeaker?.date
            }

            if (item.isCamMuted) {
                participants.push(item)
            } else {
                const firstInactiveCamOffIndex = prevState.participants.findIndex(
                    p => p.isCamMuted && !p.isLocal && !p.isActiveSpeaker,
                )
                if (firstInactiveCamOffIndex >= 0) {
                    participants.splice(firstInactiveCamOffIndex, 0, item)
                } else {
                    participants.push(item)
                }
            }

            // Participant is sharing screen
            if (action.participant.screen) {
                screens.push(getScreenItem(action.participant))
            }

            return {
                ...prevState,
                lastPendingUnknownActiveSpeaker: !isPendingActiveSpeaker
                    ? prevState.lastPendingUnknownActiveSpeaker
                    : undefined,
                participants,
                screens,
            }
        }
        case PARTICIPANT_UPDATED: {
            if (!action.participant) return prevState
            const item = getUpdatedParticipant(action.participant, prevState.participants)
            const {id} = item
            const screenId = getScreenId(id)

            const participants = [...prevState.participants]
            const idx = participants.findIndex(p => p.id === id)
            participants[idx] = item

            const screens: CallItem[] = [...prevState.screens]
            const screenIdx = screens.findIndex(s => s.id === screenId)

            if (action.participant.screen) {
                const screenItem = getScreenItem(action.participant)
                if (screenIdx >= 0) {
                    screens[screenIdx] = screenItem
                } else {
                    screens.push(screenItem)
                }
            } else if (screenIdx >= 0) {
                screens.splice(screenIdx, 1)
            }

            const newState = {
                ...prevState,
                participants,
                screens,
            }

            if (fasteq(newState, prevState)) {
                return prevState
            }

            return newState
        }
        case PARTICIPANT_LEFT: {
            if (!action.participant) return prevState
            const id = getId(action.participant)
            const screenId = getScreenId(id)

            return {
                ...prevState,
                participants: [...prevState.participants].filter(p => p.id !== id),
                screens: [...prevState.screens].filter(s => s.id !== screenId),
            }
        }
        case SWAP_POSITION: {
            const participants = [...prevState.participants]
            if (!action.id1 || !action.id2) return prevState
            const idx1 = participants.findIndex(p => p.id === action.id1)
            const idx2 = participants.findIndex(p => p.id === action.id2)
            if (idx1 === -1 || idx2 === -1) return prevState
            const tmp = participants[idx1]
            participants[idx1] = participants[idx2]
            participants[idx2] = tmp
            return {
                ...prevState,
                participants,
            }
        }
        default:
            throw new Error()
    }
}

export {
    ACTIVE_SPEAKER,
    getId,
    getScreenId,
    isLocalId,
    isScreenId,
    participantsReducer,
    initialParticipantsState,
    PARTICIPANT_JOINED,
    PARTICIPANT_LEFT,
    PARTICIPANT_UPDATED,
    SWAP_POSITION,
}
