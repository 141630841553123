import cn from 'classnames'
import {useEffect, useState} from 'react'
import {Combobox} from '@headlessui/react'
import {ChevronUp} from '@styled-icons/bootstrap/ChevronUp'

import './RecipientSelect.scss'
import {useAppSelector} from '../../../../../store'

let everyOne = {name: 'Everyone', id: undefined}

interface RecipientSelectProps {
    setRecipient: (recipient?: string) => void
    recipientOverride?: string
}
export default function RecipientSelect({setRecipient, recipientOverride}: RecipientSelectProps) {
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson]: [any, any] = useState(everyOne)

    const {allParticipants, localId} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
        localId: amplifierSocket.localId,
    }))

    useEffect(() => {
        setRecipient((selectedPerson as any)?.id)
    }, [selectedPerson])

    useEffect(() => {
        setRecipient(
            !recipientOverride ? everyOne.id : Object.values(allParticipants).find(e => e.id === recipientOverride)?.id,
        )

        setSelectedPerson(
            recipientOverride == null ? everyOne : Object.values(allParticipants).find(e => e.id === recipientOverride),
        )
    }, [recipientOverride])

    const filteredPeople =
        query === ''
            ? [...Object.values(allParticipants), everyOne].filter(e => e.id !== localId)
            : ([...Object.values(allParticipants), everyOne].filter(person => {
                  return person.name?.toLowerCase().includes(query.toLowerCase()) && person.id !== localId
              }) as any[])

    return (
        <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
            {filteredPeople.length > 0 && (
                <Combobox.Options className="comboOptions w-52 absolute z-10 max-h-48 h-48 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredPeople.map(person => (
                        <Combobox.Option
                            key={person.id}
                            value={person}
                            className={({active}) =>
                                cn(
                                    'relative cursor-default h-8 w- select-none py-2 pl-3 pr-9 text-gray-900',
                                    active ? 'bg-primary text-white' : '',
                                )
                            }
                        >
                            {({active, selected}) => (
                                <>
                                    <div className="flex items-center">
                                        <span className={cn('ml-3 truncate', selected && 'font-semibold')}>
                                            {person.name}
                                        </span>
                                    </div>

                                    {selected && (
                                        <span
                                            className={cn(
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                active ? 'text-white' : 'text-indigo-600',
                                            )}
                                        ></span>
                                    )}
                                </>
                            )}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            )}

            <div className="recipientInput mt-1 relative">
                <span>To:</span>
                <Combobox.Input
                    className="h-8  text-black rounded-md border border-gray-300 bg-white  shadow-sm  focus:outline-none focus:ring-1  sm:text-sm"
                    onChange={event => setQuery(event.target.value)}
                    displayValue={person => (person as any)?.name}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2  focus:outline-none">
                    <ChevronUp></ChevronUp>
                </Combobox.Button>
            </div>
        </Combobox>
    )
}
