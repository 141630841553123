import {EventViewModel, EventDetailsModel, EventPostViewModel, PastEventViewModel} from 'shared-types/view-models'
import * as Response from 'shared-types/schema/response'
import api from '.'

export async function checkEventCodeAvalibility(
    code: string,
): Promise<string | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.get(`/event/code/avaliable?code=${code}`)
    return response as string | Response.ErrorResponse | Response.InternalServerError
}

export async function getEvents(
    tenant: string,
): Promise<EventViewModel[] | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.get(`/event?tenant=${tenant}`)
    return response as EventViewModel[] | Response.ErrorResponse | Response.InternalServerError
}
export async function getEvent(
    id: string,
): Promise<EventViewModel | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.get(`/event/${id}`)
    return response as EventViewModel
}

export async function getEventSessions(
    id: string,
    tenant: string,
): Promise<Response.GetEventSessionResponse | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.get(`/event/${id}/sessions?tenant=${tenant}`)

    return response as Response.GetEventSessionResponse | Response.ErrorResponse | Response.InternalServerError
}

export async function getEventDetails(id: string, tenant: string): Promise<EventDetailsModel> {
    const response = await api.get(`/event/${id}/details?tenant=${tenant}`)
    return response as EventDetailsModel
}

export async function createEvent(
    body: EventPostViewModel,
    tenant: string,
): Promise<Response.CreateEventResponse | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.post(`/event?tenant=${tenant}`, body)
    return response as Response.CreateEventResponse | Response.ErrorResponse | Response.InternalServerError
}

export async function updateEvent(
    code: string,
    body: EventPostViewModel,
    tenant: string,
): Promise<EventViewModel | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.put(`/event/${code}?tenant=${tenant}`, body)
    return response as EventViewModel | Response.ErrorResponse | Response.InternalServerError
}

export async function deleteEvent(
    code: string,
    tenant: string,
): Promise<Response.DeleteEventResponse | Response.ErrorResponse | Response.InternalServerError> {
    const response = await api.post(`/event/${code}/delete?tenant=${tenant}`)
    return response as Response.DeleteEventResponse | Response.ErrorResponse | Response.InternalServerError
}

export async function getPastEvents(): Promise<PastEventViewModel[] | Response.ErrorResponse> {
    const response = await api.get('/event/past')
    return response as PastEventViewModel[] | Response.ErrorResponse
}
export async function getPastEvent(eventSessionId: string): Promise<PastEventViewModel> {
    const response = await api.get(`/event/past/${eventSessionId}`)
    return response as PastEventViewModel
}

export function getEventSessionParticipantsCSVUrl(eventId: string, eventSessionId: string, tenantSlug: string) {
    return `/api/v1/event/${eventId}/${eventSessionId}/participants.csv?tenant=${tenantSlug}`
}
export function getEventSessionBreakoutsCSVUrl(eventId: string, eventSessionId: string, tenantSlug: string) {
    return `/api/v1/event/${eventId}/${eventSessionId}/breakouts.csv?tenant=${tenantSlug}`
}
