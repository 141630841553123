import {createContext, useContext, useEffect, useRef, useState} from 'react'
import {AmplifierSocketClient} from 'shared-types/socket-events'
import {Hashtag, TeamPayload, UpdateHashtagsPayload} from 'shared-types/view-models/SocketModels'
import {useAppSelector} from '../store'
import createSocket from '../utils/createSocket'

const LobbySocketContext = createContext<LobbySocketContextState>({teams: [], hashtags: []})

interface LobbySocketContextState {
    teams: TeamPayload[]
    hashtags: Hashtag[]
}
const LobbySocketProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [socket, setSocket] = useState<AmplifierSocketClient>()
    const [lobbyState, setLobbyState] = useState<LobbySocketContextState>({teams: [], hashtags: []})
    const socketRef = useRef<AmplifierSocketClient>()

    const {roomCode} = useAppSelector(globalState => globalState.eventState)

    // Create the socket
    useEffect(() => {
        if (socket || !roomCode) return
        const newSocket = createSocket(roomCode, 'lobby')
        setSocket(newSocket)
        socketRef.current = newSocket
    }, [roomCode])

    // Destroy the socket on component unmount
    useEffect(() => {
        return () => {
            if (!socketRef.current) return
            socketRef.current.close()
            setSocket(undefined)
        }
    }, [])

    const updateHashtags = (payload: UpdateHashtagsPayload) => {
        setLobbyState({
            ...lobbyState,
            hashtags: payload.hashtags,
        })
    }

    // Register socket events
    useEffect(() => {
        if (!socket) return
        socket.on('UPDATE_HASHTAGS', updateHashtags)
        return () => {
            socket.off('UPDATE_HASHTAGS', updateHashtags)
        }
    }, [socket])

    return <LobbySocketContext.Provider value={lobbyState}>{children}</LobbySocketContext.Provider>
}

const useLobbySocket = (): LobbySocketContextState => useContext(LobbySocketContext)
export {LobbySocketContext as default, LobbySocketProvider, useLobbySocket}
