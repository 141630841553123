import {DailyTrackState} from '@daily-co/daily-js'

export const isDeviceBlocked = (kind: 'audio' | 'video', trackState?: DailyTrackState) => {
    if (!trackState) return
    if (trackState.state !== 'blocked') return
    if (trackState.blocked?.byPermissions) {
        return `${kind} Permission Denied`
    } else if (trackState.blocked?.byDeviceMissing) {
        return `${kind} Device Missing`
    } else if (trackState.blocked?.byDeviceInUse) {
        return `${kind} In Use`
    }
    return
}
