import AmplifierModal from '~/widgets/AmplifierModal'
import BreakoutSettings from './BreakoutSettings/BreakoutSettings'
import './ControlCenter.scss'
import ModeSelect from './ModeSelect/ModeSelect'
import {Play as PlayIcon} from '@styled-icons/fa-solid/Play'
import {useEffect, useMemo, useState} from 'react'
import Participants from './Participants/Participants'
import {useAppSelector} from '~/store'
import {RoomState} from 'shared-types/view-models/SocketModels'
import {Infinite as InfiniteIcon} from '@styled-icons/boxicons-regular/Infinite'
import {DateTime} from 'luxon'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {toast} from 'react-toastify'
import StopBreakoutMenu from '../Common/StopBreakoutMenu'
import HostView from './HostView/HostView'
import MoveParticipant from './MoveParticipant/MoveParticipant'
import PresetMatches from './PresetMatches/PresetMatches'
import {PopoupListAction} from '../Tray/HostTray/HostTray'

interface ControlCenterProps {
    isOpen: boolean
    onClose: () => void
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function formatState(s: RoomState) {
    if (s === 'normal') return 'Home'
    if (s === 'intermission' || !s) return 'Intermission'
    if (s === 'breakout') return 'Breakout'
}

function StateTimer() {
    const {stateEnd} = useAppSelector(({amplifierSocket}) => amplifierSocket.breakout)

    const [secondsRemaining, setSecondsRemaining] = useState<number>()

    const end = DateTime.fromJSDate(stateEnd!)

    useEffect(() => {
        if (stateEnd === null) {
            setSecondsRemaining(undefined)
            return
        }

        const setTime = () => {
            setSecondsRemaining(end.diff(DateTime.now()).as('seconds') + 1)
        }
        setTime()
        const interval = setInterval(setTime, 100)
        return () => clearInterval(interval)
    }, [stateEnd])

    const formattedTimeLeft = (): React.ReactNode => {
        if (!secondsRemaining) {
            return
        }
        const minutesRemaining = Math.floor(secondsRemaining / 60)
        const seconds = Math.floor(secondsRemaining) % 60
        if (minutesRemaining < 0 || secondsRemaining < 0) {
            return '0'
        }
        if (minutesRemaining <= 0) {
            return `${seconds}`
        }
        return minutesRemaining + ':' + (seconds < 10 ? '0' + seconds : seconds)
    }

    return <span>{formattedTimeLeft()}</span>
}

export default function ControlCenter({isOpen, onClose}: ControlCenterProps) {
    const {startBreakout, stopBreakout} = useAmplifierSocket()

    const [moveParticipant, setMoveParticipant] = useState<{
        isOpen: boolean
        participantId?: string
    }>({
        isOpen: false,
    })

    const {
        breakout: {nextState, state, stateEnd},
        isHost,
        allParticipantIds,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    const tabs = useMemo(() => {
        return [
            {name: 'Controls', key: 0, current: false, disabled: false},
            {name: 'Participants', key: 1, current: false, disabled: false},
            {
                name: 'Preset Matches',
                key: 3,
                current: false,
                disabled: false,
            },
            {
                name: 'Breakouts',
                key: 2,
                current: true,
                disabled: state === 'normal',
            },
        ]
    }, [state])

    const [activeTab, setActiveTab] = useState(tabs[0])

    const returnPopupActions: PopoupListAction[] = [
        {
            text: 'End after this round',
            onClick: () => stopBreakout(),
        },
        {
            text: 'End Now',
            onClick: () => stopBreakout(true),
        },
    ]

    const startBreakouts = () => {
        if (allParticipantIds.length < 3) {
            toast.info('Breakout rounds require 3 or more participants', {containerId: 'All'})
            return false
        }

        startBreakout()
    }

    if (!isHost) return null

    return (
        <>
            <AmplifierModal showClose={false} size="xlarge" theme="dark" isOpen={isOpen} onClose={onClose}>
                <div className="flex w-full  flex-col  h-2/3  align-top p-8 mb-8">
                    <div className="border-b grid pb-6 mb-6 grid-cols-1 gap-5 sm:grid-cols-3 border-gray-800">
                        <div className=" w-full">
                            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                                <div className="overflow-hidden flex w-48  rounded-lg   bg-neutral px-2 py-3 shadow sm:p-3">
                                    <div className="w-2/3">
                                        <dt className="truncate text-sm font-medium text-gray-500">Current State</dt>
                                        <dd className="mt-1 text-1xl font-semibold tracking-tight text-gray-200">
                                            {formatState(state)}
                                        </dd>
                                    </div>
                                    <div className="h-full w-full flex-grow flex items-center content-center justify-center">
                                        {stateEnd === null && <InfiniteIcon size="1.2em" />}
                                        {stateEnd != null && <StateTimer />}
                                    </div>
                                </div>
                                <div className="overflow-hidden flex w-48 rounded-lg   bg-neutral px-2 py-3 shadow sm:p-3">
                                    <div className="w-2/3">
                                        <dt className="truncate text-sm font-medium text-gray-500">Next State</dt>
                                        <dd className="mt-1 text-1xl font-semibold tracking-tight text-gray-200">
                                            {formatState(nextState)}
                                        </dd>
                                    </div>
                                </div>
                            </dl>
                        </div>
                        <div></div>
                        <div className="w-full items-center flex justify-end">
                            {state === 'normal' && (
                                <div
                                    className="tooltip"
                                    daa-tip={
                                        !(allParticipantIds.length >= 3)
                                            ? 'Breakout rounds require 3 or more participants'
                                            : 'Warning: This will start breakouts immediately'
                                    }
                                >
                                    <button
                                        onClick={startBreakouts}
                                        disabled={!(allParticipantIds.length >= 3)}
                                        className="btn btn-primary"
                                    >
                                        <PlayIcon className="mr-3" size="0.8em" />
                                        Start Breakouts
                                    </button>
                                </div>
                            )}

                            {state !== 'normal' && <StopBreakoutMenu returnPopupActions={returnPopupActions} />}
                        </div>
                    </div>

                    <div className="">
                        <div className="sm:block">
                            <nav className="flex space-x-4 " aria-label="Tabs">
                                {tabs.map(tab => (
                                    <span
                                        key={tab.key}
                                        className={classNames(
                                            tab.name === activeTab.name
                                                ? ' bg-opacity-30 bg-primary text-primary'
                                                : 'text-gray-500 hover:text-gray-700',
                                            tab.disabled ? 'cursor-not-allowed text-gray-700' : 'cursor-pointer',
                                            'px-3 py-2 font-medium  cursor-pointer text-sm rounded-md',
                                        )}
                                        onClick={() => tab.disabled === false && setActiveTab(tab)}
                                    >
                                        {tab.name}
                                    </span>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {activeTab.key === 0 && (
                        <div className="max-h-96 h-96 mt-12">
                            <ModeSelect></ModeSelect>
                            <BreakoutSettings></BreakoutSettings>
                        </div>
                    )}

                    {activeTab.key === 1 && (
                        <div className="max-h-96 h-96 mt-12">
                            <Participants setMoveParticipant={setMoveParticipant} />
                        </div>
                    )}

                    {activeTab.key === 2 && (
                        <div className="max-h-96 h-96 mt-12">
                            <HostView setMoveParticipant={setMoveParticipant} />
                        </div>
                    )}
                    {activeTab.key === 3 && (
                        <div className="max-h-96 h-96 mt-12">
                            <PresetMatches />
                        </div>
                    )}
                </div>
            </AmplifierModal>
            <MoveParticipant
                defaultParticipant={moveParticipant.participantId!}
                isOpen={moveParticipant.isOpen}
                onClose={() =>
                    setMoveParticipant({
                        isOpen: false,
                        participantId: undefined,
                    })
                }
            />
        </>
    )
}
