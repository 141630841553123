import './BrowserUnsupported.scss'
import BrowserUnsupportedImage from '~/assets//svgs/upgrade-required.svg'

export default function BrowserUnsupported() {
    return (
        <div className="min-h-screen w-full text-center flex justify-center items-center">
            <div className="flex flex-col gap-4 items-center">
                <BrowserUnsupportedImage className="max-w-md max-h-64" />
                <h1>Please upgrade your browser to use Amplifier</h1>
                <p>
                    Looks like you need to upgrade your browser to make Amplifier video calls.
                    <br />
                    See{' '}
                    <a className="text-primary" href="https://docs.daily.co/docs/browsers">
                        this page
                    </a>{' '}
                    for help getting on a supported browser version.
                </p>
            </div>
        </div>
    )
}
