import EditEmailAddress from './EditEmailAddress'

export default function Settings() {
    return (
        <div className="w-full flex  p-5 flex-col">
            <div className="bg-white w-full px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="flex items-center">
                        <div className="">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Settings</h3>
                        </div>
                    </div>
                    <div className=" flex-shrink-0">
                        <button
                            type="button"
                            className="btn btn-primary relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex w-full mt-10 flex-col flex-grow h-auto overflow-scroll">
                <div className="w-full">
                    <EditEmailAddress />
                </div>
            </div>
        </div>
    )
}
