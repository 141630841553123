import {useEffect, useState} from 'react'
import './CountDownPopUp.scss'
import {useAppSelector} from '../../../store'

// TODO - delete this
// This is now only a message to participants stuck in the home room.
export default function CountDownPopUp() {
    const {
        currentRoomParticipantIds,
        channel,
        breakout: {state},
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    const [showing, setShowing] = useState(false)

    useEffect(() => {
        // Meet all of these conditions to show this popup
        if (state === 'breakout' && channel === 0) {
            !showing && setShowing(true)
            return
        }
        setShowing(false)
    }, [currentRoomParticipantIds, state])

    if (!showing) return null

    return (
        <>
            <div className="breakoutPopupCard">Home Room</div>
        </>
    )
}
