import {Menu, Transition} from '@headlessui/react'
import {Fragment} from 'react'
import ReturnHomeIcon from '~/assets/icons/return-home.svg'
import {PopoupListAction} from '../Tray/HostTray/HostTray'

interface StopBreakoutMenuProps {
    returnPopupActions: PopoupListAction[]
}
export default function StopBreakoutMenu({returnPopupActions}: StopBreakoutMenuProps) {
    return (
        <Menu as="div" className="relative text-left flex h-16 ml-2 justify-center items-center">
            <div>
                <Menu.Button className="btn btn-error">
                    <ReturnHomeIcon className="mr-3 w-4"></ReturnHomeIcon>
                    Stop Breakouts
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="-top-2 transform -translate-y-full absolute right-0 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {returnPopupActions.map(e => (
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        className={`${
                                            active ? 'bg-primary text-white' : 'text-gray-900'
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={e.onClick}
                                    >
                                        {e.icon}
                                        <span className="pl-2">{e.text}</span>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
