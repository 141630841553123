import React, {useState, useMemo} from 'react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList} from 'react-window'
import useParticipantItems, {RowItem} from '../../../../hooks/useParticipantItems'
import {useAppDispatch, useAppSelector} from '../../../../store'
import './ParticipantsList.scss'
import EmptyParticipantsList from './EmptyParticipantsList'
import SearchInput from './SearchInput'
import {Item, DraggableItem, PlaceholderItem} from './DragDropItem'
import actions from '../../../../store/amplifierSocketState'
import sortBy from 'lodash/sortBy'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import {Hashtag} from 'shared-types/view-models/SocketModels'

export default function ParticipantListOrEmpty() {
    // Optimize component reloads
    const isLoading = useAppSelector(({amplifierSocket}) => amplifierSocket.socketState !== 'connected')
    const isEmpty = useAppSelector(({amplifierSocket}) => amplifierSocket.allParticipantIds.length === 0)

    if (isLoading || isEmpty) {
        return (
            <div className="w-full h-full">
                <EmptyParticipantsList isLoading={isLoading} />
            </div>
        )
    }
    return <ParticipantsList />
}

function ParticipantsList() {
    // Optimize state fetching by querying all data as one object
    const {allParticipantIds, localId, favouriteIds, matchedIds} = useAppSelector(({amplifierSocket}) => ({
        allParticipantIds: amplifierSocket.allParticipantIds,
        localId: amplifierSocket.localId,
        favouriteIds: amplifierSocket.favouriteIds,
        matchedIds: amplifierSocket.localParticipant.matchedIds,
    }))

    const [searchTerm, setSearchTerm] = useState('')
    const [filterHashtags, setFilterHashtags] = useState<Hashtag[]>([])

    // Get RowItems to apply searching on
    const rowItems = useParticipantItems(allParticipantIds.filter(id => id !== localId))

    // Memoized function to search participants
    //
    // Sort items by Search Term & Selected hashtags
    // Excludes favourites
    const {filteredAllItems, favouriteItems, matchedItems}: {[key: string]: RowItem[]} = useMemo(() => {
        const searchQuery = searchTerm.trim().toLowerCase().replace('#', '')

        const result: {[key: string]: RowItem[]} = {}

        result.filteredAllItems = rowItems.filter(item => {
            // Filter out local ID
            if (item.id === localId) return false
            if (item.group && !item.isOppositeGroup) return false
            // Filter out Favourite Ids
            if (favouriteIds.includes(item.id)) return false

            // Do searching
            if (searchQuery) {
                const {name, title, company, hashtags} = item.participant
                // Combine all the searchable participant data
                const inSearch = `${name} ${title} ${company} ${hashtags.map(tag => tag?.name).join('')}`
                    .toLowerCase()
                    .includes(searchQuery)
                if (!inSearch) return false
            }

            // Filter list by selected hashtags
            if (filterHashtags.length) {
                const participantHasHashtags = (participant: ParticipantViewModel) => {
                    const hashtagIds = participant.hashtags.filter(tag => !!tag).map(tag => tag.id)
                    return filterHashtags.every(tag => hashtagIds.includes(tag.id))
                }
                const participant = item.participant
                if (!participantHasHashtags(participant)) {
                    return false
                }
            }

            return true
        })

        result.favouriteItems = rowItems.filter(item => favouriteIds.includes(item.id))
        result.favouriteItems = sortBy(result.favouriteItems, item => favouriteIds.indexOf(item.id))
        result.matchedIds = rowItems.filter(item => matchedIds.includes(item.id) && item.id === localId)

        return result
    }, [searchTerm, rowItems, matchedIds, favouriteIds, filterHashtags])

    // Show an "empty list" component
    if (allParticipantIds.filter(id => id !== localId).length === 0) {
        return <EmptyParticipantsList isLoading={allParticipantIds.length === 0 ? true : false} />
    }

    return (
        <div className="w-full h-full flex flex-col">
            <DragDropList
                allItems={filteredAllItems}
                favouriteItems={favouriteItems}
                matchedItems={matchedItems}
                searchInput={
                    <SearchInput
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        selectedHashtags={filterHashtags}
                        setSelectedHashtags={setFilterHashtags}
                    />
                }
            />
        </div>
    )
}

interface DragDropListProps {
    allItems: RowItem[]
    favouriteItems: RowItem[]
    matchedItems: RowItem[]
    searchInput: React.ReactNode
}
const DragDropList = ({allItems = [], favouriteItems = [], matchedItems = [], searchInput}: DragDropListProps) => {
    const dispatch = useAppDispatch()

    // const [allListIndex, setAllListIndex] = useState<string[]>([])
    // useEffect(() => {
    //     if (allListIndex.length === allItems.length) return
    //     let newList = sortBy(allItems, item => allListIndex.indexOf(item.id))
    //     setAllListIndex(newList.map(item => item.id))
    // }, [allItems])
    // const sortedAllItems = sortBy(allItems, item => allListIndex.indexOf(item.id))

    // Re-order list logic. There's some commented out stuff for animation
    const onDragEnd = ({source, destination, draggableId}: DropResult) => {
        if (!destination) {
            return
        }
        if (source.droppableId === destination.droppableId) {
            if (source.index === destination.index) return

            const listId = source.droppableId
            const list: RowItem[] = listId === 'favourites' ? favouriteItems : allItems
            // Reorder the list
            const newList = reorder(list, source.index, destination.index)
            if (listId === 'favourites') {
                dispatch(actions.SetFavouriteIds(newList.map(item => item.id)))
            } else {
                // setAllListIndex(newList.map(item => item.id))
            }
        } else {
            // Remove from one list and Insert into the other
            if (destination.droppableId === 'favourites') {
                const favouriteIds: string[] = favouriteItems.map(item => item.id)
                const newList = insert(favouriteIds, draggableId, destination.index)
                dispatch(actions.SetFavouriteIds(newList))
            } else {
                dispatch(actions.RemoveFromFavourites(favouriteItems[source.index].id))
            }
        }
    }

    const mainListItems = [...allItems, ...matchedItems]

    return (
        <DragDropContext
            onDragEnd={(result, provided) => onDragEnd(result)}
            // sensors={[favUpdateListener(idSequence)]}
        >
            {/* Favourite IDs droppable list */}
            <Droppable droppableId="favourites">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} className="favouritesList" ref={provided.innerRef}>
                        {favouriteItems.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, {isDragging, isDropAnimating}) => (
                                    <Item
                                        provided={provided}
                                        rowItem={item}
                                        isDragging={isDragging}
                                        isDropping={isDropAnimating}
                                        isFavourite={true}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {favouriteItems.length < 3 && (
                            <PlaceholderItem isFavouritesEmpty={favouriteItems.length === 0} />
                        )}
                    </div>
                )}
            </Droppable>
            {searchInput}
            {/* All IDs droppable list */}
            <Droppable
                droppableId="all"
                mode="virtual"
                renderClone={(provided, {isDragging, isDropAnimating}, rubric) => (
                    <Item
                        provided={provided}
                        isDragging={isDragging && !isDropAnimating}
                        isDropping={isDropAnimating}
                        rowItem={mainListItems[rubric.source.index]}
                        isFavourite={false}
                    />
                )}
            >
                {provided => {
                    return (
                        <div style={{flex: '1 1 auto'}}>
                            <AutoSizer>
                                {({height, width}) => (
                                    <FixedSizeList
                                        className="allList"
                                        width={width}
                                        height={height}
                                        itemCount={mainListItems.length}
                                        itemSize={72}
                                        outerRef={provided.innerRef}
                                        itemData={mainListItems}
                                    >
                                        {DraggableItem}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

function insert<T>(list: T[], item: T, index: number): T[] {
    const result = Array.from(list)
    result.splice(index, 0, item)
    return result
}
