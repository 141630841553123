import {useSelector, TypedUseSelectorHook, useDispatch} from 'react-redux'
import {applyMiddleware, combineReducers, legacy_createStore as createStore, compose} from 'redux'
import thunkMiddleware, {ThunkMiddleware} from 'redux-thunk'
import {identityReducer} from './identityState'
import {eventReducer} from './eventState'
import {eventsReducer} from './eventsState'
import {amplifierSocketReducer} from './amplifierSocketState'
import {RootState, RootDispatch, RootActions} from './types'

const reduxDevtoolsExtension =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()

const rootReducer = combineReducers({
    identityState: identityReducer,
    amplifierSocket: amplifierSocketReducer,
    eventState: eventReducer,
    eventsState: eventsReducer,
})

const middlewares = [applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, RootActions>)]
if (reduxDevtoolsExtension) {
    middlewares.push(reduxDevtoolsExtension)
}

//@ts-ignore
const store = createStore(rootReducer, compose(...middlewares))

// Create a selector for our state
export const useAppDispatch: () => RootDispatch = useDispatch

export default store

// These are just the hooks from react-redux except typed explicitly to our store.
// You can see examples of their use in MFA.tsx
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
