import {To, useNavigate} from 'react-router-dom'
import {ArrowBackOutline as BackIcon} from '@styled-icons/evaicons-outline/ArrowBackOutline'
import './BackButton.scss'

export default function BackButton({to}: {to?: To}) {
    const navigate = useNavigate()
    return (
        <div className="backButton">
            <button className="w-8 text-gray-500" onClick={() => navigate(to ? to : (-1 as To))}>
                <BackIcon />
            </button>
        </div>
    )
}
