import {useDaily} from '@daily-co/daily-react'
import {useEffect, useMemo} from 'react'
import {useDeepCompareMemo} from 'use-deep-compare'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'
import {useParticipants} from '../../../contexts/ParticipantsProvider'
import Tile from '../Tile/Tile'
import MicSelect from './MicSelect'
import CameraButton from './CameraSelect'
import SpeakerButton from './SpeakerSelect'
import {Warning as WarningIcon} from '@styled-icons/ionicons-solid/Warning'
import './Haircheck.scss'
import SettingsModal from '../SettingsModal/SettingsModal'
import actions, {SettingsPage} from '../../../store/eventState'
import {useDispatch} from 'react-redux'
import {Sparkles as EffectsIcon} from '@styled-icons/ionicons-sharp/Sparkles'
import AnimatedAudioLine from '../Audio/AnimatedAudioLine'

export default function Haircheck() {
    const daily = useDaily()
    const {localParticipant} = useParticipants()
    const {deviceState, camError, micError, isCamMuted, isMicMuted, updateDeviceState} = useMediaDevices()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!daily) return
        daily.startCamera()
    }, [daily])

    // Memoize the to prevent unnecessary re-renders
    const tileMemo = useDeepCompareMemo(
        () => <Tile participant={localParticipant} showName={true} />,
        [localParticipant, isMicMuted],
    )

    const isLoading = useMemo(() => ['pending', 'loading'].includes(deviceState), [deviceState])

    const hasError = useMemo(() => camError || micError, [camError, micError])

    const camErrorVerbose = useMemo(() => {
        switch (camError) {
            case 'blocked':
                return 'Camera blocked by user'
            case 'not-found':
                return 'Camera not found'
            case 'in-use':
                return 'Device in use'
            default:
                return 'unknown'
        }
    }, [camError])

    const micErrorVerbose = useMemo(() => {
        switch (micError) {
            case 'blocked':
                return 'Microphone blocked by user'
            case 'not-found':
                return 'Microphone not found'
            case 'in-use':
                return 'Microphone in use'
            default:
                return 'unknown'
        }
    }, [micError])

    const toggleCamera = () => {
        daily?.setLocalVideo(isCamMuted)
        updateDeviceState()
    }

    const toggleMic = () => {
        daily?.setLocalAudio(isMicMuted)
        updateDeviceState()
    }

    return (
        <div className="hairCheck">
            <div className="contianer card max-w-full">
                {!isLoading ? (
                    <>
                        <SettingsModal isInLobby={true} />
                        <div className="haircheckTileContainer">
                            {tileMemo}
                            <div>
                                <button
                                    className="effectsButton"
                                    onClick={() => dispatch(actions.SetModalOpen(SettingsPage.Video))}
                                >
                                    <EffectsIcon />
                                </button>
                            </div>
                        </div>

                        <div className="haircheckDevices">
                            <AnimatedAudioLine isOnLobby />
                            <MicSelect disabled={isLoading} isMicMuted={isMicMuted} onClick={toggleMic} />
                            <CameraButton disabled={isLoading} isCamMuted={isCamMuted} onClick={toggleCamera} />
                            <SpeakerButton disabled={isLoading} />

                            <div className="text-sm mt-2 text-black md:text-gray-500 mx-auto">
                                Experiencing issues? Try this{' '}
                                <a
                                    className="underline"
                                    target="_blank"
                                    href="https://www.amplifier.community/posts/troubleshooting-camera-and-mic-issues"
                                    rel="noreferrer"
                                >
                                    Help Article
                                </a>
                            </div>

                            {hasError && (
                                <div className="alert alert-error alert-sm mt-4 shadow-lg">
                                    <div>
                                        <WarningIcon size="1.5rem" />
                                        <div>
                                            {micError && camError ? (
                                                <h5 className="font-bold">Devices Blocked</h5>
                                            ) : micError ? (
                                                <h5 className="font-bold">{micErrorVerbose}</h5>
                                            ) : (
                                                <h5 className="font-bold">{camErrorVerbose}</h5>
                                            )}
                                            <div className="text-md">
                                                There's some issues with our access to your speakers/mic
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
}
