import {useState} from 'react'
import cn from 'classnames'
import {User as AvatarIcon} from '@styled-icons/fa-solid/User'

interface AvatarProps {
    name: string
    src?: string
    square?: boolean
    size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '100%' | 'xs2'
    className?: string
}
export default function AmplifierAvatar({src, name, size = 'md', square = false, className = ''}: AvatarProps) {
    // Keeps track of if the iamge could be downloaded.
    // If there's no image found, Show ann avatar icon instead
    const [loadedImage, setLoadedImage] = useState(false)

    return (
        <div className={'avatar text-gray-200 ' + className}>
            <div
                className={cn({
                    'rounded-full': !square,
                    'w-5': size === 'xxs',
                    'w-7': size === 'xs2',
                    'w-10': size === 'xs',
                    'w-14': size === 'sm',
                    'w-20': size === 'md',
                    'w-24': size === 'lg',
                    'w-28': size === 'xl',
                    'w-full': size === '100%',
                })}
            >
                <img
                    className={cn({
                        'opacity-0 absolute': !loadedImage || src == null,
                    })}
                    src={src}
                    alt={name}
                    onLoad={() => setLoadedImage(true)}
                    onError={() => setLoadedImage(false)}
                />
                {(!loadedImage || src == null) && (
                    <div className="bg-gray-600 w-full h-full flex justify-center items-center">
                        <AvatarIcon className="w-1/2" />
                    </div>
                )}
            </div>
        </div>
    )
}
