import cn from 'classnames'
import {People as PeopleIcon} from '@styled-icons/material-rounded/People'
import {Record2 as RecordIcon} from '@styled-icons/bootstrap/Record2'
import {useRecording} from '@daily-co/daily-react'
import './TopBar.scss'
import {useAppDispatch, useAppSelector} from '~/store'
import {ChevronLeft as DrawArrowIcon} from '@styled-icons/material/ChevronLeft'

import Pill from '~/widgets/Pill'
import actions from '~/store/eventState'
import {PhotoSizeSelectSmall as SmallLocalTileIcon} from '@styled-icons/material/PhotoSizeSelectSmall'
import {GridView as LargeLocalTileIcon} from '@styled-icons/material-rounded/GridView'

// horizontal list up the top of the screen during breakouts.
// Combines same-team participants into one item
export default function TopBar() {
    const dispatch = useAppDispatch()
    const {
        allParticipantIds,
        breakout: {state},
        localParticipant,
        homeRoomMode,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const {isRecording} = useRecording()

    const event = useAppSelector(({eventState}) => eventState.event!)

    let groupLabel: string | undefined = undefined

    if (localParticipant.group && event.settings.enableAtoB) {
        if (localParticipant.group === 'A' && event.settings.groupOneLabel) {
            groupLabel = event.settings.groupOneLabel
        }
        if (localParticipant.group === 'B' && event.settings.groupTwoLabel) {
            groupLabel = event.settings.groupTwoLabel
        }
    }

    const minimizeLocalTile = useAppSelector(({eventState}) => eventState.settings.minimizeLocalTile)
    const setMinimizeLocalTile = (value: boolean) => {
        if (minimizeLocalTile === value) return

        dispatch(
            actions.SetSetting({
                setting: 'minimizeLocalTile',
                value: value,
            }),
        )
    }

    const sidebarOpen = useAppSelector(({eventState}) => eventState.settings.sidebarOpen)

    const setSidebarOpen = (value: boolean) => {
        if (sidebarOpen === value) return

        dispatch(
            actions.SetSetting({
                setting: 'sidebarOpen',
                value: value,
            }),
        )
    }

    return (
        <div className="topBar">
            <span className="inline-flex h-10 items-center rounded-lg bg-base-100 px-2.5 py-0.5 text-md font-bold text-primary">
                <PeopleIcon className="mr-2" size="1em" />
                {allParticipantIds.length}
            </span>

            <div className="md:inline-flex  hidden justify-center  text-left rounded-lg ml-0 flex-col  px-2.5 py-0.5    ">
                <span className="text-md font-bold text-white">{event.title}</span>
                <span className="text-sm">
                    {localParticipant.group && groupLabel && <span className="groupTag">{groupLabel}</span>}
                </span>
            </div>

            {isRecording ? (
                <Pill icon={<RecordIcon size="1.2em" />} color="warning" size="lg">
                    Rec
                </Pill>
            ) : null}

            <div className="flex-grow"></div>

            {state === 'normal' && homeRoomMode === 'hosts' ? (
                <></>
            ) : (
                <div className="btn-group">
                    <button
                        onClick={() => setMinimizeLocalTile(false)}
                        className={cn('btn btn-sm h-2', {'btn-active': !minimizeLocalTile})}
                    >
                        <LargeLocalTileIcon size="1em" />
                    </button>
                    <button
                        onClick={() => setMinimizeLocalTile(true)}
                        className={cn('btn btn-sm h-2', {'btn-active': minimizeLocalTile})}
                    >
                        <SmallLocalTileIcon size="1em" />
                    </button>
                </div>
            )}

            <div className="btn-group">
                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    className={cn('btn btn-sm h-2', {'rotate-180': sidebarOpen})}
                >
                    <DrawArrowIcon size="1.5em" />
                </button>
            </div>
        </div>
    )
}
