import DailyIframe, {DailyCall, DailyCallOptions, DailyEvent} from '@daily-co/daily-js'
import {DailyProvider} from '@daily-co/daily-react'
import {useCallback, useEffect, useState} from 'react'
import {AmplifierSocketProvider} from '../../contexts/AmplifierSocketProvider'
import {CallProvider} from '../../contexts/CallProvider'
import {LobbySocketProvider} from '../../contexts/LobbySocketProvider'
import {MediaDeviceProvider} from '../../contexts/MediaDeviceProvider'
import {ParticipantsProvider} from '../../contexts/ParticipantsProvider'
import {TracksProvider} from '../../contexts/TracksProvider'
import {useAppDispatch, useAppSelector} from '../../store'
import actions from '../../store/eventState'
import {roomCodeFromPageUrl, pageUrlFromRoomCode} from '../../urlUtils'
import {getSavedBackgroundFilter} from '../../utils/backgroundFilterUtils'
import BrowserUnsupported from './BrowserUnsupported/BrowserUnsupported'
import Call from './Call/Call'
import Lobby from './Lobby/Lobby'
import Sidebar from './Sidebar/Sidebar'
import Tray from './Tray/Tray'
import * as Sentry from '@sentry/react'
import './VideoPlatform.scss'

export type AppState = 'lobby' | 'haircheck' | 'joining' | 'joined' | 'leaving' | 'error'

// Main wrapper for the Video Call & Lobby
export default function VideoPlatform() {
    const {event, storedRoomCode} = useAppSelector(({eventState}) => ({
        event: eventState.event,
        storedRoomCode: eventState.roomCode,
    }))
    const dispatch = useAppDispatch()

    const [appState, setAppState] = useState<AppState>('lobby')
    const [roomCode, setRoomCode] = useState<string | undefined>(roomCodeFromPageUrl())
    const [callObject, setCallObject] = useState<DailyCall>()

    useEffect(() => {
        if (event && !callObject) {
            startHaircheck()
        }
    }, [!!event])

    const startHaircheck = useCallback(() => {
        if (!event) {
            return
        }
        const newCallObject = DailyIframe.createCallObject({
            dailyConfig: {
                keepCamIndicatorLightOn: false,
                userMediaVideoConstraints: {
                    aspectRatio: 1.7777777778,
                    advanced: [
                        {
                            facingMode: 'user',
                        },
                    ],
                },
                useDevicePreferenceCookies: true,
            },
        })
        setCallObject(newCallObject)
        setAppState('haircheck')
        const joinData: DailyCallOptions = {
            url: event.eventUrl,
            subscribeToTracksAutomatically: false,
        }
        if (event.token) {
            joinData.token = event.token
        }
        newCallObject.preAuth(joinData)
    }, [event])

    const startJoining = useCallback(() => {
        if (!callObject || !event) return
        const joinData: DailyCallOptions = {
            url: event.eventUrl,
            subscribeToTracksAutomatically: false,
        }
        if (event.token) {
            joinData.token = event.token
        }
        setAppState('joining')
        callObject?.join(joinData)
    }, [callObject])

    /**
     * Starts leaving the current call.
     */
    const startLeavingCall = useCallback(() => {
        if (!callObject) return
        if (appState === 'error') {
            // If we're in the error state, we've already "left", so just clean up
            callObject.destroy().then(() => {
                setRoomCode(undefined)
                setCallObject(undefined)
                setAppState('lobby')
            })
        } else {
            setAppState('leaving')

            callObject.leave()
            window.location.reload()
        }
    }, [callObject, appState])

    const stopHairCheck = useCallback(() => {
        if (!callObject) return
        callObject.leave()
        callObject.destroy().then(() => {
            setRoomCode(undefined)
            setCallObject(undefined)
            setAppState('lobby')
        })
    }, [callObject, appState])

    // RoomCode state logic
    useEffect(() => {
        if (!roomCode) return
        dispatch(actions.FetchEvent(roomCode))
        const pageUrl = pageUrlFromRoomCode(roomCode)
        if (pageUrl === window.location.href) return
        window.history.replaceState(null, '', pageUrl)
    }, [roomCode])

    useEffect(() => {
        if (storedRoomCode === null) return
        setRoomCode(storedRoomCode)
    }, [storedRoomCode])

    /**
     * Update app state based on reported meeting state changes.
     *
     * NOTE: Here we're showing how to completely clean up a call with destroy().
     * This isn't strictly necessary between join()s, but is good practice when
     * you know you'll be done with the call object for a while and you're no
     * longer listening to its events.
     */
    useEffect(() => {
        if (!callObject) return

        const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error']

        function handleNewMeetingState() {
            if (!callObject) return
            switch (callObject.meetingState()) {
                case 'joined-meeting':
                    setAppState('joined')
                    break
                case 'left-meeting':
                    callObject.destroy().then(() => {
                        setRoomCode(undefined)
                        setCallObject(undefined)
                        setAppState('lobby')
                    })
                    break
                case 'error':
                    setAppState('error')
                    throw new Error()
                default:
                    break
            }
        }

        // Use initial state
        handleNewMeetingState()

        callObject.on('error', error => {
            if (!error) return
            Sentry.captureException(error)
            throw new Error(error.errorMsg)
        })

        callObject.on('nonfatal-error', error => {
            if (!error) return
            Sentry.captureMessage(
                `Nonfatal daily error: ${error.type} ${error.errorMsg}. Action: ${error.action}, details: ${error.details}`,
                'warning',
            )
        })

        // Listen for changes in state
        for (const event of events) {
            callObject.on(event, handleNewMeetingState)
        }

        // Stop listening for changes in state
        return function cleanup() {
            for (const event of events) {
                callObject.off(event, handleNewMeetingState)
            }
            callObject.off('error', () => {})
            callObject.off('nonfatal-error', () => {})
        }
    }, [callObject])

    // Set the saved background filter on init
    useEffect(() => {
        const filter = getSavedBackgroundFilter()
        if (!callObject || filter.type === 'none') return
        // daily can't set the background filter on init >:(
        // This sets the background filter 1.2s after daily initializes
        setTimeout(() => {
            try {
                callObject.updateInputSettings({
                    video: {
                        processor: filter,
                    },
                })
            } catch (e) {
                // It doesn't matter so much, i'll skip logging this error
            }
        }, 1200)
    }, [callObject])

    const isInLobby = ['lobby', 'haircheck', 'profile'].includes(appState)

    if (!DailyIframe.supportedBrowser().supported) return <BrowserUnsupported />

    return (
        <div className="videoWrap">
            <DailyProvider callObject={callObject}>
                {isInLobby ? (
                    <ContextWrapper>
                        <LobbySocketProvider>
                            {/* Include relevant providers as they're used in the haircheck form */}
                            <Lobby
                                startLeavingCall={stopHairCheck}
                                joinCall={startJoining}
                                startHaircheck={startHaircheck}
                                appState={appState}
                            />
                        </LobbySocketProvider>
                    </ContextWrapper>
                ) : (
                    <ContextWrapper>
                        <AmplifierSocketProvider>
                            <div className="callLayout">
                                <Sidebar>
                                    <Call />
                                    <Tray
                                        disabled={!['joined', 'error'].includes(appState)}
                                        leaveCall={startLeavingCall}
                                    />
                                </Sidebar>
                            </div>
                        </AmplifierSocketProvider>
                    </ContextWrapper>
                )}
            </DailyProvider>
        </div>
    )
}

interface ContextWrapperProps {
    children: React.ReactNode
}
const ContextWrapper = ({children}: ContextWrapperProps) => {
    return (
        <CallProvider>
            <ParticipantsProvider>
                <TracksProvider>
                    <MediaDeviceProvider>{children}</MediaDeviceProvider>
                </TracksProvider>
            </ParticipantsProvider>
        </CallProvider>
    )
}
