import {useDaily} from '@daily-co/daily-react'
import {createContext, useCallback, useContext, useEffect, useReducer, useMemo} from 'react'
import {useAppSelector} from '../store'

import {sortByKey} from '../utils/sortByKey'

import {useCallState} from './CallProvider'

import {
    initialParticipantsState,
    isLocalId,
    ACTIVE_SPEAKER,
    PARTICIPANT_JOINED,
    PARTICIPANT_LEFT,
    PARTICIPANT_UPDATED,
    participantsReducer,
    SWAP_POSITION,
    CallItem,
    ParticipantItem,
} from './participantsState'
import {
    DailyEvent,
    DailyEventObjectActiveSpeakerChange,
    DailyEventObjectParticipant,
    DailyEventObjectParticipantLeft,
    DailyParticipantUpdateOptions,
    DailyReceiveSettingsUpdates,
} from '@daily-co/daily-js'

interface ParticipantsContextState {
    activeParticipant?: ParticipantItem
    allParticipants: Array<CallItem | ParticipantItem>
    currentSpeaker?: ParticipantItem
    localParticipant: ParticipantItem
    participantCount: number
    participants: ParticipantItem[]
    screens: CallItem[]
    muteAll: () => void
    setUsername: (username: string) => void
    swapParticipantPosition: (p1: string, p2: string) => void
    username: string
    isOwner: boolean
}

export const ParticipantsContext = createContext<ParticipantsContextState>({} as ParticipantsContextState)

export const ParticipantsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const callObject = useDaily()
    const {videoQuality, networkState} = useCallState()
    const [state, dispatch] = useReducer(participantsReducer, initialParticipantsState)
    const currentRoomParticipantIds = useAppSelector(({amplifierSocket}) => amplifierSocket.currentRoomParticipantIds)
    const nextRoomParticipantIds = useAppSelector(({amplifierSocket}) => amplifierSocket.nextRoomParticipantIds)

    /**
     * ALL participants (incl. shared screens) in a convenient array
     */
    const allParticipants = useMemo<Array<CallItem | ParticipantItem>>(
        () => [...state.participants, ...state.screens],
        [state?.participants, state?.screens, currentRoomParticipantIds, nextRoomParticipantIds],
    )

    /**
     * Only return participants that should be visible in the call
     */
    const participants = useMemo(() => {
        return state.participants.filter(({id, isLocal}) => {
            if (isLocal) return true
            if (currentRoomParticipantIds.includes(id)) return true
            return false
        })
    }, [state.participants, currentRoomParticipantIds, nextRoomParticipantIds])

    /**
     * Array of participant IDs
     */
    const participantIds = useMemo(() => participants.map(p => p.id).join(','), [participants])

    /**
     * The number of participants, who are not a shared screen
     * (technically a shared screen counts as a participant, but we shouldn't tell humans)
     */
    const participantCount = useMemo(
        () => participants.filter(({isScreenshare}) => !isScreenshare).length,
        [participants],
    )

    /**
     * The participant who most recently got mentioned via a `active-speaker-change` event
     */
    const activeParticipant = useMemo(() => participants.find(({isActiveSpeaker}) => isActiveSpeaker), [participants])

    /**
     * The local participant
     */
    const localParticipant = useMemo(
        () => allParticipants.find(({isLocal, isScreenshare}) => isLocal && !isScreenshare) as ParticipantItem,
        [allParticipants],
    )

    const isOwner = useMemo(() => Boolean(localParticipant?.isOwner), [localParticipant])

    /**
     * The participant who should be rendered prominently right now
     */
    const currentSpeaker = useMemo(() => {
        /**
         * Ensure activeParticipant is still present in the call.
         * The activeParticipant only updates to a new active participant so
         * if everyone else is muted when AP leaves, the value will be stale.
         */
        const isPresent = participants.some(p => p?.id === activeParticipant?.id)

        const displayableParticipants = participants.filter(p => !p?.isLocal)

        if (
            !isPresent &&
            displayableParticipants.length > 0 &&
            displayableParticipants.every(p => p.isMicMuted && !p.lastActiveDate)
        ) {
            // Return first cam on participant in case everybody is muted and nobody ever talked
            // or first remote participant, in case everybody's cam is muted, too.
            return displayableParticipants.find(p => !p.isCamMuted) ?? displayableParticipants?.[0]
        }

        const sorted = displayableParticipants.sort((a, b) => sortByKey(a, b, 'lastActiveDate')).reverse()

        return isPresent ? activeParticipant : sorted?.[0] ?? localParticipant
    }, [activeParticipant, localParticipant, participants]) as ParticipantItem

    /**
     * Screen shares
     */
    const screens = useMemo(
        () =>
            allParticipants.filter(({id, isScreenshare, isLocal}) => {
                if (!isScreenshare) return false
                if (isLocal) return true
                return Boolean(currentRoomParticipantIds.find(pId => id.includes(pId)))
            }),
        [allParticipants, currentRoomParticipantIds],
    ) as CallItem[]

    /**
     * The local participant's name
     */
    const username = callObject?.participants()?.local?.user_name ?? ''

    const muteAll = useCallback(() => {
        if (!localParticipant.isOwner) return
        const unmutedParticipants = participants.filter(p => !p.isLocal && !p.isMicMuted)
        if (!unmutedParticipants.length) return
        const result = unmutedParticipants.reduce<{
            [sessionId: string]: DailyParticipantUpdateOptions
        }>((acc, p) => ({...acc, [p.id]: {setAudio: false}}), {})
        callObject!.updateParticipants(result)
    }, [callObject, localParticipant, participants])

    /**
     * Sets the local participant's name in daily-js
     * @param name The new username
     */
    const setUsername = (name: string) => {
        callObject!.setUserName(name)
    }

    const swapParticipantPosition = (id1: string, id2: string) => {
        if (id1 === id2 || !id1 || !id2 || isLocalId(id1) || isLocalId(id2)) return
        dispatch({
            type: SWAP_POSITION,
            id1,
            id2,
        })
    }

    const handleNewParticipantsState = useCallback(
        (event: DailyEventObjectParticipantLeft | DailyEventObjectParticipant) => {
            switch (event?.action) {
                case 'participant-joined':
                    dispatch({
                        type: PARTICIPANT_JOINED,
                        participant: event.participant!,
                    })
                    break
                case 'participant-updated':
                    dispatch({
                        type: PARTICIPANT_UPDATED,
                        participant: event.participant,
                    })
                    break
                case 'participant-left':
                    dispatch({
                        type: PARTICIPANT_LEFT,
                        participant: event.participant,
                    })
                    break
                default:
                    break
            }
        },
        [dispatch],
    )

    /**
     * Start listening for participant changes, when the callObject is set.
     */
    useEffect(() => {
        if (!callObject) return

        const events: DailyEvent[] = ['participant-joined', 'participant-updated', 'participant-left']

        // Listen for changes in state
        events.forEach(event => {
            callObject.on(event, e => handleNewParticipantsState(e))
        })

        // Stop listening for changes in state
        return () => events.forEach(event => callObject.off(event as any, handleNewParticipantsState))
    }, [callObject, handleNewParticipantsState])

    /**
     * Change between the simulcast layers based on view / available bandwidth
     */
    const setBandWidthControls = useCallback(() => {
        if (!(callObject && callObject.meetingState() === 'joined-meeting')) return

        const ids = participantIds.split(',')
        const receiveSettings: DailyReceiveSettingsUpdates = {}

        ids.forEach(id => {
            if (isLocalId(id)) return

            if (
                // weak or bad network
                (['low', 'very-low'].includes(networkState) && videoQuality === 'auto') ||
                // Low quality or Bandwidth saver mode enabled
                ['high', 'low'].includes(videoQuality)
            ) {
                receiveSettings[id] = {video: {layer: 0}}
                return
            }

            // Grid view settings are handled separately in GridView
        })
        callObject.updateReceiveSettings(receiveSettings)
    }, [currentSpeaker?.id, callObject, networkState, participantIds, videoQuality])

    useEffect(() => {
        setBandWidthControls()
    }, [setBandWidthControls])

    useEffect(() => {
        if (!callObject) return
        const handleActiveSpeakerChange = ({activeSpeaker}: DailyEventObjectActiveSpeakerChange) => {
            /**
             * Ignore active-speaker-change events for the local user.
             * Our UX doesn't ever highlight the local user as the active speaker.
             */
            const localId = callObject.participants().local.session_id
            if (localId === activeSpeaker?.peerId) return

            dispatch({
                type: ACTIVE_SPEAKER,
                id: activeSpeaker?.peerId,
            })
        }
        callObject.on('active-speaker-change', e => handleActiveSpeakerChange(e!))
        return () => {
            callObject.off('active-speaker-change', e => handleActiveSpeakerChange(e!))
        }
    }, [callObject])

    const result: ParticipantsContextState = {
        activeParticipant,
        allParticipants,
        currentSpeaker,
        localParticipant,
        participantCount,
        participants,
        screens,
        muteAll,
        setUsername,
        swapParticipantPosition,
        username,
        isOwner,
    }

    return <ParticipantsContext.Provider value={result}>{children}</ParticipantsContext.Provider>
}

export const useParticipants = () => useContext(ParticipantsContext)
