import {Combobox} from '@headlessui/react'
import {useEffect, useMemo, useState} from 'react'

import {ChevronDown as CollapseIcon} from '@styled-icons/boxicons-regular/ChevronDown'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import classNames from 'classnames'

interface ParticipantSelectProps {
    participants: {
        [id: string]: ParticipantViewModel
    }
    defaultId?: string
    label?: string
    onChange: (p?: string) => void
    className?: string
    placeholder?: string
    exclude?: string[]
}

export default function ParticipantSelect({
    participants,
    defaultId,
    label = '',
    onChange,
    className,
    placeholder,
    exclude,
}: ParticipantSelectProps) {
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(defaultId ? participants[defaultId] : undefined)

    useEffect(() => {
        onChange(selectedPerson?.id)
    }, [selectedPerson])

    const filteredParticipants = useMemo(() => {
        let participantsExcluded = exclude
            ? Object.values(participants).filter(p => !exclude.includes(p.id))
            : Object.values(participants)

        if (query === '') return Object.values(participantsExcluded)
        return Object.values(participantsExcluded).filter(participant => {
            return participant.name.toLowerCase().includes(query.toLowerCase())
        })
    }, [participants, query])

    return (
        <Combobox as="div" className={className} value={selectedPerson} onChange={setSelectedPerson}>
            <Combobox.Label className="block text-sm font-medium text-gray-500">{label}</Combobox.Label>
            <div className="relative">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-800 bg-neutral py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
                    onChange={event => setQuery(event.target.value)}
                    displayValue={(person?: ParticipantViewModel) => person?.name ?? ''}
                    placeholder={placeholder}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <CollapseIcon className="w-5"></CollapseIcon>
                </Combobox.Button>

                {filteredParticipants.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredParticipants.map(person => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({active}) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-primary text-white' : 'text-gray-900',
                                    )
                                }
                            >
                                {({active, selected}) => (
                                    <>
                                        <div className="flex items-center">
                                            <AmplifierAvatar
                                                src={person.picture}
                                                name={person.name}
                                                size="xs2"
                                            ></AmplifierAvatar>
                                            <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                                                {person.name}
                                            </span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-primary-600',
                                                )}
                                            ></span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
}
