import {ChevronLeft} from '@styled-icons/fa-solid/ChevronLeft'
import {ChevronRight} from '@styled-icons/fa-solid/ChevronRight'
import './Pagination.scss'

interface PaginationProps {
    page: number
    pages: number
    previous: () => void
    next: () => void
}
export default function Pagination({page, pages, previous, next}: PaginationProps) {
    if (pages < 2) {
        return null
    }

    return (
        <>
            <button className="pagination prev" type="button" onClick={previous} disabled={!(pages > 1 && page > 1)}>
                <ChevronLeft size="1em" />
            </button>
            <button className="pagination next" type="button" onClick={next} disabled={!(pages > 1 && page < pages)}>
                <ChevronRight size="1em" />
            </button>
        </>
    )
}
