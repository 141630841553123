import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useDaily} from '@daily-co/daily-react'
import {useAmplifierSocket} from '../../../../contexts/AmplifierSocketProvider'
import actions from '../../../../store/eventState'
import HashtagInput from '../../Common/HashtagInput'
import {useAppDispatch, useAppSelector} from '../../../../store'
import HashtagPills from '../../Common/HashtagPills'
import {Hashtag} from 'shared-types/view-models/SocketModels'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'

interface ProfileFormData {
    name: string
    company: string
    title: string
    teamId?: string
}
const SidebarProfile = () => {
    const callObject = useDaily()!

    const dispatch = useAppDispatch()
    const {updateProfile} = useAmplifierSocket()
    const {localParticipant} = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const hashtags = useAppSelector(({amplifierSocket}) => amplifierSocket.hashtags)
    const {updateHashtags} = useAmplifierSocket()

    const socketProfile = {
        name: localParticipant.name,
        company: localParticipant.company,
        title: localParticipant.title,
        group: localParticipant.group,
    }

    const [profile, setProfile] = useState<ProfileFormData>({
        name: '',
        company: '',
        title: '',
        teamId: undefined,
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value,
        } as ProfileFormData)
    }

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(
            actions.SetAttendeeData({
                name: profile.name,
                company: profile.company,
                title: profile.title,
                group: socketProfile.group,
                hashtags: localParticipant.hashtags,
            }),
        )

        callObject.setUserName(`${profile.name}`)

        updateProfile({
            name: profile.name,
            title: profile.title,
            company: profile.company,
        })

        toast.info('Profile updated 🎉', {containerId: 'All'})
    }

    const submitNewHashtags = (hashtags: Hashtag[]) => {
        dispatch(
            actions.SetAttendeeData({
                ...profile,
                hashtags,
            }),
        )
        updateHashtags(hashtags)
    }

    // Update profile data when socket data updates
    useEffect(() => {
        if (!localParticipant) return
        callObject.setUserName(localParticipant.name)
        if (!hasProfileChanged()) return
        setProfile({
            name: localParticipant.name,
            company: localParticipant.company,
            title: localParticipant.title,
            teamId: localParticipant.teamId,
        })
    }, [localParticipant])

    const hasProfileChanged = (): boolean => {
        return !(
            localParticipant.name === profile.name &&
            localParticipant.title === profile.title &&
            localParticipant.company === profile.company &&
            localParticipant.teamId === profile.teamId
        )
    }

    return (
        <div className="my-2 px-3 flex flex-col gap-6">
            <form onSubmit={submitHandler} noValidate className="flex flex-col gap-2 mt-2 mb-6">
                <div className="flex flex-row justify-between items-end">
                    <h1 className="mb-2">Profile</h1>
                    <AmplifierAvatar size="sm" src={localParticipant.picture} name={localParticipant.name} />
                </div>
                <div className="form-control w-full">
                    <label className="label" htmlFor="name">
                        <span className="label-text">Edit Full Name</span>
                    </label>
                    <input
                        id="full-name"
                        type="text"
                        placeholder="Full Name"
                        className="input input-bordered w-full"
                        name="name"
                        value={profile.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control w-full">
                    <label className="label" htmlFor="title">
                        <span className="label-text">Edit Title</span>
                    </label>
                    <input
                        id="title"
                        type="text"
                        placeholder="Edit title"
                        className="input input-bordered w-full"
                        name="title"
                        value={profile.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control w-full">
                    <label className="label" htmlFor="company">
                        <span className="label-text">Edit Organization</span>
                    </label>
                    <input
                        id="company"
                        type="text"
                        placeholder="Edit Organization"
                        className="input input-bordered w-full"
                        name="company"
                        value={profile.company}
                        onChange={handleChange}
                    />
                </div>
                <div className="mt-2">
                    <button type="submit" disabled={!hasProfileChanged()} className="btn btn-primary">
                        Update Profile
                    </button>
                </div>
            </form>
            <div>
                <h2 className="mb-3">Your Hashtags</h2>
                <HashtagInput
                    selectedHashtags={localParticipant.hashtags}
                    addHashtag={newHashtag => updateHashtags([...localParticipant.hashtags, newHashtag])}
                    hashtags={hashtags}
                    placeholder="Enter a new hashtag"
                />
                <div className="form-control w-full">
                    <label className="label" htmlFor="hashtags">
                        <span className="label-text">Enter some of your interests for others to see</span>
                    </label>
                </div>
                <HashtagPills
                    selectedHashtags={localParticipant.hashtags}
                    setSelectedHashtags={submitNewHashtags}
                    hashtags={hashtags}
                    showMax={30}
                />
            </div>
        </div>
    )
}

export default SidebarProfile
