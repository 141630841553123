import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import clsx from 'clsx'
import {useAmplifierSocket} from '../../../contexts/AmplifierSocketProvider'
import {useParticipants} from '../../../contexts/ParticipantsProvider'
import {useDaily} from '@daily-co/daily-react'
import {LockOpen as LockOpenIcon} from '@styled-icons/material/LockOpen'
import {Lock as LockIcon} from '@styled-icons/material/Lock'
import {MicOff as MicOffIcon} from '@styled-icons/material/MicOff'
import {Mic as MicOnIcon} from '@styled-icons/material/Mic'
import {useAppSelector} from '../../../store'
import {createPortal} from 'react-dom'
import './HostControls.scss'

export interface HostControlsHandleProps {
    canOpenControls: () => boolean
    openControls: (event: React.MouseEvent<HTMLDivElement>) => void
}
interface HostControlsProps {
    participantId: string
}
// Right Click on a participant to see different host controls
// Included anywhere a participant is displayed
const HostControls = forwardRef<HostControlsHandleProps, HostControlsProps>(
    ({participantId}, ref: React.ForwardedRef<HostControlsHandleProps>) => {
        const [contextMenu, setContextMenu] = React.useState<{mouseX: number; mouseY: number} | undefined>()
        const {isHost} = useAppSelector(({amplifierSocket}) => amplifierSocket)
        const participant = useAppSelector(({amplifierSocket}) => amplifierSocket.allParticipants[participantId])
        const {grantHost} = useAmplifierSocket()
        const daily = useDaily()!

        const {participants, isOwner} = useParticipants()

        useImperativeHandle(ref, () => ({
            canOpenControls: () => isHost,
            openControls: event => {
                if (isHost) {
                    event.preventDefault()
                    if (contextMenu) {
                        setContextMenu(undefined)
                    }
                    setContextMenu({
                        mouseX: event.clientX - 2,
                        mouseY: event.clientY - 4,
                    })
                }
            },
        }))

        const dailyParticipant = participants.find(p => !p.isScreenshare && p.id === participantId)

        const handleClose = () => {
            setContextMenu(undefined)
        }

        useEffect(() => {
            window.addEventListener('click', handleClose)
            return () => {
                window.removeEventListener('click', handleClose)
            }
        }, [])

        if (
            !isHost ||
            !participant ||
            !dailyParticipant ||
            dailyParticipant.isLocal ||
            participantId?.includes('-screen')
        )
            return <></>

        const sniperMute = (mute = true) => {
            daily.updateParticipant(participant.id, {
                setAudio: mute,
            })
        }

        const menuItems = [
            {
                key: 'Host Action',
                value: !participant.isHost,
                trueLabel: 'Make Host',
                falseLabel: 'Disable Host',
                trueIcon: LockOpenIcon,
                falseIcon: LockIcon,
                action: () => {
                    grantHost(participant.id, !participant.isHost)
                },
                show: true,
            },
            {
                key: 'Mute Action',
                show: isOwner,
                value: !dailyParticipant.isMicMuted,
                trueLabel: 'Mute',
                falseLabel: 'Unmute',
                trueIcon: MicOnIcon,
                falseIcon: MicOffIcon,
                action: () => {
                    sniperMute(dailyParticipant.isMicMuted)
                },
            },
        ]

        const isOpen = contextMenu !== undefined

        if (!isOpen) {
            return null
        }

        return createPortal(
            <div
                className={clsx('hostControls menu-dropdown', isOpen && 'menu-dropdown-open')}
                style={{top: contextMenu?.mouseY ?? 0, left: contextMenu?.mouseX ?? 0}}
            >
                <ul className="menu bg-base-100 rounded-box">
                    {menuItems.map(menuItem => {
                        if (!menuItem.show) return null
                        const {key, action, value, trueIcon, trueLabel, falseIcon, falseLabel} = menuItem

                        const Icon = value ? trueIcon : falseIcon
                        const label = value ? trueLabel : falseLabel
                        return (
                            <li
                                key={key}
                                onClick={() => {
                                    action()
                                    handleClose()
                                }}
                            >
                                <div className="flex flex-row gap-2">
                                    <Icon size="1.5em" />
                                    <div>{label}</div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>,
            document.body,
        )
    },
)

export default HostControls
