import {useEffect, useRef} from 'react'

// Custom pollyfills for Old chrome & safari.
if (!window.AudioContext) {
    window.AudioContext = (window as any).webkitAudioContext
}

// Copied from https://github.com/stramanu/get-float-time-domain-data-polyfill/blob/master/src/get-float-time-domain-data-polyfill.ts
if (window.AnalyserNode && !window.AnalyserNode.prototype.getFloatTimeDomainData) {
    var uint8 = new Uint8Array(2048)
    window.AnalyserNode.prototype.getFloatTimeDomainData = function (e) {
        this.getByteTimeDomainData(uint8)
        for (var i = 0, imax = e.length; i < imax; i++) {
            e[i] = (uint8[i] - 128) * 0.0078125
        }
    }
}

export const useAudioMeterRef = (mediaStream?: MediaStream) => {
    const animationFrameRef = useRef<number>()
    const audioMeterRef = useRef<number>()

    // Setup audio levels visual thing
    useEffect(() => {
        if (!mediaStream) return

        registerAnimationFrame()
        return () => {
            animationFrameRef.current && cancelAnimationFrame(animationFrameRef.current)
        }
    }, [mediaStream])

    const registerAnimationFrame = async () => {
        const audioContext = new AudioContext()
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(mediaStream!)
        const analyserNode = audioContext.createAnalyser()
        mediaStreamAudioSourceNode.connect(analyserNode)

        const pcmData = new Float32Array(analyserNode.fftSize)
        const animate = () => {
            analyserNode.getFloatTimeDomainData(pcmData)
            let sumSquares = 0.0
            for (const amplitude of pcmData) {
                sumSquares += amplitude * amplitude
            }
            const float = Math.sqrt(sumSquares / pcmData.length)

            // Convert 0-1 float to an integer between 1-10
            let magicRounding = Math.round(Math.round(float * 1000) / 15)
            if (magicRounding < 1) magicRounding = 1
            else if (magicRounding > 10) magicRounding = 10
            audioMeterRef.current = magicRounding

            // Run function on next animation frame
            animationFrameRef.current = requestAnimationFrame(animate)
        }
        animationFrameRef.current = requestAnimationFrame(animate)
    }

    return audioMeterRef
}

export default useAudioMeterRef
