import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {backendTarget} from '~/api'
import './Ignite.scss'

export default function IgniteLanding() {
    return (
        <>
            <h3 className="text-primary w-full igniteheader text-center">Ignite</h3>
            <h2 className="w-full text-center mb-12">Founder & Investor Series</h2>
            <p className="mt-2 text-sm text-gray-600">Please enter your payment details to join the waitlist.</p>
            <p className="mt-2 text-sm text-gray-600">
                You won’t be charged unless you are accepted. You can request a full refund if you change your mind
                within 30 days.
            </p>
            <a href={backendTarget + `/api/v1/ignite`}>
                <button type="submit" className={cn('btn btn-primary w-full mt-8')}>
                    Continue to Payment
                </button>
            </a>

            <a href="#" className="group block no-underline w-full flex-shrink-0">
                <div className="flex items-center w-full justify-center mt-12">
                    <div>
                        <img className="inline-block h-12 w-12 " src={goldLogo} alt="" />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm no-underline font-medium text-gray-700 group-hover:text-gray-900">
                            Powered By Amplifier
                        </p>
                    </div>
                </div>
            </a>
        </>
    )
}
