import {useAppDispatch, useAppSelector} from '~/store'
import actions from '~/store/eventState'

export default function ChatSettings() {
    const dispatch = useAppDispatch()

    const eventState = useAppSelector(state => state.eventState)

    const submitSettingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            actions.SetSetting({
                setting: e.target.name,
                value: e.target.checked,
            }),
        )
    }

    return (
        <div>
            <div className="w-64">
                <div className="form-control">
                    <label className="label cursor-pointer pl-0">
                        <span className="label-text font-medium">Enable Chat Notifications</span>
                        <input
                            type="checkbox"
                            className="toggle"
                            name="chatNotifications"
                            checked={eventState.settings.chatNotifications}
                            onChange={submitSettingChange}
                        />
                    </label>
                </div>
            </div>
        </div>
    )
}
