import {useEffect, useState} from 'react'
import cn from 'classnames'
import DailyIframe, {DailyInputVideoProcessorSettings} from '@daily-co/daily-js'
import {useDaily} from '@daily-co/daily-react'
import {
    backgroundFilters,
    BackgroundFilterType,
    getLocalBackgroundFilter,
    saveBackgroundFilter,
} from '../../../utils/backgroundFilterUtils'
import isEqual from 'lodash/isEqual'
import LightBlurImage from '../../../assets/public/light-blur.jpg'
import HeavyBlurImage from '../../../assets/public/heavy-blur.jpg'
import './BackgroundFilter.scss'

type BackgroundFilterOption = {
    title: string
    name: BackgroundFilterType
    value: DailyInputVideoProcessorSettings
    image?: string
}

const backgroundFilterOptions: BackgroundFilterOption[] = [
    {title: 'None', name: 'none', value: backgroundFilters.none, image: undefined},
    {
        title: 'Light Blur',
        name: 'lightBlur',
        value: backgroundFilters.lightBlur,
        image: LightBlurImage,
    },
    {
        title: 'Heavy Blur',
        name: 'heavyBlur',
        value: backgroundFilters.heavyBlur,
        image: HeavyBlurImage,
    },
]

const supported = DailyIframe.supportedBrowser().supportsAudioProcessing

export default function BackgroundFilter() {
    const daily = useDaily()!
    const [selectedFilter, setSelectedFilter] = useState<BackgroundFilterType>(getLocalBackgroundFilter())

    useEffect(() => {
        saveBackgroundFilter(selectedFilter)
    }, [selectedFilter])

    useEffect(() => {
        getBackgroundFilter()
    }, [])

    const getBackgroundFilter = async () => {
        // Get Daily background processor and set it as the selected
        const inputSettings = await daily.getInputSettings()

        const videoProcessor = inputSettings.video?.processor

        const filter = backgroundFilterOptions.find(option => {
            const {value} = option
            if (!videoProcessor && value.type === 'none') {
                return true
            }
            return isEqual(videoProcessor, value)
        })

        if (filter && filter.name !== selectedFilter) {
            const filterItem = backgroundFilterOptions.find(option => option.name === selectedFilter)!
            // Daily doesn't match, switching
            onFilterSelect(filterItem)
        }
    }

    const onFilterSelect = (item: BackgroundFilterOption) => {
        setSelectedFilter(item.name)
        daily.updateInputSettings({
            video: {
                processor: item.value,
            },
        })
    }

    if (!supported) {
        return <span>We don't currently support background filters on this browser, please use safari</span>
    }

    return (
        <div className="filterSelect">
            {backgroundFilterOptions.map(item => (
                <label
                    key={item.name}
                    htmlFor={item.name}
                    className={cn('filterButton', {active: item.name === selectedFilter})}
                    style={{backgroundImage: `url(${item.image})`}}
                >
                    <p>{item.title}</p>
                    <input
                        type="radio"
                        name="backgroundFilter"
                        id={item.name}
                        value={item.name}
                        checked={item.name === selectedFilter}
                        onChange={() => onFilterSelect(item)}
                    />
                </label>
            ))}
        </div>
    )
}
