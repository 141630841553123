import uniqBy from 'lodash/uniqBy'
import {HTMLAttributes} from 'react'
import {Hashtag} from 'shared-types/view-models/SocketModels'
import clsx from 'clsx'
import Pill from '~/widgets/Pill'
import './HashtagPills.scss'

export interface HashtagPillsProps {
    selectedHashtags: Hashtag[]
    setSelectedHashtags: (hashtags: Hashtag[]) => void
    hashtags: Hashtag[]
    incrementSelectedHashtag?: boolean
    showMax?: number
    className?: string
}
function HashtagPills({
    selectedHashtags,
    setSelectedHashtags,
    hashtags,
    incrementSelectedHashtag,
    showMax = 12,
    className,
}: HashtagPillsProps & HTMLAttributes<HTMLDivElement>) {
    const addTag = (hashtag: Hashtag) => {
        let count = hashtag.count

        if (incrementSelectedHashtag) {
            count++
        }

        setSelectedHashtags([
            ...selectedHashtags,
            {
                id: hashtag.id,
                name: hashtag.name,
                count: count,
            },
        ])
    }

    const removeTag = (hashtag: Hashtag) => {
        setSelectedHashtags(selectedHashtags.filter(tag => tag.id !== hashtag.id))
    }

    const allExistingHashtags = [...selectedHashtags.filter(t => !!t), ...hashtags.filter(t => !!t)]
    let uniqueHashtags = uniqBy(allExistingHashtags, tag => tag.id)

    let hiddenCount = 0
    if (uniqueHashtags.length > showMax) {
        hiddenCount = uniqueHashtags.length - showMax
        uniqueHashtags = uniqueHashtags.slice(0, showMax)
    }

    return (
        <div className={clsx('hashtagPillsList', className)}>
            {uniqueHashtags.map(tag => {
                const isSelected = selectedHashtags.some(selectedTag => selectedTag?.id === tag.id)
                return (
                    <Pill
                        key={tag.id}
                        onClick={() => (isSelected ? removeTag(tag) : addTag(tag))}
                        color={isSelected ? 'primary' : undefined}
                        rounded
                        size="sm"
                        badge={tag.count}
                    >
                        #{tag.name}
                    </Pill>
                )
            })}
            {hiddenCount > 0 && (
                <Pill color="secondary" rounded size="sm">
                    + {hiddenCount} more
                </Pill>
            )}
        </div>
    )
}

export default HashtagPills
