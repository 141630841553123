import {RadioGroup} from '@headlessui/react'
import {FormEvent, useState} from 'react'
import {TenantType} from 'shared-types/view-models/EventModels'
import './CreateCompany.scss'

import clsx from 'clsx'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import {useAppDispatch, useAppSelector} from '~/store'
import {motion} from 'framer-motion'
import actions from '~/store/identityState'
import LoadingSvg from '~/assets/svgs/loading.svg'
import {useNavigate} from 'react-router-dom'

export default function CreateCompany() {
    const [selectedTenantType, setSelectedTenantType] = useState<TenantType>()
    const [tenantName, setTenantName] = useState('')
    const [stage, setStage] = useState('type')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [error, setError] = useState(null)

    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.identityState.user!)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setError(null)
        setLoading(true)
        const response = await dispatch(actions.CreateTenant(tenantName, selectedTenantType!))

        if (typeof response === 'string') {
            setError(response)
            setLoading(false)
            return
        }

        setLoading(false)
        navigate('/start/plan')
        return
    }

    const tenantTypes = [
        {
            type: 'PERSONAL',
            name: 'Individual',
            onClick: () => {
                setStage('name')
                setSelectedTenantType('PERSONAL')
            },
            content: (
                <div className="ob__content">
                    <div className="ob__content__avatar">
                        <AmplifierAvatar src={user.picture} size="lg" name={user.nickname ?? ''} />
                    </div>
                    <RadioGroup.Label>
                        <h2>Individual</h2>
                    </RadioGroup.Label>
                    <p>Create and host events as a personal account</p>
                </div>
            ),
        },
        {
            type: 'COMPANY',
            name: 'Company',
            onClick: () => {
                setStage('name')
                setSelectedTenantType('COMPANY')
            },
            content: (
                <div className="ob__content">
                    <div className="ob__content__avatar">
                        <AmplifierAvatar
                            src="/src/assets/public/white-logo.png"
                            size="lg"
                            className="companyAvatar rounded-full"
                            name={user.nickname ?? ''}
                        />
                    </div>
                    <RadioGroup.Label>
                        <h2>Company</h2>
                    </RadioGroup.Label>
                    <p>Create and host events as a company and add teammates</p>
                </div>
            ),
        },
    ]

    return (
        <motion.div layout className="ob__company__wrapper">
            <div className="ob__company__header">
                <motion.div layout className="ob__company__header__title">
                    <h1>
                        {stage === 'type'
                            ? 'How will you use Amplifier?'
                            : selectedTenantType === 'COMPANY'
                            ? "What's your company name?"
                            : "What's your full name?"}
                    </h1>
                </motion.div>
            </div>

            <motion.div className={clsx('ob__company__form flex')}>
                {stage === 'type' && (
                    <motion.div
                        initial={{opacity: 0, scale: 0.8}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            duration: 0.5,
                            delay: 0,
                            ease: [0, 0.71, 0.2, 1.01],
                        }}
                    >
                        <RadioGroup value={selectedTenantType} onChange={setSelectedTenantType}>
                            <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                {tenantTypes.map(option => (
                                    <RadioGroup.Option
                                        key={option.type}
                                        value={option.type}
                                        className={({checked, active}) =>
                                            clsx(
                                                checked ? ' ring-primary ring-2' : 'border-gray-800',
                                                active ? ' ring-primary ring-2' : '',
                                                'ob__company__option',
                                            )
                                        }
                                        onClick={option.onClick}
                                    >
                                        {() => <>{option.content}</>}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>
                    </motion.div>
                )}

                {stage === 'name' && (
                    <motion.div
                        className="ob__tenant__name"
                        initial={{opacity: 0, scale: 0.8}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            duration: 0.5,
                            delay: 0,
                            ease: [0, 0.71, 0.2, 1.01],
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <div className="ob__tenant__name__input ">
                                {error ? (
                                    <label className="ob__error" htmlFor="tenantName">
                                        {error}
                                    </label>
                                ) : (
                                    <label htmlFor="tenantName">
                                        {selectedTenantType === 'COMPANY' ? 'Company Name' : 'Name'}
                                    </label>
                                )}

                                <input
                                    type="text"
                                    name="email"
                                    value={tenantName}
                                    onChange={e => setTenantName(e.target.value)}
                                    id="tenantName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm   placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                    placeholder={selectedTenantType === 'COMPANY' ? 'Acme Ltd' : 'Bob Jones'}
                                />
                            </div>

                            <button
                                type="submit"
                                disabled={tenantName.length <= 2 || loading}
                                className={clsx(
                                    'btn',
                                    tenantName.length > 2 && !loading ? 'btn-primary' : 'btn-disabled',
                                )}
                            >
                                Continue{' '}
                                {loading ? (
                                    <LoadingSvg
                                        className={clsx('ml-3', !loading && 'hidden')}
                                        height="1em"
                                        width="1em"
                                    />
                                ) : (
                                    '→'
                                )}
                            </button>
                        </form>
                        <button
                            className="btn ob__backButton"
                            onClick={() => {
                                setStage('type')
                                setSelectedTenantType(undefined)
                                setTenantName('')
                            }}
                        >
                            Back
                        </button>
                    </motion.div>
                )}
            </motion.div>
        </motion.div>
    )
}
