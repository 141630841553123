import './DashboardWrap.scss'

import {Outlet, useOutletContext} from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import {useState} from 'react'
import BreadCrumbs from './BreadCrumbs/BreadCrumbs'

type ContextType = {breadCrumbs: any; setBreadCrumbs: any}

export default function DashboardWrap() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [breadCrumbs, setBreadCrumbs] = useState([])

    return (
        <div className="dashboard__wrapper" data-theme="light">
            <div className="container">
                <div className="under">
                    <Navigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <div className="page__content">
                        <BreadCrumbs setSidebarOpen={setSidebarOpen} pages={breadCrumbs} />
                        <div className="content">
                            <Outlet context={{breadCrumbs, setBreadCrumbs}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function useBreadCrumbs() {
    return useOutletContext<ContextType>()
}
