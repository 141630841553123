import {useMemo, useState} from 'react'
import {useAppSelector} from '~/store'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import './Participants.scss'

import {Logout as LeaveIcon} from '@styled-icons/material/Logout'
import {VariableSizeList as List} from 'react-window'

import {MicOff as MicOffIcon} from '@styled-icons/fluentui-system-filled/MicOff'
import {MicOn as MicOnIcon} from '@styled-icons/fluentui-system-filled/MicOn'
import {Videocam as VideoOnIcon} from '@styled-icons/material/Videocam'
import {VideocamOff as VideoOffIcon} from '@styled-icons/material/VideocamOff'
import {useParticipants} from '~/contexts/ParticipantsProvider'
import {ParticipantItem} from '~/contexts/participantsState'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import {ABGroup} from 'shared-types/view-models/SocketModels'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'

interface ParticipantProps {
    index: number
    style: React.CSSProperties
    data: ListParticipant[]
}
function Participant({index, style, data}: ParticipantProps) {
    const {grantHost, removeParticipant} = useAmplifierSocket()

    const {localId} = useAppSelector(({amplifierSocket}) => ({
        localId: amplifierSocket.localId,
    }))

    const settings = useAppSelector(({eventState}) => eventState.event!.settings)

    const getGroupLabel = (group?: ABGroup) => {
        if (group === 'A') return settings.groupOneLabel
        if (group === 'B') return settings.groupTwoLabel
    }

    return (
        <div style={style} className="w-full h-11 flex">
            <div className=" flex-grow  pl-4 py-3 pr-3 text-sm font-medium align-top text-white sm:pl-6">
                <div className="flex items-center content-center">
                    <div className=" flex-shrink-0">
                        <AmplifierAvatar
                            src={data[index].viewmodel?.picture}
                            name={data[index].viewmodel?.name}
                            size="xs2"
                        ></AmplifierAvatar>{' '}
                    </div>
                    <div className="ml-4">
                        <div className="font-medium text-white space-x-2">
                            <span>{data[index].viewmodel?.name}</span>

                            {data[index].viewmodel?.group && (
                                <span className="groupTag">{getGroupLabel(data[index].viewmodel.group)}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden flex-grow whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {data[index].viewmodel?.title} at {data[index].viewmodel?.company}
            </div>
            <div className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {data[index].viewmodel?.isHost ? 'Host' : 'Participant'}
            </div>
            <div className="whitespace-nowrap py-2 space-x-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                {data[index].item?.isCamMuted ? (
                    <div className="tooltip" data-tip={"Hosts can't enable video"}>
                        <button disabled className="btn btn-circle w-7 bg-red-500 bg-opacity-50 participant-button">
                            <VideoOffIcon size="1.2em"></VideoOffIcon>
                        </button>
                    </div>
                ) : (
                    <div className="tooltip" data-tip={'Disable video for ' + data[index].viewmodel?.name}>
                        <button className="btn btn-circle w-7 participant-button">
                            <VideoOnIcon size="1.2em" />
                        </button>
                    </div>
                )}

                {data[index].item?.isMicMuted ? (
                    <div className="tooltip" data-tip={'Unmute ' + data[index].viewmodel?.name}>
                        <button className="btn btn-circle bg-red-500 bg-opacity-50 w-7 participant-button">
                            <MicOffIcon size="1.2em" />
                        </button>
                    </div>
                ) : (
                    <div className="tooltip " data-tip={'Mute ' + data[index].viewmodel?.name}>
                        <button className="btn btn-circle  w-7 participant-button">
                            <MicOnIcon size="1.2em" />
                        </button>
                    </div>
                )}

                {data[index].viewmodel?.isHost ? (
                    <div
                        className="tooltip tooltip-left "
                        data-tip={
                            data[index].item?.isOwner || data[index].viewmodel?.id === localId
                                ? "Can't remove owner or yourself as host"
                                : 'Remove ' + data[index].viewmodel?.name + ' as Host'
                        }
                    >
                        <button
                            disabled={data[index].item?.isOwner || data[index].viewmodel?.id === localId}
                            className=" w-32 btn btn-accent bg-transparent text-red-500 hover:bg-transparent hover:ring-2 hover:ring-red-500 border-none bg-opacity-50  participant-button"
                            onClick={() => grantHost(data[index].viewmodel?.id, false)}
                        >
                            Disable Host
                        </button>
                    </div>
                ) : (
                    <div
                        className="tooltip tooltip-left w-32"
                        data-tip="You can remove them as host afterwards"
                        onClick={() => grantHost(data[index].viewmodel?.id, true)}
                    >
                        <button className=" w-32 btn btn-accent bg-transparent  hover:bg-transparent hover:ring-2 hover:ring-accent text-accent border-none bg-opacity-50  participant-button">
                            Make Host
                        </button>
                    </div>
                )}

                <div
                    onClick={() => removeParticipant(data[index].viewmodel?.id, true)}
                    className="tooltip tooltip-left"
                    data-tip={"This user won't be able to rejoin this session"}
                >
                    <button className="btn btn-accent bg-red-500 hover:bg-transparent hover:ring-2 hover:ring-red-500 text-red-500 border-none bg-opacity-50  participant-button">
                        <LeaveIcon size="1em" /> Remove
                    </button>
                </div>
            </div>
        </div>
    )
}

type ListParticipant = {
    item: ParticipantItem
    viewmodel: ParticipantViewModel
    filterstring: string
    moveParticipant: any
}

export default function Participants({
    setMoveParticipant,
}: {
    setMoveParticipant: (value: {isOpen: boolean; participantId?: string | undefined}) => void
}) {
    const [filter, setFilter] = useState('')

    const {allParticipants, localId} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
        localId: amplifierSocket.localId,
    }))

    const {participants} = useParticipants()

    let p: ListParticipant[] = useMemo(() => {
        return Object.values(allParticipants)
            .map(viewmodel => {
                return {
                    filterstring:
                        viewmodel.name +
                        viewmodel.company +
                        viewmodel.title +
                        viewmodel.hashtags.map(e => e.name).toString() +
                        (viewmodel.isHost ? 'host' : 'participant') +
                        (viewmodel.group ? viewmodel.group : ''),

                    viewmodel: viewmodel,
                    moveParticipant: setMoveParticipant,
                    item:
                        localId === viewmodel.id
                            ? participants.find(e => e.id === 'local')
                            : participants.find(e => e.id === viewmodel.id),
                } as ListParticipant
            })
            .filter(e => e.filterstring.toLowerCase().includes(filter.toLowerCase()))
    }, [allParticipants, participants, filter])

    return (
        <div className="w-full h-96 max-h-96 flex flex-wrap relative">
            <div className="w-1/3 h-12">
                <input
                    type="text"
                    name="filter"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    className="block w-full rounded-md border-gray-800 shadow-sm focus:border-primary focus:ring-primary bg-neutral text-white sm:text-sm"
                    placeholder="Filter Participants..."
                />
            </div>

            <div className="w-full h-80">
                <div className="-mx-4 mt-6 bg-neutral shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    <List height={320} itemData={p} itemCount={p.length} itemSize={() => 44} width="100%">
                        {({index, data, style}) => <Participant key={index} data={data} style={style} index={index} />}
                    </List>
                </div>
            </div>
        </div>
    )
}
