import React from 'react'
import {trpc} from '~/api/trpc'
import Loading from '../common/Loading'
import styles from './AdminWrapper.module.scss'
import goldLogo from '~/assets/public/gold-logo.png'
import {Link, Outlet} from 'react-router-dom'

const AdminWrapper: React.FC = () => {
    const userQuery = trpc.identity.me.useQuery()

    const navigation = [
        {
            name: 'home',
            route: '/admin',
        },
        {
            name: 'customers',
            route: '/admin/customers',
        },
    ]

    if (userQuery.isLoading) {
        return <Loading />
    }

    if (userQuery.isError) {
        return <span className="text-error">{userQuery.error.message}</span>
    }

    return (
        <div className={styles.adminDashboard}>
            <div className={styles.container}>
                <nav className={styles.nav} data-theme="dark">
                    <div className={styles.adminLogo}>
                        <img className="inline-block h-12 w-12 " src={goldLogo} alt="" />
                        <h2>Admin</h2>
                    </div>
                    <ul>
                        {navigation.map(item => (
                            <li>
                                <Link to={item.route}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className={styles.outlet} data-theme="light">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AdminWrapper
