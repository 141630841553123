import {Link, useLocation} from 'react-router-dom'
import goldLogo from '~/assets/public/gold-logo.png'
import GoogleIcon from '~/assets/icons/google-icon.svg'
import LinkedInIcon from '~/assets/icons/linkedin.svg'
import {useAppSelector} from '../../store'
import Loading from '../common/Loading'
import './Login.scss'
import {backendTarget} from '~/api'

const Login = () => {
    const location = useLocation()
    const identityLoading = useAppSelector(state => state.identityState.identityLoadingState === 'loading')

    let next = ''
    if (location.search) {
        next = location.search
    }

    return (
        <>
            {identityLoading && <Loading />}
            {!identityLoading && (
                <>
                    <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
                    <h2>Welcome!</h2>
                    <p className="mt-2 text-sm text-gray-600">Welcome! How would you like to log in?</p>
                    <div className="mt-6 flex flex-col gap-4">
                        <a href={backendTarget + '/api/v1/identity/google' + next} className="loginButton">
                            <div className="startIcon">
                                <GoogleIcon />
                            </div>
                            Continue with Google
                        </a>
                        <a href={backendTarget + '/api/v1/identity/linkedin' + next} className="loginButton">
                            <div className="startIcon">
                                <LinkedInIcon />
                            </div>
                            Continue with LinkedIn
                        </a>
                        <Link to={'/signup' + next} className="loginButton create">
                            Create an account
                        </Link>
                        <p className="mt-2 text-sm text-gray-600">
                            Already have an account?
                            <Link to={'/login/manual' + next} className="underline">
                                {' '}
                                Log in with email
                            </Link>
                        </p>
                    </div>
                </>
            )}
        </>
    )
}

export default Login
