import cn from 'classnames'
import {useAppDispatch, useAppSelector} from '../../../store'
import {PeopleFill as BreakoutSettingsIcon} from '@styled-icons/bootstrap/PeopleFill'
import {Videocam as VideocamIcon} from '@styled-icons/material/Videocam'
import {Settings as SettingsIcon} from '@styled-icons/material/Settings'
import {Mic as MicIcon} from '@styled-icons/material/Mic'
import {ChatSquareDotsFill as ChatIcon} from '@styled-icons/bootstrap/ChatSquareDotsFill'
import AudioSettings from './AudioSettings'
import VideoSettings from './VideoSettings'
import BreakoutSettings from './BreakoutSettings'
import actions, {SettingsPage} from '../../../store/eventState'
import ChatSettings from './ChatSettings'
import GeneralSettings from './GeneralSettings'
import AmplifierModal from '~/widgets/AmplifierModal'
import './SettingsModal.scss'

interface SettingsModalProps {
    isInLobby?: boolean
}
export default function SettingsModal({isInLobby = false}: SettingsModalProps) {
    const {isOpen, page} = useAppSelector(store => store.eventState.settingsModal)
    const isHost = useAppSelector(({amplifierSocket}) => amplifierSocket.isHost)

    const dispatch = useAppDispatch()
    const close = () => dispatch(actions.SetModalOpen(false))

    const navigation = [
        {name: 'Video', page: SettingsPage.Video, icon: VideocamIcon},
        {name: 'Audio', page: SettingsPage.Audio, icon: MicIcon},
        {name: 'Chat', page: SettingsPage.Chat, icon: ChatIcon, disabled: isInLobby},
        {name: 'Breakout', page: SettingsPage.Breakout, icon: BreakoutSettingsIcon, disabled: !isHost || isInLobby},
        {name: 'General', page: SettingsPage.General, icon: SettingsIcon},
    ]

    let currentPage = navigation.find(item => item.page === page)
    if (!currentPage) {
        currentPage = navigation[0]
    }

    return (
        <AmplifierModal isOpen={isOpen} onClose={close} size="large">
            {/* Modal Content */}
            <div className="settingsModal">
                <div className="navigationWrap">
                    <nav className="settingsNav">
                        {navigation.map(item => {
                            if (item.disabled) return null
                            return (
                                <button
                                    key={item.name}
                                    onClick={() => dispatch(actions.SetModalOpen(item.page))}
                                    className={cn(item.page === page ? 'active' : '', 'navItem')}
                                >
                                    <item.icon aria-hidden="true" />
                                    <span className="hidden sm:inline">{item.name}</span>
                                </button>
                            )
                        })}
                    </nav>
                </div>
                <div className="settingsContent">
                    <h2 className="my-2 text-2xl font-medium">{currentPage.name} Settings</h2>
                    {page === SettingsPage.Audio && <AudioSettings />}
                    {page === SettingsPage.Video && <VideoSettings />}
                    {page === SettingsPage.Breakout && <BreakoutSettings />}
                    {page === SettingsPage.Chat && <ChatSettings />}
                    {page === SettingsPage.General && <GeneralSettings />}
                </div>
            </div>
        </AmplifierModal>
    )
}
