import {useEffect} from 'react'
import cn from 'classnames'
import {MicrophoneOff as MicOffIcon} from '@styled-icons/boxicons-solid/MicrophoneOff'
import {Microphone as MicOnIcon} from '@styled-icons/boxicons-solid/Microphone'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'

interface MicSelectProps {
    isMicMuted: boolean
    onClick: () => void
    disabled?: boolean
}
export default function MicSelect({isMicMuted, onClick, disabled = false}: MicSelectProps) {
    const {currentDevices, mics, setMicDevice, updateDeviceState} = useMediaDevices()

    const currentMic =
        mics?.find(i => {
            if (!currentDevices?.mic) {
                return false
            }
            return (currentDevices?.mic as MediaDeviceInfo)?.deviceId === i.deviceId
        }) ?? null

    const currentMicId = currentMic?.deviceId

    useEffect(() => {
        updateDeviceState()
    }, [])

    if (!currentDevices || !mics) return null

    const setMic = (deviceId: string) => {
        const newDevice = mics.find(mic => mic.deviceId === deviceId)
        if (newDevice) {
            setMicDevice(newDevice, true)
        }
    }

    return (
        <>
            <div className="form-control mt-2">
                <div className="input-group input-group-sm">
                    <button
                        className={cn('deviceButton btn btn-accent', {disabled: isMicMuted})}
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {!isMicMuted ? <MicOnIcon width="1.5rem" /> : <MicOffIcon width="1.5rem" />}
                    </button>
                    <select
                        className="select select-bordered"
                        value={currentMicId}
                        onChange={e => setMic(e.target.value)}
                        disabled={mics.length === 0}
                    >
                        <option disabled value="">
                            Select a Microphone
                        </option>
                        {mics.map(mic => {
                            const title = mic.deviceId === 'default' ? `System ${mic.label}` : mic.label

                            return (
                                <option key={mic.deviceId} value={mic.deviceId}>
                                    {title}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </>
    )
}
