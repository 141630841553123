import {useState} from 'react'
import {EventSettings, EventViewModel} from 'shared-types/view-models'

import clsx from 'clsx'
import EventTypeSelector from '~/components/common/EventTypeSelector/EventTypeSelector'
import LoadingSvg from '~/assets/svgs/loading.svg'
import {CloseCircle as CloseCircleIcon} from '@styled-icons/evaicons-solid/CloseCircle'
import AmplifierModal from '~/widgets/AmplifierModal'
import {deleteEvent} from '~/api/eventsApi'
import {useAppSelector} from '~/store'
import {useNavigate} from 'react-router-dom'

export default function EditDetails({
    event,
    onSubmit,
    onCancel,
    loading,
    submitError,
}: {
    event: EventViewModel
    onCancel: () => void
    onSubmit: (event: EventViewModel) => void
    loading: boolean
    submitError?: string
}) {
    const [title, setTitle] = useState(event.title)
    const tenant = useAppSelector(state => state.identityState.tenant!)
    const [nameError, setNameError] = useState('')
    const [settings, setSettings]: [EventSettings, any] = useState(event.settings)

    const [deleting, setDeleting] = useState(false)
    const [deleteError, setDeleteError] = useState('')

    const navigate = useNavigate()

    const cancelEditing = () => {
        setTitle('')
        setSettings(event.settings)

        onCancel()
        return
    }

    const handleDeleteEvent = async () => {
        setDeleting(true)
        setDeleteError('')
        const response = await deleteEvent(event.code, tenant.slug)
        setDeleting(false)

        if (response.type === 'delete_event') {
            navigate('/dashboard/events')
            return
        }

        setDeleteError(response.message)
    }

    const prepareSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (title.length < 1) {
            setNameError('Event name is required')
            return
        }

        return onSubmit({
            ...event,
            title,
            settings,
        })
    }

    return (
        <form
            onSubmit={prepareSubmit}
            className="bg-white mx-12 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
            <AmplifierModal isOpen={deleting} onClose={() => setDeleting(false)}>
                <div className="flex font-semibold flex-col items-center py-4 justify-center gap-y-4">
                    <h3>Are you sure you want to delete this event?</h3>

                    <div className="ml-2 px-4 bg-gray-200 my-8 text-gray-500  h-7 text-sm font-semibold inline-flex flex-wrap items-center select-none justify-center text-center rounded-md  border-none bg-opacity-50">
                        {event?.code}
                    </div>

                    {deleteError && (
                        <div className="rounded-md w-96 bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CloseCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">{deleteError}</h3>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-row gap-x-8">
                        <button
                            className="text-sm font-semibold leading-6 text-gray-900"
                            onClick={() => setDeleting(false)}
                        >
                            Cancel
                        </button>
                        <button className="btn btn-primary" disabled={deleting} onClick={handleDeleteEvent}>
                            {deleting && <LoadingSvg className={clsx('mr-3')} height="1em" width="1em" />}
                            Delete Event
                        </button>
                    </div>
                </div>
            </AmplifierModal>

            {submitError && (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CloseCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">{submitError}</h3>
                        </div>
                    </div>
                </div>
            )}

            <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                        <div className={clsx('editevent__input', nameError.length > 1 && 'error')}>
                            <label className={clsx(nameError.length > 1 && 'text-red-500')} htmlFor="eventName">
                                {nameError.length > 1 ? nameError : 'Event Name'}
                            </label>
                            <input
                                type="text"
                                name="eventName"
                                id="eventName"
                                value={title}
                                placeholder="e.g. My Event"
                                onChange={e => {
                                    setTitle(e.target.value)
                                    setNameError('')
                                }}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <EventTypeSelector theme="light" settings={settings} onChange={e => setSettings(e)} />
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button className="btn btn-error" onClick={() => setDeleting(true)} type="button">
                    Delete Event
                </button>

                <div className="gap-x-6 flex">
                    <button
                        onClick={() => cancelEditing()}
                        type="button"
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading && <LoadingSvg className={clsx('mr-3')} height="1em" width="1em" />}
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}
