import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {backendTarget} from '~/api'

export default function IgniteSuccess() {
    return (
        <>
            <h3 className="text-primary w-full igniteheader -mb-2 text-center">Ignite</h3>
            <h3 className="w-full text-center mb-12">Founder & Investor Series</h3>

            <h2 className="w-full text-center mb-12">Congratuations!</h2>

            <p className="mt-2 text-sm text-gray-600">You are officially on the waitlist. We will be in touch.</p>
            <a href="https://amplifier.community/ignite">
                <button type="submit" className={cn('btn btn-primary w-full mt-8')}>
                    FAQs
                </button>
            </a>

            <a href={backendTarget + `/api/v1/ignite`}>
                <button
                    type="submit"
                    className={cn('btn bg-transparent ring-0 border-none hover:bg-gray-200 w-full mt-4')}
                >
                    Manage Account
                </button>
            </a>

            <a href="https://amplifier.community" className="group block no-underline w-full flex-shrink-0">
                <div className="flex items-center w-full justify-center mt-12">
                    <div>
                        <img className="inline-block h-12 w-12 " src={goldLogo} alt="" />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm no-underline font-medium text-gray-700 group-hover:text-gray-900">
                            Powered By Amplifier
                        </p>
                    </div>
                </div>
            </a>
        </>
    )
}
