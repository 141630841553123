import {useEffect, useMemo, useState} from 'react'
import {useAppSelector} from '~/store'
import ParticipantSelect from '../MoveParticipant/ParticipantSelect/ParticipantSelect'

import {DragIndicator} from '@styled-icons/material/DragIndicator'
import {DoneAll as DoneIcon} from '@styled-icons/evaicons-solid/DoneAll'
import {Cancel as CancelledIcon} from '@styled-icons/material-outlined/Cancel'
import {Groups as GroupsIcon} from '@styled-icons/material/Groups'

import './PresetMatches.scss'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {createPortal} from 'react-dom'
import classNames from 'classnames'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {PresetMatch} from 'shared-types/view-models/SocketModels'

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

interface MatchCreatorProps {
    createMatch: (value: {p1id: string; p2id: string}) => void
    existingMatches: PresetMatch[]
}

function MatchCreator({createMatch, existingMatches}: MatchCreatorProps) {
    const [participant1, setParticipant1] = useState<string>()
    const [participant2, setParticipant2] = useState<string>()

    const [createDisabled, setCreateDisabled] = useState(false)
    const [disabledReason, setDisabledReason] = useState('')

    const {allParticipants} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
        localId: amplifierSocket.localId,
    }))

    useEffect(() => {
        if (!participant1 || !participant2) {
            setCreateDisabled(true)
            setDisabledReason('Select two participants to create a match')
            return
        }

        if (participant1 === participant2) {
            setCreateDisabled(true)

            setDisabledReason('Cannot match a participant with themselves')
            return
        }

        if (
            participant1 &&
            participant2 &&
            existingMatches.find(
                m =>
                    [participant1, participant2].sort().toString() ===
                    m.match
                        .map(e => e.id)
                        .sort()
                        .toString(),
            )
        ) {
            setDisabledReason('Match already exists')
            setCreateDisabled(true)
            return
        }

        setCreateDisabled(false)
        setDisabledReason('')
    }, [participant1, participant2, existingMatches])

    function create() {
        if (!participant1 || !participant2) {
            return
        }

        createMatch({
            p1id: participant1,
            p2id: participant2,
        })
    }

    return (
        <div className="flex items-center border-gray-800 border rounded-md justify-between content-between px-4 py-4 sm:px-6 w-full bg-neutral">
            <div className="flex text-sm h-12 items-center">
                <div className="mx-2">Match</div>
                <ParticipantSelect
                    defaultId={participant1}
                    participants={allParticipants}
                    onChange={setParticipant1}
                    className="w-64"
                    placeholder="Search for a participant..."
                />
                <div className="mx-2">with</div>
                <ParticipantSelect
                    defaultId={participant2}
                    participants={allParticipants}
                    onChange={setParticipant2}
                    exclude={participant1 ? [participant1] : []}
                    className="w-64"
                    placeholder="Search for a participant..."
                />
            </div>

            <div className="tooltip" data-tip={disabledReason}>
                <button
                    onClick={create}
                    disabled={createDisabled}
                    type="button"
                    className="inline-flex disabled:btn-disabled items-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-neutral sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Create Match
                </button>
            </div>
        </div>
    )
}

interface InactiveMatchProps {
    match: PresetMatch
}
function InactiveMatch({match}: InactiveMatchProps) {
    return (
        <div className="matchItem">
            <div className="container">
                <div className="grab__handle">
                    {match.status === 'completed' ? (
                        <DoneIcon className=" text-green-400 grab__icon " aria-hidden="true" />
                    ) : (
                        <CancelledIcon className="text-red-400 grab__icon " aria-hidden="true" />
                    )}
                </div>
                <div className="participants">
                    <div>Match</div>
                    <div className=" w-64 flex items-center content-center  rounded-md border border-gray-800 bg-neutral py-1 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                        <AmplifierAvatar name={match.match[0].name} size="xs2" src={match.match[0].image} />
                        <span className="ml-2">{match.match[0].name}</span>
                    </div>{' '}
                    <div>with</div>
                    <div className=" w-64 flex items-center content-center  rounded-md border border-gray-800 bg-neutral py-1 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                        <AmplifierAvatar name={match.match[1].name} size="xs2" src={match.match[1].image} />
                        <span className="ml-2">{match.match[1].name}</span>
                    </div>
                </div>
                <div className="ml-4 flex-grow-2"></div>

                <div className=" w-36 flex items-center justify-center text-sm">
                    {match.status === 'cancelled' ? (
                        <div className="tooltip tooltip-left" data-tip={match.cancelledReason}>
                            <span className="text-red-400">Cancelled</span>
                        </div>
                    ) : (
                        <div className="tooltip tooltip-left" data-tip="The match is in-progress or completed">
                            <span className="text-green-400">Matched</span>
                        </div>
                    )}
                </div>

                <div className="action__buttons "></div>
            </div>
        </div>
    )
}

interface ActiveMatchProps {
    match: PresetMatch
    index: number
    isDragging: boolean
    deleteMatch: (index: number) => void
}
function ActiveMatch({index, match, isDragging, deleteMatch}: ActiveMatchProps) {
    const {allParticipants} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
    }))

    const participant1 = useMemo(() => allParticipants[match.match[0].id], [allParticipants, match])
    const participant2 = useMemo(() => allParticipants[match.match[1].id], [allParticipants, match])

    if (!participant1 || !participant2) {
        return null
    }

    return (
        <div className={classNames('matchItem', isDragging && 'dragging')} key={index}>
            <div className="container">
                <div className="grab__handle">
                    <DragIndicator className="grab__icon" aria-hidden="true" />
                </div>
                <div className="participants">
                    <div>Match</div>
                    <div className=" w-64 flex items-center content-center  rounded-md border border-gray-800 bg-neutral py-1 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                        <AmplifierAvatar name={participant1.name} size="xs2" src={participant1.picture} />
                        <span className="ml-2">{participant1.name}</span>
                    </div>{' '}
                    <div>with</div>
                    <div className=" w-64 flex items-center content-center  rounded-md border border-gray-800 bg-neutral py-1 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                        <AmplifierAvatar name={participant2.name} size="xs2" src={participant2.picture} />
                        <span className="ml-2">{participant2.name}</span>
                    </div>
                </div>

                <div className="ml-4 flex-grow-2"></div>

                <div className="w-36 flex items-center justify-center text-sm">Waiting...</div>
                <div className="action__buttons ">
                    <button
                        onClick={() => deleteMatch(match.order)}
                        className="btn btn-accent bg-red-500 hover:bg-transparent hover:ring-2 hover:ring-red-500 text-red-500 border-none bg-opacity-50  participant-button"
                    >
                        Cancel Match
                    </button>
                </div>
            </div>
        </div>
    )
}

export default function PresetMatches() {
    const {allParticipants, preInfluenceMatches} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
        preInfluenceMatches: amplifierSocket.preInfluenceMatches,
    }))

    const {updatePreInfluenceMatches} = useAmplifierSocket()

    function createMatch({p1id, p2id}: {p1id: string; p2id: string}) {
        if (p1id === p2id) return
        if (!allParticipants[p1id] || !allParticipants[p2id]) return
        if (
            activeMatches.find(
                m =>
                    m.match.find(participant => participant.id === p1id) &&
                    m.match.find(participant => participant.id === p2id),
            )
        )
            return

        // add to end of list
        let matchesLength = preInfluenceMatches.filter(e => e.status === 'waiting').length

        updatePreInfluenceMatches([
            ...preInfluenceMatches,
            {
                status: 'waiting',
                order: matchesLength,
                match: [{id: p1id}, {id: p2id}],
            } as PresetMatch,
        ])
    }

    const activeMatches = useMemo(() => {
        return preInfluenceMatches.filter(e => e.status === 'waiting')
    }, [preInfluenceMatches])

    const completeMatches = useMemo(() => {
        return preInfluenceMatches.filter(e => e.status === 'completed')
    }, [preInfluenceMatches])

    const cancelledMatches = useMemo(() => {
        return preInfluenceMatches.filter(e => e.status === 'cancelled')
    }, [preInfluenceMatches])

    function deleteMatch(order: number) {
        let updatedMatches = preInfluenceMatches.map(match => {
            if (order === match.order && match.status === 'waiting') {
                match.status = 'cancelled'
                match.cancelledReason = 'Cancelled by host'
                match.order = -1
            }
            return match
        })

        updatePreInfluenceMatches(updatedMatches)
    }

    function onDragStart() {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100)
        }
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination) return
        if (result.destination.index === result.source.index) return

        let matchesReordered = reorder(activeMatches, result.source.index, result.destination.index)

        matchesReordered = matchesReordered.map((match, index) => {
            match.order = index
            return match
        })

        updatePreInfluenceMatches(matchesReordered.concat(completeMatches).concat(cancelledMatches))
    }

    return (
        <div className="preset-matches">
            <MatchCreator existingMatches={activeMatches} createMatch={createMatch} />

            <div className="overflow-scroll max-h-80 mb-2 pb-3  sm:rounded-md ">
                {activeMatches.length > 0 && (
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="divide-y mt-5 divide-gray-800"
                                >
                                    {activeMatches.map((e, i) => (
                                        <Draggable key={i} draggableId={`${i}`.valueOf()} index={i}>
                                            {(provided, snapshot) => {
                                                const children = (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={provided.draggableProps.style}
                                                        key={i}
                                                    >
                                                        <ActiveMatch
                                                            isDragging={snapshot.isDragging}
                                                            deleteMatch={deleteMatch}
                                                            index={i}
                                                            match={e}
                                                        />
                                                    </div>
                                                )

                                                if (snapshot.isDragging) return createPortal(children, document.body)

                                                return children
                                            }}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}

                {completeMatches.map(m => {
                    return <InactiveMatch match={m} />
                })}
                {cancelledMatches.map(m => {
                    return <InactiveMatch match={m} />
                })}

                {preInfluenceMatches.length === 0 && (
                    <div className="flex items-center flex-wrap flex-col justify-center h-40 text-white">
                        <GroupsIcon className="w-16 text-primary" />
                        <h3 className="font-semibold text-gray-200">Want two people to talk to each other?</h3>
                        <span className="text-sm text-gray-300">
                            Add them above then prioritise by dragging the matches.
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}
