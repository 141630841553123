import {useEffect, useState} from 'react'
import DailyIframe, {DailyCall} from '@daily-co/daily-js'
import Bowser from 'bowser'
import Mousetrap from 'mousetrap'

import {MicOff as MicOffIcon} from '@styled-icons/fluentui-system-filled/MicOff'
import {MicOn as MicOnIcon} from '@styled-icons/fluentui-system-filled/MicOn'
import {ScreenShare as ScreenShareIcon} from '@styled-icons/material-outlined/ScreenShare'
import {Videocam as VideoOnIcon} from '@styled-icons/material/Videocam'
import {VideocamOff as VideoOffIcon} from '@styled-icons/material/VideocamOff'
import ReturnHomeIcon from '~/assets/icons/return-home.svg'
import {Settings as SettingsIcon} from '@styled-icons/material/Settings'
import {Logout as LeaveIcon} from '@styled-icons/material/Logout'

import './Tray.scss'
import TrayButton from './TrayButton/TrayButton'
import {useDaily} from '@daily-co/daily-react'
import actions, {SettingsPage} from '../../../store/eventState'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'
import {useAppDispatch, useAppSelector} from '../../../store'
import RecordingTrayButton from './RecordingButton'
import HostTray from './HostTray/HostTray'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'

/**
 * Gets [isCameraMuted, isMicMuted, isSharingScreen].
 * This function is declared outside Tray() so it's not recreated every render
 * (which would require us to declare it as a useEffect dependency).
 */
function getStreamStates(callObject: DailyCall) {
    let isCameraMuted,
        isMicMuted,
        isSharingScreen = false
    if (callObject && callObject.participants() && callObject.participants().local) {
        const localParticipant = callObject.participants().local
        isCameraMuted = !localParticipant.video
        isMicMuted = !localParticipant.audio
        isSharingScreen = localParticipant.screen
    }
    return [isCameraMuted, isMicMuted, isSharingScreen]
}

interface TrayProps {
    leaveCall: () => void
    disabled: boolean
}

export default function Tray({disabled, leaveCall}: TrayProps) {
    const callObject = useDaily()!

    const {
        breakout: {state},
        channel,
        isHost,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const {leaveBreakout} = useAmplifierSocket()
    const dispatch = useAppDispatch()

    const [isCameraMuted, setCameraMuted] = useState(false)
    const [isMicMuted, setMicMuted] = useState(false)
    const [isSharingScreen, setSharingScreen] = useState(false)

    const {updateDeviceState} = useMediaDevices()

    const toggleCamera = () => {
        callObject.setLocalVideo(isCameraMuted)
        updateDeviceState()
    }

    const toggleMic = () => {
        callObject.setLocalAudio(isMicMuted)
        updateDeviceState()
    }

    const toggleSharingScreen = () => {
        isSharingScreen ? callObject.stopScreenShare() : callObject.startScreenShare()
    }

    // Only allow screenshare if
    // - They're a host
    // - They're a normal participant inn breakouts
    // - They're currently screensharing (to turn it off)
    const allowScreenShare =
        (DailyIframe.supportedBrowser().supportsScreenShare && (isHost || state === 'breakout')) ||
        isSharingScreen === true

    // Bind Shortcuts
    useEffect(() => {
        const operatingSystem = Bowser.parse(window.navigator.userAgent).os
        const superkey = operatingSystem.name === 'macOS' ? 'command' : 'ctrl'
        // Bind Super+D to toggleMic
        Mousetrap.bind(`${superkey}+d`, e => {
            e.preventDefault()
            toggleMic()
        })
        // Bind Super+R to toggleCamera
        Mousetrap.bind(`${superkey}+e`, e => {
            e.preventDefault()
            toggleCamera()
        })
        return () => {
            Mousetrap.unbind([`${superkey}+d`, `${superkey}+e`])
        }
    }, [isMicMuted, isCameraMuted])

    /**
     * Start listening for participant changes when callObject is set (i.e. when the component mounts).
     * This event will capture any changes to your audio/video mute state.
     */
    useEffect(() => {
        if (!callObject) return

        function handleNewParticipantsState() {
            // event && logDailyEvent(event);
            const [isCameraMuted, isMicMuted, isSharingScreen] = getStreamStates(callObject)
            setCameraMuted(!!isCameraMuted)
            setMicMuted(!!isMicMuted)
            setSharingScreen(!!isSharingScreen)
        }

        // Use initial state
        handleNewParticipantsState()

        // Listen for changes in state
        callObject.on('participant-updated', handleNewParticipantsState)

        // Stop listening for changes in state
        return function cleanup() {
            callObject.off('participant-updated', handleNewParticipantsState)
        }
    }, [callObject])

    const operatingSystem = Bowser.parse(window.navigator.userAgent).os
    const superkey = operatingSystem.name === 'macOS' ? '⌘' : 'Ctrl'
    return (
        <>
            <div className="tray">
                <TrayButton
                    id="muteButton"
                    disabled={disabled}
                    highlighted={isMicMuted}
                    onClick={toggleMic}
                    tooltip={`${superkey} + D`}
                >
                    {!isMicMuted ? <MicOnIcon width="1.2em" /> : <MicOffIcon width="1.2em" />}
                </TrayButton>
                <TrayButton
                    id="cameraButton"
                    disabled={disabled}
                    highlighted={isCameraMuted}
                    onClick={toggleCamera}
                    tooltip={`${superkey} + E`}
                >
                    {!isCameraMuted ? <VideoOnIcon width="1.2em" /> : <VideoOffIcon width="1.2em" />}
                </TrayButton>
                <TrayButton
                    disabled={disabled}
                    onClick={() => {
                        dispatch(actions.SetModalOpen(SettingsPage.Audio))
                    }}
                    tooltip="Settings"
                >
                    <SettingsIcon width="1.2em" />
                </TrayButton>
                {allowScreenShare && (
                    <TrayButton
                        disabled={disabled}
                        highlighted={isSharingScreen}
                        onClick={toggleSharingScreen}
                        tooltip={`${!isSharingScreen ? 'Start' : 'Stop'} Screenshare`}
                    >
                        <ScreenShareIcon width="1.2em" />
                    </TrayButton>
                )}
                <RecordingTrayButton disabled={disabled} />
                {isHost && <HostTray />}
                {
                    <div className="flex flex-row ml-auto">
                        {state === 'breakout' && channel !== 0 && (
                            <TrayButton tooltip="Return Home" onClick={leaveBreakout}>
                                <ReturnHomeIcon />
                            </TrayButton>
                        )}
                        <TrayButton disabled={disabled} highlighted={true} onClick={leaveCall} tooltip="Leave Call">
                            <LeaveIcon size="1.2em" />
                        </TrayButton>
                    </div>
                }
            </div>
        </>
    )
}
