import {DailyInputVideoProcessorSettings} from '@daily-co/daily-js'

export type BackgroundFilterType = 'none' | 'lightBlur' | 'heavyBlur'

// Map of available backgroundFilters.
export const backgroundFilters: {
    [key: BackgroundFilterType | string]: DailyInputVideoProcessorSettings
} = {
    none: {type: 'none'},
    lightBlur: {
        type: 'background-blur',
        config: {strength: 0.3},
    },
    heavyBlur: {
        type: 'background-blur',
        config: {strength: 1},
    },
}

export function getLocalBackgroundFilter(): BackgroundFilterType {
    // Get the last selected input processor from localStorage
    const savedFilter = localStorage.getItem('backgroundFilter') as BackgroundFilterType
    return savedFilter ?? 'none'
}

export function getSavedBackgroundFilter(): DailyInputVideoProcessorSettings {
    // Get the latest input settings
    // Used when creating the callObejct to remember the latest input settings
    const filter = getLocalBackgroundFilter()
    return backgroundFilters[filter]
}

export function saveBackgroundFilter(backgroundFilter: BackgroundFilterType) {
    localStorage.setItem('backgroundFilter', backgroundFilter)
}
