import {ErrorResponse, GetInvoicesResponse, InternalServerError} from 'shared-types/schema/response'
import {PlanViewModel, TenantBillingViewModel} from 'shared-types/view-models'
import api from '.'
import isArray from 'lodash/isArray'

export async function getPlans(): Promise<PlanViewModel[] | ErrorResponse | InternalServerError> {
    const response = await api.get(`/billing/plans`)
    if (isArray(response)) {
        // Pre-sort it, it's always displayed lowest to hightest
        response.sort((a, b) => a.price - b.price)
        return response
    }
    return response as PlanViewModel[] | ErrorResponse | InternalServerError
}

export async function stagePlan(
    planId: string,
    tenant: string,
): Promise<TenantBillingViewModel | ErrorResponse | InternalServerError> {
    const response = await api.post(`/billing/plan/stage?tenant=${tenant}`, {planId})
    return response as TenantBillingViewModel | ErrorResponse | InternalServerError
}

export async function addPaymentMethod(
    paymentMethodId: string,
    email: string,
    tenant: string,
): Promise<TenantBillingViewModel | ErrorResponse | InternalServerError> {
    const response = await api.post(`/billing/paymentmethod?tenant=${tenant}`, {paymentMethodId, email})

    return response as TenantBillingViewModel | ErrorResponse | InternalServerError
}

export async function changePlan(
    planId: string,
    tenant: string,
): Promise<TenantBillingViewModel | ErrorResponse | InternalServerError> {
    const response = await api.post(`/billing/plan/change?tenant=${tenant}`, {planId})

    return response as TenantBillingViewModel | ErrorResponse | InternalServerError
}

export async function getInvoices(tenant: string): Promise<GetInvoicesResponse | ErrorResponse | InternalServerError> {
    const response = await api.get(`/billing/invoices?tenant=${tenant}`)

    return response as GetInvoicesResponse | ErrorResponse | InternalServerError
}

// export async function getTenantUsage(

// )
