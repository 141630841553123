import {useRef, useEffect} from 'react'

export default function AudioTrack({track}: {track: MediaStreamTrack}) {
    const audioRef = useRef<HTMLAudioElement>(null as any)

    useEffect(() => {
        if (!audioRef.current) return
        let playTimeout: NodeJS.Timeout

        const handleCanPlay = () => {
            playTimeout = setTimeout(() => {
                console.log('Unable to autoplay audio element')
            }, 1500)
        }
        const handlePlay = () => {
            clearTimeout(playTimeout)
        }
        audioRef.current.addEventListener('canplay', handleCanPlay)
        audioRef.current.addEventListener('play', handlePlay)
        audioRef.current.srcObject = new MediaStream([track])

        const audioEl = audioRef.current

        return () => {
            audioEl?.removeEventListener('canplay', handleCanPlay)
            audioEl?.removeEventListener('play', handlePlay)
        }
    }, [track])

    return track ? (
        <audio autoPlay playsInline ref={audioRef}>
            <track kind="captions" />
        </audio>
    ) : null
}
