import {Hashtag} from 'shared-types/view-models/SocketModels'

type ValidationRule<T, FormValues = {[field: string]: any}> = (
    value: T,
    values: FormValues,
) => Promise<string | void> | string | void

type FormErrors<FormValues> = Partial<{[K in keyof FormValues]: string}>

type ValidationFunction<FormValues> = (values: FormValues) => Promise<FormErrors<FormValues>>

const generate = <FormValues>(formRules: {
    [Field in keyof FormValues]: Array<ValidationRule<FormValues[Field], FormValues>>
}): ValidationFunction<FormValues> => {
    return async (values: FormValues) => {
        const errors: FormErrors<FormValues> = {}

        for (const field in formRules) {
            const fieldRules = formRules[field]
            for (const rule of fieldRules) {
                const error = await rule(values[field], values)
                if (error) {
                    errors[field] = error
                    break
                }
            }
        }

        if (Object.keys(errors).length) {
            return errors
        }

        return {}
    }
}

function email(): ValidationRule<string> {
    return value => {
        if (value.includes(' ')) {
            return "Email can't contain a space"
        }

        if (!value.includes('@')) {
            return 'Email must inlude an @'
        }

        const regexpEmail = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        if (!regexpEmail.test(value)) {
            return 'Email must be valid'
        }
    }
}

function required(message: string = 'This field is required'): ValidationRule<any> {
    return value => (value || value === false ? undefined : message)
}

function isTrue(message: string): ValidationRule<boolean> {
    return value => (value ? undefined : message)
}

function password(): ValidationRule<string | undefined> {
    return async value => {
        if (!value) {
            return
        }
        if (!/[0-9]/.test(value)) {
            return 'Your password must contain a number'
        }
        if (!/[a-zA-Z]/.test(value)) {
            return 'Your password must contain both a lowercase and an uppercase letter'
        }
        if (value.length <= 8) {
            return 'Your password must contain a minimum eight characters'
        }
        return
    }
}

export function sameAs(field: string, message: string): ValidationRule<any> {
    return (value, data) => (value === data[field] ? undefined : message)
}

function minLength(min: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }
        // valid other field is more than min alphabet (english) characters
        const characters = value.replace(/[^A-Za-z]/g, '')
        if (characters.length <= min) {
            return message
        }
    }
}

function maxLength(max: number, message: string): ValidationRule<string> {
    return value => {
        if (!value) {
            return // Nothing to validate
        }

        message.trim()

        // valid field is less than max characters
        if (value.length > max) {
            return message
        }
    }
}

function hashtag(hashtags: Hashtag[]): ValidationRule<string> {
    return value => {
        if (value.length < 3) {
            return 'Please enter more than 3 characters'
        }
        if (value.includes(' ')) {
            return 'hashtag cannot contain a space'
        }
        if (hashtags.find(hashtag => hashtag.name.toLowerCase() === value.toLowerCase())) {
            return 'Please enter a unique hashtag'
        }
        return
    }
}

function linkedinProfile(
    message: string = 'Please enter a valid LinkedIn profile URL',
): ValidationRule<string | undefined> {
    return value => {
        if (!value) return

        const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm

        const isValid = regex.test(value)
        if (isValid) return
        return message
    }
}

function twitterHandle(message: string = 'Twitter handle is not valid'): ValidationRule<string | undefined> {
    return value => {
        if (!value) return undefined

        const regex = /^[a-zA-Z0-9_]{1,15}$/gm

        const isValid = regex.test(value)
        if (isValid) return

        return message
    }
}

const validate = {
    generate,
    email,
    minLength,
    maxLength,
    required,
    isTrue,
    password,
    sameAs,
    hashtag,
    linkedinProfile,
    twitterHandle,
}

export default validate
