import {motion} from 'framer-motion'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Loading from '~/components/common/Loading'
import './CreateEvent.scss'

import clsx from 'clsx'

import {checkEventCodeAvalibility, createEvent} from '~/api/eventsApi'
import EventTypeSelector from '~/components/common/EventTypeSelector/EventTypeSelector'
import {useAppSelector} from '~/store'

export default function CreateEvent() {
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [eventName, setEventName] = useState('')
    const [roomCode, setRoomCode] = useState('')
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>()
    const [codeLoading, setCodeLoading] = useState(false)
    const [nameError, setNameError] = useState('')

    const [formError, setFormError] = useState('')

    const [settings, setSettings]: [
        {
            enableAtoB: boolean
            groupOneLabel: string
            groupOneTeam: boolean
            groupTwoLabel: string
            groupTwoTeam: boolean
        },
        any,
    ] = useState({
        enableAtoB: false,
        groupOneLabel: 'Group A',
        groupOneTeam: false,
        groupTwoLabel: 'Group B',
        groupTwoTeam: false,
    })

    const checkRoomCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventName(e.target.value)
        setFormError('')
        setNameError('')

        const roomCode = e.target.value.toLowerCase().replace(/ /g, '-')

        //setRoomCode(roomCode)
        if (typingTimeout) {
            setCodeLoading(false)
            clearTimeout(typingTimeout)
        }

        setCodeLoading(true)

        setTypingTimeout(
            setTimeout(async () => {
                // Theyve stopped typing, set roomcode to null so we can load one
                setRoomCode('')

                if (e.target.value.length < 4) {
                    setNameError(e.target.value.length !== 0 ? 'Event name must be at least 4 characters long' : '')
                    setCodeLoading(false)
                    return
                }

                let newCode = await checkEventCodeAvalibility(roomCode)

                if (typeof newCode === 'string') {
                    setRoomCode(newCode)
                }

                setCodeLoading(false)
            }, 500),
        )
    }

    const navigate = useNavigate()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        setFormError('')

        const response = await createEvent(
            {
                title: eventName,
                code: roomCode,
                settings: settings,
            },
            tenant.slug,
        )
        if (response.type === 'error' || response.type === 'internal_server_error') {
            setFormError(response.message)
            return
        }

        navigate('/dashboard')
    }

    return (
        <motion.div layout className="ob__createevent__wrapper">
            <motion.div layout className="ob__createevent__header ">
                <motion.div layout className="ob__company__header__title">
                    <h1>First, lets create an event</h1>
                </motion.div>
                <motion.div layout className="ob__company__header__subtitle text-gray-400 text-sm text-center">
                    <p>This is just for practise, you can delete this event later.</p>
                </motion.div>
            </motion.div>

            <motion.div layout className="ob__createevent__form">
                <motion.form layout onSubmit={handleSubmit} className="flex flex-col gap-y-2">
                    <motion.div className="ob__form__group h-32 w-full">
                        <motion.div layout className={clsx('ob__createevent__input', nameError.length > 1 && 'error')}>
                            <label className={clsx(nameError.length > 1 && 'text-red-500')} htmlFor="eventName">
                                {nameError.length > 1 ? nameError : 'Event Name'}
                            </label>
                            <input
                                type="text"
                                name="eventName"
                                id="eventName"
                                value={eventName}
                                placeholder="e.g. My Event"
                                onChange={e => checkRoomCode(e)}
                            />
                        </motion.div>

                        {eventName.length >= 4 && (
                            <motion.div
                                initial={false}
                                animate={eventName.length > 3 ? 'open' : 'closed'}
                                transition={{
                                    duration: 0.2,
                                    delay: 0,
                                    ease: [0, 0.71, 0.2, 1.01],
                                }}
                                className="ob__createevent__input uneditable"
                            >
                                <div>
                                    <span className="inline-flex w-auto whitespace-nowrap items-center rounded-l-md  text-gray-200 sm:text-sm">
                                        app.amplifier.community/?room=
                                    </span>
                                    <input
                                        type="text"
                                        name="eventName"
                                        disabled={true}
                                        id="eventName"
                                        value={roomCode}
                                        onChange={e => setRoomCode(e.target.value)}
                                    />

                                    <div className="input__statusindicator ">{codeLoading && <Loading />}</div>
                                </div>
                            </motion.div>
                        )}
                    </motion.div>

                    <EventTypeSelector onChange={e => setSettings(e)} />

                    {formError && (
                        <div className="text-red-500 text-sm mt-2">
                            <p>{formError}</p>
                        </div>
                    )}

                    <motion.button
                        disabled={eventName.length < 4 || roomCode.length < 3}
                        layout
                        className="btn btn-primary mt-6 w-full max-w-sm mx-auto"
                    >
                        Create Event
                    </motion.button>
                </motion.form>
            </motion.div>

            <div className="ob__createevent__footer mt-4">
                <button className="btn" onClick={() => navigate('/dashboard')}>
                    Skip for now
                </button>
            </div>
        </motion.div>
    )
}
