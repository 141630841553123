import {DailyCall, DailyEventObjectNetworkQualityEvent} from '@daily-co/daily-js'
import {useCallback, useEffect, useState} from 'react'
import {VideoQuality, NetworkState} from '../constants'

const STANDARD_HIGH_BITRATE_CAP = 980
const STANDARD_LOW_BITRATE_CAP = 300

export const useNetworkState: (daily: DailyCall, quality: VideoQuality) => NetworkState = (
    callObject: DailyCall | undefined,
    quality = VideoQuality.HIGH,
): NetworkState => {
    const [threshold, setThreshold] = useState<NetworkState>(NetworkState.GOOD)

    const setQuality = useCallback(
        (q: VideoQuality) => {
            if (!callObject || typeof (globalThis as any).rtcpeers === 'undefined') return

            const peers = Object.keys(callObject.participants()).length - 1
            const isSFU = (globalThis as any).rtcpeers?.currentlyPreferred?.typeName?.() === 'sfu'

            const lowKbs = isSFU ? STANDARD_LOW_BITRATE_CAP : STANDARD_LOW_BITRATE_CAP / Math.max(1, peers)

            switch (q) {
                case VideoQuality.HIGH:
                    callObject.setBandwidth({kbs: STANDARD_HIGH_BITRATE_CAP})
                    break
                case VideoQuality.LOW:
                    callObject.setBandwidth({
                        kbs: lowKbs,
                    })
                    break
                case VideoQuality.BANDWIDTH_SAVER:
                    callObject.setLocalVideo(false)
                    callObject.setBandwidth({
                        kbs: lowKbs,
                    })
                    break
                default:
                    break
            }
        },
        [callObject],
    )

    const handleNetworkQualityChange = useCallback(
        (ev: DailyEventObjectNetworkQualityEvent) => {
            if (ev.threshold === threshold) return

            switch (ev.threshold) {
                case NetworkState.VERY_LOW:
                    setQuality(VideoQuality.BANDWIDTH_SAVER)
                    setThreshold(NetworkState.VERY_LOW)
                    break
                case NetworkState.LOW:
                    setQuality(quality === VideoQuality.BANDWIDTH_SAVER ? quality : VideoQuality.LOW)
                    setThreshold(NetworkState.LOW)
                    break
                case NetworkState.GOOD:
                    setQuality(
                        [VideoQuality.BANDWIDTH_SAVER, VideoQuality.LOW].includes(quality)
                            ? quality
                            : VideoQuality.HIGH,
                    )
                    setThreshold(NetworkState.GOOD)
                    break
                default:
                    break
            }
        },
        [setQuality, threshold, quality],
    )

    useEffect(() => {
        if (!callObject) return
        callObject.on('network-quality-change', e => handleNetworkQualityChange(e!))
        return () => {
            callObject.off('network-quality-change', e => handleNetworkQualityChange(e!))
        }
    }, [callObject, handleNetworkQualityChange])

    useEffect(() => {
        setQuality(quality)
    }, [quality, setQuality])

    return threshold
}
