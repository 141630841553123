import {
    ABGroup,
    Hashtag,
    HostSettingsPayload,
    ParticipantEventPayload,
    RoomPayload,
    TeamPayload,
    UpdateHashtagsPayload,
    ChannelUpdatePayload,
    MessagePayload,
    RoomState,
    HomeRoomMode,
    UpdateRoomSettingsPayload,
    PresetMatch,
    UpdateMatchablePayload,
} from 'shared-types/view-models/SocketModels'
import {DateTime} from 'luxon'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import {ActionsUnion, createAction} from './redux-tools'
import {assertUnreachable} from '~/utils/typeUtils'
import {getBestDate} from '../utils/timestampTTL'

// Data from amplifier's socket.
// Contains data about the rooms, participant data, breakout stuff.
// Both CurrentRoomParticipants and AllParticipants don't include the ID 'local', they have the legitimate IDs of everyone

export interface AmplifierSocketState {
    hasJoined: boolean
    socketState: 'connecting' | 'connected'
    serverTimeOffset: number //Offset in milliseconds between Client/Server local dates
    channel: number
    localId: string // Id from Daily
    channels: {
        [key: number]: string[]
    }
    allParticipants: {
        [id: string]: ParticipantViewModel
    }
    allParticipantIds: string[]
    currentRoomParticipantIds: string[]
    nextRoomParticipantIds: string[]
    localParticipant: {
        id: string
        name: string
        title: string
        company: string
        picture?: string
        matchedIds: string[]
        isHost: boolean
        hashtags: Hashtag[]
        group?: ABGroup
        teamId?: string
    }
    hashtags: Hashtag[]
    homeRoomMode: HomeRoomMode
    favouriteIds: string[]
    preInfluenceMatches: PresetMatch[]
    isHost: boolean
    breakout: {
        state: RoomState
        nextState: RoomState
        stateStart?: Date
        stateEnd?: Date
    }
    hostSettings?: HostSettingsPayload
    messages: MessagePayload[]
    teams: TeamPayload[]
}

export const initialState: AmplifierSocketState = {
    hasJoined: false,
    serverTimeOffset: 0,
    localId: '',
    channel: 0,
    channels: {},
    allParticipants: {},
    allParticipantIds: [],
    currentRoomParticipantIds: [],
    nextRoomParticipantIds: [],
    hashtags: [],
    homeRoomMode: 'all',
    localParticipant: {
        id: '',
        name: '',
        title: '',
        company: '',
        matchedIds: [],
        isHost: false,
        picture: undefined,
        hashtags: [],
        group: undefined,
        teamId: undefined,
    },
    favouriteIds: [],
    preInfluenceMatches: [],
    isHost: false,
    breakout: {
        state: 'normal',
        nextState: 'normal',
        stateStart: undefined,
        stateEnd: undefined,
    },
    hostSettings: undefined,
    messages: [],
    teams: [],
    socketState: 'connecting',
}

// --- Reducer --

export function amplifierSocketReducer(
    prevState: AmplifierSocketState = initialState,
    action: ActionsType,
): AmplifierSocketState {
    switch (action.type) {
        case 'amplifierSocketState.SetServerTimeOffset':
            return {
                ...prevState,
                serverTimeOffset: action.payload,
            }
        case 'amplifierSocketState.UpdateRoom': {
            const data: RoomPayload = action.payload
            const localId = prevState.localId

            let localChannel = 0
            let localParticipant = {...prevState.localParticipant}
            Object.keys(data.channels).forEach((c: string) => {
                const me = data.channels[c as unknown as number].find(pid => pid === localId)
                if (me) {
                    //Set my channel to the channel I'm in
                    localChannel = parseInt(c + '')
                    localParticipant = {
                        ...data.participants[me],
                    }
                    return
                }
            })

            const allParticipants = data.participants

            const allParticipantIds = Object.keys(data.participants)

            const isStageMode = data.homeRoomMode === 'hosts' && data.state === 'normal'
            const roomParticipantIds: string[] = []

            data.channels[localChannel as unknown as number].forEach(id => {
                if (localParticipant.id === id) return
                if (isStageMode && !data.participants[id]?.isHost) return

                roomParticipantIds.push(id)
            })

            let currentRoomParticipantIds: string[] = []
            let nextRoomParticipantIds: string[] = []
            if (data.state === 'intermission') {
                nextRoomParticipantIds = roomParticipantIds
            } else {
                currentRoomParticipantIds = roomParticipantIds
            }
            return {
                ...prevState,
                hasJoined: true,
                channel: localChannel,
                channels: data.channels,
                homeRoomMode: data.homeRoomMode,
                allParticipants,
                allParticipantIds,
                currentRoomParticipantIds,
                nextRoomParticipantIds,
                hashtags: data.hashtags,
                breakout: {
                    ...prevState.breakout,
                    state: data.state,
                    nextState: data.nextState,
                    stateStart: data.stateStart
                        ? getBestDate(
                              data.stateStartTTL,
                              DateTime.fromISO(data.stateStart).minus({milliseconds: prevState.serverTimeOffset}),
                          ).toJSDate()
                        : undefined,
                    stateEnd: data.stateEnd
                        ? getBestDate(
                              data.stateEndTTL,
                              DateTime.fromISO(data.stateEnd).minus({milliseconds: prevState.serverTimeOffset}),
                          ).toJSDate()
                        : undefined,
                },
                localParticipant,
            }
        }
        case 'amplifierSocketState.UpdateChannels':
            const data: ChannelUpdatePayload = action.payload

            const localId = prevState.localId

            let localChannel = 0
            let localParticipant = {...prevState.localParticipant}

            Object.keys(data.channels).forEach(c => {
                const me = data.channels[c as unknown as number].find(pid => pid === localId)
                if (me) {
                    //Set my channel to the channel I'm in
                    localChannel = parseInt(c + '')
                    localParticipant = {
                        ...prevState.allParticipants[me],
                    }
                    return
                }
            })

            const isStageMode = data.homeRoomMode === 'hosts' && data.state === 'normal'
            const roomParticipantIds: string[] = []

            data.channels[localChannel].forEach(id => {
                if (localParticipant.id === id) return
                if (isStageMode && !prevState.allParticipants[id]?.isHost) return

                roomParticipantIds.push(id)
            })

            let currentRoomParticipantIds: string[] = []
            let nextRoomParticipantIds: string[] = []
            if (data.state === 'intermission') {
                nextRoomParticipantIds = roomParticipantIds
            } else {
                currentRoomParticipantIds = roomParticipantIds
            }

            return {
                ...prevState,
                hasJoined: true,
                channels: data.channels,
                currentRoomParticipantIds,
                nextRoomParticipantIds,
                homeRoomMode: data.homeRoomMode,
                channel: localChannel,
                breakout: {
                    ...prevState.breakout,
                    state: data.state,
                    nextState: data.nextState,
                    stateStart: data.stateStart
                        ? getBestDate(
                              data.stateStartTTL,
                              DateTime.fromISO(data.stateStart).minus({milliseconds: prevState.serverTimeOffset}),
                          ).toJSDate()
                        : undefined,
                    stateEnd: data.stateEnd
                        ? getBestDate(
                              data.stateEndTTL,
                              DateTime.fromISO(data.stateEnd).minus({milliseconds: prevState.serverTimeOffset}),
                          ).toJSDate()
                        : undefined,
                },
            }
        case 'amplifierSocketState.SetHost':
            return {
                ...prevState,
                isHost: action.payload,
            }

        case 'amplifierSocketState.UpdateMessages':
            //Map timestamps from timestamps to DateTimes, as Socket.io JSON.parses them on transmittion
            return {
                ...prevState,
                messages: action.payload
                    .map(m => {
                        return {
                            ...m,
                            timestamp: DateTime.fromISO(m.timestamp as any as string).toJSDate(),
                        }
                    })
                    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
            }

        case 'amplifierSocketState.ReceiveMessage':
            return {
                ...prevState,
                messages: [
                    ...prevState.messages,
                    {
                        ...action.payload,
                        timestamp: DateTime.fromISO(action.payload.timestamp as any as string).toJSDate(),
                    },
                ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
            }

        case 'amplifierSocketState.UpdateTeams':
            const teams = action.payload as TeamPayload[]
            return {
                ...prevState,
                teams: teams,
            }

        case 'amplifierSocketState.SetSocketState':
            return {
                ...prevState,
                socketState: action.payload,
            }
        case 'amplifierSocketState.SetLocalDailyId':
            return {
                ...prevState,
                localId: action.payload,
            }
        case 'amplifierSocketState.UpdateMatchable':
            // avoid duplicates
            const favIds = [...new Set(action.payload.favoriteIds)]
            return {
                ...prevState,
                favouriteIds: favIds,
                localParticipant: {
                    ...prevState.localParticipant,
                    matchedIds: action.payload.matchedIds,
                },
            }
        case 'amplifierSocketState.SetFavouriteIds':
            const favouriteIds = [...new Set(action.payload)]

            return {
                ...prevState,
                favouriteIds: favouriteIds,
            }

        case 'amplifierSocketState.RemoveFromFavourites':
            return {
                ...prevState,
                favouriteIds: prevState.favouriteIds.filter(id => action.payload !== id),
            }
        case 'amplifierSocketState.SetHostSettings':
            return {
                ...prevState,
                hostSettings: action.payload?.hostSettings,
                homeRoomMode: action.payload?.homeRoomMode ?? 'all',
                localParticipant: {
                    ...prevState.localParticipant,
                    isHost: true,
                },
            }
        case 'amplifierSocketState.ParticipantJoined': {
            const participant = action.payload.participant
            const participantsChannel = action.payload.channel

            const isLocal = participant.id === prevState.localParticipant.id

            prevState.channels[participantsChannel] = [] // fix to init channel event when join room hasnt been created.

            let doShowParticipant = true

            // If in home room, determine if we should show the participant
            if (prevState.breakout.state === 'normal' && participantsChannel === 0) {
                if (prevState.homeRoomMode === 'all') {
                    doShowParticipant = true
                } else if (prevState.homeRoomMode === 'hosts') {
                    doShowParticipant = participant.isHost
                }
            }

            if (participantsChannel !== prevState.channel) {
                doShowParticipant = false
            }

            return {
                ...prevState,
                localParticipant: isLocal ? participant : prevState.localParticipant,
                allParticipants: {
                    ...prevState.allParticipants,
                    [participant.id]: participant,
                },
                channels: {
                    ...prevState.channels,
                    [participantsChannel]: prevState.channels[participantsChannel].includes(participant.id)
                        ? prevState.channels[participantsChannel]
                        : [...prevState.channels[participantsChannel], participant.id],
                },
                allParticipantIds: prevState.allParticipantIds.includes(participant.id)
                    ? [...prevState.allParticipantIds.filter(id => id !== participant.id), participant.id]
                    : [...prevState.allParticipantIds, participant.id], //[...new Set(...prevState.allParticipantIds, participant.id)],
                currentRoomParticipantIds: doShowParticipant
                    ? [...prevState.currentRoomParticipantIds, participant.id]
                    : prevState.currentRoomParticipantIds.filter(id => id !== participant.id),
            }
        }
        case 'amplifierSocketState.ParticipantUpdated': {
            const participant = action.payload.participant

            const isLocal = participant.id === prevState.localParticipant.id

            let doShowParticipant = true

            // If in home room, determine if we should show the participant
            if (prevState.breakout.state === 'normal' && action.payload.channel === 0) {
                if (prevState.homeRoomMode === 'all') {
                    doShowParticipant = true
                } else if (prevState.homeRoomMode === 'hosts') {
                    doShowParticipant = participant.isHost
                }
            }

            if (action.payload.channel !== prevState.channel) {
                doShowParticipant = false
            }

            // Todo: update currentRoomParticipantIds, nextRoomParticipantIds, etc when participant changes channel

            return {
                ...prevState,
                localParticipant: isLocal ? participant : prevState.localParticipant,
                allParticipants: {
                    ...prevState.allParticipants,
                    [participant.id]: participant,
                },
                currentRoomParticipantIds: doShowParticipant
                    ? [...prevState.currentRoomParticipantIds, participant.id]
                    : prevState.currentRoomParticipantIds.filter(id => id !== participant.id),
            }
        }
        case 'amplifierSocketState.ParticipantLeft': {
            const participant = action.payload.participant

            const {[participant.id]: omit, ...allParticipants} = prevState.allParticipants

            return {
                ...prevState,
                allParticipants,
                allParticipantIds: prevState.allParticipantIds.filter(id => id !== participant.id),
                currentRoomParticipantIds: prevState.currentRoomParticipantIds.filter(id => id !== participant.id),
                nextRoomParticipantIds: prevState.nextRoomParticipantIds.filter(id => id !== participant.id),
            }
        }
        case 'amplifierSocketState.UpdateHashtags': {
            const newHashtags = action.payload.hashtags

            const updateParticipantHashtags = (participant: ParticipantViewModel): ParticipantViewModel => {
                const {hashtags} = participant
                return {
                    ...participant,
                    hashtags: (hashtags || [])
                        .filter(tag => !!tag)
                        .map(tag => newHashtags.find(newHashtag => newHashtag.id === tag.id)) as Hashtag[],
                }
            }

            // Replace all the participant's hashtag arrays.
            const allParticipants = Object.values(prevState.allParticipants).reduce<
                AmplifierSocketState['allParticipants']
            >(
                (map, currentParticipant) => ({
                    ...map,
                    [currentParticipant.id]: updateParticipantHashtags(currentParticipant),
                }),
                {},
            )

            return {
                ...prevState,
                hashtags: newHashtags,
                localParticipant: updateParticipantHashtags(prevState.localParticipant),
                allParticipants,
            }
        }
        case 'amplifierSocketState.SetPreInfluenceMatches': {
            const matches = action.payload
            return {
                ...prevState,
                preInfluenceMatches: matches,
            }
        }

        default:
            assertUnreachable(action)
    }
    return prevState
}

// --- Actions ---

const actions = {
    UpdateChannels: (payload: ChannelUpdatePayload) => createAction('amplifierSocketState.UpdateChannels', payload),
    SetServerTimeOffset: (offset: number) => createAction('amplifierSocketState.SetServerTimeOffset', offset),
    UpdateHashtags: (payload: UpdateHashtagsPayload) => createAction('amplifierSocketState.UpdateHashtags', payload),
    SetLocalDailyId: (localId: string) => createAction('amplifierSocketState.SetLocalDailyId', localId),
    SetSocketState: (state: AmplifierSocketState['socketState']) =>
        createAction('amplifierSocketState.SetSocketState', state),
    UpdateTeams: (state: TeamPayload[]) => createAction('amplifierSocketState.UpdateTeams', state),
    UpdateMessages: (messages: MessagePayload[]) => createAction('amplifierSocketState.UpdateMessages', messages),
    ReceiveMessage: (message: MessagePayload) => createAction('amplifierSocketState.ReceiveMessage', message),
    SetHost: (isHost: boolean) => createAction('amplifierSocketState.SetHost', isHost),
    UpdateMatchable: (payload: UpdateMatchablePayload) => createAction('amplifierSocketState.UpdateMatchable', payload),
    SetFavouriteIds: (favoriteIds: string[]) => createAction('amplifierSocketState.SetFavouriteIds', favoriteIds),
    SetPreInfluenceMatches: (matches: PresetMatch[]) =>
        createAction('amplifierSocketState.SetPreInfluenceMatches', matches),
    SetHostSettings: (payload: UpdateRoomSettingsPayload | undefined) =>
        createAction('amplifierSocketState.SetHostSettings', payload),
    RemoveFromFavourites: (id: string) => createAction('amplifierSocketState.RemoveFromFavourites', id),
    UpdateRoom: (payload: RoomPayload) => createAction('amplifierSocketState.UpdateRoom', payload),
    ParticipantJoined: (payload: ParticipantEventPayload) =>
        createAction('amplifierSocketState.ParticipantJoined', payload),
    ParticipantLeft: (payload: ParticipantEventPayload) =>
        createAction('amplifierSocketState.ParticipantLeft', payload),
    ParticipantUpdated: (payload: ParticipantEventPayload) =>
        createAction('amplifierSocketState.ParticipantUpdated', payload),
}

export type ActionsType = ActionsUnion<typeof actions>
export default actions
