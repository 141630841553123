import permissionsSrc from '../../../assets/public/permissions-anim.gif'
import goldLogo from '../../../assets/public/gold-logo.png'

interface RequestPermissionsProps {
    updateContinueWithoutPermissions: (p: boolean) => void
    cameraError: string
}

function RequestPermissions({updateContinueWithoutPermissions, cameraError}: RequestPermissionsProps) {
    return (
        <div className="text-center">
            <img className="h-24 w-auto mx-auto" src={goldLogo} alt="Amplifier" />
            <img src={permissionsSrc} alt="How to enable permissions" />

            <span className="mb-2 block">{cameraError}</span>

            <h4 className="mb-4">So others can see & hear you, unblock your device permissions.</h4>

            <div className="text-sm mt-2 text-black md:text-gray-500 mx-auto mb-12">
                <a
                    className="underline"
                    target="_blank"
                    href="https://www.amplifier.community/posts/troubleshooting-camera-and-mic-issues"
                    rel="noreferrer"
                >
                    Need Help?
                </a>
            </div>
            <div className="block mb-2 text-center">
                <button className="btn btn-outline hidden sm:inline" onClick={() => window.location.reload()}>
                    I've allowed access
                </button>

                <button
                    className="btn btn-ghost text-gray-700 mt-2"
                    onClick={() => updateContinueWithoutPermissions(true)}
                >
                    Continue without permissions
                </button>

                <button className="btn btn-outline sm:hidden" onClick={() => window.location.reload()}>
                    Allow Access
                </button>
            </div>
        </div>
    )
}

export default RequestPermissions
