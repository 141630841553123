import {ArrayElement, assertUnreachable} from '~/utils/typeUtils'
import {ActionsUnion, createAction} from './redux-tools'
import {ThunkAction} from './types'
import {fetchMe, login, resetPassword, setPassword, signUp} from '~/api/identityApi'
import {TenantBillingViewModel, TenantType} from 'shared-types/view-models'
import {createTenant} from '~/api/tenantApi'
import * as Response from 'shared-types/schema/response'
import {stagePlan, addPaymentMethod, changePlan} from '~/api/billingApi'
import {RouterOutputs} from '~/api/trpc'

export type User = Extract<RouterOutputs['identity']['login'], {type: 'identity'}>
type Tenant = ArrayElement<User['tenants']>

export interface IdentityState {
    identityLoadingState: 'loading' | 'ready' | 'error'
    isAuthenticated: boolean
    isOnboarded: boolean // Onboarded as a host
    user?: User
    tenant?: Tenant
}

const initialState: IdentityState = {
    identityLoadingState: 'loading',
    isOnboarded: false,
    isAuthenticated: false,
    user: undefined,
    tenant: undefined,
}

export const identityReducer = (prevState: IdentityState = initialState, action: ActionsType): IdentityState => {
    switch (action.type) {
        case 'identityState.identityLoadingState':
            return {
                ...prevState,
                identityLoadingState: action.payload,
            }
        case 'identityState.SetUser':
            const isAuthenticated = !!action.payload
            const isOnboarded = action.payload?.tenants && action.payload?.tenants.length > 0 ? true : false
            const userBilling = action.payload?.UserBilling ?? {plan: 'free'}

            return {
                ...prevState,
                isAuthenticated,
                isOnboarded,
                tenant: isAuthenticated && isOnboarded ? action.payload!.tenants![0] : undefined,
                user: action.payload
                    ? {
                          ...action.payload!,
                          id: action.payload.id,
                          tenants: [],
                          UserBilling: userBilling,
                      }
                    : undefined,
            }
        case 'identityState.SetTenant':
            return {
                ...prevState,
                tenant: action.payload,
            }
        case 'identityState.SetTenantBilling':
            return {
                ...prevState,
                tenant: {
                    ...prevState.tenant!,
                    billing: action.payload,
                },
            }

        default:
            assertUnreachable(action)
    }
    return prevState
}

// --- Actions ---

const actions = {
    SetLoadingState: (state: IdentityState['identityLoadingState']) =>
        createAction('identityState.identityLoadingState', state),
    SetUser: (user?: User) => createAction('identityState.SetUser', user),
    SetTenant: (tenant: Tenant) => createAction('identityState.SetTenant', tenant),
    SetTenantBilling: (tenantBilling: TenantBillingViewModel) =>
        createAction('identityState.SetTenantBilling', tenantBilling),
}

const thunkActions = {
    CreateTenant(
        name: string,
        type: TenantType,
    ): ThunkAction<Promise<Response.Tenant | Response.ErrorResponse | Response.InternalServerError>> {
        return async dispatch => {
            const response = await createTenant(name, type)
            if (response.type === 'error' || response.type === 'internal_server_error') {
                return response
            }

            dispatch(actions.SetTenant(response))
            return response
        }
    },
    StagePlan(planId: string, tenantId: string): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const response = await stagePlan(planId, tenantId)
            if (response.type === 'error' || response.type === 'internal_server_error') {
                return response['message']
            }

            dispatch(actions.SetTenantBilling(response as TenantBillingViewModel))
            return
        }
    },
    AddPaymentMethod(paymentMethodId: string, email: string, tenantId: string): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const response = await addPaymentMethod(paymentMethodId, email, tenantId)
            if (response.type === 'error' || response.type === 'internal_server_error') {
                return response.message
            }
            dispatch(actions.SetTenantBilling(response as TenantBillingViewModel))
            return
        }
    },
    ChangePlan(
        planId: string,
        tenantId: string,
    ): ThunkAction<Promise<Response.ErrorResponse | Response.InternalServerError | undefined>> {
        return async dispatch => {
            const response = await changePlan(planId, tenantId)

            if (response.type === 'tenant_billing') {
                dispatch(actions.SetTenantBilling(response))
                return
            } else if (response.type === 'error' || response.type === 'internal_server_error') {
                return response
            }
        }
    },
    FetchIdentity(): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            dispatch(actions.SetLoadingState('loading'))

            let response: RouterOutputs['identity']['login']
            try {
                response = await fetchMe()
                dispatch(actions.SetLoadingState('ready'))
            } catch (e) {
                // Allow this to bubble up to ErrorBoundary
                throw e
            }

            if (response.type === 'identity') {
                dispatch(actions.SetUser(response))
            }
        }
    },
    Login(data: {username: string; password: string}): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const response = await login(data)
            if (response.type === 'error') {
                return response.message
            }
            dispatch(actions.SetUser(response))
            dispatch(actions.SetLoadingState('ready'))
        }
    },
    ResetPassword(data: {email: string}): ThunkAction<Promise<void | string | boolean>> {
        return async () => {
            const response = await resetPassword(data)

            if (response) {
                return response.message
            }
            return true
        }
    },
    SetPassword(data: {password: string; token: string}) {
        return async () => {
            return setPassword(data)
        }
    },

    SignUp(signupData: {
        username: string
        password: string
        confirm_password: string
        given_name: string
        family_name: string
    }): ThunkAction<Promise<void | string>> {
        return async dispatch => {
            const response = await signUp({
                familyName: signupData.family_name,
                username: signupData.username,
                password: signupData.password,
                givenName: signupData.given_name,
            })
            if (response.type === 'internal_server_error') {
                return response.message
            }
            dispatch(actions.SetUser(response))
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>
export default {...actions, ...thunkActions}
