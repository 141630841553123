import {useMemo} from 'react'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import Pill from '~/widgets/Pill'
import {useAppSelector} from '../../../store'
import {ArrowReply as ReturnIcon} from '@styled-icons/fluentui-system-filled/ArrowReply'
import './Intermission.scss'
import {ABGroup} from 'shared-types/view-models/SocketModels'

export default function Intermission() {
    const {
        breakout: {nextState},
        allParticipants,
        nextRoomParticipantIds,
        channel,
        isHost,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    const settings = useAppSelector(({eventState}) => eventState.event!.settings)

    const participants = useMemo(() => nextRoomParticipantIds.map(id => allParticipants[id]), [nextRoomParticipantIds])

    const getGroupLabel = (group: ABGroup) => {
        if (group === 'A') return settings.groupOneLabel
        if (group === 'B') return settings.groupTwoLabel
    }

    const renderNextBreakout = () => {
        if (participants.length === 0) {
            return (
                <div className="text-lg p-2 text-center">
                    Everyone else is matched.
                    <br /> You're going to sit this one out!
                </div>
            )
        }

        if (channel === 0 && !isHost) {
            return (
                <div className="flex flex-col p-2 gap-4">
                    <div className="text-lg p-2 text-center">
                        Everyone else is matched,
                        <br /> You'll be in the home room this round.
                    </div>
                    <div className="text-md p-2 text-center">(hint) Try prioritising some people in your sidebar.</div>
                </div>
            )
        }
        return participants
            .filter(p => p !== undefined)
            .map(participant => (
                <div key={participant.id} className="mx-2">
                    <div className="p-1 gap-4 flex flex-col items-center text-center">
                        <AmplifierAvatar src={participant.picture} name={participant.name} size="md" />
                        <div className="flex flex-col justify-start align-start">
                            <h4 className="text-xl font-semibold mb-3">{participant.name}</h4>
                            <p className="text-lg">{participant.title}</p>
                            <p className="text-lg mb-2">{participant.company}</p>
                            <p className="text-md">
                                {participant.group && (
                                    <span className="groupTag">{getGroupLabel(participant.group)}</span>
                                )}
                                {participant.hashtags.map(tag => {
                                    if (!tag?.name) return null
                                    return (
                                        <Pill key={tag.id} color="primary" rounded size="sm" className="mb-1 mr-2">
                                            <span className="hash">#</span>
                                            {tag.name}
                                        </Pill>
                                    )
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            ))
    }

    return (
        <div data-theme="light" className="intermissionWrap">
            <div className="intermissionCard">
                <h2 className="text-3xl font-medium my-4 mx-auto">{nextState === 'breakout' ? `Next Up...` : null}</h2>
                <div className="flex flex-col justify-center align-stretch flex-1 text-gray-600">
                    {nextState === 'breakout' && renderNextBreakout()}
                    {nextState === 'normal' && (
                        <div className="flex flex-col items-center">
                            <ReturnIcon size="3em" />
                            <h6 className="text-2xl text-center my-2">Returning Home</h6>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
