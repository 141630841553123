import ReturnHomeIcon from '~/assets/icons/return-home.svg'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {useParticipants} from '~/contexts/ParticipantsProvider'
import {useAppSelector} from '~/store'
import {pageUrlFromRoomCode} from '~/urlUtils'

// Shows if the current room is empty.
// state = 'normal': Shows a message about no one here
// state = 'breakout': Information about the other person left / you're in the home room
export default function EmptyRoomState() {
    const {leaveBreakout} = useAmplifierSocket()
    const {participantCount} = useParticipants()

    const {state, channel, isConnecting, matchedIds} = useAppSelector(({amplifierSocket}) => ({
        state: amplifierSocket.breakout.state,
        channel: amplifierSocket.channel,
        isConnecting: amplifierSocket.socketState === 'connecting',
        matchedIds: amplifierSocket.localParticipant.matchedIds,
    }))

    const roomCode = useAppSelector(({eventState}) => eventState.roomCode!)

    if (isConnecting) {
        return null
    }

    const wrapper = (children: React.ReactNode) => {
        return <div className="text-center">{children}</div>
    }

    if (state === 'normal' && participantCount <= 1) {
        return wrapper(
            <>
                <h1 className="text-gray-200 mb-4 select-none">There's no one here</h1>
                <p className="text-gray-200 mb-2 select-none">Add someone by copying the link</p>
                <p className="text-gray-300 select-all">{pageUrlFromRoomCode(roomCode)}</p>
            </>,
        )
    }

    if (state === 'breakout' && matchedIds.length === 0 && channel !== 0) {
        return wrapper(
            <>
                <h1 className="text-gray-200 mb-4 select-none">Speed networking is already underway!</h1>
                <p>Please wait to be automatically matched with someone in the next round.</p>
                <p>You can also click the button below to wait in the lobby with the host. </p>
                   <p> </p>
                                <button type="button" className="btn btn-primary text-gray-800" onClick={leaveBreakout}>
                    <ReturnHomeIcon className="h-5 mr-2" />
                    Return home
                </button>
            </>,
        )
    }

    if (state === 'breakout' && channel !== 0) {
        return wrapper(
            <>
                <h1 className="text-gray-200 mb-4 select-none">The other person left the room</h1>
                <button type="button" className="btn btn-primary text-gray-800" onClick={leaveBreakout}>
                    <ReturnHomeIcon className="h-5 mr-2" />
                    Return home
                </button>
            </>,
        )
    }

    return <></>
}
