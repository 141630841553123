import {useState} from 'react'
import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {Warning as WarningIcon} from '@styled-icons/ionicons-solid/Warning'
import {EyeOff as HidePasswordIcon} from '@styled-icons/evaicons-solid/EyeOff'
import {Eye as ShowPasswordIcon} from '@styled-icons/evaicons-solid/Eye'
import {useLocation, useNavigate} from 'react-router-dom'
import BackButton from '../common/BackButton'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useAppDispatch} from '../../store'
import actions from '../../store/identityState'
import './SignUp.scss'
import validate from '~/utils/validate'

const SignUp = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const [showPassword, setShowPassword] = useState(false)

    const renderInput = (field: string, label: string, error: boolean, touched: boolean): React.ReactNode => {
        return (
            <>
                <label className="label label-text text-black" htmlFor={field}>
                    {label}
                </label>
                <Field
                    id={field}
                    name={field}
                    className={cn('input mt-1', {'input-error': error && touched})}
                    type={field.includes('password') ? 'password' : 'text'}
                />
                <p className="text-sm text-error">
                    <ErrorMessage className="text-sm text-error" name={field} />
                </p>
            </>
        )
    }

    return (
        <>
            <BackButton />
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Create Your Account:</h2>
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                    confirm_password: '',
                    given_name: '',
                    family_name: '',
                }}
                validate={validate.generate({
                    username: [validate.required(), validate.email()],
                    password: [validate.required(), validate.password()],
                    confirm_password: [validate.required(), validate.sameAs('password', "Passwords don't match")],
                    given_name: [validate.required()],
                    family_name: [validate.required()],
                })}
                onSubmit={async (values, {setSubmitting, setStatus}) => {
                    setSubmitting(true)

                    const error = await dispatch(actions.SignUp(values))
                    if (error) {
                        setSubmitting(false)
                        setStatus(error)
                        return
                    }

                    let nextRoomQuery = ''
                    //Get the /?room URL
                    const queryObj = new URLSearchParams(location.search)
                    if (queryObj.get('next')) {
                        nextRoomQuery = decodeURIComponent(queryObj.get('next')!)
                    }

                    //Redirect
                    window.location.href = `${nextRoomQuery}`

                    navigate(nextRoomQuery)

                    return
                }}
            >
                {({errors, touched, isSubmitting, status}) => (
                    <Form className="mt-6">
                        {renderInput('given_name', 'Given Name', !!errors['given_name'], !!touched['given_name'])}
                        {renderInput('family_name', 'Family Name', !!errors['family_name'], !!touched['family_name'])}
                        {renderInput('username', 'Email', !!errors['username'], !!touched['username'])}
                        <label className="label label-text text-black" htmlFor="password">
                            Enter password
                        </label>
                        <div className="relative">
                            <Field
                                id="password"
                                name="password"
                                className={cn('input mt-1', {'input-error': errors.password})}
                                type={showPassword ? 'text' : 'password'}
                            />
                            {showPassword ? (
                                <ShowPasswordIcon
                                    className="showPasswordToggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            ) : (
                                <HidePasswordIcon
                                    className="showPasswordToggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            )}
                        </div>
                        <p className="text-sm text-error">
                            <ErrorMessage className="text-sm text-error" name="password" />
                        </p>
                        {renderInput(
                            'confirm_password',
                            'Confirm password',
                            !!errors['confirm_password'],
                            !!touched['confirm_password'],
                        )}
                        {status ? (
                            <div className="alert alert-error alert-sm mt-4 shadow-lg">
                                <div>
                                    <WarningIcon size="1.5rem" />
                                    <div>
                                        <h5 className="font-bold">Error</h5>
                                        <div className="text-md">{status}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <button type="submit" className={'btn btn-primary w-full mt-8'} disabled={isSubmitting}>
                            Create Account
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default SignUp
