import {createContext, useContext} from 'react'

import {DeviceState, useDevices} from './useDevices'
import {useDaily, useLocalParticipant} from '@daily-co/daily-react'

interface MediaDeviceProviderProps {
    cams: MediaDeviceInfo[]
    mics: MediaDeviceInfo[]
    speakers: MediaDeviceInfo[]
    camError?: DeviceState
    micError?: DeviceState
    currentDevices?: {
        camera?: MediaDeviceInfo
        mic?: MediaDeviceInfo
        speaker?: MediaDeviceInfo
    }
    deviceState: DeviceState
    isCamMuted: boolean
    isMicMuted: boolean
    setMicDevice: (newMic: MediaDeviceInfo, useLocalStorage?: boolean) => void
    setCamDevice: (newCam: MediaDeviceInfo, useLocalStorage?: boolean) => void
    setSpeakersDevice: (newSpeaker: MediaDeviceInfo, useLocalStorage?: boolean) => void
    updateDeviceState: () => void
}

export const MediaDeviceContext = createContext<MediaDeviceProviderProps>({} as MediaDeviceProviderProps)

export const MediaDeviceProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const callObject = useDaily()!
    const localParticipant = useLocalParticipant()

    const {
        cams,
        mics,
        speakers,
        camError,
        micError,
        currentDevices,
        deviceState,
        setMicDevice,
        setCamDevice,
        setSpeakersDevice,
        updateDeviceState,
    } = useDevices(callObject)

    return (
        <MediaDeviceContext.Provider
            value={{
                cams,
                mics,
                speakers,
                camError,
                micError,
                currentDevices,
                deviceState,
                isCamMuted: Boolean(localParticipant?.tracks?.video.off ?? false),
                isMicMuted: Boolean(localParticipant?.tracks?.audio.off ?? false),
                setMicDevice,
                setCamDevice,
                setSpeakersDevice,
                updateDeviceState,
            }}
        >
            {children}
        </MediaDeviceContext.Provider>
    )
}

export const useMediaDevices = () => useContext(MediaDeviceContext)
