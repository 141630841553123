import {DateTime} from 'luxon'

export const getBestDate = (ttl: number, timestamp: DateTime): DateTime => {
    const ttlDateTime = DateTime.now().plus(ttl)

    if (ttlDateTime.diff(timestamp).milliseconds >= 1500) {
        return ttlDateTime
    }

    return timestamp
}
