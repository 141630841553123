import {useEffect, useMemo, useState} from 'react'
import {useAppDispatch, useAppSelector} from '~/store'
import './Events.scss'
import actions from '../../../store/eventsState'

import {Plus as PlusIcon} from '@styled-icons/heroicons-solid/Plus'
import {
    ChevronDown as ChevronDownIcon,
    SortDescending as BarsArrowUpIcon,
    ChevronUp as ChevronUpIcon,
} from '@styled-icons/heroicons-solid'
import {MagnifyingGlass as MagnifyingGlassIcon} from '@styled-icons/entypo/MagnifyingGlass'
import EventLineItem from './EventLineItem'
import {useNavigate} from 'react-router-dom'
import {useBreadCrumbs} from '../DashboardWrap'

export default function Events() {
    const {eventsLoadingState, events} = useAppSelector(state => state.eventsState)
    const tenant = useAppSelector(state => state.identityState.tenant!)
    const dispatch = useAppDispatch()

    const [sortMode, setSortMode] = useState<'asc' | 'desc'>('asc')
    const [filter, setFilter] = useState('')

    const {setBreadCrumbs} = useBreadCrumbs()

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(actions.FetchEvents(tenant.slug))
        setBreadCrumbs([
            {
                name: 'Events',
                href: '/dashboard/events',
                current: true,
            },
        ])
    }, [])

    let header = <></>

    const eventsSearchString = useMemo(() => {
        return events.map(event => JSON.stringify(event).toLowerCase())
    }, [events])

    const eventsFiltered = useMemo(() => {
        if (filter.length === 0) {
            return events.sort((a, b) => {
                if (sortMode === 'asc') {
                    return a.title.localeCompare(b.title)
                } else {
                    return b.title.localeCompare(a.title)
                }
            })
        }

        return events
            .filter((_, index) => {
                return eventsSearchString[index].includes(filter.toLowerCase())
            })
            .sort((a, b) => {
                if (sortMode === 'asc') {
                    return a.title.localeCompare(b.title)
                } else {
                    return b.title.localeCompare(a.title)
                }
            })
    }, [events, filter, sortMode])

    if (!eventsLoadingState) {
        return <>{header}</>
    }

    return (
        <div className="events__page">
            {header}

            {events.length === 0 ? (
                <div className="text-center my-10">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        />
                    </svg>
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No Events</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by creating a new event.</p>
                    <div className="mt-6">
                        <button
                            type="button"
                            className="btn inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={() => navigate('/dashboard/events/new')}
                        >
                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Create Event
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex justify-end border-b mx-8 py-6">
                    <div className="flex  my-3 flex-grow  rounded-md w-96  ">
                        <div className="relative w-72  focus-within:z-10">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                name="mobile-search-candidate"
                                id="mobile-search-candidate"
                                className="block w-full h-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:hidden"
                                placeholder="Search"
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                            />
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="hidden h-full w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:block"
                                placeholder="Search Events"
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => setSortMode(sortMode === 'asc' ? 'desc' : 'asc')}
                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            <BarsArrowUpIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Sort
                            {sortMode === 'asc' ? (
                                <ChevronUpIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            ) : (
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                    <div className="my-3 px-6 mx-3 justify-self-end">
                        <button
                            onClick={() => navigate('/dashboard/events/new')}
                            className="btn btn-primary bg-gray-800 text-white border-none hover:bg-gray-600"
                        >
                            Create Event
                        </button>
                    </div>
                </div>
            )}

            <div className="events__list">
                <ul>
                    {eventsFiltered.map(e => {
                        return <EventLineItem key={e.id} event={e} />
                    })}
                </ul>
            </div>
        </div>
    )
}
