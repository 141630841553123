import cn from 'classnames'
import {Listbox, Transition} from '@headlessui/react'
import {ChevronDown as CollapseIcon} from '@styled-icons/boxicons-regular/ChevronDown'
import {Fragment, useEffect, useState} from 'react'

interface ChannelSelectProps {
    label: string
    channels: {
        [key: number]: string[]
    }
    onChange: (channel: number) => void
}

export default function ChannelSelect({label, channels, onChange}: ChannelSelectProps) {
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        onChange(selected)
    }, [selected])

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({open}) => (
                <>
                    <Listbox.Label className="block text-sm font-medium text-gray-500">{label}</Listbox.Label>
                    <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-800 bg-neutral py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                            <span className="block truncate">
                                {selected === 0 ? 'Home Room' : 'Breakout ' + selected}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <CollapseIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {Object.values(channels).map((channel, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({active}) =>
                                            cn(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                            )
                                        }
                                        value={index}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <span
                                                    className={cn(
                                                        selected ? 'font-semibold' : 'font-normal',
                                                        'block truncate',
                                                    )}
                                                >
                                                    {index === 0 ? 'Home Room' : 'Breakout ' + index}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={cn(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        )}
                                                    ></span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
