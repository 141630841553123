import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import './OnboardingWrap.scss'

import clsx from 'clsx'

import {motion} from 'framer-motion'
import {RawLogo} from '../common/RawLogo'
import {useEffect, useState} from 'react'
import ProfileMenu from '../common/ProfileMenu/ProfileMenu'
import {useAppSelector} from '~/store'

const stages = ['/start', '/start/plan', '/start/payment', '/start/event']

export default function OnboardingWrap() {
    let {pathname} = useLocation()

    const identity = useAppSelector(state => state.identityState)

    const navigate = useNavigate()

    useEffect(() => {
        if (!identity.isAuthenticated) {
            navigate('/login')
            return
        }

        if (!identity.tenant) {
            navigate('/start')
            return
        }

        if (!identity.tenant.billing?.staged_plan && !identity.tenant.billing?.plan) {
            navigate('/start/plan')
            return
        }

        if (!identity.tenant.billing?.email) {
            navigate('/start/payment')
            return
        }

        if (identity.tenant.billing?.email) {
            navigate('/start/event')
            return
        }

        navigate('/dashboard')
    }, [])

    const [showContinue, setShowContinue] = useState(pathname === '/start')

    return (
        <div className="wrapper">
            <div className="onboarding__container">
                <motion.div className="onboarding__navbar">
                    <ProfileMenu
                        theme="dark"
                        topOption="join"
                        startLeavingCall={function (): void {
                            throw new Error('Function not implemented.')
                        }}
                    />
                </motion.div>

                {showContinue && (
                    <motion.div layout className={clsx('onboarding__navigation initial')}>
                        <motion.div className="onboarding__navigation__lgs">
                            <motion.div
                                layout
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: 0.5, delay: 0.1}}
                                className={clsx('w-12', 'logo')}
                            >
                                <RawLogo className="fill-black" />

                                <motion.span layout className="">
                                    Amplifier
                                </motion.span>
                            </motion.div>

                            <motion.div layout className="startPrompt">
                                <motion.span
                                    layout
                                    initial={{y: -10, opacity: 0}}
                                    animate={{y: 0, opacity: 1}}
                                    transition={{duration: 0.5, delay: 0.2}}
                                    className=""
                                >
                                    Let's get started
                                </motion.span>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            layout
                            initial={{width: '0%', opacity: 0}}
                            animate={{width: '100%', opacity: 1}}
                            transition={{duration: 0.5, delay: 0.5}}
                            className="onboarding__navigation__action"
                        >
                            <motion.button
                                initial={{opacity: 0, width: '4rem'}}
                                animate={{opacity: 1, width: '10rem'}}
                                transition={{duration: 0.5, delay: 1}}
                                className="btn btn-primary"
                                onClick={() => {
                                    setShowContinue(false)
                                }}
                            >
                                Continue →
                            </motion.button>
                        </motion.div>
                    </motion.div>
                )}

                {!showContinue && (
                    <motion.div
                        initial={{opacity: 0}}
                        transition={{duration: 0.5, delay: 0.5}}
                        animate={{opacity: 1}}
                        className="modalBox"
                    >
                        <Outlet />
                    </motion.div>
                )}

                <motion.div
                    initial={{opacity: 0}}
                    transition={{duration: 0.5, delay: 0.5}}
                    animate={{opacity: 1}}
                    className="onboarding__steps"
                >
                    <motion.div
                        layout
                        className={clsx('step', stages.indexOf(pathname) >= 0 && !showContinue && 'active')}
                    >
                        <span>Provide Information</span>
                        <motion.span layout className="artifact"></motion.span>
                    </motion.div>
                    <motion.div
                        layout
                        className={clsx('step', stages.indexOf(pathname) >= 1 && !showContinue && 'active')}
                    >
                        <span>Select Plan</span>
                        <motion.span layout className="artifact"></motion.span>
                    </motion.div>
                    <motion.div
                        layout
                        className={clsx('step', stages.indexOf(pathname) >= 2 && !showContinue && 'active')}
                    >
                        <span>Payment</span>
                        <span className="artifact"></span>
                    </motion.div>
                    <motion.div
                        layout
                        className={clsx('step', stages.indexOf(pathname) >= 3 && !showContinue && 'active')}
                    >
                        <span>Create Event</span>
                        <span className="artifact"></span>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    )
}
