import {DailyTrackState} from '@daily-co/daily-js'
import {useEffect, useRef} from 'react'
import {useDeepCompareEffect, useDeepCompareMemo} from 'use-deep-compare'

interface CombinedAudioTrackProps {
    tracks: Record<string, DailyTrackState>
}
const CombinedAudioTrack = ({tracks}: CombinedAudioTrackProps) => {
    const audioEl = useRef<HTMLAudioElement>(null as any)

    useEffect(() => {
        if (!audioEl || !audioEl.current) return
        audioEl.current!.srcObject = new MediaStream()
    }, [])

    const trackIds = useDeepCompareMemo(() => Object.values(tracks).map((t: any) => t?.persistentTrack?.id), [tracks])

    useDeepCompareEffect(() => {
        const audio = audioEl.current
        if (!audio || !audio.srcObject) return

        const stream = audio.srcObject as MediaStream
        const allTracks = Object.values(tracks)

        allTracks.forEach(track => {
            const persistentTrack = track?.persistentTrack
            if (persistentTrack) {
                persistentTrack.addEventListener('ended', ev => stream.removeTrack(ev.target as any), {once: true})
                stream.addTrack(persistentTrack)
            }
        })

        const playAudio = async () => {
            try {
                if (stream.getAudioTracks().some(t => t.enabled && t.readyState === 'live') && audio.paused) {
                    await audio.play()
                }
            } catch {
                // ...
            }
        }

        audio.load()
        playAudio()
    }, [tracks, trackIds])

    return (
        <audio autoPlay playsInline ref={audioEl}>
            <track kind="captions" />
        </audio>
    )
}

export default CombinedAudioTrack
