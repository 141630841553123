import {Fragment, useEffect, useState} from 'react'
import Bowser from 'bowser'
import Mousetrap from 'mousetrap'
import {useCallState} from '../../../contexts/CallProvider'
import {useAmplifierSocket} from '../../../contexts/AmplifierSocketProvider'
import {useAppSelector} from '../../../store'
import {Dialog, Transition} from '@headlessui/react'
import {useTranscription} from '@daily-co/daily-react'

const operatingSystem = Bowser.parse(window.navigator.userAgent).os
const superkey = operatingSystem.name === 'macOS' ? 'command' : 'ctrl'
const superkeyIcon = operatingSystem.name === 'macOS' ? '⌘' : 'Ctrl'

export default function DebugPanel() {
    const [isOpen, setIsOpen] = useState(false)

    const {channel, grantHost, debugSocketConnection, breakoutLatecomers, setDebugSocketConnection} =
        useAmplifierSocket()

    const {isHost, localParticipant} = useAppSelector(({amplifierSocket}) => amplifierSocket)

    const {networkState, videoQuality} = useCallState()
    const {
        startTranscription,
        stopTranscription,
        isTranscriptionEnabled,
        isTranscribing,
        error,
        model,
        profanity_filter,
        tier,
        transcriptions,
        language,
    } = useTranscription()

    const toggleSelfGrantHost = () => {
        grantHost(localParticipant.id, !isHost)
    }

    const executeBreakoutLatecomers = () => {
        breakoutLatecomers()
    }

    // Register Keyboard Shortcuts for the video debug panel
    useEffect(() => {
        Mousetrap.bind(`${superkey}+shift+f9`, e => {
            e.preventDefault()
            setIsOpen(!isOpen)
        })
        return () => {
            Mousetrap.unbind(`${superkey}+shift+f9`)
        }
    }, [isOpen])

    if (isOpen)
        return (
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="backdrop-blur-xl bg-white/40 shadow-xl absolute top-16 left-4 p-4 rounded-lg"
                    onClose={() => setIsOpen(!isOpen)}
                    data-theme="light"
                >
                    <div className="grid grid-cols-1 gap-2">
                        <div>
                            <kbd className="kbd kbd-xs inline-block w-auto">{superkeyIcon} Shift F9</kbd>
                        </div>
                        <h6 className="text-xl font-bold">Debug Panel</h6>
                        <div>
                            <b>Channel:</b> {channel}
                        </div>
                        <div>
                            <b>Network Condition:</b> {networkState}
                        </div>
                        <div className="mb-4">
                            <b>Video Quality:</b> {videoQuality}
                        </div>
                        <button className="btn btn-small btn-accent" onClick={toggleSelfGrantHost}>
                            {isHost ? 'De-escalate Privileges' : 'Escalate Privileges'}
                        </button>
                        <button
                            className="btn btn-small btn-accent"
                            onClick={() => setDebugSocketConnection(!debugSocketConnection)}
                        >
                            {debugSocketConnection ? 'Reconnect Socket' : 'Disconnect Socket'}
                        </button>

                        <button className="btn btn-small btn-accent" onClick={() => executeBreakoutLatecomers()}>
                            Breakout Latecomers
                        </button>
                        <button
                            className="btn btn-small btn-accent"
                            onClick={() => {
                                if (isTranscribing) {
                                    stopTranscription()
                                } else {
                                    startTranscription({
                                        tier: 'nova',
                                        model: 'general',
                                    })
                                }
                            }}
                            disabled={!isTranscriptionEnabled}
                        >
                            {isTranscribing ? 'Stop Transcribing' : 'Start Transcribing'}{' '}
                        </button>
                        {error && (
                            <div>
                                <b>Transcription Error:</b> {error ? 'yep' : 'no'}
                            </div>
                        )}
                        {(isTranscribing || transcriptions.length > 0) && (
                            <>
                                <hr />
                                <h2>Transcription data from deepgram</h2>
                                <code>
                                    <div>
                                        <b>Model:</b> {model}
                                    </div>
                                    <div>
                                        <b>Tier:</b> {tier}
                                    </div>
                                    <div>
                                        <b>Profanity filter:</b> {profanity_filter ? 'on' : 'off'}
                                    </div>
                                    <div>
                                        <b>Language:</b> {language}
                                    </div>
                                    <code>
                                        <div>
                                            <b>Transcriptions:</b>
                                        </div>
                                        <small>
                                            {transcriptions.map(transcription => (
                                                <>
                                                    <b>
                                                        {(transcription as any).user_name} delay{' '}
                                                        {(transcription as any).transcription_delay / 1000}s
                                                    </b>
                                                    : {transcription.text}
                                                    <br />
                                                </>
                                            ))}
                                        </small>
                                    </code>
                                </code>
                                <hr />
                            </>
                        )}
                    </div>
                </Dialog>
            </Transition.Root>
        )

    return <></>
}
