import React, {useEffect, useMemo, useRef, useState} from 'react'
import HostControls, {HostControlsHandleProps} from '../Common/HostControls'
import {useVideoTrack} from '../../../hooks/useVideoTrack'
import TileVideo from './TileVideo'
import {DEFAULT_ASPECT_RATIO} from '../../../constants'
import {CallItem, ParticipantItem} from '../../../contexts/participantsState'
import './Tile.scss'
import {MicOff as MicOffIcon} from '@styled-icons/fluentui-system-filled/MicOff'
import {PresenceBlocked} from '@styled-icons/fluentui-system-regular/PresenceBlocked'
import {ErrorCircle} from '@styled-icons/fluentui-system-regular/ErrorCircle'
import {useAppSelector} from '../../../store'
import {DateTime} from 'luxon'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'

function ellipsis(input: string, max: number) {
    if (input.length > max) {
        return input.substring(0, max) + '...'
    }
    return input
}

interface TileProps {
    participant: CallItem | ParticipantItem
    showActiveSpeaker?: boolean
    showName?: boolean
    showAvatar?: boolean
    videoFit?: 'contain' | 'cover'
    aspectRatio?: number
    onVideoResize?: (ratio: number) => void
    style?: React.CSSProperties
}
export default function Tile({
    participant,
    showActiveSpeaker = true,
    showName = true,
    showAvatar = true,
    videoFit = 'contain',
    aspectRatio = DEFAULT_ASPECT_RATIO,
    onVideoResize,
    style,
}: TileProps) {
    const videoTrack = useVideoTrack(participant)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [fadeToBlack, setFadeToBlack] = useState(false)

    const hostControlsRef = useRef<HostControlsHandleProps>(null)
    const {allParticipants, isHost, socketState, homeRoomMode} = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const {state, stateEnd} = useAppSelector(({amplifierSocket}) => amplifierSocket.breakout)
    const localParticipant = useAppSelector(({amplifierSocket}) => amplifierSocket.localParticipant)
    const {picture} = useAppSelector(({identityState}) => identityState.user!)

    useEffect(() => {
        if (stateEnd === null || state !== 'breakout') {
            if (fadeToBlack === true) setFadeToBlack(false)
            return
        }

        const setTime = () => {
            const secondsRemaining = DateTime.fromJSDate(stateEnd!).diff(DateTime.now()).as('seconds') + 1
            if (secondsRemaining < 10) {
                setFadeToBlack(true)
            } else setFadeToBlack(false)
        }
        setTime()
        const interval = setInterval(setTime, 100)
        return () => clearInterval(interval)
    }, [stateEnd])

    /**
     * When video track changes, update video srcObject
     */
    useEffect(() => {
        const persistentTrack = (videoTrack as any)?.persistentTrack
        videoRef.current && persistentTrack && (videoRef.current.srcObject = new MediaStream([persistentTrack]))
    }, [videoTrack?.track])

    const getClassNames = () => {
        let classNames = `tile ${videoFit}`
        if (showActiveSpeaker && participant.type === 'participant_item' && participant.isActiveSpeaker)
            classNames += ' active'
        if (participant.id.endsWith('-screen')) classNames += ' screen'
        if (participant.isLocal) classNames += ' local'
        if (fadeToBlack) classNames += ' fadeToBlack'
        return classNames
    }

    /**
     * Effect: Resize
     *
     * Add optional event listener for resize event so the parent component
     * can know the video's native aspect ratio.
     */
    useEffect(() => {
        const video = videoRef.current
        if (!onVideoResize || !video) return

        const handleResize = () => {
            if (!video) return
            const width = video?.videoWidth
            const height = video?.videoHeight
            if (width && height) {
                // Return the video's aspect ratio to the parent's handler
                onVideoResize(width / height)
            }
        }

        handleResize()
        video?.addEventListener('resize', handleResize)

        return () => video?.removeEventListener('resize', handleResize)
    }, [onVideoResize, videoRef, participant])

    const showWarning: boolean = useMemo(
        () => participant.isLocal && state === 'normal' && homeRoomMode === 'hosts' && !isHost,
        [isHost, homeRoomMode, state],
    )

    const displayName = useMemo(() => {
        if (participant.type === 'call_item') return ''
        let id = participant.id
        if (!id) return ''
        if (id.includes('-screen')) return ''
        if (id === 'local' && participant.name) {
            return ellipsis(participant.name + ' (You)', 24)
        }
        return ellipsis(participant.name, 24)
    }, [participant])

    const displayOccupation = useMemo(() => {
        let id = participant.id

        if (id === 'local') {
            return ellipsis(localParticipant.title + ' at ' + localParticipant.company, 32)
        }
        if (id.includes('-screen')) return ''
        if (!id) return ''
        if (allParticipants[id]) {
            if (allParticipants[id].company && allParticipants[id].title) {
                return ellipsis(allParticipants[id]['title'] + ' at ' + allParticipants[id]['company'], 32)
            }
        }

        return ''
    }, [participant, localParticipant])

    const stateIcon: React.ReactElement = useMemo((): React.ReactElement => {
        let stateIcon: React.ReactNode = undefined
        switch (videoTrack?.state) {
            case 'loading':
                stateIcon = <span className="loading loading-spinner loading-sm text-primary" />
                break
            case 'blocked':
                stateIcon = <PresenceBlocked size="25px" />
                break
            case 'interrupted':
                stateIcon = <ErrorCircle size="25px" />
                break
            case 'off':
            case 'sendable':
            case 'playable':
            default:
                break
        }
        if (!stateIcon) return <></>

        return (
            <div className="tooltip tooltip-right" data-tip={`Video ${videoTrack?.state}`}>
                {stateIcon}
            </div>
        )
    }, [videoTrack, videoTrack?.state])

    const getTileImageURL = (): string | undefined => {
        if (participant.isLocal) {
            return picture
        }
        return allParticipants[participant.id]?.picture ?? undefined
    }

    return (
        <div
            className={getClassNames()}
            onContextMenu={event =>
                hostControlsRef.current?.canOpenControls ? hostControlsRef.current.openControls(event) : null
            }
            style={style}
        >
            {videoTrack ? (
                <TileVideo
                    ref={videoRef}
                    participantId={participant?.id}
                    videoTrack={(videoTrack as any)?.persistentTrack}
                />
            ) : (
                showAvatar && (
                    <div className="avatar">
                        <div
                            style={{
                                position: 'relative',
                                width: '25%',
                                height: '0',
                                paddingTop: '25%',
                            }}
                        >
                            <AmplifierAvatar
                                className="absolute w-full h-full top-0"
                                src={getTileImageURL()}
                                name={participant.type === 'participant_item' ? participant.name : ''}
                                size="100%"
                            />
                        </div>
                    </div>
                )
            )}

            {showWarning && <div className="warning">You aren't visible to anyone</div>}

            {showName && participant.type === 'participant_item' && (
                <div className="tile-face">
                    <p className="corner">
                        {participant.isMicMuted && !participant.isScreenshare && <MicOffIcon size="18px" />}
                        {displayName}
                        <br></br>
                        <span className="text-sm">{displayOccupation}</span>
                    </p>
                </div>
            )}
            <div className={`state state-${videoTrack?.state}`}>{stateIcon}</div>
            {socketState === 'connected' && isHost ? (
                <HostControls participantId={participant.id} ref={hostControlsRef} />
            ) : null}
        </div>
    )
}
