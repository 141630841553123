import {useEffect, useState} from 'react'
import {useSettings} from '../SettingsWrap'
import {Edit as EditIcon} from '@styled-icons/evaicons-solid/Edit'
import {getInvoices} from '~/api/billingApi'
import {useAppSelector} from '~/store'
import {InvoiceViewModel} from 'shared-types/view-models'
import InvoiceTable from './InvoiceTable'
import NewPaymentMethod from './NewPaymentMethod'
import ChangePlan from './ChangePlan'
import {DateTime} from 'luxon'
import Skeleton from '~/components/common/Skeleton'
import isArray from 'lodash/isArray'

export default function Payment() {
    const {settings, fetchSettings} = useSettings()
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [invoices, setInvoices]: [InvoiceViewModel[], any] = useState([])

    const [newPaymentMethodOpen, setNewPaymentMethodOpen] = useState(false)
    const [changePlanOpen, setChangePlanOpen] = useState(false)

    const fetchInvoices = async () => {
        setLoading(true)
        let invoiceResponse = await getInvoices(tenant.slug)
        setLoading(false)

        if (!isArray(invoiceResponse)) {
            // error occured
            setError(invoiceResponse.message)
            return
        }

        setInvoices(invoiceResponse)
    }

    const paymentMethodClose = (updated: boolean) => {
        setNewPaymentMethodOpen(false)
        if (updated) {
            fetchSettings()
        }
    }

    const changePlanClose = (updated: boolean) => {
        setChangePlanOpen(false)
        if (updated) {
            fetchSettings()
        }
    }

    useEffect(() => {
        fetchInvoices()
    }, [])

    return (
        <div className="container grid grid-cols-6 ">
            <NewPaymentMethod isOpen={newPaymentMethodOpen} onClose={paymentMethodClose} />

            {settings?.plan?.id != null && (
                <ChangePlan
                    endOfPeriod={settings?.period_end}
                    isOpen={changePlanOpen}
                    currentPlan={settings?.plan?.id}
                    onClose={changePlanClose}
                />
            )}

            <div className="section col-span-6 mb-8 px-8  border-b py-8">
                <h1 className="font-semibold">Payment</h1>
                <span className="text-gray-600">Update your plan and payment method</span>
            </div>

            <div className="section px-8 mb-8 col-span-6">
                <div className="label">
                    <label>Current Plan</label>
                </div>

                <div className="section__content">
                    <div className="card border flex-row justify-between items-center rounded-md p-4">
                        <div className="flex flex-col">
                            {!settings?.plan && !settings?.payment_method && (
                                <>
                                    <Skeleton className="inline h-4 mb-1 w-72" />
                                    <Skeleton className="inline h-4 mb-1 w-48" />
                                    <Skeleton className="inline h-4 mb-1 w-48" />
                                    <Skeleton className="inline h-4 mb-1 w-64" />
                                </>
                            )}

                            {settings?.payment_method && !settings?.plan && (
                                <span className="font-semibold">
                                    Please ensure all invoices are paid, as a failed payment may have occurred on the
                                    most recent invoice.
                                </span>
                            )}

                            {settings?.plan && (
                                <>
                                    <span className="font-semibold">
                                        {settings?.plan?.name}

                                        <div className="ml-2 px-4 bg-gray-200 text-gray-500  h-7 text-sm font-semibold inline-flex flex-wrap items-center select-none justify-center text-center rounded-md  border-none bg-opacity-50">
                                            {'$' + settings?.plan?.price / 100}/mo
                                        </div>
                                    </span>
                                    {settings?.plan?.lineItems.map(e => (
                                        <span className="text-gray-600 text-sm">{e}</span>
                                    ))}
                                    {settings?.downgrades_to ? (
                                        <span className="text-red-500 text-sm">
                                            Downgrading to {settings?.downgrades_to?.name} on{' '}
                                            {settings?.period_end &&
                                                DateTime.fromSeconds(settings?.period_end).toLocaleString()}
                                        </span>
                                    ) : (
                                        <span className="text-black text-sm">
                                            Next Invoice on{' '}
                                            {settings?.period_end &&
                                                DateTime.fromSeconds(settings?.period_end).toLocaleString({
                                                    month: 'long',
                                                    day: '2-digit',
                                                })}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="actions">
                            {settings?.plan?.id != null && (
                                <button
                                    //onClick={() => setEditing(true)}
                                    className="btn text-black bg-transparent border-none hover:bg-gray-200"
                                    onClick={() => setChangePlanOpen(true)}
                                >
                                    Change Plan <EditIcon className="w-5 h-5 pl-2"></EditIcon>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {error && (
                <div className="text-red-500 error text-sm mt-2">
                    <p className="text-red-500">{error}</p>
                </div>
            )}

            <div className="section px-8 mb-8 col-span-6">
                <div className="label">
                    <label>Default Payment Method</label>
                </div>

                <div className="section__content">
                    <div className=" card border flex flex-row justify-between items-center rounded-md p-4">
                        {!settings?.payment_method?.brand && (
                            <div className="flex flex-col gap-x-4">
                                <Skeleton className="inline h-4 mb-1 w-72" />
                                <Skeleton className="inline h-4 mb-1 w-48" />
                                <Skeleton className="inline h-4 mb-1 w-48" />
                            </div>
                        )}

                        {settings?.payment_method?.brand && (
                            <div className="flex items-center gap-x-4">
                                <img
                                    src={`https://img.icons8.com/color/48/000000/${settings?.payment_method?.brand.toLowerCase()}.png`}
                                    alt=""
                                />
                                <div className="flex flex-col">
                                    <span className="font-semibold">
                                        {settings?.payment_method?.brand.toLocaleUpperCase()} ••••{' '}
                                        {settings?.payment_method?.last4}
                                    </span>
                                    <div className="">
                                        Expires: {settings?.payment_method?.exp_month}/
                                        {settings?.payment_method?.exp_year}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="actions">
                            <button
                                onClick={() => setNewPaymentMethodOpen(true)}
                                className="btn text-black bg-transparent border-none hover:bg-gray-200"
                            >
                                Update Payment Method <EditIcon className="w-5 h-5 pl-2"></EditIcon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section px-8   col-span-6">
                <div className="label">
                    <label>Billing History</label>
                </div>

                <div className="section__content border rounded-md">
                    <InvoiceTable loading={loading} invoices={invoices} />
                </div>
            </div>
        </div>
    )
}
