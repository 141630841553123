import cn from 'classnames'
import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {trpc} from '~/api/trpc'
import styles from './Admin.module.scss'
import Loading from '../common/Loading'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'

const AdminCustomer: React.FC = () => {
    const navigate = useNavigate()
    const customerId = useParams().id as string
    const utils = trpc.useContext()

    const customerQuery = trpc.admin.customer.useQuery({customerId})
    const updateFlag = trpc.admin.updateFlag.useMutation({
        onSuccess: () => {
            utils.admin.customer.invalidate({customerId})
        },
    })
    const updateRole = trpc.admin.updateRole.useMutation({
        onSuccess: () => {
            utils.admin.customer.invalidate({customerId})
        },
    })

    const mutationsLoading = updateFlag.isLoading || updateRole.isLoading

    if (customerQuery.isLoading) {
        return <Loading />
    }

    const data = customerQuery.data!

    if (data.type === 'error') {
        return <span className="text-error">{data.message}</span>
    }

    const customer = data

    return (
        <div>
            <button type="button" className="btn btn-neutral mb-5" onClick={() => navigate(-1)}>
                Back
            </button>
            <h2 className={styles.title}>Customer - {customer.email}</h2>
            <div className="grid grid-cols-3 gap-4">
                <div className={cn(styles.analyticsCard, 'col-span-2')}>
                    <span>Details</span>
                    <div>
                        <AmplifierAvatar src={customer.picture} name={`${customer.givenName} ${customer.familyName}`} />
                    </div>
                    <div>
                        <b>Email: </b>
                        {customer.email}
                    </div>
                    <div>
                        <b>Family Name: </b>
                        {customer.familyName}
                    </div>
                    <div>
                        <b>Given Name: </b>
                        {customer.givenName}
                    </div>
                    <div>
                        <b>Title: </b>
                        {customer.title ?? '-'}
                    </div>
                    <div>
                        <b>Role: </b>
                        <select
                            className="select select-bordered select-primary text-black"
                            value={customer.role}
                            disabled={mutationsLoading}
                            onChange={async e => {
                                const newValue = e.target.value as 'ADMIN' | 'USER'
                                await updateRole.mutate({customerId, role: newValue})
                            }}
                        >
                            <option value="ADMIN">Admin</option>
                            <option value="USER">User</option>
                        </select>
                    </div>
                </div>
                <div className={styles.analyticsCard}>
                    <span>Flags</span>
                    {Object.keys(customer.flags).map(flag => (
                        <div key={flag} className="flex justify-between border-b border-b-neutral py-1">
                            <p>{flag}</p>
                            <input
                                type="checkbox"
                                className="checkbox checkbox-primary"
                                checked={customer.flags[flag]}
                                onChange={async e => {
                                    await updateFlag.mutate({customerId, flag, enable: e.target.checked})
                                }}
                                disabled={mutationsLoading}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AdminCustomer
