import React from 'react'
import SiteCrash from '~/assets/svgs/site-crash.svg'
import * as Sentry from '@sentry/browser'

interface ErrorBoundaryState {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<{children: React.ReactNode}, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    componentDidCatch(e: Error) {
        console.error(e)
        Sentry.captureException(e)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />
        }
        return this.props.children
    }
}

function ErrorPage() {
    return (
        <div className="max-h-screen h-full bg-base-100 bg-gradient-to-b from-base-300 to-base-100 bg-fixed max-w-full">
            <div className="h-full flex flex-col gap-2 justify-center items-center text-white">
                <SiteCrash className="mb-4 max-w-md" style={{height: '200px'}} />
                <h6 className="text-xl">Oops, we slipped up...</h6>
                <p>Something went wrong. Please reload the page.</p>
                <button className="my-5 btn btn-primary" onClick={() => document.location.reload()}>
                    Reload Page
                </button>
            </div>
        </div>
    )
}
