import {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import {DateTime} from 'luxon'
import {MessagePayload} from 'shared-types/view-models/SocketModels'
import './SidebarChat.scss'
import {useAmplifierSocket} from '../../../../contexts/AmplifierSocketProvider'
import {useDaily} from '@daily-co/daily-react'
import ChatInput from './ChatInput'
import {useAppSelector} from '../../../../store'
import orderBy from 'lodash/orderBy'

// isFocused is whether or not the chat tab is in view
export default function Chat({
    handleNewChat,
    isFocused,
    focusChat,
}: {
    handleNewChat: () => void
    isFocused: boolean
    focusChat: () => void
}) {
    const {sendMessage} = useAmplifierSocket()
    const [seenMessages, setSeenMessages] = useState(0)
    const [recipientOverride, setRecipientOverride] = useState<string>()

    const {localId, messages} = useAppSelector(({amplifierSocket}) => ({
        localId: amplifierSocket.localId,
        messages: amplifierSocket.messages,
    }))

    const daily = useDaily()!

    const eventState = useAppSelector(state => state.eventState)

    function handleSendMessage(message: string, toId?: string) {
        sendMessage(message, toId)
    }

    const onNotificationClick = () => {
        toast.clearWaitingQueue({containerId: 'Messages'})
        focusChat()
    }

    // Display notifications for new messages.
    // We limit the new messages to 10 seconds ago to avoid bombarding with messages when someone joins.
    useEffect(() => {
        if (messages.length > 0) {
            toast.clearWaitingQueue({containerId: 'Messages'})
            handleNewChat()
            if (!isFocused) {
                // Get the latest messages
                const newMessages = messages
                    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
                    .slice(seenMessages, messages.length)
                newMessages.forEach(message => {
                    if (message.fromId === daily.participants().local.user_id) return
                    // Dont display if message was more than 10 seconds ago.
                    // This is helpful when people first join, so they don't get bombarded with messages
                    const differenceSeconds = DateTime.now()
                        .diff(DateTime.fromJSDate(message.timestamp), 'seconds')
                        .get('seconds')
                    if (differenceSeconds > 10) return

                    if (!eventState.settings.chatNotifications) return

                    toast(<div onClick={onNotificationClick}>{renderMessage(message, true)}</div>, {
                        containerId: 'Messages',
                    })
                })
            }
            setSeenMessages(messages.length)
        }
    }, [messages.length])

    // Render links in message
    function renderMessage(entry: MessagePayload, isNotification?: boolean) {
        // Strip any potentially unsafe script tags
        let text = entry.content.replace(/(<([^>]+)>)/gi, '')
        // Create links
        const urlRegex =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
        text = text.replace(urlRegex, url => {
            return `<a target="_blank" href="${url}">${url}</a>`
        })

        const showReplyButton = entry.toId === localId

        return (
            <div className="flex space-x-3">
                <div>
                    <div className="text-sm">
                        <span className="font-medium text-gray-300">
                            {entry.fromId === localId ? 'You' : entry.fromName}
                        </span>
                        {!!entry.toId ? (
                            <>
                                <span className="font-medium text-gray-300"> {'>'} </span>
                                <span className="text-gray-300">{entry.toId === localId ? 'You' : entry.toName}</span>
                            </>
                        ) : null}
                    </div>
                    <div className="mt-1 text-sm text-white-700">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    </div>
                    {!isNotification && (
                        <div className="mt-1 text-sm space-x-2">
                            <span className="text-gray-500 font-medium">
                                {DateTime.fromJSDate(entry.timestamp).diffNow().milliseconds > -(5 * 1000)
                                    ? 'Just now'
                                    : DateTime.fromJSDate(entry.timestamp).toRelative({style: 'narrow'})}
                            </span>{' '}
                            {showReplyButton ? (
                                <>
                                    <span className="text-gray-500 font-medium">&middot;</span>{' '}
                                    <button
                                        type="button"
                                        onClick={e => setRecipientOverride(entry.fromId)}
                                        className="text-gray-400 font-medium"
                                    >
                                        Reply
                                    </button>
                                </>
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="sidebarChat">
            <ul className="messageList">
                {orderBy(messages, message => message.timestamp.getTime(), 'desc').map((entry, index) => (
                    <li key={index} className="message">
                        {renderMessage(entry)}
                    </li>
                ))}
            </ul>
            <ChatInput recipientOverride={recipientOverride} sendMessage={handleSendMessage} />
        </div>
    )
}
