import {useState} from 'react'
import {useAppSelector} from '~/store'
import {ExclamationCircleFill as ExclamationCircleFillIcon} from '@styled-icons/bootstrap/ExclamationCircleFill'
import clsx from 'clsx'
import LoadingSvg from '~/assets/svgs/loading.svg'
import {requestEmailVerification} from '~/api/identityApi'

export default function EditEmailAddress() {
    const user = useAppSelector(state => state.identityState.user!)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [sent, setSent] = useState(false)

    let [email, setEmail] = useState(user.email)

    const handleRequestVerify = async () => {
        setLoading(true)
        setError('')
        setSent(false)

        const response = await requestEmailVerification()

        setLoading(false)

        if (response.type === 'error') {
            setError(response.message)
            return
        }

        setSent(true)
    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-5">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Email Address
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="relative mt-2 w-96 rounded-md shadow-sm">
                    <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        disabled
                        autoComplete="given-name"
                        className={clsx(
                            !user.email_verified &&
                                'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500',
                            'block w-96 rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 ring-gray-300 focus:ring-inset  sm:text-sm sm:leading-6',
                        )}
                    />
                    {!user.email_verified && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleFillIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    )}
                </div>

                {user.email_verified == false ? (
                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {error && !sent && (
                            <>
                                <span className="text-red-500">{error} </span>
                                <a className="cursor-pointer" onClick={() => handleRequestVerify()}>
                                    Try Again{' '}
                                </a>
                            </>
                        )}

                        {sent && !error && 'Verification email sent '}

                        {!error && 'Please verify your email address '}

                        {!loading && !error && (
                            <a className="cursor-pointer" onClick={() => handleRequestVerify()}>
                                Resend Verification{' '}
                            </a>
                        )}

                        {loading && <LoadingSvg className="w-3 h-3 inline" />}
                    </p>
                ) : (
                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                        You can't update your email when signing in with Google or Linkedin
                    </p>
                )}
            </div>
        </div>
    )
}
