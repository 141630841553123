import {useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {useBreadCrumbs} from '../DashboardWrap'
import actions from '../../../store/eventsState'
import {useAppSelector} from '~/store'
import Loading from '~/components/common/Loading'
import clsx from 'clsx'
import './Event.scss'
import {ExternalLinkOutline} from '@styled-icons/evaicons-outline/ExternalLinkOutline'
import {Copy as CopyIcon} from '@styled-icons/boxicons-regular/Copy'

export default function EventWrap() {
    const {roomCode} = useParams()
    const dispatch = useDispatch()
    const {pathname} = useLocation()
    const {eventsLoadingState, stagedDetailEvent} = useAppSelector(state => state.eventsState)

    const {setBreadCrumbs} = useBreadCrumbs()

    const tabs = useMemo(() => {
        return [
            {
                name: 'Details',
                href: `/dashboard/events/${roomCode}/details`,
                current: pathname === `/dashboard/events/${roomCode}/details`,
            },
            {
                name: 'Sessions',
                href: `/dashboard/events/${roomCode}/sessions`,
                current: pathname === `/dashboard/events/${roomCode}/sessions`,
            },
        ]
    }, [roomCode, pathname])

    useEffect(() => {
        if (!roomCode) {
            return
        }
        dispatch(actions.FetchAndStageEvent(roomCode))
        setBreadCrumbs([
            {
                name: 'Events',
                href: '/dashboard/events',
                current: true,
            },
            {
                name: roomCode,
                href: `/dashboard/events/${roomCode}`,
                current: true,
            },
        ])
    }, [])

    if (eventsLoadingState === 'loading' || !stagedDetailEvent) {
        return <Loading></Loading>
    }

    return (
        <div className="event__container">
            <div className="event__actions__header">
                <div className="actions">
                    <div className="flex flex-col">
                        <div className="flex items-center gap-3">
                            <h1 className="font-semibold">{stagedDetailEvent.title}</h1>

                            {stagedDetailEvent.settings.enableAtoB ? (
                                <div className="event__type ab-mode">A:B Mode</div>
                            ) : (
                                <div className="event__type onetoone-mode">1:1 Mode</div>
                            )}
                        </div>

                        <div className="event__lineitem__roomlink">
                            <a target="_blank" href={`/?room=${stagedDetailEvent.code}`} rel="noreferrer">
                                <p>
                                    {window.location.host}/?room={stagedDetailEvent.code}
                                </p>
                                <ExternalLinkOutline className="h-3 w-3 text-gray-400" aria-hidden="true" />
                            </a>
                        </div>
                    </div>

                    <div className="flex gap-2  items-center">
                        <button
                            type="button"
                            className="btn bg-transparent text-black ring-0 border-none hover:bg-gray-200"
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    window.location.protocol +
                                        '//' +
                                        window.location.host +
                                        `/?room=${stagedDetailEvent.code}`,
                                )
                            }
                        >
                            Copy Link <CopyIcon className="w-5 h-5 pl-2" />
                        </button>
                        <Link to={`/?room=${stagedDetailEvent.code}`} className="btn btn-primary">
                            Join Event
                        </Link>
                    </div>
                </div>

                <div className="tabs">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            defaultValue={tabs.find(tab => tab.current)?.name}
                        >
                            {tabs.map(tab => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="flex space-x-4" aria-label="Tabs">
                            {tabs.map(tab => (
                                <Link
                                    key={tab.name}
                                    to={tab.href}
                                    className={clsx(
                                        tab.current
                                            ? 'bg-primary bg-opacity-20 text-primary'
                                            : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 no-underline text-sm font-medium',
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            <Outlet />
        </div>
    )
}
