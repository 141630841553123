import {useState, useEffect} from 'react'
import clsx from 'clsx'
import './Sidebar.scss'
import SidebarProfile from './Profile/SidebarProfile'
import Chat from './Chat/SidebarChat'
import ParticipantsList from './Participants/ParticipantsList'
import {useAppDispatch, useAppSelector} from '../../../store'
import actions from '~/store/eventState'
import {ArrowIosBackOutline as BackIcon} from '@styled-icons/evaicons-outline/ArrowIosBackOutline'

type TabValue = 'participants' | 'chat' | 'profile'

// Participants, Chat and Profile sidebar
export default function Sidebar({children}: {children: React.ReactNode}) {
    const dispatch = useAppDispatch()

    const [selectedTab, setSelectedTab] = useState<TabValue>('participants')
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 600)

    const sidebarOpen = useAppSelector(({eventState}) => eventState.settings.sidebarOpen)
    const setSidebarOpen = (value: boolean) => {
        if (sidebarOpen === value) return

        dispatch(
            actions.SetSetting({
                setting: 'sidebarOpen',
                value: value,
            }),
        )
    }

    // Subscribe to the window size, to automatically hide the sidebar on mobile
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600 !== isMobile) {
                setIsMobile(window.innerWidth < 600)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (isMobile === true) setSidebarOpen(false)
    }, [isMobile])

    const [unreadMessages, setUnreadMessages] = useState(0)

    function handleNewChat() {
        if (selectedTab === 'chat') {
            return
        }
        setUnreadMessages(count => count + 1)
    }
    useEffect(() => {
        if (selectedTab === 'chat') {
            setUnreadMessages(0)
        }
    }, [selectedTab])

    return (
        <>
            <div
                className="callLayoutMain"
                style={{
                    marginRight: sidebarOpen ? 'var(--sidebar-width)' : 0,
                }}
            >
                {children}
            </div>

            <div className={clsx('callSidebar', sidebarOpen && 'open')}>
                <div
                    onClick={() => setSidebarOpen(false)}
                    className="flex sm:hidden pt-5 pb-5 items-center flex-shrink-0 px-4 cursor-pointer"
                >
                    <span className=" text-base font-medium text-primary">
                        <BackIcon size="1.5rem" aria-hidden="true" />
                        <span className="backText">Back to event</span>
                    </span>
                </div>
                <div className="daisyTabs">
                    <button
                        type="button"
                        className={clsx('', selectedTab === 'participants' && 'active')}
                        onClick={() => setSelectedTab('participants')}
                    >
                        Participants
                    </button>
                    <button
                        type="button"
                        className={clsx(selectedTab === 'chat' && 'active')}
                        onClick={() => setSelectedTab('chat')}
                    >
                        Chat
                        {unreadMessages !== 0 && <div className="badge badge-primary ml-2">{unreadMessages}</div>}
                    </button>
                    <button
                        type="button"
                        className={clsx(selectedTab === 'profile' && 'active')}
                        onClick={() => setSelectedTab('profile')}
                    >
                        Profile
                    </button>
                </div>
                <div className="tabContent p-2">
                    <div className="sidebarContent">
                        <div className="h-full w-full">
                            <div
                                className="tabPanel"
                                style={{
                                    display: selectedTab === 'participants' ? 'block' : 'none',
                                    userSelect: 'none',
                                }}
                            >
                                <ParticipantsList />
                            </div>
                            <div className="tabPanel" style={{display: selectedTab === 'chat' ? 'block' : 'none'}}>
                                <Chat
                                    handleNewChat={handleNewChat}
                                    isFocused={selectedTab === 'chat'}
                                    focusChat={() => setSelectedTab('chat')}
                                />
                            </div>
                            <div
                                className="tabPanel"
                                style={{
                                    display: selectedTab === 'profile' ? 'block' : 'none',
                                    userSelect: 'none',
                                }}
                            >
                                <SidebarProfile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
