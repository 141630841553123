import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'

import {useAppSelector} from '../../store'
import {useNavigate, useSearchParams} from 'react-router-dom'

export default function VerifyEmailAddress() {
    const isAuthenticated = useAppSelector(state => state.identityState.isAuthenticated)

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const success = searchParams.get('success')

    if (!success || success === 'false') {
        return (
            <>
                <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
                <h2>Failed to Verify Email</h2>
                <p className="mt-2 text-sm text-gray-600">
                    Unfortunately there was an error verifying that email address.
                </p>

                <button
                    type="submit"
                    onClick={() => navigate('/dashboard/settings')}
                    className={cn('btn btn-primary w-full mt-8')}
                >
                    Try Again
                </button>
            </>
        )
    }

    return (
        <>
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Email Address Verified</h2>
            <p className="mt-2 text-sm text-gray-600">
                Congratulations, you can now use the full suite of Amplifier features
            </p>

            {isAuthenticated ? (
                <>
                    <button type="submit" onClick={() => navigate('/')} className={cn('btn btn-primary w-full mt-16')}>
                        Join Event
                    </button>

                    <button
                        className="btn btn-primary bg-transparent w-full mt-2 border-none hover:bg-gray-300"
                        onClick={() => navigate('/dashboard')}
                    >
                        Continue to Host Dashboard
                    </button>
                </>
            ) : (
                <button type="submit" onClick={() => navigate('/')} className={cn('btn btn-primary w-full mt-16')}>
                    Continue to Login
                </button>
            )}
        </>
    )
}
