import difference from 'lodash/difference'
import {useState} from 'react'
import {v4 as uuidV4} from 'uuid'
import {Hashtag} from 'shared-types/view-models/SocketModels'
import uniqBy from 'lodash/uniqBy'
import DropdownInput from './DropdownInput'
import {validateHashtag} from '~/utils/validateHashtag'

export default function HashtagInput({
    selectedHashtags,
    addHashtag,
    hashtags,
    placeholder,
}: {
    selectedHashtags: Hashtag[]
    addHashtag: (hashtag: Hashtag) => string | void
    hashtags: Hashtag[]
    placeholder?: string
}) {
    //All Tags
    const [newTag, setNewTag] = useState('')
    const [error, setError] = useState('')

    const allHashtags = uniqBy([...selectedHashtags, ...hashtags], tag => tag?.id)

    const handleSubmit = async (value: Hashtag | string) => {
        let hashtag: Hashtag | undefined = undefined
        if (typeof value !== 'string') {
            hashtag = allHashtags.find(tag => tag.id === value.id)!
        }

        if (!hashtag) {
            // User is creating a new hashtag
            const hashtagError = await validateHashtag(newTag, allHashtags)
            if (hashtagError) {
                setError(hashtagError)
                console.log(hashtagError)
                return
            }

            hashtag = {
                id: uuidV4(),
                name: newTag,
                count: 1,
            }
        } else {
            hashtag = {
                ...hashtag,
                count: hashtag.count + 1,
            }
        }

        addHashtag(hashtag)
        setNewTag('')
        setError('')
    }

    const handleInputChange = (value: string) => {
        // Replace all special characters
        const tag = value.replace(/[^a-zA-Z0-9 ]/g, '')
        setNewTag(tag)
        setError('')
    }

    const unselectedHashtags = difference(allHashtags, selectedHashtags).filter(tag => {
        return tag?.name.toLowerCase().includes(newTag.toLowerCase())
    })

    const options: (string | Hashtag)[] = unselectedHashtags

    if (newTag) {
        options.unshift(`Create #${newTag}`)
    }

    return (
        <DropdownInput
            placeholder={placeholder}
            options={options}
            newValue={newTag}
            newValueChange={handleInputChange}
            optionSubmit={handleSubmit}
            renderOptionInList={(option: Hashtag) => `#${option.name}`}
            errorText={error}
        />
    )
}
