import {useEffect, useMemo, useState} from 'react'
import {useBreadCrumbs} from '../DashboardWrap'
import {Link, Outlet, useLocation, useOutletContext} from 'react-router-dom'
import clsx from 'clsx'

import {getTenantSettings} from '~/api/tenantApi'
import {useAppSelector} from '~/store'
import {TenantSettingsViewModel} from 'shared-types/view-models'

type ContextType = {settings: TenantSettingsViewModel; fetchSettings: any}

export default function SettingsWrap() {
    const {setBreadCrumbs} = useBreadCrumbs()
    //const dispatch = useDispatch()
    const {pathname} = useLocation()
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [settings, setSettings] = useState<TenantSettingsViewModel>()

    useEffect(() => {
        setBreadCrumbs([
            {
                name: 'Settings',
                href: '/dashboard/host-settings',
                current: true,
            },
        ])
    }, [])

    const fetchSettings = async () => {
        let response = await getTenantSettings(tenant.slug)

        if (response.type === 'error' || response.type === 'internal_server_error') {
            // error occured
            return
        }

        setSettings(response)
    }

    const tabs = useMemo(() => {
        return [
            {
                name: 'Payment',
                href: `/dashboard/host-settings/payment`,
                current: pathname === `/dashboard/host-settings/payment`,
            },
            {
                name: 'Usage',
                href: `/dashboard/host-settings/usage`,
                current: pathname === `/dashboard/host-settings/usage`,
            },
            {
                name: 'Account',
                href: `/dashboard/host-settings/account`,
                current: pathname === `/dashboard/host-settings/account`,
            },
        ]
    }, [pathname])

    useEffect(() => {
        fetchSettings()
    }, [tenant])

    return (
        <div className="settings__container">
            <div className="tabs">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={tabs.find(tab => tab.current)?.name}
                    >
                        {tabs.map(tab => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        {tabs.map(tab => (
                            <Link
                                key={tab.name}
                                to={tab.href}
                                className={clsx(
                                    tab.current
                                        ? 'bg-primary bg-opacity-20 text-primary'
                                        : 'text-gray-500 hover:text-gray-700',
                                    'rounded-md px-3 py-2 no-underline text-sm font-medium',
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            <Outlet context={{settings, fetchSettings}} />
        </div>
    )
}

export function useSettings() {
    return useOutletContext<ContextType>()
}
