import cn from 'classnames'
import React from 'react'
import './Pill.scss'

interface PillProps {
    color?: 'primary' | 'secondary' | 'accent' | 'info' | 'success' | 'warning' | 'error' | 'neutral'
    children: React.ReactNode
    icon?: React.ReactNode
    badge?: string | number
    rounded?: boolean
    size?: 'sm' | 'md' | 'lg'
}
export default function Pill({
    color,
    icon,
    rounded,
    children,
    badge,
    size = 'md',
    className,
    ...props
}: PillProps & React.HTMLAttributes<HTMLDivElement>) {
    let clickable = false
    if (props.onClick) {
        props.tabIndex = 0
        clickable = true
    }
    if (!color) {
        color = 'neutral'
    }
    return (
        <div
            className={cn(
                `pill`,
                {
                    [`${color}`]: color,
                    pillRounded: rounded,
                    pillLg: size === 'lg',
                    pillMd: size === 'md',
                    pillSm: size === 'sm',
                    clickable,
                },
                className,
            )}
            {...props}
        >
            {icon && <span className="d-inline-block pr-2 ">{icon}</span>}
            {children}
            {badge && <div className="pillBadge">{badge}</div>}
        </div>
    )
}
