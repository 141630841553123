import {useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import ManualLogin from './Auth/ManualLogin'
import actions from '../store/identityState'
import AuthRoute from './Auth/AuthRoute'
import {useAppDispatch, useAppSelector} from '../store'
import LoadingSvg from '~/assets/svgs/loading.svg'
import Login from './Auth/Login'
import SignUp from './Auth/SignUp'
import LoginWrapper from './Auth/LoginWrapper'
import VideoPlatform from './VideoComponents/VideoPlatform'
import NotFound from './common/NotFound'
import Settings from './AttendeeDashboard/Settings/Settings'
import Connections from './AttendeeDashboard/Connnections/Connections'
import Profile from './AttendeeDashboard/Profile/Profile'
import * as Sentry from '@sentry/browser'
import ResetPassword from './Auth/ResetPassword'
import SetPassword from './Auth/SetPassword'
import OnboardingWrap from './Onboarding/OnboardingWrap'
import SelectPlan from './Onboarding/SelectPlan/SelectPlan'
import OnboardingPayment from './Onboarding/Payment/Payment'
import OnboardingCreateEvent from './Onboarding/CreateEvent/CreateEvent'
import CreateEvent from './Dashboard/CreateEvent/CreateEvent'
import OnboardedRoute from './Auth/OnboardedRoute'
import CreateCompany from './Onboarding/CreateCompany/CreateCompany'
import DashboardWrap from './Dashboard/DashboardWrap'
import Events from './Dashboard/Events/Events'
import EventWrap from './Dashboard/Event/EventWrap'
import Details from './Dashboard/Event/Details/Details'
import SettingsWrap from './Dashboard/HostSettings/SettingsWrap'
import Payment from './Dashboard/HostSettings/Payment/Payment'
import Usage from './Dashboard/HostSettings/Usage/Usage'
import VerifyEmailAddress from './Auth/VerifyEmail'
import BaseWrapper from './common/BaseWrapper'
import ConfirmTenantDeletion from './Auth/ConfirmTenantDeletion'
import Account from './Dashboard/HostSettings/Account/Account'
import IgniteLanding from './Ignite/Landing'
import IgniteSuccess from './Ignite/Success'
import IgniteCancel from './Ignite/Cancelled'
import PastEvent from './AttendeeDashboard/Connnections/PastEvent/PastEvent'
import Sessions from './Dashboard/Event/Sessions/Sessions'
import AdminWrapper from './Admin/AdminWrapper'
import {trpc} from '~/api/trpc'
import AdminIndex from './Admin'
import AdminCustomers from './Admin/customers'
import AdminCustomer from './Admin/customer'

export default function App() {
    const dispatch = useAppDispatch()
    const identityState = useAppSelector(state => state.identityState)
    const userQuery = trpc.identity.me.useQuery()
    // const identityLoading = true

    useEffect(() => {
        dispatch(actions.FetchIdentity())
    }, [])

    useEffect(() => {
        if (!identityState.user?.email) return
        Sentry.setUser({email: identityState.user.email})
    }, [identityState.user])

    if (
        identityState.identityLoadingState === 'loading' ||
        identityState.identityLoadingState === 'error' ||
        userQuery.isLoading
    ) {
        return (
            <div className="loadingWrapper">
                <LoadingSvg />
            </div>
        )
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<OnboardedRoute />}>
                    <Route element={<DashboardWrap />}>
                        <Route element={<SettingsWrap />}>
                            <Route path="/dashboard/host-settings/payment" element={<Payment />} />
                            <Route path="/dashboard/host-settings/usage" element={<Usage />} />
                            <Route path="/dashboard/host-settings/account" element={<Account />} />
                        </Route>
                        <Route
                            path="/dashboard/host-settings/*"
                            element={<Navigate to="/dashboard/host-settings/payment" replace />}
                        />
                        <Route path="/dashboard/events" element={<Events />} />
                        <Route path="/dashboard/events/new" element={<CreateEvent />} />
                        <Route element={<EventWrap />}>
                            <Route path="/dashboard/events/:roomCode/details" element={<Details />} />
                            <Route path="/dashboard/events/:roomCode/sessions" element={<Sessions />} />
                        </Route>
                        <Route path="/dashboard/profile" element={<Profile />} />
                        <Route path="/dashboard/connections" element={<Connections />} />
                        <Route path="/dashboard/connections/:eventSessionId" element={<PastEvent />} />
                        <Route path="/dashboard/settings" element={<Settings />} />
                        <Route path="/dashboard/*" element={<Navigate to="/dashboard/events" />} />
                    </Route>
                </Route>

                <Route element={<AuthRoute />}>
                    <Route path="/" element={<VideoPlatform />} />

                    <Route element={<BaseWrapper />}>
                        <Route path="/tenant/delete" element={<ConfirmTenantDeletion />} />
                        <Route path="/ignite/start" element={<IgniteLanding />} />
                        <Route path="/ignite/success" element={<IgniteSuccess />} />
                        <Route path="/ignite/cancel" element={<IgniteCancel />} />
                    </Route>

                    <Route element={<OnboardingWrap />}>
                        <Route path="/start" element={<CreateCompany />}></Route>
                        <Route path="/start/plan" element={<SelectPlan />}></Route>
                        <Route path="/start/payment" element={<OnboardingPayment />}></Route>
                        <Route path="/start/event" element={<OnboardingCreateEvent />}></Route>
                    </Route>
                </Route>
                <Route element={<LoginWrapper />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/manual" element={<ManualLogin />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />
                </Route>

                <Route element={<BaseWrapper />}>
                    <Route path="/setpassword" element={<SetPassword />} />
                    <Route path="/verifyemail" element={<VerifyEmailAddress />} />
                </Route>

                <Route element={<AdminWrapper />}>
                    <Route path="/admin" element={<AdminIndex />} />
                    <Route path="/admin/customers" element={<AdminCustomers />} />
                    <Route path="/admin/customers/:id" element={<AdminCustomer />} />
                </Route>

                {/* <Route element={< />}> { /* Routes that can be either */}

                {/* </Route> */}

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
