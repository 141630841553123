import cn from 'classnames'
import {Transition, Menu} from '@headlessui/react'
import {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useAppSelector} from '../../../store'
import {backendTarget} from '~/api'
import {MoreHorizontalOutline as MoreIcon} from '@styled-icons/evaicons-outline/MoreHorizontalOutline'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import './ProfileMenu.scss'
import clsx from 'clsx'

export default function ProfileMenu({
    startLeavingCall,
    theme,
    topOption = 'host',
    className,
    direction = 'down',
}: {
    startLeavingCall?: () => void
    theme?: 'dark' | 'light' | 'transparent'
    topOption?: 'host' | 'join'
    className?: string
    direction?: 'up' | 'down'
}) {
    const user = useAppSelector(state => state.identityState.user!)

    return (
        <div className={clsx(className, 'profileMenu', theme)}>
            <Menu as="div" className="ml-3 relative">
                <div>
                    <Menu.Button className="menuCardButton">
                        <AmplifierAvatar
                            src={user.picture}
                            name={`${user.given_name} ${user.family_name}`}
                            size="xs"
                            className="menuAvatar"
                        />

                        <div className="textWrapper truncate">
                            <p className="text-sm font-medium truncate text-gray-900">{user.nickname}</p>
                            <p className=" text-sm truncate text-gray-500">{user.email}</p>
                        </div>

                        <div className="moreIcon">
                            <MoreIcon />
                        </div>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={clsx('menuItems', direction === 'up' && 'bottom-full')}>
                        {topOption === 'host' ? (
                            <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="/dashboard"
                                        onClick={startLeavingCall}
                                        className={cn(active ? 'bg-gray-100' : '', 'menuItem')}
                                    >
                                        Host an Event
                                    </Link>
                                )}
                            </Menu.Item>
                        ) : (
                            <Menu.Item>
                                {({active}) => (
                                    <Link
                                        to="/"
                                        onClick={startLeavingCall}
                                        className={cn(active ? 'bg-gray-100' : '', 'menuItem')}
                                    >
                                        Join an Event
                                    </Link>
                                )}
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            {({active}) => (
                                <Link
                                    to="/dashboard/profile"
                                    onClick={startLeavingCall}
                                    className={cn(active ? 'bg-gray-100' : '', 'menuItem')}
                                >
                                    Your Profile
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href={`${backendTarget}/api/v1/identity/logout`}
                                    className={cn(active ? 'bg-gray-100' : '', 'menuItem')}
                                >
                                    Sign out
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
