import {useEffect, useState} from 'react'
import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {Warning as WarningIcon} from '@styled-icons/ionicons-solid/Warning'
import {useNavigate} from 'react-router'
import {useAppDispatch, useAppSelector} from '../../store'
import actions from '../../store/identityState'
import {Link, useLocation} from 'react-router-dom'
import {EyeOff as HidePasswordIcon} from '@styled-icons/evaicons-solid/EyeOff'
import {Eye as ShowPasswordIcon} from '@styled-icons/evaicons-solid/Eye'
import BackButton from '../common/BackButton'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import validate from '~/utils/validate'

export default function ManualLogin() {
    const isAuthenticated = useAppSelector(state => state.identityState.isAuthenticated)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (isAuthenticated) {
            const {from} = (location.state as any) || {from: {pathname: '/'}}
            navigate(from, {replace: true})
        }
    }, [isAuthenticated])

    return (
        <>
            <BackButton />
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Welcome Back!</h2>
            <p className="mt-2 text-sm text-gray-600">How would you like to join the event?</p>
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                validate={validate.generate({
                    username: [validate.required(), validate.email()],
                    password: [validate.required()],
                })}
                onSubmit={async (values, {setSubmitting, setStatus}) => {
                    setSubmitting(true)
                    const error = await dispatch(actions.Login(values))
                    if (error) {
                        setStatus(error)
                        setSubmitting(false)
                        return
                    }

                    let nextRoomQuery = ''
                    //Get the /?room URL
                    const queryObj = new URLSearchParams(location.search)
                    if (queryObj.get('next')) {
                        nextRoomQuery = decodeURIComponent(queryObj.get('next')!)
                    }

                    //Redirect
                    window.location.href = `${nextRoomQuery}`

                    navigate(nextRoomQuery)
                }}
            >
                {({errors, touched, isSubmitting, status}) => (
                    <Form className="mt-6">
                        <label className="label label-text text-black" htmlFor="username">
                            Email Address
                        </label>
                        <Field
                            id="username"
                            name="username"
                            className={cn('input mt-1', {'input-error': errors.username && touched.username})}
                            type="text"
                            isSubmitting={isSubmitting}
                        />
                        <p className="text-sm text-error">
                            <ErrorMessage className="text-sm text-error" name="username" />
                        </p>

                        <label className="label label-text text-black" htmlFor="password">
                            Enter password
                        </label>
                        <div className="relative">
                            <Field
                                id="password"
                                name="password"
                                className={cn('input mt-1', {'input-error': errors.password && touched.password})}
                                type={showPassword ? 'text' : 'password'}
                            />
                            {showPassword ? (
                                <ShowPasswordIcon
                                    className="showPasswordToggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            ) : (
                                <HidePasswordIcon
                                    className="showPasswordToggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            )}
                        </div>
                        <p className="text-sm text-error">
                            <ErrorMessage className="text-sm text-error" name="password" />
                        </p>

                        {status ? (
                            <div className="alert alert-error alert-sm mt-4 shadow-lg">
                                <div>
                                    <WarningIcon size="1.5rem" />
                                    <div>
                                        <h5 className="font-bold">Error</h5>
                                        <div className="text-md">{status}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <button type="submit" className="btn btn-primary w-full mt-8" disabled={isSubmitting}>
                            Login
                        </button>

                        <p className="mt-2 text-sm text-gray-600">
                            Forgot Password?
                            <Link to="/resetpassword" className="underline">
                                {' '}
                                Reset Password
                            </Link>
                        </p>
                    </Form>
                )}
            </Formik>
        </>
    )
}
