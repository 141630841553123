import {DailyParticipant, DailyTrackState} from '@daily-co/daily-js'
import {getId, getScreenId} from './participantsState'

/*
Track state & reducer
--
All (participant & screen) video and audio tracks indexed on participant ID
If using manual track subscriptions, we'll also keep a record of those
and their playing / paused state
*/

interface TracksState {
    audioTracks: {
        [id: string]: DailyTrackState
    }
    videoTracks: {
        [id: string]: DailyTrackState
    }
}
export const initialTracksState = {
    audioTracks: {},
    videoTracks: {},
}

// --- Actions ---

export const TRACK_STARTED = 'TRACK_STARTED'
export const TRACK_STOPPED = 'TRACK_STOPPED'
export const TRACK_VIDEO_UPDATED = 'TRACK_VIDEO_UPDATED'
export const TRACK_AUDIO_UPDATED = 'TRACK_AUDIO_UPDATED'
export const REMOVE_TRACKS = 'REMOVE_TRACKS'

// --- Reducer and helpers --

export function tracksReducer(
    prevState: TracksState,
    action:
        | {type: 'TRACK_STARTED'; participant: DailyParticipant; track: MediaStreamTrack}
        | {type: 'TRACK_STOPPED'; items: [DailyParticipant, MediaStreamTrack][]}
        | {type: 'TRACK_VIDEO_UPDATED' | 'TRACK_AUDIO_UPDATED' | 'REMOVE_TRACKS'; participant: DailyParticipant},
): TracksState {
    switch (action.type) {
        case TRACK_STARTED: {
            const {participant, track}: {participant: DailyParticipant; track: MediaStreamTrack} = action
            const id = getId(participant)
            const screenId = getScreenId(id)

            if (track.kind === 'audio') {
                if (participant?.local) {
                    // Ignore local audio from mic and screen share
                    return prevState
                }
                const newAudioTracks = {
                    [id]: participant.tracks.audio,
                }
                if (participant.screen) {
                    newAudioTracks[screenId] = participant.tracks.screenAudio
                }
                return {
                    ...prevState,
                    audioTracks: {
                        ...prevState.audioTracks,
                        ...newAudioTracks,
                    },
                }
            }

            const newVideoTracks = {
                [id]: participant.tracks.video,
            }
            if (participant.screen) {
                newVideoTracks[screenId] = participant.tracks.screenVideo
            }
            return {
                ...prevState,
                videoTracks: {
                    ...prevState.videoTracks,
                    ...newVideoTracks,
                },
            }
        }

        case TRACK_STOPPED: {
            const {audioTracks, videoTracks} = prevState

            const newAudioTracks = {...audioTracks}
            const newVideoTracks = {...videoTracks}

            action.items.forEach(([participant, track]) => {
                const id = participant ? getId(participant) : undefined
                const screenId = id ? getScreenId(id) : undefined

                if (track.kind === 'audio') {
                    if (!participant?.local) {
                        // Ignore local audio from mic and screen share
                        newAudioTracks[id!] = participant.tracks.audio
                        if (participant.screen && screenId) {
                            newAudioTracks[screenId] = participant.tracks.screenAudio
                        }
                    }
                } else if (track.kind === 'video' && id) {
                    newVideoTracks[id] = participant.tracks.video
                    if (participant.screen && screenId) {
                        newVideoTracks[screenId] = participant.tracks.screenVideo
                    }
                }
            })

            return {
                audioTracks: newAudioTracks,
                videoTracks: newVideoTracks,
            }
        }

        case TRACK_VIDEO_UPDATED: {
            const {participant}: {participant: DailyParticipant} = action
            const id = getId(participant)
            if (participant?.local) {
                // Ignore local audio from mic and screen share
                return prevState
            }
            const newAudioTracks = {
                ...prevState.audioTracks,
                [id]: participant.tracks.audio,
            }
            return {
                ...prevState,
                audioTracks: newAudioTracks,
            }
        }

        case TRACK_AUDIO_UPDATED: {
            const {participant}: {participant: DailyParticipant} = action
            const id = getId(participant)
            const newVideoTracks = {
                ...prevState.videoTracks,
                [id]: participant.tracks.video,
            }
            return {
                ...prevState,
                videoTracks: newVideoTracks,
            }
        }

        case REMOVE_TRACKS: {
            const {participant}: {participant: DailyParticipant} = action
            const {audioTracks, videoTracks} = prevState
            const id = getId(participant)
            const screenId = getScreenId(id)

            delete audioTracks[id]
            delete audioTracks[screenId]
            delete videoTracks[id]
            delete videoTracks[screenId]

            return {
                audioTracks,
                videoTracks,
            }
        }

        default:
            throw new Error()
    }
}
