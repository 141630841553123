import {Link} from 'react-router-dom'
import {useAppSelector} from '~/store'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'

import {Menu2} from '@styled-icons/remix-line/Menu2'

import './BreadCrumbs.scss'

export default function BreadCrumbs({
    pages,
    setSidebarOpen,
}: {
    pages: {name: string; href: string; current: boolean}[]
    setSidebarOpen: (open: boolean) => void
}) {
    const {user, tenant} = useAppSelector(state => ({
        user: state.identityState.user!,
        tenant: state.identityState.tenant!,
    }))

    return (
        <nav className="flex justify-between left-0 my-4 h-20 bg-gray-100 pl-6 p-2 rounded-3xl" aria-label="Breadcrumb">
            <ol className="flex h-full items-center space-x-4">
                <li className="lg:hidden mr-4 cursor-pointer" onClick={() => setSidebarOpen(true)}>
                    <Menu2 className="w-7 h-7 text-gray-800" />
                </li>

                <li>
                    <div>
                        <span className="text-gray-400 px-3 py-1 rounded-xl flex items-center pointer-events-none">
                            <AmplifierAvatar
                                src={user.picture}
                                name={`${tenant.name}`}
                                size="xs2"
                                className="menuAvatar"
                            />

                            <span className="ml-2  no-underline font-semibold text-black">{tenant.name}</span>
                        </span>
                    </div>
                </li>
                {pages.map(page => (
                    <li key={page.name} className="text-lg">
                        <div className="flex  items-center">
                            <svg
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <Link
                                to={page.href}
                                className="ml-4 no-underline text-base font-medium text-gray-600 hover:text-gray-700"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
