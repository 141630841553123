/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useMemo, useCallback, useRef, useState} from 'react'
import {useDeepCompareMemo} from 'use-deep-compare'
import cn from 'classnames'
import {Slide, toast, ToastContainer} from 'react-toastify'
import {DailyReceiveSettingsUpdates} from '@daily-co/daily-js'
import CountDownPopUp from '../CountDownPopUp/CountDownPopUp'
import SettingsModal from '../SettingsModal/SettingsModal'
import {useParticipants} from '../../../contexts/ParticipantsProvider'
import {useCamSubscriptions} from '../../../hooks/useCamSubscriptions'
import Audio from '../Audio/Audio'
import {SECONDARY_ASPECT_RATIO, DEFAULT_ASPECT_RATIO, MEETING_STATE_JOINED} from '../../../constants'
import {ParticipantItem, isLocalId} from '../../../contexts/participantsState'
import sortByKey from '../../../utils/sortByKey'
import useActiveSpeaker from '../../../hooks/useActiveSpeaker'
import Tile from '../Tile/Tile'
import Notifications from '../Notifications/Notifications'
import Timer from '../CountDownPopUp/Timer'
import {useDaily} from '@daily-co/daily-react'
import Pagination from '../Pagination/Pagination'
import Intermission from '../Intermission/Intermission'
import DebugPanel from '../DebugPanel/DebugPanel'
import TopBar from './TopBar/TopBar'
import {useAppSelector} from '../../../store'
import './Call.scss'
import './react-toastify.scss'
import {ResizeObserver} from '@juggle/resize-observer' // pollyfills for native ResizeObserver not available on IOS devices
import EmptyRoomState from './EmptyRoomState'

const MAX_TILES_PER_PAGE = 9

export default function Call({maxTilesPerPage = MAX_TILES_PER_PAGE}) {
    // -- State --
    const callObject = useDaily()!
    const {activeParticipant, participantCount, participants, screens, swapParticipantPosition} = useParticipants()
    const activeSpeakerId = useActiveSpeaker()
    const {state, minimizeLocalTile, homeRoomMode, allParticipants, channel, isHost, matches} = useAppSelector(
        ({amplifierSocket, eventState}) => ({
            state: amplifierSocket.breakout.state,
            minimizeLocalTile: eventState.settings.minimizeLocalTile,
            homeRoomMode: amplifierSocket.homeRoomMode,
            allParticipants: amplifierSocket.allParticipants,
            isHost: amplifierSocket.isHost,
            currentRoomParticipantIds: amplifierSocket.currentRoomParticipantIds,
            matches: amplifierSocket.localParticipant?.matchedIds
                ? amplifierSocket.localParticipant.matchedIds.length
                : 0,
            channel: amplifierSocket.channel,
        }),
    )

    // -- State --
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)

    const dimensInit = {width: 1, height: 1}

    // -- Tiles Containers --
    // Dimensions of the tile container.
    const gridRef = useRef(null)
    const [size, setSize] = useState(dimensInit)

    // Dimensions of the screen tile container.
    const screenGridRef = useRef(null)
    const [screenSize, setScreenSize] = useState(dimensInit)

    // Dimensions of the floating tiles container
    const floatingGridRef = useRef(null)
    const [floatingSize, setFloatingSize] = useState(dimensInit)

    // -- Devide which tiles are where --
    // Note: this is super verbose because its pretty confusing.
    const displayableParticipantIds = useMemo(() => {
        if (state === 'intermission' || (state === 'breakout' && matches === 0 && channel !== 0)) return []

        if (state === 'normal' && homeRoomMode === 'hosts') {
            // we want to show all hosts

            return participants
                .filter(p => (allParticipants[p.id]?.isHost || (p.isLocal && isHost)) && !p.isScreenshare)
                .map(p => p.id)
        }

        return participants.filter(p => (!p.isScreenshare && minimizeLocalTile ? !p.isLocal : true)).map(p => p.id)
    }, [minimizeLocalTile, allParticipants, participants, homeRoomMode, state, isHost])

    const displayableFloatingIds = useMemo(() => {
        if (state === 'intermission' || (state === 'breakout' && matches === 0 && channel !== 0)) {
            return participants.filter(p => (!p.isScreenshare && !minimizeLocalTile ? p.isLocal : true)).map(p => p.id)
        }

        if (state === 'normal' && homeRoomMode === 'hosts') {
            return participants
                .filter(({isLocal, isScreenshare}) => isLocal && !isScreenshare && !isHost)
                .map(p => p.id)
        }

        return participants.filter(p => (minimizeLocalTile ? p.isLocal : false)).map(p => p.id)
    }, [participantCount, minimizeLocalTile, isHost, homeRoomMode, state])

    const displayableScreensCount = useMemo(() => {
        return screens.length
    }, [screens.length])

    // -- useEffects & useMemos --

    // Set all the size states.
    // This uses ResizeObserver, which captures the dimentions of an element
    useEffect(() => {
        if (!gridRef.current) return

        const refsCollection: Array<{
            ref: React.MutableRefObject<any>
            setter: React.Dispatch<
                React.SetStateAction<{
                    width: number
                    height: number
                }>
            >
            observer?: ResizeObserver
        }> = [
            {ref: gridRef, setter: setSize, observer: undefined},
            {ref: screenGridRef, setter: setScreenSize, observer: undefined},
            {ref: floatingGridRef, setter: setFloatingSize, observer: undefined},
        ]

        for (const refItem of refsCollection) {
            if (!refItem.ref?.current) continue

            refItem.observer = new ResizeObserver(entries => {
                // Dimensions - Tiles container
                const width = entries[0].contentRect.width
                const height = entries[0].contentRect.height
                if (width === 0 || height === 0) return
                refItem.setter({width, height})
            })
            refItem.observer.observe(refItem.ref.current)
        }

        return () => {
            for (const refItem of refsCollection) {
                if (!refItem.ref?.current) continue
                refItem.observer!.unobserve(refItem.ref.current)
            }
        }
    }, [gridRef, screenGridRef, floatingGridRef])

    // Make sure resize triggers when a screen/new participant tile is added
    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [displayableParticipantIds.length, displayableScreensCount, displayableFloatingIds.length])

    const isMobile = useMemo(() => {
        return size?.width <= 480
    }, [size])

    const minTileWidth = useMemo(() => {
        return isMobile ? 140 : 240
    }, [size])

    // Setup useMemo / useEffects for:
    // Main tiles (paginated)
    // Screen tiles
    // Floating tiles
    const items = {
        tileProps: {
            size: size,
            itemLength: displayableParticipantIds.length,
            paginate: true,
            tileWidth: 0,
            tileHeight: 0,
            pageSize: 0,
        },
        screenTileProps: {
            size: screenSize,
            itemLength: displayableScreensCount,
            paginate: true, // Screens are paginated to a max length (no more than 9), but there's no controls
            tileWidth: 0,
            tileHeight: 0,
            pageSize: 0,
        },
    }

    let key: keyof typeof items
    for (key in items) {
        let {size, itemLength, paginate} = items[key]

        //Calcualte Max tiles per page based off page height and width
        const {maxColumns, maxRows} = useMemo(() => {
            // return
            const {width, height} = size
            const columns = Math.max(1, Math.floor(width / minTileWidth))

            const widthPerTile = width / columns

            const rows = isMobile
                ? Math.ceil(height / widthPerTile)
                : Math.max(1, Math.floor(height / (widthPerTile * (9 / 16))) - 1)

            return {maxColumns: columns, maxRows: rows}
        }, [size, minTileWidth, isMobile])

        let localPageSize = 1
        if (paginate) {
            // Calc and set the total number of pages as participant count mutates
            // Only for the main tile grid which paginates ok
            localPageSize = useMemo(() => {
                return Math.min(maxColumns * maxRows, maxTilesPerPage)
            }, [maxColumns, maxRows])
            useEffect(() => {
                setPages(Math.ceil(itemLength / localPageSize))
            }, [localPageSize, itemLength])
            // Make sure we never see a blank page (if we're on the last page and people leave)
            useEffect(() => {
                if (page <= pages) return
                if (pages === 0) {
                    setPage(1)
                    return
                }
                setPage(pages)
            }, [page, pages])
        }

        const {tileW, tileH} = useMemo(() => {
            const margin = 4
            const {width, height} = size
            const n = Math.min(localPageSize, displayableParticipantIds.length)
            if (n === 0) return {tileW: width, tileH: height}
            const dims = []
            for (let i = 1; i <= n; i += 1) {
                let maxWidthPerTile = (width - (i - 1)) / i
                let maxHeightPerTile = maxWidthPerTile / (isMobile ? SECONDARY_ASPECT_RATIO : DEFAULT_ASPECT_RATIO)
                const rows = Math.ceil(n / i)
                if (rows * maxHeightPerTile > height) {
                    maxHeightPerTile = (height - (rows - 1)) / rows
                    maxWidthPerTile = maxHeightPerTile * (isMobile ? SECONDARY_ASPECT_RATIO : DEFAULT_ASPECT_RATIO)
                    dims.push([maxWidthPerTile, maxHeightPerTile])
                } else {
                    dims.push([maxWidthPerTile, maxHeightPerTile])
                }
            }
            const [w, h] = dims.reduce(
                ([rw, rh], [w, h]) => {
                    if (w * h < rw * rh) return [rw, rh]
                    return [w - margin * 2, h - margin * 2]
                },
                [0, 0],
            )
            return {tileW: w, tileH: h}
        }, [size, localPageSize, itemLength, isMobile])

        items[key].tileWidth = tileW
        items[key].tileHeight = tileH
        items[key].pageSize = localPageSize
    }

    // -- Track subscriptions

    // Memoized array of participants on the current page (those we can see) (Supports pagination controls)
    const visibleParticipants = useMemo(() => {
        const pageSize = items.tileProps.pageSize
        const visiblePIds =
            displayableParticipantIds.length - page * pageSize > 0
                ? displayableParticipantIds.slice((page - 1) * pageSize, page * pageSize)
                : displayableParticipantIds.slice(-pageSize)

        return visiblePIds.map(id => participants.find(p => p.id === id)).filter(p => !!p) as ParticipantItem[]
    }, [page, items.tileProps.pageSize, displayableParticipantIds, participants])

    // Memoized array of screens on the current page (those we can see) (Only shows first page)
    const visibleScreens = useMemo(
        () => screens.slice(0, items.screenTileProps.pageSize),
        [items.screenTileProps.pageSize, screens],
    )
    /**
     * Play / pause tracks based on pagination
     * Note: we pause adjacent page tracks and unsubscribe from everything else
     */
    const camSubscriptions = useMemo(() => {
        const pageSize = items.tileProps.pageSize
        const maxSubs = 3 * pageSize

        // Determine participant ids to subscribe to or stage, based on page
        let renderedOrBufferedIds = []
        switch (page) {
            // First page
            case 1:
                renderedOrBufferedIds = participants.slice(0, Math.min(maxSubs, 2 * pageSize)).map(p => p.id)
                break
            // Last page
            case Math.ceil(participants.length / pageSize):
                renderedOrBufferedIds = participants.slice(-Math.min(maxSubs, 2 * pageSize)).map(p => p.id)
                break
            // Any other page
            default:
                const buffer = (maxSubs - pageSize) / 2
                const min = (page - 1) * pageSize - buffer
                const max = page * pageSize + buffer
                renderedOrBufferedIds = participants.slice(min, max).map(p => p.id)
                break
        }

        const subscribedIds: string[] = []
        const stagedIds: string[] = []

        // Decide whether to subscribe to or stage participants'
        // track based on visibility
        renderedOrBufferedIds.forEach(id => {
            if (!id.startsWith('local')) {
                if (visibleParticipants.some(vp => !!vp && vp.id === id)) {
                    subscribedIds.push(id)
                } else {
                    stagedIds.push(id)
                }
            }
        })

        return {
            subscribedIds,
            stagedIds,
        }
    }, [page, items.tileProps.pageSize, participants, visibleParticipants])

    useCamSubscriptions(camSubscriptions?.subscribedIds, camSubscriptions?.stagedIds)

    /**
     * Set bandwidth layer based on amount of visible participants
     */
    useEffect(() => {
        if (!(callObject && callObject.meetingState() === MEETING_STATE_JOINED)) return
        const count = visibleParticipants.length

        let layer: number
        if (count < 5) {
            // highest quality layer
            layer = 2
        } else if (count < 10) {
            // mid quality layer
            layer = 1
        } else {
            // low qualtiy layer
            layer = 0
        }

        const receiveSettings: DailyReceiveSettingsUpdates = visibleParticipants.reduce((settings, participant) => {
            if (isLocalId(participant.id)) return settings
            return {...settings, [participant.id]: {video: {layer}}}
        }, {})
        callObject.updateReceiveSettings(receiveSettings)
    }, [visibleParticipants, callObject])

    // -- Active speaker

    /**
     * Handle position updates based on active speaker events
     */
    const handleActiveSpeakerChange = useCallback(
        (peerId: string) => {
            if (!peerId) return
            // active participant is already visible
            if (visibleParticipants.some(({id}) => id === peerId)) return

            if (page > 1) return

            /**
             * We can now assume that
             * a) the user is looking at page 1
             * b) the most recent active participant is not visible on page 1
             * c) we'll have to promote the most recent participant's position to page 1
             *
             * To achieve that, we'll have to
             * - find the least recent active participant on page 1
             * - swap least & most recent active participant's position via setParticipantPosition
             */
            const sortedVisibleRemoteParticipants = visibleParticipants
                .filter(({isLocal}) => !isLocal)
                .sort((a, b) => sortByKey(a, b, 'lastActiveDate'))

            if (!sortedVisibleRemoteParticipants.length) return

            swapParticipantPosition(sortedVisibleRemoteParticipants[0].id, peerId)
        },
        [page, swapParticipantPosition, visibleParticipants],
    )

    useEffect(() => {
        if (!activeSpeakerId) return
        handleActiveSpeakerChange(activeSpeakerId)
    }, [activeSpeakerId, handleActiveSpeakerChange, page])

    const {tiles, floatingTiles} = useDeepCompareMemo(() => {
        let floatingParticipants = displayableFloatingIds
            .map(id => participants.find(p => p.id === id))
            .filter(p => !!p) as ParticipantItem[]

        return {
            tiles: visibleParticipants.map(p => (
                <Tile
                    participant={p}
                    key={p.id}
                    style={{
                        maxHeight: items.tileProps.tileHeight,
                        maxWidth: items.tileProps.tileWidth,
                    }}
                />
            )),
            floatingTiles: floatingParticipants.map(p => (
                <Tile
                    participant={p}
                    key={p.id}
                    style={{
                        minWidth: '250px',
                        width: 'auto',
                    }}
                />
            )),
        }
    }, [
        activeParticipant,
        displayableFloatingIds.length,
        items.tileProps.tileWidth,
        items.tileProps.tileHeight,
        visibleParticipants,
        minimizeLocalTile,
    ])

    const screenTiles = useDeepCompareMemo(
        () => visibleScreens.map(p => <Tile participant={p} key={p.id} showName={false} showAvatar={false} />),
        [screens, items.screenTileProps.tileHeight, items.screenTileProps.tileWidth],
    )

    const layoutMode: 'grid' | 'sidebar' = screenTiles.length === 0 ? 'grid' : 'sidebar'

    const renderTiles = () => {
        return (
            <>
                <div className={cn('tilesLayout', layoutMode)}>
                    <div className="screenTiles" ref={screenGridRef}>
                        {screenTiles.length > 0 ? screenTiles : null}
                    </div>
                    <div className="tilesGrid" ref={gridRef}>
                        {tiles.length > 0 ? tiles : <EmptyRoomState />}
                    </div>
                    <div className="floatingTilesContainer">
                        <div className="tilesGrid" ref={floatingGridRef}>
                            {floatingTiles.length > 0 ? floatingTiles : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="outerCall">
            <TopBar />
            <div className="callContainer">
                <Pagination
                    page={page}
                    pages={pages}
                    previous={() => setPage(p => p - 1)}
                    next={() => setPage(p => p + 1)}
                />
                <Notifications />
                <Timer />
                <CountDownPopUp />
                <SettingsModal />
                <ToastContainer
                    enableMultiContainer
                    pauseOnFocusLoss={false}
                    containerId="All"
                    limit={2}
                    transition={Slide}
                    position={toast.POSITION.TOP_CENTER}
                />
                <ToastContainer
                    enableMultiContainer
                    containerId="Messages"
                    pauseOnFocusLoss={false}
                    hideProgressBar={true}
                    draggable
                    autoClose={2000}
                    transition={Slide}
                    limit={2}
                    position={toast.POSITION.BOTTOM_RIGHT}
                />
                {state === 'intermission' && <Intermission />}
                {renderTiles()}
                <DebugPanel />
            </div>
            <Audio />
        </div>
    )
}
