import {RadioGroup} from '@headlessui/react'
import {useEffect, useMemo, useState} from 'react'
import {CheckCircleFill as CheckCircleIcon} from '@styled-icons/bootstrap/CheckCircleFill'
import {useAppSelector} from '~/store'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {HomeRoomMode} from 'shared-types/view-models/SocketModels'
import classNames from 'classnames'

export default function ModeSelect() {
    const {allParticipants, homeRoomMode, hostSettings} = useAppSelector(({amplifierSocket}) => ({
        allParticipants: amplifierSocket.allParticipants,
        localId: amplifierSocket.localId,
        homeRoomMode: amplifierSocket.homeRoomMode,
        hostSettings: amplifierSocket.hostSettings!,
    }))

    const {updateRoomSettings} = useAmplifierSocket()

    const hostCount = useMemo(() => {
        return Object.values(allParticipants).filter(e => e.isHost).length
    }, [allParticipants])
    const participantCount = useMemo(() => {
        return Object.values(allParticipants).length
    }, [allParticipants])

    const options = useMemo(() => {
        return [
            {
                id: 'hosts',
                selected: homeRoomMode == 'hosts',
                title: 'Stage Mode',
                description: 'Only hosts can talk in home room',
                users: hostCount + ' hosts',
            },
            {
                id: 'all',
                selected: homeRoomMode == 'all',
                title: 'Everyone',
                description: 'One big video call',
                users: participantCount + ' participants',
            },
        ]
    }, [participantCount, hostCount, homeRoomMode])

    const [selectedOption, setSelectedOption] = useState(options.find(e => e.selected))

    useEffect(() => {
        if (!selectedOption) {
            return
        }
        updateRoomSettings({homeRoomMode: selectedOption.id as HomeRoomMode, hostSettings: hostSettings})
    }, [selectedOption])

    return (
        <RadioGroup value={selectedOption} onChange={setSelectedOption}>
            <RadioGroup.Label className="text-base font-medium text-white">Home Room Mode</RadioGroup.Label>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {options.map(option => (
                    <RadioGroup.Option
                        key={option.id}
                        value={option}
                        className={({checked, active}) =>
                            classNames(
                                checked ? 'border-transparent' : 'border-gray-800',
                                active ? 'border-primary ring-2 ring-primary' : '',
                                'relative flex cursor-pointer rounded-lg border bg-neutral p-4 shadow-sm focus:outline-none',
                            )
                        }
                    >
                        {({active}) => (
                            <>
                                <span className="flex flex-1">
                                    <span className="flex flex-col">
                                        <RadioGroup.Label as="span" className="block text-sm font-medium text-white">
                                            {option.title}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                            as="span"
                                            className="mt-1 flex items-center text-sm text-gray-500"
                                        >
                                            {option.description}
                                        </RadioGroup.Description>
                                        <RadioGroup.Description
                                            as="span"
                                            className="mt-6 text-sm font-medium text-gray-400"
                                        >
                                            {option.users}
                                        </RadioGroup.Description>
                                    </span>
                                </span>
                                <CheckCircleIcon
                                    className={classNames(
                                        !(selectedOption?.id === option.id) ? 'invisible' : '',
                                        'h-5 w-5 text-primary',
                                    )}
                                    aria-hidden="true"
                                />
                                <span
                                    className={classNames(
                                        active ? 'border' : 'border-2',
                                        selectedOption?.id === option.id ? 'border-primary' : 'border-transparent',
                                        'pointer-events-none absolute -inset-px rounded-lg',
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
