import {useState} from 'react'
import {useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js'

export default function PaymentForm({
    handlePaymentMethod,
    theme = 'dark',
    buttonText = 'Continue',
}: {
    handlePaymentMethod: (id: string, email: string) => void
    theme?: 'light' | 'dark'
    buttonText?: string
}) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [cardComplete, setCardComplete] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')
    const stripe = useStripe()
    const elements = useElements()

    const cardElementOptions = {
        style: {
            base: {
                color: theme === 'dark' ? 'white' : 'black',
                fontSize: '12px',
            },
            invalid: {
                color: '#fa755a',
                fontSize: '0.875rem',
            },
        },
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        setError('')

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        if (email.length === 0) {
            setError('Please enter a valid email address')
            return
        }

        // Disable the submit button to prevent repeated clicks
        setProcessing(true)

        const cardNumberElement = elements.getElement(CardNumberElement)

        // Create PaymentMethod object with the card data
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement!,
            billing_details: {
                name: name,
                email: email,
            },
        })

        if (error) {
            // Handle error
            setError(error.message ?? '')
        } else {
            // Send paymentMethod.id to your server to create a PaymentIntent

            handlePaymentMethod(paymentMethod.id, email)
        }

        // Reset the form status
        setProcessing(false)
    }

    return (
        <form className="payment__form w-full" onSubmit={handleSubmit}>
            <div className="payment__input w-full">
                <label>Billing Email</label>

                <input
                    type="email"
                    value={email}
                    onChange={event => {
                        setEmail(event.target.value)
                    }}
                    placeholder="hello@example.com"
                />
            </div>

            <div className="w-full mt-6 grid grid-cols-4 gap-y-2 gap-x-2">
                <div className="payment__input col-span-4">
                    <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                        Card number
                    </label>
                    <div className="stripe__element__wrapper">
                        <CardNumberElement
                            onChange={e => {
                                setCardComplete(e.complete)
                            }}
                            options={cardElementOptions}
                        />
                    </div>
                </div>

                <div className="payment__input col-span-4">
                    <label htmlFor="name-on-card" className="block text-sm font-medium text-gray-700">
                        Name on card
                    </label>

                    <input
                        type="text"
                        id="name-on-card"
                        name="name-on-card"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        autoComplete="cc-name"
                        placeholder="J Smith"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="payment__input col-span-3">
                    <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                        Expiration date (MM/YY)
                    </label>
                    <div className="stripe__element__wrapper">
                        <CardExpiryElement options={cardElementOptions} />
                    </div>
                </div>

                <div className="payment__input col-span-1">
                    <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
                        CVC
                    </label>
                    <div className="stripe__element__wrapper">
                        <CardCvcElement options={cardElementOptions} />
                    </div>
                </div>
            </div>

            {error && (
                <div className="text-red-500 error text-sm mt-2">
                    <p className="text-red-500">{error}</p>
                </div>
            )}

            <button disabled={processing || !cardComplete}>{buttonText}</button>
        </form>
    )
}
