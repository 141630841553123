import type {TRPCRouter} from '../../../server/app'
import {createTRPCProxyClient, httpLink, loggerLink} from '@trpc/client'
import {createTRPCReact} from '@trpc/react-query'
import {backendTarget} from '.'
import superjson from 'superjson'
import {inferRouterInputs, inferRouterOutputs} from '@trpc/server'
import {QueryClient} from '@tanstack/react-query'

export type RouterInputs = inferRouterInputs<TRPCRouter>
export type RouterOutputs = inferRouterOutputs<TRPCRouter>

export const trpcFetch = createTRPCProxyClient<TRPCRouter>({
    links: [
        loggerLink({
            enabled: opts =>
                process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpLink({
            url: backendTarget + '/api',
            headers: {
                'X-Version': String(AMPLIFIER_ENV_INFO.buildVersion),
                'X-Git-Hash': AMPLIFIER_ENV_INFO.buildGitHash,
            },
            fetch(url, options) {
                return fetch(url, {
                    ...options,
                    credentials: 'include',
                })
            },
        }),
    ],
    // superjson serializes/deserializes json Dates, Maps, Sets safely
    transformer: superjson,
})

export const trpc = createTRPCReact<TRPCRouter>({})
export const createTRPCQueryClients = () => {
    return {
        queryClient: new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    refetchOnReconnect: false,
                },
            },
        }),
        trpcClient: trpc.createClient({
            links: [
                loggerLink({
                    enabled: opts =>
                        process.env.NODE_ENV === 'development' ||
                        (opts.direction === 'down' && opts.result instanceof Error),
                }),
                httpLink({
                    url: backendTarget + '/api',
                    headers: {
                        'X-Version': String(AMPLIFIER_ENV_INFO.buildVersion),
                        'X-Git-Hash': AMPLIFIER_ENV_INFO.buildGitHash,
                    },
                    fetch(url, options) {
                        return fetch(url, {
                            ...options,
                            credentials: 'include',
                        })
                    },
                }),
            ],
            // superjson serializes/deserializes json Dates, Maps, Sets safely
            transformer: superjson,
        }),
    }
}
