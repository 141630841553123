import clsx from 'clsx'
import {useState} from 'react'
import {requestTenantDeletion} from '~/api/tenantApi'
import {useAppSelector} from '~/store'
import LoadingSvg from '~/assets/svgs/loading.svg'

export default function Account() {
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const handleRequestDeletion = async () => {
        setError('')
        setMessage('')
        setLoading(true)
        const response = await requestTenantDeletion(tenant.slug)
        setLoading(false)
        if (response.type === 'error' || response.type === 'internal_server_error') {
            setError(response.message)
            return
        }
        setError(response.message)
    }

    return (
        <div className="container grid grid-cols-6 ">
            <div className="section col-span-6 mb-8 px-8  border-b py-8">
                <h1 className="font-semibold">Account</h1>
                <span className="text-gray-600">Make changes to your host account</span>
            </div>

            <div className="section col-span-6 mb-8 px-8">
                <div className=" flex flex-col   font-semibold">
                    <h3>Delete Host Account</h3>
                    <span className="text-sm font-normal">
                        An email will be sent to you asking for confirmation before the deletion process is initiated.{' '}
                    </span>
                </div>

                <div className="btn btn-warning mt-4">
                    {loading && <LoadingSvg className={clsx('mr-3')} height="1em" width="1em" />}
                    <button onClick={() => handleRequestDeletion()} disabled={loading || message !== ''}>
                        Delete Account
                    </button>
                </div>

                <div className="status">
                    {error && <span className="text-sm text-red-500">{error}</span>}

                    {message && <span className="text-sm">{message}</span>}
                </div>
            </div>

            <div className="section col-span-6 mb-8 px-8"></div>
        </div>
    )
}
