import cn from 'classnames'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'

import {DotsThreeHorizontal as MoreIcon} from '@styled-icons/entypo/DotsThreeHorizontal'
import {Menu, Transition} from '@headlessui/react'
import {Fragment} from 'react'

interface ParticipantProps {
    inTeam: boolean
    participant: ParticipantViewModel
    participantGroupLabel?: string
    setMoveParticipant: (o: any) => void
}

interface ParticipantDropdownProps {
    setMoveParticipant: any
    participantId: string
}

function ParticipantDropdown({setMoveParticipant, participantId}: ParticipantDropdownProps) {
    return (
        <Menu as="div" className="dropdownMenu">
            <div>
                <Menu.Button>
                    <MoreIcon className="h-5 w-10 text-white" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute cursor-pointer left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-neutral shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({active}) => (
                                <span
                                    className={cn(
                                        active ? 'bg-gray-800 text-white' : 'text-white',
                                        'block px-4 py-2 text-sm font-semibold',
                                    )}
                                    onClick={() =>
                                        setMoveParticipant({
                                            isOpen: true,
                                            participantId: participantId,
                                        })
                                    }
                                >
                                    Move Participant
                                </span>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default function Participant({
    inTeam,
    participant,
    participantGroupLabel,
    setMoveParticipant,
}: ParticipantProps) {
    if (participant === undefined) return <></>

    return (
        <div className="hostViewParticipant ">
            <ParticipantDropdown participantId={participant['id']} setMoveParticipant={setMoveParticipant} />

            <AmplifierAvatar src={participant['picture']} name={participant['name']} size="sm" className="menuAvatar" />
            {participantGroupLabel != null && <span className="groupTag">{participantGroupLabel}</span>}

            <div>
                <p className="name">{participant['name']}</p>
                <p className="company">{participant.company}</p>
            </div>
        </div>
    )
}
