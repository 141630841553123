import {breakoutTimeOptions} from '~/utils/breakoutTimeOptions'
import {useAmplifierSocket} from '../../../contexts/AmplifierSocketProvider'
import {useAppSelector} from '../../../store'

export default function BreakoutSettings() {
    const {updateRoomSettings} = useAmplifierSocket()
    const {isHost, hostSettings, homeRoomMode} = useAppSelector(({amplifierSocket}) => amplifierSocket)

    if (!isHost || !hostSettings) return <></>

    const handleBreakoutTimeChange = (value: number) => {
        updateRoomSettings({
            hostSettings: {
                breakoutMode: hostSettings?.breakoutMode ?? '1:1',
                breakoutGroupSize: hostSettings?.breakoutGroupSize ?? 2,
                breakoutTime: value,
            },
            homeRoomMode: homeRoomMode,
        })
    }

    return (
        <div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Breakout Length</span>
                </label>
                <select
                    className="select select-neutral w-full max-w-xs"
                    value={hostSettings.breakoutTime}
                    onChange={e => handleBreakoutTimeChange(parseFloat(e.target.value))}
                >
                    <option disabled selected>
                        Select a Breakout Length
                    </option>
                    {breakoutTimeOptions.map((minutes, index) => (
                        <option key={index} value={minutes}>
                            {minutes % 1 === 0 ? minutes + ' minutes' : minutes * 60 + ' seconds'}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
