import BreakoutRoom from './BreakoutRoom/BreakoutRoom'
import './HostView.scss'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'

import {VariableSizeGrid as Grid} from 'react-window'
import {useEffect, useRef, useState} from 'react'

interface HostViewProps {
    setMoveParticipant: (o: any) => void
}

interface BreakoutWrapperProps {
    columnIndex: number
    rowIndex: number
    style: any
    data: {
        channels: {
            [key: number]: string[]
        }
        setMoveParticipant: (o: any) => void
    }
}

const BreakoutWrapper = ({columnIndex, rowIndex, style, data}: BreakoutWrapperProps) => {
    let channelIndex = rowIndex * 3 + columnIndex

    return (
        <div
            className={
                columnIndex % 2
                    ? rowIndex % 2 === 0
                        ? 'GridItemOdd'
                        : 'GridItemEven'
                    : rowIndex % 2
                    ? 'GridItemOdd'
                    : 'GridItemEven'
            }
            style={style}
        >
            {data.channels[channelIndex] && (
                <BreakoutRoom
                    setMoveParticipant={data.setMoveParticipant}
                    key={channelIndex}
                    channel={data.channels[channelIndex]}
                    channelIndex={channelIndex}
                />
            )}
        </div>
    )
}

export default function HostView({setMoveParticipant}: HostViewProps) {
    const {channels, localParticipant} = useAmplifierSocket()

    const [parentDimensions, setParentDimensions] = useState([0, 0])
    const parentRef = useRef<HTMLDivElement>(null as any)

    useEffect(() => {
        setParentDimensions([parentRef.current.clientHeight, parentRef.current.clientWidth])
    }, [parentRef])

    /*
    if (state == 'normal') {
        return <></>
    }
    */

    //Use this to simulate lots of users

    // let testChannels: {
    //     [key: number]: string[]
    // } = {}

    // Array(50)
    //     .fill([])
    //     .map((n, i) => {
    //         testChannels[i] = Array(Math.floor(Math.random() * Math.floor(7)) + 1).fill(localParticipant.id)
    //     })

    if (localParticipant.isHost === false) return <></>

    if (channels['0'] === null) {
        channels['0'] = []
    }

    return (
        <div ref={parentRef} className="hostView">
            <Grid
                key={parentDimensions[0] + parentDimensions[1]}
                className="container Grid"
                columnCount={3}
                columnWidth={() => {
                    return Math.floor(parentDimensions[1] / 3)
                }}
                height={parentDimensions[0]}
                rowCount={Math.ceil(Object.values(channels).length / 3)}
                rowHeight={() => {
                    return parentDimensions[1] / 3
                }}
                itemData={{channels: channels, setMoveParticipant}}
                width={parentDimensions[1] + 10}
            >
                {BreakoutWrapper}
            </Grid>
        </div>
    )
}
