import {Link} from 'react-router-dom'
import './NotFound.scss'

const NotFound: React.FunctionComponent = () => {
    return (
        <div className="notFoundPage">
            <main className="notFoundContainer">
                <p className="notFoundHeading">404</p>
                <div className="sm:ml-6">
                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 className="notFoundTitle">Page not found</h1>
                        <p className="pageNotFoundText">Please check the URL in the address bar and try again.</p>
                    </div>
                    <div className="pageButtons">
                        <Link to="/">Go back home</Link>
                        <Link to="/dashboard">Host Event</Link>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default NotFound
