import {
    ConfirmDeleteTenant,
    ErrorResponse,
    InternalServerError,
    RequestTenantDeletion,
} from 'shared-types/schema/response'
import {EventUsage, TenantSettingsViewModel, TenantViewModel} from 'shared-types/view-models'
import api from '.'

export async function createTenant(
    name: string,
    type: TenantViewModel['tenantType'],
): Promise<TenantViewModel | ErrorResponse | InternalServerError> {
    const response = await api.post(`/tenant/create`, {name, type})
    return response as TenantViewModel | ErrorResponse | InternalServerError
}

export async function getTenantSettings(
    tenant: string,
): Promise<TenantSettingsViewModel | ErrorResponse | InternalServerError> {
    const response = await api.get(`/tenant/settings?tenant=${tenant}`)
    return response as TenantSettingsViewModel | ErrorResponse | InternalServerError
}

export async function confirmTenantDeletion(
    token: string,
): Promise<ConfirmDeleteTenant | ErrorResponse | InternalServerError> {
    const response = await api.get(`/tenant/delete/confirm?t=${token}`)
    return response as ConfirmDeleteTenant | ErrorResponse | InternalServerError
}

export async function requestTenantDeletion(
    tenant: string,
): Promise<RequestTenantDeletion | ErrorResponse | InternalServerError> {
    const response = await api.post(`/tenant/delete?tenant=${tenant}`)

    return response as RequestTenantDeletion | ErrorResponse | InternalServerError
}

export async function getUsage(
    tenant: string,
    start: number,
    end: number,
): Promise<EventUsage[] | ErrorResponse | InternalServerError> {
    const response = await api.get(`/tenant/usage?tenant=${tenant}&start=${start}&end=${end}`)
    return response as EventUsage[] | ErrorResponse | InternalServerError
}
