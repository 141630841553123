import {Record2 as RecordIcon} from '@styled-icons/bootstrap/Record2'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRecording, useRoom} from '@daily-co/daily-react'
import TrayButton from './TrayButton/TrayButton'
import {useAppSelector} from '../../../store'

interface RecordingButtonProps {
    disabled: boolean
}
const RecordingTrayButton = ({disabled}: RecordingButtonProps) => {
    const {
        breakout: {state},
        isHost,
        localId,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    // Check if recording is available on this daily event
    const room = useRoom()
    const [recordingLoading, setRecordingLoading] = useState(false) // State to show the loading spinner when they press Recording
    const {isRecording, startRecording, stopRecording, startedBy} = useRecording()

    // Stop the recording when breakouts is triggered
    useEffect(() => {
        if (isRecording && state !== 'normal') {
            triggerStopRecording()
        }
    }, [state])

    useEffect(() => {
        if (isRecording && recordingLoading) {
            setRecordingLoading(false)
            toast.dismiss()
        }
    }, [isRecording])

    const triggerStopRecording = () => {
        stopRecording()
        toast.info('Recording Stopped', {containerId: 'All'})
        setRecordingLoading(false)
        toast.dismiss()
    }

    const toggleRecording = () => {
        if (!isRecording && !recordingLoading) {
            toast.info('Starting Recording ...', {containerId: 'All', isLoading: true})
            setRecordingLoading(true)
            startRecording({
                backgroundColor: '#FF2D343A',
                layout: {
                    preset: 'active-participant',
                },
            })
        } else {
            triggerStopRecording()
        }
    }

    const allowRecording = isHost && state === 'normal' && (!isRecording || startedBy === localId)

    if (!allowRecording || !room?.config.enable_recording) return <></>

    return (
        <TrayButton
            disabled={disabled || recordingLoading === true}
            highlighted={isRecording}
            onClick={toggleRecording}
            tooltip={`${!isRecording ? 'Start' : 'Stop'} Recording`}
        >
            <RecordIcon width="1em" />
        </TrayButton>
    )
}

export default RecordingTrayButton
