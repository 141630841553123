import {IndexRouteProps, LayoutRouteProps, Navigate, Outlet, PathRouteProps, useLocation} from 'react-router-dom'
import {useAppSelector} from '~/store'

export default function OnboardedRoute(props: PathRouteProps | LayoutRouteProps | IndexRouteProps): React.ReactElement {
    const identity = useAppSelector(state => state.identityState)
    const location = useLocation()

    if (!identity.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace />
    }

    // Part of this is to find how far onboarded a user is.

    // If they dont event have a tenant
    if (!identity.tenant) {
        return <Navigate to="/start" state={{from: location}} replace />
    }

    // If they dont have a plan
    if (!identity.tenant['billing']?.staged_plan && !identity.tenant['billing']?.plan) {
        return <Navigate to="/start/plan" state={{from: location}} replace />
    }

    // If they dont have a payment method
    if (!identity.tenant['billing'].email) {
        return <Navigate to="/start/payment" state={{from: location}} replace />
    }

    // // If they dont have a payment method
    // if (!identity.tenant.paymentMethod) {
    //     return <Navigate to="/start/payment" state={{from: location}} replace />
    // }

    return <Outlet />
}
