import {createContext, useCallback, useContext, useEffect, useMemo, useReducer} from 'react'

import {useDeepCompareEffect} from 'use-deep-compare'
import {sortByKey} from '../utils/sortByKey'
import {useParticipants} from './ParticipantsProvider'
import {isLocalId, isScreenId} from './participantsState'
import {
    initialTracksState,
    REMOVE_TRACKS,
    TRACK_STARTED,
    TRACK_STOPPED,
    TRACK_VIDEO_UPDATED,
    TRACK_AUDIO_UPDATED,
    tracksReducer,
} from './tracksState'
import isEqual from 'lodash/isEqual'
import {
    DailyEventObjectParticipantLeft,
    DailyEventObjectTrack,
    DailyParticipant,
    DailyParticipantUpdateOptions,
    DailyTrackState,
} from '@daily-co/daily-js'
import {useAppSelector} from '~/store'
import {useDaily} from '@daily-co/daily-react'

/**
 * Maximum amount of concurrently subscribed most recent speakers.
 */
const MAX_RECENT_SPEAKER_COUNT = 6
/**
 * Threshold up to which all videos will be subscribed.
 * If the remote participant count passes this threshold,
 * cam subscriptions are defined by UI view modes.
 */
const SUBSCRIBE_OR_STAGE_ALL_VIDEO_THRESHOLD = 9

interface TracksContextType {
    audioTracks: {
        [id: string]: DailyTrackState
    }
    videoTracks: {
        [id: string]: DailyTrackState
    }
    subscribeToCam: (id: string) => void
    updateCamSubscriptions: (subscribedIds: string[], stagedIds: string[]) => void
    remoteRoomParticipantIds: string[]
    recentSpeakerIds: string[]
}

const TracksContext = createContext<TracksContextType>({} as TracksContextType)

export const TracksProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const callObject = useDaily()!
    const {participants, allParticipants} = useParticipants()
    const {homeRoomMode} = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const [state, dispatch] = useReducer(tracksReducer, initialTracksState)

    const recentSpeakerIds = useMemo(
        () =>
            participants
                .filter(p => Boolean(p.lastActiveDate) && !p.isLocal)
                .sort((a, b) => sortByKey(a, b, 'lastActiveDate'))
                .slice(-MAX_RECENT_SPEAKER_COUNT)
                .map(p => p.id)
                .reverse(),
        [participants],
    )

    const remoteParticipantIds = useMemo(
        () => allParticipants.filter(p => !p.isLocal && !p.isScreenshare).map(p => p.id),
        [allParticipants],
    )

    const remoteRoomParticipantIds = useMemo(() => {
        return participants.filter(p => !p.isLocal && !p.isScreenshare).map(p => p.id)
    }, [participants, homeRoomMode])

    const subscribeToCam = useCallback(
        (id?: string) => {
            // Ignore undefined, local or screenshare.
            if (!id || isLocalId(id) || isScreenId(id)) return
            callObject.updateParticipant(id, {
                setSubscribedTracks: {video: true},
            })
        },
        [callObject],
    )

    /**
     * Updates cam subscriptions based on passed subscribedIds and stagedIds.
     * For ids not provided, cam tracks will be unsubscribed from
     */
    const updateCamSubscriptions = useCallback(
        (subscribedIds: string[], stagedIds: string[] = []) => {
            if (!callObject) return

            // If total number of remote participants is less than a threshold, simply
            // stage all remote cams that aren't already marked for subscription.
            // Otherwise, honor the provided stagedIds, with recent speakers appended
            // who aren't already marked for subscription.
            const stagedIdsFiltered =
                remoteRoomParticipantIds.length <= SUBSCRIBE_OR_STAGE_ALL_VIDEO_THRESHOLD
                    ? remoteRoomParticipantIds.filter(id => !subscribedIds.includes(id))
                    : [...stagedIds, ...recentSpeakerIds.filter(id => !subscribedIds.includes(id))]

            // Assemble updates to get to desired cam subscriptions
            const updates = remoteParticipantIds.reduce<{
                [sessionId: string]: DailyParticipantUpdateOptions
            }>((updates, id) => {
                let desiredSubscription: 'staged' | boolean
                const currentSubscription = callObject.participants()?.[id]?.tracks?.video?.subscribed
                let isInRoom = true
                const currentIsInRoom = callObject.participants()?.[id]?.tracks?.audio?.subscribed

                // Ignore undefined, local or screenshare participant ids
                if (!id || isLocalId(id) || isScreenId(id)) return updates

                // Decide on desired cam subscription for this participant:
                // subscribed, staged, or unsubscribed
                if (subscribedIds.includes(id)) {
                    desiredSubscription = true
                } else if (stagedIdsFiltered.includes(id)) {
                    desiredSubscription = 'staged'
                } else {
                    desiredSubscription = false
                    isInRoom = false
                }

                // Skip if we already have the desired subscription to this
                // participant's cam
                if (desiredSubscription === currentSubscription && currentIsInRoom === isInRoom) return updates

                updates[id] = {
                    setSubscribedTracks: {
                        audio: isInRoom,
                        screenAudio: isInRoom,
                        screenVideo: isInRoom,
                        video: desiredSubscription,
                    },
                }
                return updates
            }, {})

            if (Object.keys(updates).length === 0) return
            callObject.updateParticipants(updates)
        },
        [callObject, remoteRoomParticipantIds, recentSpeakerIds],
    )

    useEffect(() => {
        if (!callObject) return

        /*
        setInterval(async () => {
            try {
                const transaction = Sentry.startTransaction({ name: "daily-video-quality" });
                let x = await callObject.getNetworkStats()

                const span = transaction.startChild({ op: "daily-video-stats", data: x }); // This function returns a Span

                span.finish(); // Remember that only finished spans will be sent with the transaction
                transaction.finish(); // Finishing the transaction will send it to Sentry

            } catch (error) {
                throw error;
            }

        }, 1000 * 10)
        */

        const trackStoppedQueue: Array<[DailyParticipant, MediaStreamTrack]> = []

        const handleTrackStarted = ({participant, track}: DailyEventObjectTrack) => {
            /**
             * If track for participant was recently stopped, remove it from queue,
             * so we don't run into a stale state
             */
            if (!participant) return
            const stoppingIdx = trackStoppedQueue.findIndex(
                ([p, t]) => p.session_id === participant.session_id && t.kind === track.kind,
            )
            if (stoppingIdx >= 0) {
                trackStoppedQueue.splice(stoppingIdx, 1)
            }
            dispatch({
                type: TRACK_STARTED,
                participant,
                track,
            })
        }

        const trackStoppedBatchInterval = setInterval(() => {
            if (!trackStoppedQueue.length) return
            dispatch({
                type: TRACK_STOPPED,
                items: trackStoppedQueue.splice(0, trackStoppedQueue.length),
            })
        }, 3000)

        const handleTrackStopped = ({participant, track}: DailyEventObjectTrack) => {
            if (participant) {
                trackStoppedQueue.push([participant, track])
            }
        }

        const handleParticipantLeft = ({participant}: DailyEventObjectParticipantLeft) => {
            if (!participant) return
            dispatch({
                type: REMOVE_TRACKS,
                participant,
            })
        }

        callObject.on('track-started', e => handleTrackStarted(e!))
        callObject.on('track-stopped', e => handleTrackStopped(e!))
        callObject.on('participant-left', e => handleParticipantLeft(e!))
        return () => {
            clearInterval(trackStoppedBatchInterval)
            callObject.off('track-started', e => handleTrackStarted(e!))
            callObject.off('track-stopped', e => handleTrackStopped(e!))
            callObject.off('participant-left', e => handleParticipantLeft(e!))
        }
    }, [callObject])

    useDeepCompareEffect(() => {
        if (!callObject) return

        const handleParticipantUpdated = ({participant}: {participant: DailyParticipant}) => {
            const hasAudioChanged =
                // State changed
                participant.tracks.audio.state !== state.audioTracks?.[participant.user_id]?.state ||
                // Off/blocked reason changed
                !isEqual(
                    {
                        ...(participant.tracks.audio?.blocked ?? {}),
                        ...(participant.tracks.audio?.off ?? {}),
                    },
                    {
                        ...(state.audioTracks?.[participant.user_id].blocked ?? {}),
                        ...(state.audioTracks?.[participant.user_id].off ?? {}),
                    },
                )
            const hasVideoChanged =
                // State changed
                participant.tracks.video.state !== state.videoTracks?.[participant.user_id]?.state ||
                // Off/blocked reason changed
                !isEqual(
                    {
                        ...(participant.tracks.video?.blocked ?? {}),
                        ...(participant.tracks.video?.off ?? {}),
                    },
                    {
                        ...(state.videoTracks?.[participant.user_id].blocked ?? {}),
                        ...(state.videoTracks?.[participant.user_id].off ?? {}),
                    },
                )
            if (hasAudioChanged) {
                // Update audio track state
                dispatch({
                    type: TRACK_AUDIO_UPDATED,
                    participant,
                })
            }
            if (hasVideoChanged) {
                // Update video track state
                dispatch({
                    type: TRACK_VIDEO_UPDATED,
                    participant,
                })
            }
        }

        callObject.on('participant-updated', e => handleParticipantUpdated(e!))
        return () => {
            callObject.off('participant-updated', e => handleParticipantUpdated(e!))
        }
    }, [callObject, state.audioTracks, state.videoTracks])

    return (
        <TracksContext.Provider
            value={{
                audioTracks: state.audioTracks,
                videoTracks: state.videoTracks,
                subscribeToCam,
                updateCamSubscriptions,
                remoteRoomParticipantIds,
                recentSpeakerIds,
            }}
        >
            {children}
        </TracksContext.Provider>
    )
}

export const useTracks = () => useContext(TracksContext)
