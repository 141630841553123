import {ABGroup, Hashtag, TeamPayload} from 'shared-types/view-models/SocketModels'
import {EventViewModel} from 'shared-types/view-models/EventModels'
import {getEvent} from '../api/eventsApi'
import {ActionsUnion, createAction} from './redux-tools'
import {assertUnreachable} from '~/utils/typeUtils'
import {ThunkAction} from './types'

export enum SettingsPage {
    Video = 'Video',
    Audio = 'Audio',
    Breakout = 'Breakout',
    Chat = 'Chat',
    General = 'General',
}

export interface EventState {
    eventLoadingState: 'loading' | 'ready' | 'error'
    event?: EventViewModel
    error?: string
    roomCode?: string
    enableAtoB: boolean
    enableTeams: boolean
    profile: {
        name: string
        title: string
        company: string
        hashtags: Hashtag[]
        team?: TeamPayload
        group?: ABGroup
    }
    settingsModal: {
        isOpen: boolean
        page: SettingsPage
    }
    settings: {
        chatNotifications: boolean
        minimizeLocalTile: boolean
        sidebarOpen: boolean
    }
    removed: boolean
}

const initialState: EventState = {
    eventLoadingState: 'ready',
    event: undefined,
    error: undefined,
    roomCode: undefined,
    enableAtoB: false,
    enableTeams: false,
    // Profile shared between the lobby & the event.
    // Also usefull for reconnecting
    profile: {
        name: '',
        title: '',
        company: '',
        hashtags: [],
        team: undefined,
        group: undefined,
    },
    settingsModal: {
        isOpen: false,
        page: SettingsPage.Audio,
    },
    settings: {
        chatNotifications: JSON.parse(localStorage.getItem('settings.chatNotifications')!) ?? false,
        minimizeLocalTile: JSON.parse(localStorage.getItem('settings.minimizeLocalTile')!) ?? true,
        sidebarOpen: JSON.parse(localStorage.getItem('settings.sidebarOpen')!) ?? window.innerWidth > 600,
    },
    removed: false,
}

export const eventReducer = (prevState: EventState = initialState, action: ActionsType): EventState => {
    switch (action.type) {
        case 'eventState.SetLoadingState':
            return {
                ...prevState,
                eventLoadingState: action.payload,
            }
        case 'eventState.SetEvent':
            return {
                ...prevState,
                eventLoadingState: 'ready',
                event: action.payload,
                roomCode: action.payload.code,
                enableAtoB: !!action.payload.settings.enableAtoB,
                enableTeams: !!action.payload.settings.groupOneTeam || !!action.payload.settings.groupTwoTeam,
                error: undefined,
            }
        case 'eventState.SetError':
            return {
                ...prevState,
                eventLoadingState: 'error',
                error: action.payload,
            }
        case 'eventState.SetAttendeeData':
            const profile = action.payload
            return {
                ...prevState,
                profile: {
                    name: profile.name,
                    title: profile.title,
                    company: profile.company,
                    group: profile.group,
                    hashtags: profile.hashtags,
                    team: profile.team
                        ? {
                              id: profile.team.id,
                              name: profile.team.name,
                              group: profile.group,
                              color: profile.team.color,
                          }
                        : undefined,
                },
            }
        case 'eventState.SetModalOpen':
            if (typeof action.payload === 'boolean') {
                return {
                    ...prevState,
                    settingsModal: {
                        ...prevState.settingsModal,
                        isOpen: action.payload,
                    },
                }
            } else {
                return {
                    ...prevState,
                    settingsModal: {
                        page: action.payload,
                        isOpen: true,
                    },
                }
            }
        case 'eventState.SetSetting':
            const settings = {
                ...prevState.settings,
                [action.payload.setting]: action.payload.value,
            }
            localStorage.setItem(`settings.${action.payload.setting}`, action.payload.value)
            return {...prevState, settings}
        case 'eventState.Removed':
            return {
                ...prevState,
                removed: true,
            }
        default:
            assertUnreachable(action)
    }
    return prevState
}

// --- Actions ---

const actions = {
    SetLoadingState: (state: EventState['eventLoadingState']) => createAction('eventState.SetLoadingState', state),
    SetEvent: (event: EventViewModel) => createAction('eventState.SetEvent', event),
    SetError: (error: string) => createAction('eventState.SetError', error),
    SetModalOpen: (page: boolean | SettingsPage) => createAction('eventState.SetModalOpen', page),
    SetSetting: (entry: {[setting: string]: any}) => createAction('eventState.SetSetting', entry),
    SetAttendeeData: (payload: EventState['profile']) => {
        return createAction('eventState.SetAttendeeData', payload)
    },
    Removed: () => createAction('eventState.Removed'),
}

const thunkActions = {
    FetchEvent(roomCode: string): ThunkAction<Promise<void | string>> {
        return async (dispatch, getState) => {
            dispatch(actions.SetLoadingState('loading'))
            try {
                const response = await getEvent(roomCode)

                if (response.type === 'event') {
                    dispatch(actions.SetEvent(response))
                } else {
                    dispatch(actions.SetError(response.message))
                    return response.message
                }

                return
            } catch (error) {
                if (error instanceof Error) {
                    const errorText = error?.message ?? "Couldn't find that event"
                    return errorText
                }
            }
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>
export default {...actions, ...thunkActions}
