import React, {createContext, useContext, useEffect, useState, useMemo} from 'react'
import {Socket} from 'socket.io-client'
import {toast} from 'react-toastify'
import {ParticipantViewModel} from 'shared-types/view-models/ParticipantModels'
import {
    ChannelUpdatePayload,
    Hashtag,
    HomeRoomMode,
    JoinRoomPayload,
    PresetMatch,
    RoomPayload,
    RoomState,
    SocketJoinData,
    UpdateRoomSettingsPayload,
} from 'shared-types/view-models/SocketModels'
import {AmplifierSocketClient} from 'shared-types/socket-events'
import {useDaily} from '@daily-co/daily-react'
import {useAppDispatch, useAppSelector} from '../store'
import {DateTime} from 'luxon'
import isEqual from 'lodash/isEqual'
import createSocket from '../utils/createSocket'
import actions from '../store/amplifierSocketState'
import eventActions from '../store/eventState'

import {DailyEventObjectParticipantLeft, DailyEventObjectParticipants} from '@daily-co/daily-js'
import {assertUnreachable} from '~/utils/typeUtils'

interface AmplifierSocketContextState {
    socket: Socket
    channel: number
    startBreakout: () => void
    stopBreakout: (force?: boolean) => void
    currentRoomParticipantIds: string[]
    nextRoomParticipantIds: string[]
    allParticipants: {[id: string]: ParticipantViewModel}
    allParticipantIds: string[]
    localParticipant: ParticipantViewModel
    channels: {
        [key: number]: string[]
    }
    breakoutTime: [number, React.Dispatch<number>]
    breakoutMode: ['1:1', React.Dispatch<'1:1'>]
    breakoutGroupSize: [number, React.Dispatch<number>]
    state: RoomState
    nextState: RoomState
    homeRoomMode: HomeRoomMode
    matchedIds: string[]
    updateProfile: (data: {name: string; company: string; title: string}) => void
    updateHashtags: (hashtags: Hashtag[]) => void
    grantHost: (participantId: string, privlege: boolean) => void
    breakoutLatecomers: () => void
    removeParticipant: (participantId: string, remove: boolean) => void
    moveChannel: (participantId: string, channelIndex: number) => void
    sendMessage: (message: string, toId?: string) => void
    leaveBreakout: () => void
    updateRoomSettings: (payload: UpdateRoomSettingsPayload) => void
    updatePreInfluenceMatches: (payload: PresetMatch[]) => void
    debugSocketConnection: boolean
    setDebugSocketConnection: (connected: boolean) => void
}

const AmplifierSocketContext = createContext<AmplifierSocketContextState>({} as AmplifierSocketContextState)

const AmplifierSocketProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const callObj = useDaily()!
    const dispatch = useAppDispatch()

    const {
        currentRoomParticipantIds,
        nextRoomParticipantIds,
        allParticipants,
        allParticipantIds,
        localParticipant,
        favouriteIds,
        channel,
        channels,
        hasJoined,
        breakout,
    } = useAppSelector(({amplifierSocket}) => amplifierSocket)

    const matchedIds = useMemo(() => localParticipant.matchedIds, [localParticipant.matchedIds])
    const {state, nextState} = useMemo(() => breakout, [breakout])

    const [socket, setSocket] = useState<AmplifierSocketClient>()
    const [debugSocketConnection, setDebugSocketConnection] = useState(false)

    // States for other components to consume
    const user = useAppSelector(globalState => globalState.identityState.user!)
    const {roomCode} = useAppSelector(globalState => globalState.eventState)
    const profile = useAppSelector(globalState => globalState.eventState.profile)

    const [breakoutTime, setBreakoutTime] = useState(5) // Breakout Timer length
    const [breakoutMode, setBreakoutMode] = useState<'1:1'>('1:1')
    const [breakoutGroupSize, setBreakoutGroupSize] = useState(2) // Number of rooms to create

    const [homeRoomMode] = useState<HomeRoomMode>('all')

    const [isPermissionBlocked, setIsPermissionBlocked] = useState(false)

    useEffect(() => {
        if (!socket) return

        socket.on('connect', () => {
            if (hasJoined) {
                // Reconnect to socket
                const localId = callObj.participants().local.user_id
                const socketJoinData: JoinRoomPayload = {
                    dailyCoId: localId,
                    name: profile.name,
                    title: profile.title,
                    company: profile.company,
                    hashtags: profile.hashtags,
                    group: profile.group,
                    team: profile.team,
                }
                socket.emit('RECONNECT_ROOM', socketJoinData)
            }
        })
        socket.on('disconnect', () => {
            dispatch(actions.SetSocketState('connecting'))
        })
        return () => {
            socket?.off('connect')
            socket?.off('disconnect')
        }
    }, [socket, hasJoined])

    // Emit favourite updates to server
    useEffect(() => {
        setFavourites(favouriteIds)
    }, [favouriteIds])

    /**
     * Set up Breakout Room socket
     **/
    useEffect(() => {
        if (!callObj || hasJoined) return

        callObj.on('joined-meeting', async () => {
            callObj.setSubscribeToTracksAutomatically(false)
            connectSocket()
        })
        callObj.on('left-meeting', () => {
            if (socket) {
                socket.close()
                setSocket(undefined)
            }
        })
        callObj.on('joined-meeting', e => trackChanged(e!))
        callObj.on('participant-left', e => participantLeft(e!))

        // Send the client-side date, to get the Client/Server Time offset.
        // This is a Network Time Protocol
        const checkInterval = setInterval(() => {
            socket?.emit(
                'SERVER_TIME_OFFSET',
                DateTime.now().toMillis(),
                (data: {clientEpoch: number; serverEpoch: number}) => {
                    dispatch(actions.SetServerTimeOffset(data.clientEpoch - data.serverEpoch))
                },
            )
        }, 10000)

        return () => {
            clearInterval(checkInterval)
            callObj.off('left-meeting', () => {})
            callObj.off('joined-meeting', e => trackChanged(e!))
            callObj.off('participant-left', e => participantLeft(e!))
        }
    }, [callObj])

    const participantLeft = ({participant}: DailyEventObjectParticipantLeft) => {
        //Remove participant from favourites
        dispatch(actions.RemoveFromFavourites(participant.session_id))
    }

    const connectSocket = () => {
        if (!roomCode) return
        const localId = callObj.participants().local.user_id
        dispatch(actions.SetLocalDailyId(localId))

        const data: SocketJoinData = {
            roomCode: roomCode!,
            dailyCoId: localId,
            userId: user.id,
            name: profile.name,
        }
        const newSocket = createSocket(roomCode, 'event', data)
        setSocket(newSocket)
    }

    useEffect(() => {
        if (!socket || !callObj) return
        const localId = callObj.participants().local.user_id

        const displayMessgae = (message: string) => {
            toast.info(message, {containerId: 'All'})
        }
        socket.on('SOCKET_ERROR', displayMessgae)
        socket.on('ADMIN_MESSAGE', displayMessgae)
        socket.on('UPDATE_CHANNELS', (data: ChannelUpdatePayload) => {
            dispatch(actions.SetSocketState('connected'))
            dispatch(actions.UpdateChannels(data))
            //dispatch(actions.UpdateTeams(data.teams))
        })

        socket.on('UPDATE_ROOM', (data: RoomPayload) => {
            dispatch(actions.SetSocketState('connected'))
            dispatch(actions.UpdateRoom(data))
            //dispatch(actions.UpdateTeams(data?.teams))
        })

        socket.on('UPDATE_MATCHABLE', payload => {
            dispatch(actions.UpdateMatchable(payload))
        })
        socket.on('GRANT_HOST', payload => {
            dispatch(actions.SetHost(payload.isHost))
            if (payload.isHost) {
                dispatch(actions.SetHostSettings(payload.hostSettings!))
                toast.info("You've been made a co-host", {containerId: 'All'})
            } else {
                dispatch(actions.SetHostSettings(undefined))
                toast.info("You're no longer a co-host", {containerId: 'All'})
            }
        })

        socket.on('UPDATE_PRE_INFLUENCE_MATCHES', data => {
            dispatch(actions.SetPreInfluenceMatches(data.matches))
        })

        socket.on('RECEIVE_MESSAGE', message => {
            dispatch(actions.ReceiveMessage(message))
        })

        // Receive Join event abilities from server
        socket.on('JOIN_ROOM', data => {
            dispatch(actions.SetSocketState('connected'))
            if (data.isHost) dispatch(actions.SetHost(true))
            if (data.messages) dispatch(actions.UpdateMessages(data.messages))
            if (data.hostSettings) dispatch(actions.SetHostSettings(data.hostSettings))
            dispatch(actions.UpdateRoom(data))
        })

        socket.on('HOST_SETTINGS', payload => {
            dispatch(actions.SetHostSettings(payload))
        })
        socket.on('PARTICIPANT_EVENT', payload => {
            if (payload.event === 'joined') {
                dispatch(actions.ParticipantJoined(payload))
            } else if (payload.event === 'left') {
                dispatch(actions.ParticipantLeft(payload))
            } else if (payload.event === 'updated') {
                dispatch(actions.ParticipantUpdated(payload))
            } else {
                assertUnreachable(payload.event)
            }
        })

        socket.on('UPDATE_HASHTAGS', payload => {
            dispatch(actions.UpdateHashtags(payload))
        })

        socket.on('REMOVED', () => {
            dispatch(eventActions.Removed())

            socket.close()
            callObj.leave()
            //window.location.reload()
        })

        const socketJoinData: JoinRoomPayload = {
            dailyCoId: localId,
            name: profile.name,
            company: profile.company,
            title: profile.title,
            group: profile.group,
            hashtags: profile.hashtags,
            team: profile.team,
        }

        socket.emit('JOIN_ROOM', socketJoinData)

        return () => {
            socket.off('JOIN_ROOM')
            socket.off('UPDATE_CHANNELS')
            socket.off('UPDATE_MATCHABLE')
            socket.off('GRANT_HOST')
            socket.off('ADMIN_MESSAGE')
            socket.off('SOCKET_ERROR')
            socket.off('RECEIVE_MESSAGE')
        }
    }, [socket])

    const setFavourites = (arr: string[]) => {
        if (!socket) return
        //feed in participant ID of favourites array
        socket?.emit('UPDATE_FAVOURITE', arr)
    }

    const startBreakout = () => {
        socket?.emit('BREAKOUT_START', {
            breakoutTime: breakoutTime,
            breakoutMode: breakoutMode,
            breakoutGroupSize: breakoutGroupSize,
        })
    }

    const stopBreakout = (force = false) => {
        socket?.emit('BREAKOUT_STOP', force)
    }

    const updateProfile = (data: {company: string; name: string; title: string}) => {
        socket?.emit('UPDATE_PROFILE', data)
    }

    const updateHashtags = (newHashtags: Hashtag[]) => {
        socket?.emit('UPDATE_HASHTAGS', {hashtags: newHashtags})
    }

    const grantHost = (id: string, grant = true) => {
        socket?.emit('GRANT_HOST', {id, grant})
    }

    const breakoutLatecomers = () => {
        socket?.emit('BREAKOUT_LATECOMERS')
    }

    const removeParticipant = (id: string, remove = true) => {
        socket?.emit('REMOVE_PARTICIPANT', {id, remove})
    }

    const moveChannel = (participantId: string, channelIndex: number) => {
        socket?.emit('MOVE_CHANNEL', {participantId, channelIndex})
    }

    const sendMessage = (message: string, toId?: string) => {
        socket?.emit('SEND_MESSAGE', {
            toId,
            private: !!toId,
            content: message,
        })
    }

    const leaveBreakout = () => {
        socket?.emit('LEAVE_BREAKOUT')
    }

    // Update our servers if the users's video is blocked by permissions or otherwise
    const trackChanged = (event: DailyEventObjectParticipants) => {
        if (!event.participants.local) return
        if (event.participants.local.tracks.video.state === 'blocked') {
            if (!isPermissionBlocked) {
                setIsPermissionBlocked(true)
                setTimeout(() => {
                    socket?.emit('UPDATE_VIDEO_SETTINGS', false)
                }, 5000)
            }
        } else if (isPermissionBlocked) {
            setIsPermissionBlocked(false)
            setTimeout(() => {
                socket?.emit('UPDATE_VIDEO_SETTINGS', true)
            }, 5000)
        }
    }

    const updateRoomSettings = (payload: UpdateRoomSettingsPayload) => {
        dispatch(actions.SetHostSettings(payload))
        socket?.emit('HOST_SETTINGS', payload)
    }

    const updatePreInfluenceMatches = (payload: PresetMatch[]) => {
        dispatch(actions.SetPreInfluenceMatches(payload))
        socket?.emit('UPDATE_PRE_INFLUENCE_MATCHES', payload)
    }

    // Remove participants from fav if we've talked
    useEffect(() => {
        const newFavouriteIds = favouriteIds.filter(id => {
            if (state === 'breakout' && currentRoomParticipantIds.includes(id)) return true
            return !localParticipant.matchedIds.includes(id)
        })

        if (isEqual(newFavouriteIds, favouriteIds)) return
        dispatch(actions.SetFavouriteIds(newFavouriteIds))
    }, [localParticipant.matchedIds])

    useEffect(() => {
        if (!socket) return
        if (debugSocketConnection) {
            socket.disconnect()
        } else {
            socket.connect()
        }
    }, [debugSocketConnection])

    // Items for other contexts to consume
    const amplifyContext: AmplifierSocketContextState = {
        socket: socket!,
        channel: channel,
        startBreakout,
        stopBreakout,
        allParticipants,
        allParticipantIds,
        currentRoomParticipantIds,
        nextRoomParticipantIds,
        localParticipant,
        channels,
        breakoutTime: [breakoutTime, setBreakoutTime],
        breakoutMode: [breakoutMode, setBreakoutMode],
        breakoutGroupSize: [breakoutGroupSize, setBreakoutGroupSize],
        state,
        nextState,
        matchedIds,
        updateProfile,
        updateHashtags,
        grantHost,
        breakoutLatecomers,
        removeParticipant,
        moveChannel,
        sendMessage,
        homeRoomMode,
        leaveBreakout,
        updateRoomSettings,
        updatePreInfluenceMatches,
        debugSocketConnection,
        setDebugSocketConnection,
    }

    return <AmplifierSocketContext.Provider value={amplifyContext}>{children}</AmplifierSocketContext.Provider>
}

const useAmplifierSocket = (): AmplifierSocketContextState => useContext(AmplifierSocketContext)
export {AmplifierSocketContext as default, AmplifierSocketProvider, useAmplifierSocket}
