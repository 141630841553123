import {Search as SearchIcon} from '@styled-icons/evaicons-solid/Search'
import cn from 'classnames'
import {Hashtag} from 'shared-types/view-models/SocketModels'
import {useAppSelector} from '../../../../store'
import HashtagPills from '../../Common/HashtagPills'
import './SearchInput.scss'

interface SearchInputProps {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
    selectedHashtags: Hashtag[]
    setSelectedHashtags: (hashtags: Hashtag[]) => void
}
const SearchInput = ({searchTerm, setSearchTerm, selectedHashtags, setSelectedHashtags}: SearchInputProps) => {
    const hashtags = useAppSelector(({amplifierSocket}) => amplifierSocket.hashtags)

    return (
        <div>
            <div className="searchContainer mb-2">
                <input
                    placeholder="Search Participants, Hashtags"
                    className={cn('mt-2 mx-1', {empty: searchTerm === ''})}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <SearchIcon size="1.5em" color="#FFFFFFB3" />
            </div>
            <div className="filterHashtagsContainer">
                <HashtagPills
                    hashtags={hashtags}
                    selectedHashtags={selectedHashtags}
                    setSelectedHashtags={setSelectedHashtags}
                />
            </div>
        </div>
    )
}

export default SearchInput
