import {RadioGroup} from '@headlessui/react'
import {useEffect, useState} from 'react'

import clsx from 'clsx'
import {CheckCircleFill} from '@styled-icons/bootstrap/CheckCircleFill'
import {motion} from 'framer-motion'

import './EventTypeSelector.scss'
import {EventSettings} from 'shared-types/view-models'

const options = [
    {
        id: 1,
        title: 'Standard 1:1',
        description: 'Standard 1:1 networking, users match based on preferences',
    },
    {
        id: 2,
        title: 'A to B Mode',
        description: 'Users join one of two predefined groups and speak with the opposite group',
    },
]

export default function EventTypeSelector({
    onChange,
    settings,
    theme = 'dark',
}: {
    onChange: (val: {
        enableAtoB: boolean
        groupOneLabel?: string
        groupTwoLabel?: string
        groupOneTeam: boolean
        groupTwoTeam: boolean
    }) => void
    settings?: EventSettings
    theme?: 'light' | 'dark'
}) {
    const [selectedoptions, setSelectedoptions] = useState(
        settings ? (!settings.enableAtoB ? options[0] : options[1]) : options[0],
    )

    const [AgroupLabel, setAgroupLabel] = useState<string>(settings?.groupOneLabel ?? 'Group A')
    const [BgroupLabel, setBgroupLabel] = useState<string>(settings?.groupTwoLabel ?? 'Group B')

    useEffect(() => {
        onChange({
            enableAtoB: selectedoptions.id === 2,
            groupOneLabel: AgroupLabel,
            groupTwoLabel: BgroupLabel,
            groupOneTeam: false,
            groupTwoTeam: false,
        })
    }, [AgroupLabel, BgroupLabel, selectedoptions])

    return (
        <motion.div className={clsx('eventtypeselector__container', theme)}>
            <RadioGroup value={selectedoptions} onChange={setSelectedoptions}>
                <RadioGroup.Label className="text-base font-semibold leading-6 sectionlabel">
                    Select an Event Breakout Type
                </RadioGroup.Label>
                <RadioGroup.Label className="text-sm flex  leading-6 section_sublabel">
                    If your event doesn't use breakouts, leave this as Standard
                </RadioGroup.Label>

                <motion.div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    {options.map(option => (
                        <RadioGroup.Option
                            key={option.id}
                            value={option}
                            className={({checked, active}) =>
                                clsx(
                                    checked ? 'checked' : '',
                                    active ? 'active' : '',
                                    'radio__option relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none',
                                )
                            }
                        >
                            {({checked, active}) => (
                                <>
                                    <span className="flex flex-1">
                                        <span className="flex flex-col">
                                            <RadioGroup.Label
                                                as="span"
                                                className="block text-sm font-medium option__title"
                                            >
                                                {option.title}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as="span"
                                                className="mt-1 flex items-center text-sm text-gray-500"
                                            >
                                                {option.description}
                                            </RadioGroup.Description>
                                        </span>
                                    </span>
                                    <CheckCircleFill
                                        className={clsx(!checked ? 'invisible' : '', 'h-5 w-5 text-primary')}
                                        aria-hidden="true"
                                    />

                                    <span
                                        className={clsx(
                                            active ? 'border' : 'border-2',
                                            checked ? 'border-primary' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-lg',
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </motion.div>
            </RadioGroup>

            <motion.div
                layout
                className={clsx('my-8 flex text-left flex-col', selectedoptions.id === 2 ? '' : 'ab__disabled tooltip')}
                data-tip="Not required for Standard 1:1"
            >
                <label className="text-base font-semibold leading-6 sectionlabel">Set A to B Labels</label>
                <label className="text-sm flex  leading-6 section_sublabel">
                    Set the labels for the two groups. e.g. Founder and Investor
                </label>
                <div className="mt-4 grid grid-cols-2 gap-x-2">
                    <motion.div layout className="eventtype__input">
                        <label htmlFor="eventName">Group A Name</label>
                        <input
                            type="text"
                            name="groupAName"
                            id="groupBName"
                            value={AgroupLabel}
                            disabled={selectedoptions.id === 1}
                            placeholder="e.g. Founder"
                            onChange={e => setAgroupLabel(e.target.value)}
                        />
                    </motion.div>
                    <motion.div layout className="eventtype__input">
                        <label htmlFor="eventName">Group B Name</label>
                        <input
                            type="text"
                            name="groupBName"
                            id="groupBName"
                            disabled={selectedoptions.id === 1}
                            value={BgroupLabel}
                            placeholder="e.g. Investor"
                            onChange={e => setBgroupLabel(e.target.value)}
                        />
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}
