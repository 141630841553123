import {useEffect, useState} from 'react'
import {useBreadCrumbs} from '../DashboardWrap'

import {useNavigate} from 'react-router-dom'
import {EventPostViewModel} from 'shared-types/view-models'
import {checkEventCodeAvalibility, createEvent} from '~/api/eventsApi'
import LoadingSvg from '~/assets/svgs/loading.svg'
import EventTypeSelector from '~/components/common/EventTypeSelector/EventTypeSelector'
import Loading from '~/components/common/Loading'
import {useAppSelector} from '~/store'
import './CreateEvent.scss'

import clsx from 'clsx'

export default function CreateEvent() {
    const {setBreadCrumbs} = useBreadCrumbs()
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const navigate = useNavigate()

    const [eventName, setEventName] = useState('')
    const [roomCode, setRoomCode] = useState('')
    const [settings, setSettings]: [EventPostViewModel['settings'], any] = useState({
        enableAtoB: false,
        groupOneLabel: 'Group A',
        groupOneTeam: false,
        groupTwoLabel: 'Group B',
        groupTwoTeam: false,
    })

    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>()
    const [codeLoading, setCodeLoading] = useState(false)

    const [formLoading, setFormLoading] = useState(false)
    const [nameError, setNameError] = useState('')
    const [formError, setFormError] = useState('')

    const checkRoomCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventName(e.target.value)
        setFormError('')
        setNameError('')

        const roomCode = e.target.value.toLowerCase().replace(/ /g, '-')

        //setRoomCode(roomCode)
        if (typingTimeout) {
            setCodeLoading(false)
            clearTimeout(typingTimeout)
        }

        setCodeLoading(true)

        setTypingTimeout(
            setTimeout(async () => {
                // Theyve stopped typing, set roomcode to null so we can load one
                setRoomCode('')

                if (e.target.value.length < 4) {
                    setNameError(e.target.value.length !== 0 ? 'Event name must be at least 4 characters long' : '')
                    setCodeLoading(false)
                    return
                }

                let newCode = await checkEventCodeAvalibility(roomCode)

                if (typeof newCode === 'string') {
                    setRoomCode(newCode)
                }

                setCodeLoading(false)
            }, 500),
        )
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        setFormError('')
        setFormLoading(true)

        const response = await createEvent(
            {
                title: eventName,
                code: roomCode,
                settings: settings,
            },
            tenant.slug,
        )
        if (response.type === 'event') {
            navigate(`/dashboard/events/${response.code}/details`)
        } else if (response.type === 'error' || response.type === 'internal_server_error') {
            setFormError(response.message)
        }
        setFormLoading(false)
    }

    useEffect(() => {
        setBreadCrumbs([
            {
                name: 'Events',
                href: '/dashboard/events',
                current: false,
            },
            {
                name: 'New Event',
                href: ``,
                current: true,
            },
        ])
    }, [])

    return (
        <div className="createevent___page px-12 py-12">
            <h1 className="font-semibold">Create New Event</h1>

            <form className="bg-white border-t mt-12" onSubmit={handleSubmit}>
                <div className=" py-6 mt-6">
                    <div className="grid  w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="ob__form__group sm:col-span-3 h-32 w-full">
                            <div className={clsx('createevent__input', nameError.length > 1 && 'error')}>
                                <label className={clsx(nameError.length > 1 && 'ob__error')} htmlFor="eventName">
                                    {nameError.length > 1 ? nameError : 'Event Name'}
                                </label>
                                <input
                                    type="text"
                                    name="eventName"
                                    id="eventName"
                                    value={eventName}
                                    placeholder="e.g. My Event"
                                    onChange={e => checkRoomCode(e)}
                                />
                            </div>

                            {eventName.length >= 4 && (
                                <div className="createevent__input uneditable">
                                    <div>
                                        <span className="inline-flex w-auto whitespace-nowrap items-center rounded-l-md  text-gray-600 sm:text-sm">
                                            app.amplifier.community/?room=
                                        </span>
                                        <input
                                            type="text"
                                            name="eventName"
                                            disabled={true}
                                            id="eventName"
                                            value={roomCode}
                                            onChange={e => setRoomCode(e.target.value)}
                                        />

                                        <div className="input__statusindicator ">{codeLoading && <Loading />}</div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="sm:col-span-4">
                            <EventTypeSelector theme="light" onChange={e => setSettings(e)} />

                            {formError && (
                                <div className="text-red-500 text-sm mt-2">
                                    <p>{formError}</p>
                                </div>
                            )}
                        </div>

                        <div className="sm:col-span-6 flex justify-end">
                            <button
                                onClick={() => navigate(`/dashboard/events`)}
                                type="button"
                                className="btn bg-white border-none hover:bg-gray-200 mr-2 text-sm font-semibold text-gray-900"
                            >
                                Cancel
                            </button>

                            <button
                                disabled={eventName.length < 4 || roomCode.length < 3 || formLoading}
                                className="btn btn-primary    border-none"
                            >
                                {formLoading && <LoadingSvg className={clsx('mr-3')} height="1em" width="1em" />}
                                Create Event
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
