import cn from 'classnames'
import {useAppDispatch, useAppSelector} from '../../../store'
import './Profile.scss'
import actions from '../../../store/identityState'
import Loading from '~/components/common/Loading'
import {Field, Form, Formik} from 'formik'
import {RouterInputs, trpcFetch} from '~/api/trpc'
import validate from '~/utils/validate'
import {shallowEqual} from 'react-redux'

export default function Profile() {
    const participant = useAppSelector(state => state.identityState.user!)
    const dispatch = useAppDispatch()

    const renderField = ({name, errorMessage, label}: {name: string; label: string; errorMessage?: string}) => {
        return (
            <div className="py-3 border-b border-gray-200 grid grid-cols-3">
                <label htmlFor={name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {label}
                </label>
                <div className="col-span-2">
                    <Field
                        name={name}
                        id={name}
                        autoComplete={name}
                        className={cn('input input-bordered text-sm w-full shadow-md', errorMessage && 'input-error')}
                    />
                    <div className="text-error text-sm mt-1">{errorMessage}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex-1 p-5">
            <div className="bg-white w-full px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Your Profile</h3>
                </div>
            </div>
            <Formik
                initialValues={
                    {
                        givenName: participant.given_name,
                        familyName: participant.family_name,
                        title: participant.metaData.title,
                        company: participant.metaData.company,
                        linkedInProfile: participant.metaData.linkedin_profile,
                        twitterHandle: participant.metaData.twitter_handle,
                    } as RouterInputs['identity']['updateMe']
                }
                enableReinitialize
                validate={values =>
                    validate.generate({
                        givenName: [validate.required()],
                        familyName: [validate.required()],
                        company: [validate.required()],
                        title: [validate.required()],
                        linkedInProfile: [validate.linkedinProfile()],
                        twitterHandle: [validate.twitterHandle()],
                    })(values as any)
                }
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true)
                    const response = await trpcFetch.identity.updateMe.mutate(values)
                    setSubmitting(false)

                    dispatch(actions.SetUser(response))
                }}
            >
                {({errors, touched, isValid, isSubmitting, values, initialValues}) => (
                    <Form className="flex flex-col w-full mt-10">
                        {renderField({
                            name: 'givenName',
                            errorMessage: touched.givenName ? errors.givenName : undefined,
                            label: 'Given Name',
                        })}
                        {renderField({
                            name: 'familyName',
                            errorMessage: touched.familyName ? errors.familyName : undefined,
                            label: 'Family Name',
                        })}
                        {renderField({
                            name: 'company',
                            errorMessage: touched.company ? errors.company : undefined,
                            label: 'Company',
                        })}
                        {renderField({
                            name: 'title',
                            errorMessage: touched.title ? errors.title : undefined,
                            label: 'Title',
                        })}
                        {renderField({
                            name: 'linkedInProfile',
                            errorMessage: touched.linkedInProfile ? errors.linkedInProfile : undefined,
                            label: 'Linked Profile URL',
                        })}
                        {renderField({
                            name: 'twitterHandle',
                            errorMessage: touched.twitterHandle ? errors.twitterHandle : undefined,
                            label: 'Twitter handle',
                        })}
                        <button
                            type="submit"
                            disabled={!isValid || isSubmitting || shallowEqual(values, initialValues)}
                            className="btn btn-primary mt-4 ml-auto"
                        >
                            {isSubmitting && (
                                <div className="loadingButton w-4 h-4 mx-2 ">
                                    <Loading className="w-5" />
                                </div>
                            )}
                            <span>Save Profile</span>
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
