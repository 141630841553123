import {Hashtag} from 'shared-types/view-models'
import validate from './validate'

export async function validateHashtag(newTag: string, hashtags: Hashtag[]): Promise<string | undefined> {
    // Hacky workaround to use the validation schema not in formik
    const validationSchema = validate.generate({
        hashtag: [validate.hashtag(hashtags)],
    })
    const errors = await validationSchema({
        hashtag: newTag,
    })
    if (errors.hashtag) {
        return errors.hashtag
    }
}
