import cn from 'classnames'
import {DateTime} from 'luxon'
import {useEffect, useState} from 'react'
import {useAppSelector} from '../../../store'
import styles from './Timer.module.scss'

export default function Timer() {
    const {stateEnd, state, stateStart} = useAppSelector(({amplifierSocket: {breakout}}) => ({
        state: breakout.state,
        stateStart: breakout.stateStart ? DateTime.fromJSDate(breakout.stateStart) : undefined,
        stateEnd: breakout.stateEnd ? DateTime.fromJSDate(breakout.stateEnd) : undefined,
    }))
    // initialize timeLeft with the seconds prop
    const [secondsRemaining, setSecondsRemaining] = useState<number>()

    useEffect(() => {
        if (!stateEnd || !stateStart) {
            setSecondsRemaining(undefined)
            return
        }

        const setTime = () => {
            setSecondsRemaining(stateEnd.diff(DateTime.now()).as('seconds') + 1)
        }
        setTime()
        const interval = setInterval(setTime, 100)
        return () => clearInterval(interval)
    }, [stateEnd, stateStart])

    if (!secondsRemaining || !stateEnd || !stateStart) {
        return <></>
    }
    const formattedTimeLeft = () => {
        if (!secondsRemaining) return ''
        const minutesRemaining = Math.floor(secondsRemaining / 60)
        const seconds = Math.floor(secondsRemaining) % 60
        if (minutesRemaining < 0 || secondsRemaining < 0) {
            return '0'
        }
        if (minutesRemaining <= 0) {
            return `${seconds}`
        }
        return minutesRemaining + ':' + (seconds < 10 ? '0' + seconds : seconds)
    }

    const barProgress = () => {
        if (state === 'normal') return 0
        const totalSeconds = stateEnd.diff(stateStart).as('seconds')
        return 100 - ((secondsRemaining - 1) / totalSeconds) * 100
    }

    // Make the timer larger for chat countdowns
    const isLarge = state !== 'intermission' && secondsRemaining <= 20

    return (
        <>
            <progress className={styles.progress} value={barProgress()} max="100" />
            <div className={cn(styles.breakoutTimer, isLarge && styles.countdown)}>{formattedTimeLeft()}</div>
        </>
    )
}
