import React, {useState} from 'react'
import {trpc} from '~/api/trpc'
import Loading from '../common/Loading'
import styles from './Admin.module.scss'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import {Link} from 'react-router-dom'

const AdminCustomers: React.FC = () => {
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [orderBy, setOrderBy] = useState<'createdAt' | 'email'>('createdAt')
    const [orderByDirection, setOrderByDirection] = useState<'asc' | 'desc'>('desc')

    const customersQuery = trpc.admin.customersPaginated.useQuery({perPage, page, orderBy, orderByDirection})

    if (customersQuery.isError) {
        return <span className="text-error">{customersQuery.error.message}</span>
    }

    const renderPagination = () => {
        if (customersQuery.isLoading || customersQuery.isError) {
            return null
        }

        const currentProgress = perPage * page
        const currentListCount = customersQuery.data.users.length
        const totalCount = customersQuery.data.totalCount

        const maxPage = Math.floor(totalCount / perPage)

        return (
            <div className="grid grid-cols-5 items-center mb-4">
                <div />
                <div className="join col-span-3 flex justify-center">
                    <button className="join-item btn" onClick={() => setPage(0)} disabled={page <= 0}>
                        «
                    </button>
                    <button className="join-item btn" onClick={() => setPage(page - 1)} disabled={page <= 0}>
                        {'<'}
                    </button>
                    <button className="join-item btn">Page {page + 1}</button>
                    <button className="join-item btn" onClick={() => setPage(page + 1)} disabled={page >= maxPage}>
                        {'>'}
                    </button>
                    <button className="join-item btn" onClick={() => setPage(maxPage)} disabled={page >= maxPage}>
                        »
                    </button>
                </div>
                <div className="font-medium">
                    {currentProgress} - {currentProgress + currentListCount} / {totalCount}
                </div>
            </div>
        )
    }

    return (
        <>
            <h2 className={styles.title}>Customers</h2>
            <div className="flex justify-end gap-2 mb-4">
                <select
                    className="select select-bordered"
                    value={perPage}
                    onChange={e => setOrderBy(e.target.value as any)}
                >
                    {['createdAt', 'email'].map(val => (
                        <option value={val}>Sort By: {val}</option>
                    ))}
                </select>
                <select
                    className="select select-bordered"
                    value={perPage}
                    onChange={e => setOrderByDirection(e.target.value as any)}
                >
                    {['desc', 'asc'].map(val => (
                        <option value={val}>Direction: {val}</option>
                    ))}
                </select>
                <select
                    className="select select-bordered"
                    value={perPage}
                    onChange={e => setPerPage(parseInt(e.target.value))}
                >
                    {[10, 25, 50, 100].map(val => (
                        <option value={val}>Per page: {val}</option>
                    ))}
                </select>
            </div>
            {customersQuery.isLoading ? (
                <Loading />
            ) : (
                <>
                    {renderPagination()}
                    <table className={styles.adminTable}>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Email</td>
                                <td>Name</td>
                                <td>Title</td>
                                <td>Company</td>
                                <td>Created</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {customersQuery.data.users.map(customer => (
                                <tr>
                                    <td>
                                        <AmplifierAvatar
                                            name={`${customer.givenName} ${customer.familyName}`}
                                            src={customer.picture}
                                            size="xs"
                                        />
                                    </td>
                                    <td>{customer.email}</td>
                                    <td>
                                        {customer.givenName} {customer.familyName}
                                    </td>
                                    <td>{customer.title}</td>
                                    <td>{customer.company}</td>
                                    <td>{customer.createdAt.toDateString()}</td>
                                    <td>
                                        <Link className="btn btn-neutral btn-sm" to={`/admin/customers/${customer.id}`}>
                                            View
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tr></tr>
                    </table>
                </>
            )}
        </>
    )
}

export default AdminCustomers
