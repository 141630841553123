import {EventUsage} from 'shared-types/view-models/EventModels'
import Skeleton from '~/components/common/Skeleton'

export default function UsageTable({usageRecords, loading}: {usageRecords: EventUsage[]; loading: boolean}) {
    return (
        <table className="min-w-full divide-y   divide-gray-200">
            <thead className="">
                <tr>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Room
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Sessions
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Participant Minutes (est.)
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {loading && (
                    <tr>
                        <td colSpan={5} className="px-6 py-4 text-sm text-gray-500">
                            <Skeleton className="w-full h-4" />
                        </td>
                    </tr>
                )}

                {!loading &&
                    usageRecords.map(payment => (
                        <tr key={payment.code}>
                            <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                {payment.code}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {payment.sessions.length}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {payment.totalMinutes}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
