import LoadingSvg from '~/assets/svgs/loading.svg'

interface LoadingProps {
    className?: string
}

const Loading = ({className}: LoadingProps) => (
    <div className="w-full flex justify-center">
        <LoadingSvg className={className} />
    </div>
)

export default Loading
