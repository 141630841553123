import * as Response from 'shared-types/schema/response'
import {ActionsUnion, createAction} from './redux-tools'
import {assertUnreachable} from '~/utils/typeUtils'
import {EventViewModel} from 'shared-types/view-models/EventModels'
import {ThunkAction} from './types'
import {getEvent, getEvents} from '../api/eventsApi'
import isArray from 'lodash/isArray'

export interface EventsState {
    eventsLoadingState: 'loading' | 'ready' | 'error'
    error?: string
    events: EventViewModel[]
    stagedDetailEvent?: EventViewModel
}

const initialState: EventsState = {
    eventsLoadingState: 'loading',
    error: undefined,
    events: [],
    stagedDetailEvent: undefined,
}

export function eventsReducer(prevState: EventsState = initialState, action: ActionsType): EventsState {
    switch (action.type) {
        case 'eventsState.SetLoadingState':
            return {
                ...prevState,
                eventsLoadingState: action.payload,
            }
        case 'eventsState.SetError':
            return {
                ...prevState,
                error: action.payload,
            }
        case 'eventsState.SetEvents':
            return {
                ...prevState,
                events: action.payload,
            }
        case 'eventsState.SetStagedEvent':
            return {
                ...prevState,
                stagedDetailEvent: action.payload,
            }
        case 'eventsState.ClearStagedEvent':
            return {
                ...prevState,
                stagedDetailEvent: undefined,
            }
        default:
            assertUnreachable(action)
    }
    return prevState
}

// --- Actions ---

const actions = {
    SetLoadingState: (state: EventsState['eventsLoadingState']) => createAction('eventsState.SetLoadingState', state),
    SetError: (error: EventsState['error']) => createAction('eventsState.SetError', error),
    SetEvents: (events: EventViewModel[]) => createAction('eventsState.SetEvents', events),
    SetStagedEvent: (event: EventViewModel) => createAction('eventsState.SetStagedEvent', event),
    ClearStagedEvent: () => createAction('eventsState.ClearStagedEvent'),
}

const thunkActions = {
    FetchEvents(
        tenant: string,
    ): ThunkAction<Promise<EventViewModel[] | Response.ErrorResponse | Response.InternalServerError>> {
        return async dispatch => {
            dispatch(actions.SetLoadingState('loading'))

            const response = await getEvents(tenant)
            if (isArray(response)) {
                dispatch(actions.SetLoadingState('ready'))
                dispatch(actions.SetEvents(response))
                return response
            }

            dispatch(actions.SetLoadingState('error'))
            dispatch(actions.SetError(response.message))
            return response
        }
    },
    FetchAndStageEvent(
        code: string,
    ): ThunkAction<Promise<Response.Event | Response.ErrorResponse | Response.InternalServerError>> {
        return async dispatch => {
            dispatch(actions.SetLoadingState('loading'))
            const response = await getEvent(code)

            if (response.type === 'event') {
                dispatch(actions.SetLoadingState('ready'))
                dispatch(actions.SetStagedEvent(response))
                return response
            }
            dispatch(actions.SetLoadingState('error'))
            dispatch(actions.SetError(response.message))
            return response
        }
    },
}

export type ActionsType = ActionsUnion<typeof actions>
export default {...actions, ...thunkActions}
