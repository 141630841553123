import {useMemo, forwardRef, memo, useEffect} from 'react'
import Bowser from 'bowser'
import {shallowEqualObjects} from 'shallow-equal'

interface TileVideoProps {
    participantId: string
    videoTrack: any
}
export const TileVideo = memo(
    forwardRef(({participantId, videoTrack}: TileVideoProps, videoEl: React.ForwardedRef<HTMLVideoElement>) => {
        /**
         * Memo: Chrome >= 92?
         * See: https://bugs.chromium.org/p/chromium/issues/detail?id=1232649
         */
        const isChrome92 = useMemo(() => {
            const {browser, platform, os} = Bowser.parse(navigator.userAgent)
            return (
                browser.name === 'Chrome' &&
                parseInt(browser.version ?? '-1', 10) >= 92 &&
                (platform.type === 'desktop' || os.name === 'Android')
            )
        }, [])

        /**
         * Effect: Umount
         * Note: nullify src to ensure media object is not counted
         */
        useEffect(() => {
            const video = (videoEl as any).current
            if (!video) return
            // clean up when video renders for different participant
            video.srcObject = null
            if (isChrome92) video.load()
            return () => {
                // clean up when unmounted
                video.srcObject = null
                if (isChrome92) video.load()
            }
        }, [videoEl, isChrome92, participantId])

        /**
         * Effect: mount source (and force load on Chrome)
         */
        useEffect(() => {
            const video = (videoEl as any).current
            if (!video || !videoTrack) return
            video.srcObject = new MediaStream([videoTrack])
            if (isChrome92) video.load()
        }, [videoEl, isChrome92, videoTrack])

        return <video autoPlay muted playsInline ref={videoEl} />
    }),
    (p, n) => shallowEqualObjects(p, n),
)

export default TileVideo
