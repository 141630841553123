import {useEffect} from 'react'
import cn from 'classnames'
import {Speaker2 as SpeakerIcon} from '@styled-icons/fluentui-system-filled/Speaker2'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'

class SpeakerSelectProps {
    disabled?: boolean
}
export default function SpeakerSelect({disabled = false}: SpeakerSelectProps) {
    const {currentDevices, speakers, setSpeakersDevice, updateDeviceState} = useMediaDevices()

    useEffect(() => {
        updateDeviceState()
    }, [])

    if (!currentDevices || !speakers) return null

    const currentSpeakerId = speakers?.find(i => {
        if (!currentDevices?.speaker) {
            return false
        }
        return (currentDevices.speaker as MediaDeviceInfo)?.deviceId === i.deviceId
    })?.deviceId

    const setSpeaker = (deviceId: string) => {
        const newDevice = speakers.find(device => device.deviceId === deviceId)
        if (newDevice) {
            setSpeakersDevice(newDevice, true)
        }
    }

    return (
        <div className="form-control mt-2">
            <div className="input-group input-group-sm">
                <button className={cn('deviceButton btn btn-accent')} disabled={disabled}>
                    <SpeakerIcon width="1.5em" />
                </button>
                <select
                    className="select select-bordered"
                    value={currentSpeakerId}
                    onChange={e => setSpeaker(e.target.value)}
                    disabled={speakers.length === 0}
                >
                    <option disabled value="">
                        Select a Speaker
                    </option>
                    {speakers.map(speaker => {
                        const title = speaker.deviceId === 'default' ? `System ${speaker.label}` : speaker.label

                        return (
                            <option key={speaker.deviceId} value={speaker.deviceId}>
                                {title}
                            </option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
