import {useAppSelector} from '~/store'
import {useEffect, useState} from 'react'
import {getEventSessionBreakoutsCSVUrl, getEventSessionParticipantsCSVUrl, getEventSessions} from '~/api/eventsApi'
import styles from './Sessions.module.scss'
import {EventSessionViewModel} from 'shared-types/view-models'
import isArray from 'lodash/isArray'
import Skeleton from '~/components/common/Skeleton'
import {DateTime} from 'luxon'

type Result<T> = {type: 'success'; data: T} | {type: 'error'; error: string} | {type: 'loading'}

export default function Sessions() {
    const stagedDetailEvent = useAppSelector(state => state.eventsState.stagedDetailEvent!)
    const tenant = useAppSelector(state => state.identityState.tenant!)

    const [data, setData] = useState<Result<EventSessionViewModel[]>>({type: 'loading'})

    const fetchSessions = async () => {
        setData({type: 'loading'})
        const response = await getEventSessions(stagedDetailEvent.code, tenant.slug)
        if (isArray(response)) {
            setData({type: 'success', data: response})
        } else {
            setData({type: 'error', error: response.message})
        }
    }

    useEffect(() => {
        fetchSessions()
    }, [])

    if (data.type === 'loading') {
        return (
            <>
                <Skeleton className="h-6 mb-4" fullWidth />
                <Skeleton className="h-6 mb-4" fullWidth />
                <Skeleton className="h-6" fullWidth />
            </>
        )
    }

    if (data.type === 'error') {
        return <div className="text-red-500">{data.error}</div>
    }

    return (
        <div className={styles.sessions}>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Duration</th>
                        <th>Participants</th>
                        <th>Breakout Rounds</th>
                        <th>Download CSV</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map(session => {
                        // 🤢 Print the event length in a human-readable time eg. "1 hour, 12 minutes"
                        const eventDurationString = DateTime.fromJSDate(session.endDate!)
                            .diff(DateTime.fromJSDate(session.startDate!))
                            .shiftTo('hours', 'minutes', 'seconds')
                            .set({seconds: 0})
                            .shiftTo('hours', 'minutes')
                            .rescale()
                            .toHuman()

                        const uniqueUserIds = session.breakoutRooms
                            // Get a list of user IDs
                            .reduce<string[]>((acc, curr) => {
                                for (const participant of curr.participants) {
                                    if (!acc.includes(participant.userId)) {
                                        acc.push(curr.id)
                                    }
                                }
                                return acc
                            }, [])
                            // Make the list unique
                            .filter((value, index, array) => {
                                return array.indexOf(value) === index
                            })

                        const noBreakoutRounds = Math.max(0, ...session.breakoutRooms.map(room => room.roundNumber))

                        const participantsCSVUrl = getEventSessionParticipantsCSVUrl(
                            stagedDetailEvent.id,
                            session.id,
                            tenant.slug,
                        )
                        const breakoutsCSVUrl = getEventSessionBreakoutsCSVUrl(
                            stagedDetailEvent.id,
                            session.id,
                            tenant.slug,
                        )

                        return (
                            <tr key={session.id}>
                                <td>{session.startDate!.toLocaleDateString()}</td>
                                <td>{eventDurationString}</td>
                                <td>{uniqueUserIds.length}</td>
                                <td>{noBreakoutRounds ? noBreakoutRounds : '-'}</td>
                                <td>
                                    {uniqueUserIds.length > 0 ? (
                                        <details className="dropdown">
                                            <summary className="m-1 btn">Download CSV</summary>
                                            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                                                <li>
                                                    <a href={participantsCSVUrl} download>
                                                        Download Participants
                                                    </a>
                                                </li>
                                                {session.breakoutRooms.length > 0 && (
                                                    <li>
                                                        <a href={breakoutsCSVUrl} download>
                                                            Download Breakouts
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </details>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
