/**
 * Call Provider / Context
 * ---
 * Configures the general state of a Daily call, such as which features
 * to enable, as well as instantiate the 'call machine' hook responsible
 * fir the overaching call loop (joining, leaving, etc)
 */
import {createContext, useContext, useEffect, useState} from 'react'
import Bowser from 'bowser'
import {NetworkState, VideoQuality} from '../constants'
import {useNetworkState} from '../hooks/useNetworkState'
import {useDaily} from '@daily-co/daily-react'
import {DailyCall, DailyRoomInfo} from '@daily-co/daily-js'

interface CallContextState {
    callObject: DailyCall | undefined
    networkState: NetworkState
    showLocalVideo: boolean
    roomExp?: number
    enableRecording?: string
    videoQuality: VideoQuality
    setVideoQuality: (val: VideoQuality) => void
    setShowLocalVideo: (val: boolean) => void
    startCloudRecording: boolean
}
export const CallContext = createContext<CallContextState>({} as CallContextState)

export const CallProvider = ({children}: {children: React.ReactNode}) => {
    const [videoQuality, setVideoQuality] = useState<VideoQuality>(VideoQuality.AUTO)
    const [showLocalVideo, setShowLocalVideo] = useState(true)
    const [enableRecording, setEnableRecording] = useState<string>()
    const [startCloudRecording, setStartCloudRecording] = useState(false)
    const [roomExp, setRoomExp] = useState<number>()

    // Daily CallMachine hook (primarily handles status of the call)
    const daily = useDaily()!
    const networkState = useNetworkState(daily, videoQuality)

    // Feature detection taken from daily room object and client browser support
    useEffect(() => {
        if (!daily) return
        const updateRoomConfigState = async () => {
            const roomConfig = (await daily.room()) as any as DailyRoomInfo
            if (!roomConfig) return
            if (!roomConfig.config) return

            if (roomConfig?.config?.exp) {
                setRoomExp(roomConfig?.config?.exp * 1000 || Date.now() + 1 * 60 * 1000)
            }
            const browser = Bowser.parse(window.navigator.userAgent)
            const supportsRecording = browser.platform.type === 'desktop' && browser.engine.name === 'Blink'
            // recording and screen sharing is hidden in owner_only_broadcast for non-owners
            if (supportsRecording) {
                const recordingType = roomConfig?.tokenConfig?.enable_recording ?? roomConfig?.config?.enable_recording
                if (recordingType && ['local', 'cloud'].includes(recordingType)) {
                    setEnableRecording(recordingType)
                    setStartCloudRecording(roomConfig?.tokenConfig?.start_cloud_recording ?? false)
                }
            }
        }
        updateRoomConfigState()
    }, [daily])

    const result: CallContextState = {
        callObject: daily,
        networkState,
        showLocalVideo,
        roomExp,
        enableRecording,
        videoQuality,
        setVideoQuality,
        setShowLocalVideo,
        startCloudRecording,
    }
    return (
        <>
            <CallContext.Provider value={result}>{children}</CallContext.Provider>
        </>
    )
}

export const useCallState = () => useContext(CallContext)
