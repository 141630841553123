import cn from 'classnames'
import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {Close as CloseIcon} from '@styled-icons/material/Close'
import './AmplifierModal.scss'

interface AmplifierModalAction {
    label: string
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
    disabled?: boolean
    onClick: () => void
}

export interface AmplifierModalProps {
    isOpen: boolean
    onClose: () => void
    title?: string
    children: React.ReactNode
    size?: 'small' | 'large' | 'full' | 'xlarge'
    primaryAction?: AmplifierModalAction
    secondaryAction?: AmplifierModalAction
    theme?: 'dark' | 'light'
    showClose?: boolean
}

export default function AmplifierModal({
    isOpen = false,
    onClose,
    title,
    primaryAction,
    secondaryAction,
    children,
    size = 'small',
    theme = 'light',
    showClose = true,
}: AmplifierModalProps) {
    // Delay the modal hiding, to allow the content to display mid-transition

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={'modalWrap ' + theme} onClose={onClose} data-theme={theme}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="modalBg" />
                </Transition.Child>

                <div className="modalOverlay">
                    <Transition.Child
                        as="div"
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className={cn(`modalCard`, size, theme)}>
                            {showClose && (
                                <div className="closeButton">
                                    <button type="button" onClick={() => onClose()}>
                                        <span className="sr-only">Close</span>
                                        <CloseIcon aria-hidden="true" />
                                    </button>
                                </div>
                            )}

                            <div className="contentWrap">
                                <div className="modalContent">
                                    {title && (
                                        <Dialog.Title as="h3" className="modalTitle">
                                            {title}
                                        </Dialog.Title>
                                    )}
                                    <div className="mt-2 w-full">{children}</div>
                                </div>
                            </div>
                            {primaryAction || secondaryAction ? (
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {[primaryAction, secondaryAction].map((action, index) => {
                                        if (!action) return null
                                        return (
                                            <button
                                                key={index}
                                                className={`btn btn-${action.color}`}
                                                color={action.color || 'primary'}
                                                onClick={action.onClick}
                                                disabled={action.disabled}
                                            >
                                                {action.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
