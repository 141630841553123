import * as Response from 'shared-types/schema/response'
import * as Sentry from '@sentry/browser'

export const backendTarget = AMPLIFIER_ENV_INFO.backendTarget

class APIError extends Error {}

const dateRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/

const processRequest = async (request: Request) => {
    try {
        const response = await fetch(request, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-Version': String(AMPLIFIER_ENV_INFO.buildVersion),
                'X-Git-Hash': AMPLIFIER_ENV_INFO.buildGitHash,
            },
            cache: 'no-cache',
        })

        const text = await response.text()

        let json

        try {
            json = JSON.parse(text, (_, value) =>
                typeof value === 'string' && dateRegex.test(value) ? new Date(value) : value,
            )
        } catch {
            // Handle down below
        }

        if (!response.ok || !json) {
            if (json && json.type === 'error') {
                // Expected error
                return json
            }

            if (json && json.type === 'internal_server_error') {
                // Internal server error, log and return
                Sentry.captureException({
                    message: `API Internal Server Error ${request.method} ${request.url}`,
                    status: response.status,
                    statusText: response.statusText,
                    response: text,
                })
                return json
            }

            Sentry.captureException({
                message: `Failed API request ${request.method} ${request.url}`,
                url: request.url,
                method: request.method,
                status: response.status,
                statusText: response.statusText,
            })

            // Since the response is an error, and it doesn't look like a Response.Error or Response.InternalServerError,
            // Throw the error and let it bubble up to ErrorBoundary.tsx
            throw new APIError(`Failed API request ${request.method} ${request.url}`)
        }

        return json
    } catch (error) {
        if (!(error instanceof APIError) && error instanceof Error) {
            Sentry.captureException({
                ...error,
                message: `API Network error ${request.method} ${request.url} ${error.message}`,
            })
        }
        throw error
    }
}

const get = (url: string): Promise<object | Response.InternalServerError> => {
    return processRequest(
        new Request(backendTarget + '/api/v1' + url, {
            method: 'GET',
        }),
    )
}
const post = (url: string, body?: object): Promise<object | Response.InternalServerError> => {
    return processRequest(
        new Request(backendTarget + '/api/v1' + url, {
            method: 'POST',
            body: body ? JSON.stringify(body) : '',
        }),
    )
}
const put = (url: string, body: object): Promise<object | Response.InternalServerError> => {
    return processRequest(
        new Request(backendTarget + '/api/v1' + url, {
            method: 'PUT',
            body: body ? JSON.stringify(body) : '',
        }),
    )
}

export default {get, post, put}
