export default function EmptyParticipantsList({isLoading = false}) {
    return (
        <div className="emptyParticipantList">
            {isLoading ? (
                <span className="loading loading-spinner loading-lg text-primary"></span>
            ) : (
                <>
                    <h3>There's no one else here!</h3>
                    <p>Copy and share this page's URL to invite others.</p>
                    <p style={{userSelect: 'all'}}>{window.location.href}</p>
                </>
            )}
        </div>
    )
}
