/**
 * Audio
 * ---
 * When working with audio elements it's very important to avoid mutating
 * the DOM elements as much as possible to avoid audio pops and crackles.
 * This component addresses to known browser quirks; Safari autoplay
 * and Chrome's maximum media elements. On Chrome we add all audio tracks
 * into into a single audio node using the CombinedAudioTrack component
 */
import {useEffect, useMemo} from 'react'
import Bowser from 'bowser'
import AudioTrack from './AudioTrack'
import CombinedAudioTrack from './CombinedAudioTrack'
import {useTracks} from '../../../contexts/TracksProvider'
import {DailyTrackState} from '@daily-co/daily-js'

export const Audio = () => {
    const {audioTracks} = useTracks()

    const renderedTracks: Record<string, DailyTrackState> = useMemo(
        () =>
            Object.entries(audioTracks).reduce<Record<string, DailyTrackState>>(
                (tracks, [id, track]) => ({...tracks, [id]: track}),
                {},
            ),
        [audioTracks],
    )

    // On iOS safari, when headphones are disconnected, all audio elements are paused.
    // This means that when a user disconnects their headphones, that user will not
    // be able to hear any other users until they mute/unmute their mics.
    // To fix that, we call `play` on each audio track on all devicechange events.
    useEffect(() => {
        const playTracks = () => {
            document.querySelectorAll<HTMLAudioElement>('.audioTracks audio').forEach(async audio => {
                try {
                    if (audio.paused && audio.readyState === audio.HAVE_ENOUGH_DATA) {
                        await audio?.play()
                    }
                } catch (e) {
                    // Auto play failed
                }
            })
        }
        navigator.mediaDevices.addEventListener('devicechange', playTracks)
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', playTracks)
        }
    }, [])

    const tracksComponent = useMemo(() => {
        const {browser} = Bowser.parse(navigator.userAgent)
        if (browser.name === 'Chrome' && parseInt(browser.version ?? '-1', 10) >= 92) {
            return <CombinedAudioTrack tracks={renderedTracks} />
        }
        return Object.entries(renderedTracks).map(([id, track]) => (
            <AudioTrack key={id} track={(track as any).persistentTrack} />
        ))
    }, [renderedTracks])

    return (
        <div className="audioTracks">
            {tracksComponent}
            <style>{`
                    .audioTracks {
                        position: absolute;
                        visibility: hidden;
                    }
                `}</style>
        </div>
    )
}

export default Audio
