import {useEffect, useState} from 'react'
import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'
import {Warning as WarningIcon} from '@styled-icons/ionicons-solid/Warning'
import {Email as EmailIcon} from '@styled-icons/evaicons-solid/Email'
import {useNavigate} from 'react-router'
import {useAppDispatch, useAppSelector} from '../../store'
import actions from '../../store/identityState'
import {useLocation} from 'react-router-dom'
import BackButton from '../common/BackButton'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import validate from '~/utils/validate'

export default function ResetPassword() {
    const isAuthenticated = useAppSelector(state => state.identityState.isAuthenticated)

    const [success, setSuccess] = useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (isAuthenticated) {
            const {from} = (location.state as any) || {from: {pathname: '/'}}
            navigate(from, {replace: true})
        }
    }, [isAuthenticated])

    return (
        <>
            <BackButton />
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Reset Password</h2>
            <p className="mt-2 text-sm text-gray-600"> Enter your email to have a password reset link sent to you</p>

            {success ? (
                <div className="alert alert-success alert-sm mt-4 shadow-lg">
                    <div>
                        <EmailIcon size="3.5rem" />
                        <div>
                            <h5 className="font-bold">Success</h5>
                            <div className="text-md">
                                If we find an account using this email address an email with instructions to reset your
                                password has been sent
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validate={validate.generate({
                        email: [validate.required(), validate.email()],
                    })}
                    onSubmit={async (values, {setSubmitting, setStatus}) => {
                        setSubmitting(true)

                        const error = await dispatch(actions.ResetPassword(values))

                        if (error === true) {
                            setSuccess(true)
                            setSubmitting(false)
                            return
                        }

                        setStatus(error)
                        setSubmitting(false)
                    }}
                >
                    {({errors, touched, isSubmitting, status}) => (
                        <Form className="mt-6">
                            <label className="label label-text text-black" htmlFor="username">
                                Email
                            </label>
                            <Field
                                id="username"
                                name="username"
                                className={cn('input mt-1', {'input-error': errors.email && touched.email})}
                                type="text"
                            />
                            <p className="text-sm text-error">
                                <ErrorMessage className="text-sm text-error" name="username" />
                            </p>

                            <p className="text-sm text-error">
                                <ErrorMessage className="text-sm text-error" name="password" />
                            </p>

                            {status ? (
                                <div className="alert alert-error alert-sm mt-4 shadow-lg">
                                    <div>
                                        <WarningIcon size="1.5rem" />
                                        <div>
                                            <h5 className="font-bold">Error</h5>
                                            <div className="text-md">{status}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <button type="submit" className="btn btn-primary w-full mt-8" disabled={isSubmitting}>
                                Reset Password
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    )
}
