import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import App from './components/App'
import store from './store/index'
// @ts-ignore
import hotjar from './hotjar'
import './index.scss'
import * as Sentry from '@sentry/react'
import {HttpClient as HttpClientIntegration} from '@sentry/integrations'
import {createTRPCQueryClients, trpc} from './api/trpc'
import {QueryClientProvider} from '@tanstack/react-query'
import ErrorBoundary from './components/ErrorBoundary'

// only use sentry in prod
if (AMPLIFIER_ENV_INFO.mode === 'production') {
    Sentry.init({
        dsn: 'https://38065b455b3f402197b71c557c7e299b@o1169916.ingest.sentry.io/6263151',
        integrations: [
            new Sentry.BrowserTracing(),
            new HttpClientIntegration({
                failedRequestStatusCodes: [[400, 599]],
                failedRequestTargets: ['https://api.amplifier.community/'],
            }),
        ],
        tracesSampleRate: 1.0,
        attachStacktrace: true,
        environment: 'production',
        release: AMPLIFIER_ENV_INFO.buildVersion,
    })
    hotjar()
}

;(window as any).AMPLIFIER_ENV_INFO = AMPLIFIER_ENV_INFO

const {queryClient, trpcClient} = createTRPCQueryClients()

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
    <ErrorBoundary>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <App />
                </Provider>
            </QueryClientProvider>
        </trpc.Provider>
    </ErrorBoundary>,
)
