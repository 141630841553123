import {useEffect} from 'react'
import cn from 'classnames'
import {Videocam as VideoOnIcon} from '@styled-icons/material/Videocam'
import {VideocamOff as VideoOffIcon} from '@styled-icons/material/VideocamOff'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'

interface CameraButtonProps {
    isCamMuted: boolean
    onClick: () => void
    disabled?: boolean
}
export default function CameraButton({isCamMuted, onClick, disabled = false}: CameraButtonProps) {
    const {currentDevices, cams, setCamDevice, updateDeviceState} = useMediaDevices()

    useEffect(() => {
        updateDeviceState()
    }, [])

    if (!currentDevices || !cams) return null

    const currentCamId = cams?.find(i => {
        if (!currentDevices?.camera) {
            return false
        }
        return (currentDevices.camera as MediaDeviceInfo)?.deviceId === i.deviceId
    })?.deviceId

    const setCamera = (deviceId: string) => {
        const newDevice = cams.find(cam => cam.deviceId === deviceId)!
        setCamDevice(newDevice, true)
    }

    return (
        <div className="form-control mt-2">
            <div className="input-group input-group-sm">
                <button
                    className={cn('deviceButton btn btn-accent', {disabled: isCamMuted})}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {!isCamMuted ? <VideoOnIcon width="1.5em" /> : <VideoOffIcon width="1.5em" />}
                </button>
                <select
                    className="select select-bordered"
                    value={currentCamId ?? ''}
                    onChange={e => setCamera(e.target.value)}
                    disabled={cams.length === 0}
                >
                    <option disabled value="">
                        Select a Camera
                    </option>
                    {cams.map(camera => {
                        const title = camera.deviceId === 'default' ? `System ${camera.label}` : camera.label

                        return (
                            <option key={camera.deviceId} value={camera.deviceId}>
                                {title}
                            </option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
