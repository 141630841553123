import {IndexRouteProps, LayoutRouteProps, Navigate, Outlet, PathRouteProps} from 'react-router-dom'
import {useAppSelector} from '../../store'

export default function AuthRoute(props: PathRouteProps | LayoutRouteProps | IndexRouteProps): React.ReactElement {
    const identity = useAppSelector(state => state.identityState)

    if (!identity.isAuthenticated) {
        const next = encodeURIComponent(window.location.pathname + window.location.search)
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate
                to={{
                    pathname: '/login',
                    search: next ? `?next=${next}` : undefined,
                }}
                replace
            />
        )
    }

    return <Outlet />
}
