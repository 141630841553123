import {Link, useParams} from 'react-router-dom'
import {PastEventViewModel} from 'shared-types/view-models'
import {useEffect, useState} from 'react'
import {getPastEvent} from '~/api/eventsApi'
import AmplifierAvatar from '~/widgets/AmplifierAvatar'
import {DateTime, Duration} from 'luxon'
import {InformationCircle} from '@styled-icons/heroicons-solid/InformationCircle'
import {ExternalLinkOutline} from '@styled-icons/evaicons-outline/ExternalLinkOutline'
import {useAppSelector} from '~/store'
import {LinkedinSquare as LinkedinSquareIcon} from '@styled-icons/boxicons-logos/LinkedinSquare'
import {Twitter as TwitterIcon} from '@styled-icons/bootstrap/Twitter'

type Participant = PastEventViewModel['breakouts'][0]['participants'][0]

export default function PastEvent() {
    const {eventSessionId} = useParams()

    const participant = useAppSelector(state => state.identityState.user!)

    const [event, setEvent] = useState<PastEventViewModel>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetchPastEvents = async () => {
            setLoading(true)
            try {
                const response = await getPastEvent(eventSessionId!)
                if (response) {
                    setEvent(response)
                }
            } catch (e) {
                // TODO: Handle error
            }
            setLoading(false)
        }
        fetchPastEvents()
    }, [])

    if (loading || !event || !participant) {
        return <></>
    }
    return (
        <div className="w-full flex p-5 flex-col">
            <div className="-mx-4 pb-4  px-4 max-h-full   sm:mx-0 sm:rounded-lg  sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2  xl:pb-20 ">
                <div className="flex flex-col py-4">
                    <h2 className="text-base font-semibold leading-6 text-gray-900">{event.title}</h2>
                    <div className="text-xs text-gray-400">
                        {' '}
                        <a target="_blank" href={`/?room=${event.code}`} rel="noreferrer">
                            <p className="inline">
                                {window.location.host}/?room={event.code}
                            </p>
                            <ExternalLinkOutline className="h-3 w-3  text-gray-400" aria-hidden="true" />
                        </a>
                    </div>
                    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                        {event && (
                            <>
                                <div className="sm:pr-4">
                                    <dt className="inline text-gray-500">Started at</dt>{' '}
                                    <dd className="inline text-gray-700">
                                        <time dateTime="2023-23-01">
                                            {DateTime.fromJSDate(event.startDate).toLocaleString(
                                                DateTime.DATETIME_FULL,
                                            )}
                                        </time>
                                    </dd>
                                    <span className="text-gray-500"> for </span>
                                    <span className="inline text-gray-700">
                                        {event.endDate &&
                                            Duration.fromMillis(
                                                event.endDate.getTime() - event.startDate.getTime(),
                                            ).toFormat('hh:mm:ss')}
                                    </span>
                                </div>
                                <div className="mt-2 sm:mt-0 sm:pl-4">
                                    <dt className="inline text-gray-500">Joined At</dt>{' '}
                                    <dd className="inline text-gray-700">
                                        <time dateTime="2023-31-01">
                                            {event.leftAt
                                                ? DateTime.fromJSDate(event.joinedAt).toLocaleString(
                                                      DateTime.DATETIME_FULL,
                                                  )
                                                : 'Unknown'}
                                        </time>
                                    </dd>
                                    <span className="text-gray-500"> for </span>
                                    <span className="inline text-gray-700">
                                        {event.leftAt &&
                                            Duration.fromMillis(
                                                event.joinedAt.getTime() - event.leftAt.getTime(),
                                            ).toFormat('hh:mm:ss')}
                                    </span>
                                </div>
                            </>
                        )}
                    </dl>

                    {(participant.metaData!.linkedin_profile == null && participant.metaData!.twitter_handle == null) ||
                        (participant.metaData!.linkedin_profile === '' && participant.metaData!.twitter_handle === '' && (
                            <div className="w-full mt-10 ">
                                <div className="rounded-md bg-blue-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <InformationCircle className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3 flex-1 md:flex md:justify-between">
                                            <p className="text-sm text-blue-700">
                                                Add your LinkedIn or Twitter Handle so that those you met can find you
                                            </p>
                                            <p className="mt-3 text-sm md:ml-6 md:mt-0">
                                                <Link
                                                    to="/dashboard/profile"
                                                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                                                >
                                                    Add social media
                                                    <span aria-hidden="true"> &rarr;</span>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="overflow-y-scroll pb-4 h-full ">
                    {event.breakouts.map((breakout, index) => (
                        <div className="mt-6 border-t border-gray-900/5 col-span-2 pt-6 sm:pr-4" key={index}>
                            <dt className="font-semibold text-gray-900">Breakout {index + 1}</dt>

                            <ul className="divide-y divide-gray-100">
                                {breakout.participants.map((participant: Participant) => (
                                    <li key={participant.nickname} className="flex justify-between gap-x-6 py-5">
                                        <div className="flex gap-x-4">
                                            <AmplifierAvatar
                                                name={participant.nickname ?? ''}
                                                size="sm"
                                                className="w-12 h-12"
                                                src={participant.picture}
                                            />
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    {participant.nickname}
                                                </p>
                                                <p className="-mt-1">
                                                    {participant.metaData['title'] &&
                                                    participant.metaData['company'] ? (
                                                        <span className="text-sm leading-6 text-gray-900">
                                                            {participant.metaData['title']} at{' '}
                                                            {participant.metaData['company']}
                                                        </span>
                                                    ) : (
                                                        <span className="text-sm leading-6 text-gray-900 ">
                                                            No title or company
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-6">
                                            <div className="hidden sm:flex sm:flex-col sm:items-end">
                                                <p className="text-sm gap-2 leading-6 text-gray-900">
                                                    {participant.metaData.linkedin_profile && (
                                                        <a
                                                            target="_blank"
                                                            href={participant.metaData.linkedin_profile}
                                                            className="text-blue-600 mx-2 hover:text-blue-500"
                                                            rel="noreferrer"
                                                        >
                                                            <LinkedinSquareIcon className="w-4 h-4" />
                                                        </a>
                                                    )}

                                                    {participant.metaData.twitter_handle && (
                                                        <a
                                                            target="_blank"
                                                            href={`https://twitter.com/${participant.metaData.twitter_handle}`}
                                                            className="text-blue-500 hover:text-blue-500"
                                                            rel="noreferrer"
                                                        >
                                                            <TwitterIcon className="w-4 h-4" />
                                                        </a>
                                                    )}
                                                </p>
                                                {participant.joinedAt && participant.leftAt ? (
                                                    <p className="mt-1 text-xs leading-5 text-gray-500">
                                                        Joined At{' '}
                                                        <time dateTime={new Date(participant.joinedAt).toDateString()}>
                                                            {DateTime.fromJSDate(participant.joinedAt).toLocaleString(
                                                                DateTime.DATETIME_FULL,
                                                            )}
                                                        </time>
                                                    </p>
                                                ) : (
                                                    <div className="mt-1 flex items-center gap-x-1.5">
                                                        <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                            <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                                        </div>
                                                        <p className="text-xs leading-5 text-gray-500">Online</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
