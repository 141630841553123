import {useEffect, useMemo, useState} from 'react'
import {PlanViewModel} from 'shared-types/view-models/EventModels'
import {getPlans} from '~/api/billingApi'
import Loading from '~/components/common/Loading'
import {useAppDispatch, useAppSelector} from '~/store'
import './Payment.scss'
import actions from '~/store/identityState'
import {useNavigate} from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import PaymentForm from '../../common/PaymentForm'
import {motion} from 'framer-motion'
import isArray from 'lodash/isArray'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})
//@ts-ignore
const stripePromise = loadStripe(AMPLIFIER_ENV_INFO.stripePublicKey)

export default function Payment() {
    const [plans, setPlans]: [PlanViewModel[], any] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const tenant = useAppSelector(state => state.identityState.tenant!)

    const plan = useMemo(() => {
        return plans.find(p => p.id === tenant.billing?.staged_plan)!
    }, [plans])

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const goBack = async () => {
        setLoading(true)

        navigate('/start/plan')
    }

    useEffect(() => {
        const fetchPlans = async () => {
            let response = await getPlans()

            if (isArray(response)) {
                setLoading(false)
                setPlans(response)
            } else if (response.type === 'error' || response.type === 'internal_server_error') {
                setLoading(false)
                setError(response.message)
            }
        }

        fetchPlans()
    }, [])

    const handlePaymentMethod = async (paymentMethodId: string, email: string) => {
        setLoading(true)

        const error = await dispatch(actions.AddPaymentMethod(paymentMethodId, email, tenant.slug))
        if (error) {
            setError(error)
            setLoading(false)
        }
        navigate('/start/event')
    }

    if (loading) {
        return (
            <div className="ob__payment__wrapper">
                <div className="ob__loading__wrapoer">
                    <Loading />
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="ob__payment__wrapper">
                <div className="ob__payment__error">
                    <h1>{error}</h1>
                    <p>We're sorry there was an error, please try again</p>
                    <button
                        onClick={() => setError('')}
                        className="btn btn-primary bg-white border-white my-4 hover:bg-white hover:border-white"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        )
    }

    return (
        <motion.div layout className="ob__payment__wrapper">
            <motion.div
                layout
                initial={{opacity: 0, scale: 0.8}}
                animate={{opacity: 1, scale: 1}}
                transition={{
                    duration: 0.5,
                    delay: 0,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
                className="ob__payment__header"
            >
                <h1>Add Payment Method</h1>
                <p>Enter your payment details to continue</p>
            </motion.div>

            <motion.div
                className="ob__payment__content max-w-full md:max-w-xl w-full"
                initial={{opacity: 0, scale: 0.8}}
                animate={{opacity: 1, scale: 1}}
                transition={{
                    duration: 0.5,
                    delay: 0,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <div className="ob__payment__plan">
                    <div>
                        <span className="ob__payment__name">{plan.name}</span>
                        <span className="ob__payment__desc">
                            {plan.lineItems.map(e => (
                                <span>
                                    {e}
                                    <br></br>
                                </span>
                            ))}
                        </span>
                    </div>
                    <div>
                        <span className="ob__payment__price">{formatter.format(plan.price / 100)}</span>
                    </div>
                </div>

                <div className="ob__payment__card w-full">
                    <Elements stripe={stripePromise}>
                        <PaymentForm handlePaymentMethod={handlePaymentMethod} />
                    </Elements>
                </div>
            </motion.div>

            {error && (
                <div className="text-red-500 text-sm mt-2">
                    <p>{error}</p>
                </div>
            )}

            <button className="btn mt-4" onClick={() => goBack()}>
                Choose Another Plan
            </button>
        </motion.div>
    )
}
