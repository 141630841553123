import {useDaily, useLocalParticipant} from '@daily-co/daily-react'
import cn from 'classnames'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {ABGroup, Hashtag} from 'shared-types/view-models/SocketModels'
import goldLogo from '../../../assets/public/gold-logo.png'
import {useLobbySocket} from '../../../contexts/LobbySocketProvider'
import {useAppDispatch, useAppSelector} from '../../../store'
import actions from '../../../store/eventState'
import {isDeviceBlocked} from '../../../utils/isDeviceBlocked'
import Loading from '../../common/Loading'
import HashtagInput from '../Common/HashtagInput'
import HashtagPills from '../Common/HashtagPills'
import Haircheck from '../Haircheck/Haircheck'
import {AppState} from '../VideoPlatform'
import RequestPermissions from './RequestPermissions'
import ProfileMenu from '../../common/ProfileMenu/ProfileMenu'
import validate from '~/utils/validate'
import './Lobby.scss'
import {isIOS} from 'react-device-detect'
import {DailyEventObjectCameraError} from '@daily-co/daily-js'

interface LobbyProps {
    joinCall: () => void
    startHaircheck: () => void
    appState: AppState
    startLeavingCall: () => void
}
export default function Lobby({joinCall, startHaircheck, appState, startLeavingCall}: LobbyProps) {
    const daily = useDaily()
    const localParticipant = useLocalParticipant()

    const [cameraError, setCameraError] = useState('')

    useEffect(() => {
        daily?.on('camera-error', e => handleCameraError(e!))

        return () => {
            daily?.off('camera-error', e => handleCameraError(e!))
        }
    }, [daily])

    const handleCameraError = (e: DailyEventObjectCameraError) => {
        setCameraError(e.error.msg)
    }

    const [continueWithoutPermissions, updateContinueWithoutPermissions] = useState(false)

    const isDailyLoading = daily?.meetingState() === 'loading'

    const isVideoBlocked = isDeviceBlocked('video', localParticipant?.tracks?.video)
    const isAudioBlocked = isDeviceBlocked('audio', localParticipant?.tracks?.audio)

    const removed = useAppSelector(({eventState}) => eventState.removed)

    const showHaircheck = appState === 'haircheck' && !isVideoBlocked && !isAudioBlocked && !isIOS

    const lobbyStateMachine = () => {
        if (removed) {
            return <Removed />
        }

        if (isDailyLoading) {
            return <Loading />
        }

        if (!daily) {
            return <EventCodeForm startHaircheck={startHaircheck} />
        }

        if ((isVideoBlocked || isAudioBlocked || cameraError != '') && !continueWithoutPermissions)
            return (
                <RequestPermissions
                    cameraError={cameraError}
                    updateContinueWithoutPermissions={updateContinueWithoutPermissions}
                />
            )

        return <LobbyForm joinCall={joinCall} />
    }

    return (
        <div className="lobbyWrapper">
            <div className="leftCard" data-theme="light">
                <div className="leftBox">{lobbyStateMachine()}</div>
            </div>

            <ProfileMenu startLeavingCall={startLeavingCall}></ProfileMenu>

            <div className={cn('rightCard', {withHaircheck: showHaircheck})}>
                {showHaircheck ? <Haircheck /> : null}
            </div>
        </div>
    )
}

const Removed = () => {
    return (
        <div className="rounded-md bg-red-100 p-4">
            <div className="flex">
                <div className="flex-shrink-0"></div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-600">The host removed you from the event.</h3>
                    <span>You won't be able to rejoin this session.</span> <br />
                    <span>If you think there has been an error, please contact the host.</span>
                </div>
            </div>
        </div>
    )
}

interface EventCodeFormProps {
    startHaircheck: () => void
}
const EventCodeForm = ({startHaircheck}: EventCodeFormProps) => {
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector(({eventState}) => eventState.eventLoadingState === 'loading')

    return (
        <>
            <Link className="hostEventButton" to="/dashboard">
                Go to Dashboard
            </Link>
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <p className="mt-2 text-sm text-gray-600">Before you continue to your event</p>
            <h2>Enter your event code</h2>

            <div className="mt-6">
                <Formik
                    initialValues={{
                        eventCode: '',
                    }}
                    validate={validate.generate({
                        eventCode: [validate.required()],
                    })}
                    onSubmit={async (values, {setSubmitting, setErrors}) => {
                        setSubmitting(true)
                        const error = await dispatch(actions.FetchEvent(values.eventCode))
                        if (error) {
                            setSubmitting(false)
                            setErrors({
                                eventCode: error,
                            })
                            return
                        }
                        startHaircheck()
                        return
                    }}
                >
                    {({errors, isValidating, isSubmitting}) => (
                        <Form className="mt-6">
                            <label className="label label-text text-black" htmlFor="eventCode">
                                Event Code
                            </label>
                            <Field name="eventCode" className={cn('input my-1', {'input-error': errors.eventCode})} />
                            <p className="text-sm text-error">
                                <ErrorMessage name="eventCode" />
                            </p>

                            <button
                                type="submit"
                                className={cn('btn btn-primary w-full mt-4')}
                                disabled={isLoading || isValidating || isSubmitting}
                            >
                                {(isValidating || isSubmitting || isLoading) && (
                                    <span className="loading loading-spinner"></span>
                                )}
                                Continue
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

interface LobbyFormProps {
    joinCall: () => void
}
const LobbyForm = ({joinCall}: LobbyFormProps) => {
    const dispatch = useAppDispatch()
    const event = useAppSelector(({eventState}) => eventState.event!)
    const user = useAppSelector(({identityState}) => identityState.user!)
    const {hashtags} = useLobbySocket()

    const enableGroups = event!.settings.enableAtoB || false

    return (
        <>
            <Formik
                initialValues={{
                    name: `${user.given_name} ${user.family_name}`,
                    title: '',
                    company: '',
                    group: (enableGroups ? 'A' : undefined) as ABGroup,
                    hashtags: [] as Hashtag[],
                    newHashtag: '',
                }}
                validate={validate.generate({
                    name: [validate.required('Name is required')],
                    title: [validate.required('Title is required')],
                    company: [validate.required('Company is required')],
                    group: enableGroups ? [validate.required('Please choose a group')] : [],
                })}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true)
                    dispatch(
                        actions.SetAttendeeData({
                            ...values,
                        }),
                    )
                    joinCall()
                    return
                }}
            >
                {({errors, isSubmitting, setFieldValue, values}) => (
                    <Form className="mt-6">
                        <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
                        <h2>Before you join {event.title}</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            First, let other attendees know some things about you
                        </p>
                        <div className="mt-6">
                            <FieldWrap name="name" label="Name">
                                <Field name="name" className={cn('input mt-1', {'input-error': errors.name})} />
                            </FieldWrap>
                            <FieldWrap name="title" label="Title">
                                <Field name="title" className={cn('input mt-1', {'input-error': errors.title})} />
                            </FieldWrap>
                            <FieldWrap name="company" label="Company">
                                <Field name="company" className={cn('input mt-1', {'input-error': errors.company})} />
                            </FieldWrap>
                            {enableGroups ? (
                                <FieldWrap name="group" label="Group">
                                    <Field
                                        name="group"
                                        as="select"
                                        className={cn('input mt-1', {'input-error': errors.group})}
                                    >
                                        <option value={'A' as ABGroup}>{event.settings.groupOneLabel}</option>
                                        <option value={'B' as ABGroup}>{event.settings.groupTwoLabel}</option>
                                    </Field>
                                </FieldWrap>
                            ) : null}
                            <FieldWrap name="hashtags" label="Hashtags">
                                <HashtagInput
                                    addHashtag={newHashtag =>
                                        setFieldValue('hashtags', [...values.hashtags, newHashtag])
                                    }
                                    selectedHashtags={values.hashtags}
                                    hashtags={[...values.hashtags, ...hashtags]}
                                />
                                <HashtagPills
                                    className="my-2"
                                    selectedHashtags={values.hashtags}
                                    setSelectedHashtags={(hashtags: Hashtag[]) => {
                                        setFieldValue('hashtags', hashtags)
                                    }}
                                    hashtags={[...values.hashtags, ...hashtags]}
                                />
                            </FieldWrap>
                            <button type="submit" className="btn btn-primary w-full mt-8" disabled={isSubmitting}>
                                Continue
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export const FieldWrap = ({name, label, children}: {name: string; label: string; children: React.ReactNode}) => {
    return (
        <>
            <label className="label label-text text-black" htmlFor={name}>
                {label}
            </label>
            {children}
            <p className="text-sm text-error">
                <ErrorMessage name={name} />
            </p>
        </>
    )
}
