import Participant from './Participant'
import {useAppSelector} from '~/store'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import './BreakoutRoom.scss'

interface BreakoutRoomProps {
    channel: string[]
    channelIndex: number
    setMoveParticipant: (o: any) => void
}

export default function BreakoutRoom({channel, channelIndex, setMoveParticipant}: BreakoutRoomProps) {
    const {allParticipants} = useAmplifierSocket()

    const {groupOneLabel, groupTwoLabel} = useAppSelector(({eventState}) => eventState.event!.settings)

    return (
        <ul className="breakoutRoom">
            <h6 className="block text-md font-medium text-white mb-2 mt-2">
                {channelIndex === 0 ? 'Home Room' : 'Breakout ' + channelIndex}
            </h6>
            <div className="w-full h-full overflow-hidden   flex flex-col justify-between ">
                <div className="draggableContainer ">
                    {channel?.map(id => {
                        if (allParticipants[id] === null || allParticipants[id] === undefined) return <></>

                        return (
                            <Participant
                                inTeam={false}
                                participant={allParticipants[id]}
                                setMoveParticipant={setMoveParticipant}
                                participantGroupLabel={
                                    !allParticipants[id].group
                                        ? undefined
                                        : allParticipants[id].group === 'A'
                                        ? groupOneLabel
                                        : groupTwoLabel
                                }
                            />
                        )
                    })}
                </div>
            </div>
        </ul>
    )
}
