import {useState} from 'react'
import cn from 'classnames'
import goldLogo from '../../assets/public/gold-logo.png'

import {useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'
import {confirmTenantDeletion} from '~/api/tenantApi'

export default function ConfirmTenantDeletion() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')

    const [searchParams] = useSearchParams()

    const token = searchParams.get('t')
    const tenantSlug = searchParams.get('s')

    const navigate = useNavigate()

    if (!token) return null

    const handleConfirm = async () => {
        const response = await confirmTenantDeletion(token)

        if (response.type === 'error' || response.type === 'internal_server_error') {
            setError(response.message)
            return
        }

        setSuccess(true)
    }

    return (
        <>
            <img className="h-24 w-auto" src={goldLogo} alt="Amplifier" />
            <h2>Confirm Tenant Deletion</h2>
            <p className="mt-2 text-sm text-gray-600">Please confirm that you wish to delete {tenantSlug}</p>
            {error && success === false && (
                <div className="alert alert-error alert-sm mt-8 shadow-lg">
                    <div>
                        <div>
                            <h5 className="font-bold">Error</h5>
                            <div className="text-md">{error}</div>
                        </div>
                    </div>
                </div>
            )}
            {success && error === '' ? (
                <div className="alert alert-success alert-sm mt-4 shadow-lg">
                    <div>
                        <div>
                            <h5 className="font-bold">Successfully deleted</h5>
                            <div className="text-md">
                                You've sucessfully deleted the host account {tenantSlug}. You may now close this tab.
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="">
                    {error === '' && (
                        <button
                            type="submit"
                            onClick={() => handleConfirm()}
                            className={cn('btn btn-primary w-full mt-16')}
                        >
                            Confirm Deletion
                        </button>
                    )}

                    <button
                        className="btn btn-primary bg-transparent w-full mt-2 border-none hover:bg-gray-300"
                        onClick={() => navigate('/dashboard')}
                    >
                        Back to Dashboard
                    </button>
                </div>
            )}
        </>
    )
}
