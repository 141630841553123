import {useDaily} from '@daily-co/daily-react'
import {useDeepCompareMemo} from 'use-deep-compare'
import {useParticipants} from '../../../contexts/ParticipantsProvider'
import {useMediaDevices} from '../../../contexts/MediaDeviceProvider'
import CameraSelect from '../Haircheck/CameraSelect'
import Tile from '../Tile/Tile'
import './VideoSettings.scss'
import BackgroundFilter from './BackgroundFilter'

export default function VideoSettings() {
    const daily = useDaily()!
    const {localParticipant} = useParticipants()
    const {isCamMuted, updateDeviceState} = useMediaDevices()

    const toggleCamera = () => {
        daily.setLocalVideo(isCamMuted)
        updateDeviceState()
    }

    const tileMemo = useDeepCompareMemo(
        () => <Tile participant={localParticipant} showName={false} />,
        [localParticipant],
    )

    return (
        <div className="videoSettings">
            <div className="settingsTile">{tileMemo}</div>
            <CameraSelect isCamMuted={isCamMuted} onClick={toggleCamera} />
            <div className="backgroundFilter">
                <h4>Background Filter</h4>
                <BackgroundFilter />
            </div>
        </div>
    )
}
