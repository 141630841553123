import {DateTime} from 'luxon'
import {useSettings} from '../SettingsWrap'
import {useEffect, useState} from 'react'
import {getUsage} from '~/api/tenantApi'
import {useAppSelector} from '~/store'
import UsageTable from './UsageTable'
import Skeleton from '~/components/common/Skeleton'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
})

export default function Usage() {
    const {settings} = useSettings()
    const tenant = useAppSelector(state => state.identityState.tenant!)

    // Fetch usage records
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [records, setRecords] = useState([])

    const fetchUsageRecords = async (start: number, end: number) => {
        setLoading(true)
        let p = await getUsage(tenant!.slug, start, end)

        if (!(p instanceof Array)) {
            // error occured
            setError(p['message'])
        }

        setLoading(false)
        //@ts-ignore
        setRecords(p)
    }

    useEffect(() => {
        if (settings?.period_end && settings?.period_start) {
            fetchUsageRecords(settings.period_start, settings.period_end)
        }
    }, [settings])

    if (!settings?.usage) {
        return (
            <div className="container grid grid-cols-6 ">
                <div className="section col-span-6 mb-8 px-8   py-8">
                    <span>No Usage Records found. Are you subscribed to a plan?</span>
                </div>
            </div>
        )
    }

    const used_percentage = settings.usage
        ? (
              (settings?.usage.minutes_used > settings?.usage.free_minutes
                  ? settings?.usage.free_minutes
                  : settings?.usage.minutes_used / settings?.usage.free_minutes) * 100
          ).toFixed(2)
        : 0

    const used_free_minutes =
        settings?.usage.minutes_used > settings?.usage.free_minutes
            ? settings?.usage.free_minutes
            : settings?.usage.minutes_used

    const used_excess_hours =
        settings?.usage.minutes_used > settings?.usage.free_minutes
            ? settings?.usage.minutes_used - settings?.usage.free_minutes
            : 0

    return (
        <div className="container grid grid-cols-6 ">
            <div className="section col-span-6 mb-8 px-8  border-b py-8">
                <h1 className="font-semibold">Usage</h1>
                <span className="text-gray-600">See how many minutes you've used this period</span>
            </div>

            <div className="section col-span-6 mb-8 px-8">
                <div className="period__title flex flex-row justify-between items-center font-semibold">
                    <h3>
                        Overview this period
                        <div className="ml-2 px-4 bg-gray-200 text-gray-500  h-7 text-sm font-semibold inline-flex flex-wrap items-center select-none justify-center text-center rounded-md  border-none bg-opacity-50">
                            {settings?.plan.name}
                        </div>
                    </h3>
                    <span className="text-sm  text-gray-600 font-semibold">
                        {settings && (
                            <span>
                                {DateTime.fromSeconds(settings.period_start).toLocaleString()} -{' '}
                                {DateTime.fromSeconds(settings.period_end).toLocaleString()}
                            </span>
                        )}

                        {!settings && <Skeleton className="w-48 h-2" />}
                    </span>
                </div>

                <dl className="mx-auto w-full col-span-6 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white col-span-2  py-10 ">
                        <dt className="text-sm font-medium leading-6 text-gray-500">Included Participant Minutes</dt>
                        <dd className="text-xs text-gray-700 font-medium">
                            {!settings && <Skeleton className="w-4 h-2" />}
                            {settings && used_percentage + '%'}
                        </dd>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                            {settings && (
                                <span>
                                    {used_free_minutes}/{settings?.usage.free_minutes}
                                </span>
                            )}

                            {!settings && <Skeleton className="w-20 h-6" />}
                        </dd>

                        <div className="w-full flex-none overflow-hidden   bg-gray-300 rounded-lg ">
                            <div style={{width: used_percentage + '%'}} className="progress  bg-primary w-1/2"></div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 col-span-1 bg-white  py-10 ">
                        <dt className="text-sm font-medium leading-6 text-gray-500">Excess Participant Minutes</dt>
                        <dd className="text-xs text-gray-700 font-medium">
                            {!settings && <Skeleton className="w-4 h-2" />}
                            {settings && formatter.format(settings?.usage.minute_price_after / 100) + `/hour`}
                        </dd>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                            {used_excess_hours}
                        </dd>

                        <div className="w-full flex-none opacity-0   bg-gray-300 rounded-lg ">
                            <div className="progress bg-primary w-1/2"></div>
                        </div>
                    </div>
                </dl>
            </div>

            <div className="section col-span-6 mb-8 px-8">
                <div className="period__title flex flex-row justify-between items-center font-semibold">
                    <h3>Usage Records </h3>
                    <span className="text-sm  text-gray-600 font-semibold">
                        {settings && (
                            <span>
                                {DateTime.fromSeconds(settings.period_start).toLocaleString()} -{' '}
                                {DateTime.fromSeconds(settings.period_end).toLocaleString()}
                            </span>
                        )}
                    </span>
                </div>

                <div className="section__content border rounded-md mt-8">
                    <UsageTable loading={loading} usageRecords={records} />

                    {error && (
                        <div className="text-red-500 error text-sm mt-2">
                            <p className="text-red-500">{error}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
