export function RawLogo({className}: {className: string}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.12 158.37">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" className="text-red" data-name="Layer 1">
                    <path
                        className="cls-1"
                        color="red"
                        d="M138.91,0c-18.5,0-33.5,12.39-33.5,27.68V158.37h22c.06-14.35,0-71.08,0-84.3V29.21c0-6,5.42-10.93,12.1-10.93,5.47,0,9.9,4,9.9,8.94V74.07c.06,12.93,0,70.38,0,84.3h23V27.68C172.41,12.39,157.42,0,138.91,0Z"
                    />
                    <path
                        className="cls-1"
                        d="M191.88,143.68c5.8.29,14.51.56,14.53-7.47V22.41c.1-7-7.44-8.08-12.88-7.47a9.13,9.13,0,0,0-9.13,9.13V136.21A7.48,7.48,0,0,0,191.88,143.68Z"
                    />
                    <path
                        className="cls-1"
                        d="M77.88,143.68c5.8.29,14.51.56,14.53-7.47V22.41c.1-7-7.44-8.08-12.88-7.47a9.13,9.13,0,0,0-9.13,9.13V136.21A7.48,7.48,0,0,0,77.88,143.68Z"
                    />
                    <path
                        className="cls-1"
                        d="M42.88,118.17c5.8.3,14.52.55,14.53-7.47V47.92c0-7.12-7.32-8-12.88-7.46a9.12,9.12,0,0,0-9.13,9.12V110.7A7.48,7.48,0,0,0,42.88,118.17Z"
                    />
                    <path
                        className="cls-1"
                        d="M226.88,118.17c5.8.3,14.52.55,14.53-7.47V47.92c0-7.12-7.32-8-12.88-7.46a9.12,9.12,0,0,0-9.13,9.12V110.7A7.48,7.48,0,0,0,226.88,118.17Z"
                    />
                    <path
                        className="cls-1"
                        d="M156.87,119c5.78.3,14.53.58,14.54-7.45V47.06c.1-7-7.43-8.05-12.86-7.45a9.15,9.15,0,0,0-9.15,9.14v62.81A7.45,7.45,0,0,0,156.87,119Z"
                    />
                    <path
                        className="cls-1"
                        d="M277.12,79.31c-.06,14.71-22.42,14.71-22.49,0S277.06,64.6,277.12,79.31Z"
                    />
                    <path className="cls-1" d="M22.49,79.31C22.42,94,.06,94,0,79.31S22.43,64.6,22.49,79.31Z" />
                    <path
                        className="cls-1"
                        d="M167.47,82.9c.3-5.79.54-14.51-7.47-14.53H122.59c-7.13,0-8,7.32-7.47,12.87a9.13,9.13,0,0,0,9.13,9.13H160A7.47,7.47,0,0,0,167.47,82.9Z"
                    />
                    <path
                        className="cls-1"
                        d="M112.87,118.23c5.78.29,14.53.57,14.54-7.46V47.85c0-7.12-7.31-8-12.86-7.46a9.15,9.15,0,0,0-9.15,9.15v61.23A7.46,7.46,0,0,0,112.87,118.23Z"
                    />
                </g>
            </g>
        </svg>
    )
}
