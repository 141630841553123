import {RouterInputs, trpcFetch} from './trpc'

export async function login({username, password}: {username: string; password: string}) {
    return await trpcFetch.identity.login.mutate({username, password})
}

export async function fetchMe() {
    const response = await trpcFetch.identity.me.query()
    return response
}

export async function updateMetaData(input: RouterInputs['identity']['updateMe']) {
    return await trpcFetch.identity.updateMe.mutate(input)
}

export async function resetPassword(input: RouterInputs['identity']['resetPassword']) {
    return trpcFetch.identity.resetPassword.mutate(input)
}

export async function setPassword(input: RouterInputs['identity']['setPassword']) {
    return trpcFetch.identity.setPassword.mutate(input)
}

export async function signUp(input: RouterInputs['identity']['signUp']) {
    return trpcFetch.identity.signUp.mutate(input)
}

export async function requestEmailVerification() {
    return trpcFetch.identity.requestVerifyEmail.mutate()
}
