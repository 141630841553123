import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {useAppSelector} from '~/store'
import {breakoutTimeOptions} from '~/utils/breakoutTimeOptions'
import './BreakoutSettings.scss'

export default function BreakoutSettings() {
    const {hostSettings, homeRoomMode} = useAppSelector(({amplifierSocket}) => amplifierSocket)
    const {updateRoomSettings} = useAmplifierSocket()

    const handleBreakoutTimeChange = (value: number) => {
        updateRoomSettings({
            hostSettings: {
                breakoutMode: hostSettings?.breakoutMode ?? '1:1',
                breakoutGroupSize: hostSettings?.breakoutGroupSize ?? 2,
                ...hostSettings,
                breakoutTime: value,
            },
            homeRoomMode: homeRoomMode,
        })
    }
    return (
        <div className="mt-4">
            <span className="text-base font-medium text-white">Breakout Settings</span>
            <div className="flex h-16  items-center">
                <select
                    className="select select-primary focus:border-none  focus:ring-primary focus:border-primary ring-primary focus:outline-none outline-offset-2 focus:ring-2 bg-neutral  w-full border-gray-800 max-w-xs"
                    value={hostSettings?.breakoutTime}
                    onChange={e => handleBreakoutTimeChange(parseInt(e.target.value))}
                >
                    <option disabled>Select a Breakout Length</option>
                    {breakoutTimeOptions.map((minutes, index) => (
                        <option key={index} value={minutes}>
                            {minutes % 1 === 0 ? minutes + ' minutes' : minutes * 60 + ' seconds'}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
