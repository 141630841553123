import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {useState} from 'react'
import PaymentForm from '~/components/common/PaymentForm'
import {useAppDispatch, useAppSelector} from '~/store'
import AmplifierModal from '~/widgets/AmplifierModal'
import actions from '~/store/identityState'
import clsx from 'clsx'
import LoadingSvg from '~/assets/svgs/loading.svg'

interface NewPaymentMethodProps {
    isOpen: boolean
    onClose: (updated: boolean) => void
}

//@ts-ignore
const stripePromise = loadStripe(AMPLIFIER_ENV_INFO.stripePublicKey)

export default function NewPaymentMethod({isOpen, onClose}: NewPaymentMethodProps) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const tenant = useAppSelector(state => state.identityState.tenant!)
    const dispatch = useAppDispatch()

    const handlePaymentMethod = async (paymentMethodId: string, email: string) => {
        setLoading(true)

        const billing = await dispatch(actions.AddPaymentMethod(paymentMethodId, email, tenant.slug))

        if (typeof billing === 'string') {
            setError(billing)
            setLoading(false)
            return
        }

        setLoading(false)
        onClose(true)
    }

    return (
        <>
            <AmplifierModal showClose={false} size="small" theme="light" isOpen={isOpen} onClose={() => onClose(false)}>
                {loading && (
                    <div
                        className={clsx(
                            'absolute flex items-center justify-center w-full h-full bg-white -mt-7 -ml-4 rounded-md z-50',
                        )}
                    >
                        <LoadingSvg className="w-12" />
                    </div>
                )}
                <div className="text-lg font-semibold mb-4">
                    <label>Update Payment Method</label>
                </div>

                <Elements stripe={stripePromise}>
                    <PaymentForm buttonText="Add Card" theme="light" handlePaymentMethod={handlePaymentMethod} />
                </Elements>

                {error && (
                    <div className="text-red-500 error text-sm mt-2">
                        <p className="text-red-500">{error}</p>
                    </div>
                )}
            </AmplifierModal>
        </>
    )
}
