import {useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router'
import {useAppSelector} from '../../store'
import {useLocation} from 'react-router-dom'

export default function LoginWrapper() {
    const isAuthenticated = useAppSelector(state => state.identityState.isAuthenticated)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (isAuthenticated) {
            const {from} = (location.state as any) || {from: {pathname: '/'}}
            navigate(from)
        }
    }, [isAuthenticated])

    return (
        <div className="lobbyWrapper">
            <div className="leftCard" data-theme="light">
                <div className="leftBox">
                    <Outlet />
                </div>
            </div>
            <div className="rightCard"></div>
        </div>
    )
}
