import {DailyCall, DailyEventObject, DailyEventObjectCameraError} from '@daily-co/daily-js'
import {useState, useCallback, useEffect} from 'react'
import {sortByKey} from '../utils/sortByKey'

export const DEVICE_STATE_LOADING = 'loading'
export const DEVICE_STATE_PENDING = 'pending'
export const DEVICE_STATE_ERROR = 'error'
export const DEVICE_STATE_GRANTED = 'granted'
export const DEVICE_STATE_NOT_FOUND = 'not-found'
export const DEVICE_STATE_NOT_SUPPORTED = 'not-supported'
export const DEVICE_STATE_BLOCKED = 'blocked'
export const DEVICE_STATE_IN_USE = 'in-use'
export const DEVICE_STATE_OFF = 'off'
export const DEVICE_STATE_PLAYABLE = 'playable'
export const DEVICE_STATE_SENDABLE = 'sendable'

export type DeviceState =
    | typeof DEVICE_STATE_LOADING
    | typeof DEVICE_STATE_PENDING
    | typeof DEVICE_STATE_ERROR
    | typeof DEVICE_STATE_GRANTED
    | typeof DEVICE_STATE_NOT_FOUND
    | typeof DEVICE_STATE_NOT_SUPPORTED
    | typeof DEVICE_STATE_BLOCKED
    | typeof DEVICE_STATE_IN_USE
    | typeof DEVICE_STATE_OFF
    | typeof DEVICE_STATE_PLAYABLE
    | typeof DEVICE_STATE_SENDABLE

// Unwrap the event payload, we haven't had problems with this yet, and i'm doing a TS strict mode migration
type DailyEvent<T extends Parameters<DailyCall['on']>[0]> = (event: DailyEventObject<T>) => void

export const useDevices = (callObject: DailyCall) => {
    const [deviceState, setDeviceState] = useState<DeviceState>(DEVICE_STATE_LOADING)
    const [currentDevices, setCurrentDevices] = useState<{
        camera?: MediaDeviceInfo
        mic?: MediaDeviceInfo
        speaker?: MediaDeviceInfo
    }>()

    const [cams, setCams] = useState<MediaDeviceInfo[]>([])
    const [mics, setMics] = useState<MediaDeviceInfo[]>([])
    const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([])

    const [camError, setCamError] = useState<DeviceState>()
    const [micError, setMicError] = useState<DeviceState>()

    const updateDeviceState = async () => {
        if (
            typeof navigator?.mediaDevices?.getUserMedia === 'undefined' ||
            typeof navigator?.mediaDevices?.enumerateDevices === 'undefined'
        ) {
            setDeviceState(DEVICE_STATE_NOT_SUPPORTED)
            return
        }

        try {
            const {devices} = await callObject.enumerateDevices()

            const {camera, mic, speaker} = await callObject.getInputDevices()

            const [defaultCam, ...videoDevices] = devices.filter(d => d.kind === 'videoinput' && d.deviceId !== '')
            setCams([defaultCam, ...videoDevices.sort((a, b) => sortByKey(a, b, 'label', false))].filter(Boolean))
            const [defaultMic, ...micDevices] = devices.filter(d => d.kind === 'audioinput' && d.deviceId !== '')
            setMics([defaultMic, ...micDevices.sort((a, b) => sortByKey(a, b, 'label', false))].filter(Boolean))
            const [defaultSpeaker, ...speakerDevices] = devices.filter(
                d => d.kind === 'audiooutput' && d.deviceId !== '',
            )
            setSpeakers(
                [defaultSpeaker, ...speakerDevices.sort((a, b) => sortByKey(a, b, 'label', false))].filter(Boolean),
            )

            setCurrentDevices({
                camera: Object.keys(camera) ? (camera as any) : undefined,
                mic: Object.keys(mic) ? (mic as any) : undefined,
                speaker: Object.keys(speaker) ? (speaker as any) : undefined,
            })
        } catch (e) {
            setDeviceState(DEVICE_STATE_NOT_SUPPORTED)
        }
    }

    const updateDeviceErrors = useCallback(() => {
        if (!callObject) return
        const {tracks} = callObject.participants().local

        if (tracks.video?.blocked?.byPermissions) {
            setCamError(DEVICE_STATE_BLOCKED)
        } else if (tracks.video?.blocked?.byDeviceMissing) {
            setCamError(DEVICE_STATE_NOT_FOUND)
        } else if (tracks.video?.blocked?.byDeviceInUse) {
            setCamError(DEVICE_STATE_IN_USE)
        }

        if (
            [DEVICE_STATE_LOADING, DEVICE_STATE_OFF, DEVICE_STATE_PLAYABLE, DEVICE_STATE_SENDABLE].includes(
                tracks.video.state,
            )
        ) {
            setCamError(undefined)
        }

        if (tracks.audio?.blocked?.byPermissions) {
            setMicError(DEVICE_STATE_BLOCKED)
        } else if (tracks.audio?.blocked?.byDeviceMissing) {
            setMicError(DEVICE_STATE_NOT_FOUND)
        } else if (tracks.audio?.blocked?.byDeviceInUse) {
            setMicError(DEVICE_STATE_IN_USE)
        }

        if (
            [DEVICE_STATE_LOADING, DEVICE_STATE_OFF, DEVICE_STATE_PLAYABLE, DEVICE_STATE_SENDABLE].includes(
                tracks.audio.state,
            )
        ) {
            setMicError(undefined)
        }
    }, [callObject])

    const handleParticipantUpdated = useCallback<DailyEvent<'participant-updated'>>(
        ({participant}) => {
            if (!callObject || !participant.local) return

            setDeviceState(prevState => {
                if (prevState === DEVICE_STATE_NOT_SUPPORTED) return prevState
                switch (participant?.tracks.video.state) {
                    case DEVICE_STATE_BLOCKED:
                        updateDeviceErrors()
                        return DEVICE_STATE_ERROR
                    case DEVICE_STATE_OFF:
                    case DEVICE_STATE_PLAYABLE:
                        if (prevState === DEVICE_STATE_GRANTED) {
                            return prevState
                        }
                        updateDeviceState()
                        return DEVICE_STATE_GRANTED
                    default:
                        return prevState
                }
            })
        },
        [callObject, updateDeviceState, updateDeviceErrors],
    )

    useEffect(() => {
        if (!callObject) return

        /**
            If the user is slow to allow access, we'll update the device state
            so our app can show a prompt requesting access
        */
        let pendingAccessTimeout: ReturnType<typeof setTimeout>

        const handleJoiningMeeting = () => {
            pendingAccessTimeout = setTimeout(() => {
                setDeviceState(DEVICE_STATE_PENDING)
            }, 2000)
        }

        const handleJoinedMeeting = () => {
            if (pendingAccessTimeout) clearTimeout(pendingAccessTimeout)
            // Note: setOutputDevice() is not honored before join() so we must enumerate again
            updateDeviceState()
        }

        callObject.on('joining-meeting', handleJoiningMeeting)
        callObject.on('joined-meeting', handleJoinedMeeting)
        callObject.on('participant-updated', event => handleParticipantUpdated(event!))
        return () => {
            clearTimeout(pendingAccessTimeout)
            callObject.off('joining-meeting', handleJoiningMeeting)
            callObject.off('joined-meeting', handleJoinedMeeting)
            callObject.off('participant-updated', event => handleParticipantUpdated(event!))
        }
    }, [callObject, handleParticipantUpdated, updateDeviceState])

    const setCamDevice = useCallback(
        async (newCam: MediaDeviceInfo, useLocalStorage = true) => {
            if (!callObject || newCam.deviceId === currentDevices?.camera?.deviceId) {
                return
            }

            if (useLocalStorage) {
                localStorage.setItem('defaultCamId', newCam.deviceId)
            }

            await callObject.setInputDevicesAsync({
                videoDeviceId: newCam.deviceId,
            })

            setCurrentDevices(prev => ({...prev!, camera: newCam}))
        },
        [callObject, currentDevices],
    )

    const setMicDevice = useCallback(
        async (newMic: MediaDeviceInfo, useLocalStorage = true) => {
            if (!callObject || newMic.deviceId === currentDevices?.mic?.deviceId) {
                return
            }

            if (useLocalStorage) {
                localStorage.setItem('defaultMicId', newMic.deviceId)
            }

            await callObject.setInputDevicesAsync({
                audioDeviceId: newMic.deviceId,
            })

            setCurrentDevices(prev => ({...prev!, mic: newMic}))
        },
        [callObject, currentDevices],
    )

    const setSpeakersDevice = useCallback(
        async (newSpeakers: MediaDeviceInfo, useLocalStorage = true) => {
            if (!callObject || newSpeakers.deviceId === currentDevices?.speaker?.deviceId) {
                return
            }

            if (useLocalStorage) {
                localStorage.setItem('defaultSpeakersId', newSpeakers.deviceId)
            }

            callObject.setOutputDevice({
                outputDeviceId: newSpeakers.deviceId,
            })

            setCurrentDevices(prev => ({...prev!, speaker: newSpeakers}))
        },
        [callObject, currentDevices],
    )

    useEffect(() => {
        if (!callObject) return

        const handleCameraError = ({errorMsg: {errorMsg, audioOk, videoOk}, error}: DailyEventObjectCameraError) => {
            switch (error?.type) {
                case 'cam-in-use':
                    setDeviceState(DEVICE_STATE_ERROR)
                    setCamError(DEVICE_STATE_IN_USE)
                    break
                case 'mic-in-use':
                    setDeviceState(DEVICE_STATE_ERROR)
                    setMicError(DEVICE_STATE_IN_USE)
                    break
                case 'cam-mic-in-use':
                    setDeviceState(DEVICE_STATE_ERROR)
                    setCamError(DEVICE_STATE_IN_USE)
                    setMicError(DEVICE_STATE_IN_USE)
                    break
                default:
                    switch (errorMsg) {
                        case 'devices error':
                            setDeviceState(DEVICE_STATE_ERROR)
                            setCamError(videoOk ? undefined : DEVICE_STATE_NOT_FOUND)
                            setMicError(audioOk ? undefined : DEVICE_STATE_NOT_FOUND)
                            break
                        case 'not allowed':
                            setDeviceState(DEVICE_STATE_ERROR)
                            updateDeviceErrors()
                            break
                        default:
                            break
                    }
                    break
            }
        }

        const handleError: DailyEvent<'error'> = ({errorMsg}) => {
            switch (errorMsg) {
                case 'not allowed':
                    setDeviceState(DEVICE_STATE_ERROR)
                    updateDeviceErrors()
                    break
                default:
                    break
            }
        }

        const handleStartedCamera = () => {
            updateDeviceErrors()
        }

        callObject.on('camera-error', error => handleCameraError(error!))
        callObject.on('error', error => handleError(error!))
        callObject.on('started-camera', handleStartedCamera)
        return () => {
            callObject.off('camera-error', error => handleCameraError(error!))
            callObject.off('error', error => handleError(error!))
            callObject.off('started-camera', handleStartedCamera)
        }
    }, [callObject, updateDeviceErrors])

    return {
        cams,
        mics,
        speakers,
        camError,
        micError,
        currentDevices,
        deviceState,
        setCamDevice,
        setMicDevice,
        setSpeakersDevice,
        updateDeviceState,
    }
}

export default useDevices
