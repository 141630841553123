import cn from 'classnames'
import {RadioGroup} from '@headlessui/react'
import {useState} from 'react'
import {useAmplifierSocket} from '~/contexts/AmplifierSocketProvider'
import {useAppSelector} from '~/store'
import AmplifierModal from '~/widgets/AmplifierModal'
import ChannelSelect from './ChannelSelect/ChannelSelect'
import ParticipantSelect from './ParticipantSelect/ParticipantSelect'

interface MoveParticipantProps {
    isOpen: boolean
    onClose: () => void
    defaultParticipant: string
}

const moveToOptions = [{name: 'Home'}, {name: 'Breakout'}, {name: 'Participant'}]

export default function MoveParticipant({isOpen, onClose, defaultParticipant}: MoveParticipantProps) {
    const [mem, setMem] = useState(moveToOptions[1])

    const {allParticipants, channels} = useAppSelector(({amplifierSocket}) => {
        return {
            allParticipants: amplifierSocket.allParticipants,
            channels: amplifierSocket.channels,
        }
    })

    const [participantToMove, setParticipantToMove]: [string, any] = useState(defaultParticipant)
    const [channelToMoveTo, setChannelToMoveTo]: [number, any] = useState(0)
    const [participantToMoveTo, setParticipantToMoveTo]: [string, any] = useState('')

    const {moveChannel} = useAmplifierSocket()

    const moveParticipant = () => {
        if (mem.name === 'Home') {
            moveChannel(participantToMove, 0)
        } else if (mem.name === 'Breakout') {
            moveChannel(participantToMove, channelToMoveTo)
        } else if (mem.name === 'Participant') {
            let otherParticipantsChannel = Object.values(channels).findIndex(c => c.includes(participantToMoveTo))

            if (otherParticipantsChannel !== -1) moveChannel(participantToMove, otherParticipantsChannel)
        }

        onClose()
    }

    return (
        <AmplifierModal showClose={false} size="small" theme="dark" isOpen={isOpen} onClose={onClose}>
            <h4 className="text-white font-semibold px-4">Move Participant</h4>

            <div className="w-full p-4 mt-6">
                <ParticipantSelect
                    label="Participant to move"
                    defaultId={defaultParticipant}
                    participants={allParticipants}
                    onChange={setParticipantToMove}
                ></ParticipantSelect>
            </div>

            <div className="w-full p-4">
                <span className="block text-sm font-medium text-gray-500">Move to</span>
                <RadioGroup value={mem} onChange={setMem} className="mt-2 w-full">
                    <RadioGroup.Label className="sr-only"> Select a move method </RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3">
                        {moveToOptions.map(option => (
                            <RadioGroup.Option
                                key={option.name}
                                value={option}
                                className={({active, checked}) =>
                                    cn(
                                        active ? 'border-2 border-primary-500 text-white' : '',
                                        checked
                                            ? 'bg-neutral border-transparent text-white hover:bg-primary-700 border-2 border-primary'
                                            : 'bg-neutral border-gray-200 text-gray-400',
                                        'border bg-neutral  rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium cursor-pointer border-gray-800 sm:flex-1',
                                    )
                                }
                            >
                                <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>

            <div className={cn('w-full p-4 mb-6 transition-all ', mem.name == 'Home' ? 'h-0' : 'h-24')}>
                {mem.name === 'Participant' && (
                    <ParticipantSelect
                        placeholder="Search for participant..."
                        label="Same Breakout As"
                        participants={allParticipants}
                        defaultId={undefined}
                        onChange={setParticipantToMoveTo}
                    />
                )}

                {mem.name === 'Breakout' && (
                    <ChannelSelect
                        onChange={setChannelToMoveTo}
                        label="Select Breakout"
                        channels={channels}
                    ></ChannelSelect>
                )}
            </div>

            <div className="flex items-end justify-end w-full p-4">
                <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-primary px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-neutral"
                    onClick={moveParticipant}
                >
                    Move Participant
                </button>
            </div>
        </AmplifierModal>
    )
}
