import {useEffect, useState} from 'react'
import {PlanViewModel} from 'shared-types/view-models'
import {getPlans} from '~/api/billingApi'
import Loading from '~/components/common/Loading'
import './SelectPlan.scss'

import {CheckCircleFill} from '@styled-icons/bootstrap/CheckCircleFill'
import {motion} from 'framer-motion'
import {useAppDispatch, useAppSelector} from '~/store'
import actions from '~/store/identityState'
import {useNavigate} from 'react-router-dom'
import isArray from 'lodash/isArray'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export default function SelectPlan() {
    const [plans, setPlans]: [PlanViewModel[], any] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        const fetchPlans = async () => {
            let response = await getPlans()

            if (isArray(response)) {
                setLoading(false)
                setPlans(response)
            } else if (response.type === 'error' || response.type === 'internal_server_error') {
                setLoading(false)
                setError(response.message)
            }
        }

        fetchPlans()
    }, [])

    const tenant = useAppSelector(state => state.identityState.tenant!)

    const handleSelect = async (planId: string) => {
        setLoading(true)

        const billing = await dispatch(actions.StagePlan(planId, tenant.slug))

        setLoading(false)

        if (typeof billing === 'string') {
            setError(billing)
            return
        }

        // navigate to next step
        navigate('/start/payment')
    }

    useEffect(() => {
        console.log(plans)
    }, [plans])

    if (loading) {
        return (
            <div className="ob__selectplan__wrapper">
                <div className="ob__loading__wrapoer">
                    <Loading />
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="ob__selectplan__wrapper">
                <div className="ob__selectplan__error">
                    <h1>{error}</h1>
                    <p>We're sorry there was an error, please try again</p>
                    <button
                        onClick={() => setError('')}
                        className="btn btn-primary bg-white border-white my-4 hover:bg-white hover:border-white"
                    >
                        Try Again
                    </button>
                </div>
            </div>
        )
    }

    return (
        <motion.div layout className="ob__selectplan__wrapper">
            <div className="ob__selectplan__container">
                <motion.div
                    initial={{opacity: 0, scale: 0.8}}
                    animate={{opacity: 1, scale: 1}}
                    transition={{
                        duration: 0.5,
                        delay: 0,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}
                    className="ob__selectplan__header"
                >
                    <h1 className="ob__selectplan__title">Select a plan</h1>
                    <p className="ob__selectplan__subtitle">Choose a plan that fits your needs</p>
                </motion.div>

                <motion.div
                    initial={{opacity: 0, scale: 0.8}}
                    animate={{opacity: 1, scale: 1}}
                    transition={{
                        duration: 0.5,
                        delay: 0,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}
                    className="ob__selectplan__plans"
                >
                    {plans.map(plan => (
                        <div className="ob__selectplan__plan">
                            <h3 id={plan.id} className="text-lg text-white font-semibold leading-8">
                                {plan.name}
                            </h3>

                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-white">
                                    {formatter.format(plan.price / 100)}
                                </span>
                                <span className="text-sm font-semibold leading-6 text-gray-200">/month</span>
                            </p>

                            <button
                                type="submit"
                                onClick={() => handleSelect(plan.id)}
                                className="btn my-8 border-white bg-white btn-primary"
                            >
                                Select
                            </button>

                            <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-400">
                                {plan.lineItems.map(feature => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckCircleFill
                                            className="h-6 w-5 flex-none text-primary"
                                            aria-hidden="true"
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </motion.div>
            </div>
        </motion.div>
    )
}
